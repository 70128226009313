import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

const Tooltip = ({ children, place, ...props }) => (
  <ReactTooltip
    type="light"
    className="tooltip"
    effect="solid"
    place={place || 'top'}
    {...props}
  >
    {children}
  </ReactTooltip>
);

const StyledTooltip = styled(Tooltip)`
  max-width: 15rem;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  line-height: 1rem;
  padding: 0.75rem;
  z-index: 1030;

  &.show {
    opacity: 1 !important;
  }

  .tooltip-title {
    color: ${(props) => props.theme.color_scheme_font};
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .tooltip-content {
    color: ${(props) => props.theme.color_scheme_font};
    font-weight: 400;
  }

  @media screen and (min-width: 768px) {
    max-width: 18.75rem;
  }
`;

export default StyledTooltip;
