import styled from 'styled-components';

const DatePiker = styled.div`
  .react-datepicker {
    font-family: 'Effra';
    border: none;
    box-shadow: 0px 4px 8px rgba(123, 121, 122, 0.03);
    border-radius: 8px;
    width: 100%;

    &__header {
      background-color: transparent;
      border: none;
      border-radius: initial;
      padding: 0;
    }

    &__day-names {
      padding: 0 4px;
    }

    &__day-name {
      font-size: 0.625rem;
      text-transform: uppercase;
      width: 13%;
      margin: 0.6429%;

      @media screen and (min-width: 768px) {
        font-size: 0.875rem;
        width: 11.5%;
      }
    }

    &__month {
      margin: 0;
      padding: 4px;

      &-container {
        width: 100%;
      }
    }

    &__day {
      font-size: 0.875rem;
      outline: none;
      width: 13%;
      position: relative;
      margin: 0.6429%;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }

      & > span {
        position: absolute;
      }

      &:hover {
        border-radius: 50%;
      }

      &--selected {
        border-radius: 50%;
        background-color: ${(props) => props.theme.color_scheme_button};
        color: #fff;
        font-weight: 400;
      }

      @media screen and (min-width: 768px) {
        font-size: 1rem;
        width: 11.5%;
      }
    }
  }

  .datepicker-toolbar {
    height: 2.875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem auto;

    @media screen and (max-width: 767px) {
      margin: 1.5rem auto;
    }

    @media screen and (max-width: 568px) {
      margin: 0.75rem auto;
    }
  }

  .datepicker-current-month {
    font-size: 2.625rem;
    font-weight: 500;
    line-height: 2.625rem;

    @media screen and (max-width: 568px) {
      font-size: 2rem;
      line-height: 2rem;
    }
  }

  .datepicker-prev-month,
  .datepicker-next-month {
    height: 1.125rem;
    cursor: pointer;
    margin: 0 1rem;
    stroke: ${(props) => props.theme.color_scheme_dark};
    width: auto;

    @media screen and (min-width: 768px) {
      margin: 0 2.5rem;
    }
  }

  .datepicker-next-month {
    transform: rotate(180deg);
  }
`;

export default DatePiker;
