/* eslint-disable no-template-curly-in-string */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import Loading from 'components/Loading';
import Button from 'components/Button';
import Chat from 'components/ChatNRGVariation';
import EnvironmentItem from 'components/EnvironmentItemNRGVariation';
import FixedBottomBar from 'components/FixedBottomBar';
import SavingChart from 'components/SavingChartNRGVariation';
import Expand from 'components/ExpandNRGVariation';
import { ReactComponent as CarbonDioxideIcon } from 'assets/images/icon-carbon-dioxide-nrg.svg';
import { ReactComponent as LeafIcon } from 'assets/images/icon-leaf-nrg.svg';
import { ReactComponent as StationIcon } from 'assets/images/icon-station-nrg.svg';
import {
  calculateData,
  setUserData,
  saveSession,
  navigateTo,
  trackForwardProgress,
  trackFacebookPixel,
  trackGoogleLead,
} from 'store/actions';
import { engineSolarFindBuilding } from 'common/api.mock';
import {
  formatNumber,
  extractValuesFromPlace,
  checkIfSunroofIsLoaded,
  generateSolarMaps,
  loadGoogleMaps,
  generateID,
} from 'common/utils';
import { prevLocation } from 'common/history';
import { MarvinImage } from '@rarebearsoft/marvin';
import { parseInt } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';
import { getLocalStorageItem } from 'common/localStorage';
import { VARIATION_OPTIONS } from 'pages/constants';

import { geocodeByAddress } from 'react-places-autocomplete';
import StyledResult3 from './styled';

const ZOOM = 12;

class Result3 extends Component {
  constructor(props) {
    super(props);

    const variation = getLocalStorageItem('variation');
    this.state = {
      loading: true,
      squareFeet: null,
      sunshineHours: null,
      centerLat: null,
      centerLng: null,
      error: null,
      isScrolled: false,
      solarCount: 0,
      zoom: ZOOM,
      existingSolarCount: 0,
      variation: VARIATION_OPTIONS[variation],
    };

    this.divRef = React.createRef();

    const { actions, user } = props;

    actions.trackFacebookPixel('result3', user.variation);
    actions.trackGoogleLead('result3', user.variation);
  }

  componentDidMount() {
    this.initData();
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('mouseleave', this.handleMouseLeave);

    (() => {
      const a = String(Math.random()) * 10000000000000;

      new Image().src = `https://pubads.g.doubleclick.net/activity;xsp=4715391;ord=${a}?`;
    })();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('mouseleave', this.handleMouseLeave);
  }

  initData = async () => {
    const { theme, user, actions } = this.props;

    const getBuilding = async (lat, lng) => {
      if (!theme.is_sunroof_enabled) {
        this.setState({ loading: false, error: 'Skip sunroof' });
        return;
      }

      const { data: building } = await engineSolarFindBuilding(
        { latitude: lat, longitude: lng },
        theme.google_api_gateway_key
      );

      if (!building) {
        this.setState({
          loading: false,
          error: 'Requested location is not supported by Google Sunroof API.',
        });
      } else {
        const squareFeet = formatNumber(
          Math.round(
            get(building, 'solarPotential.maxArrayAreaMeters2', 0) * 10.764
          )
        );
        const centerLat = get(building, 'center.latitude', 0);
        const centerLng = get(building, 'center.longitude', 0);
        const sunshineHours = formatNumber(
          Math.round(get(building, 'solarPotential.maxSunshineHoursPerYear', 0))
        );
        await checkIfSunroofIsLoaded(centerLat, centerLng);
        this.setState({
          loading: false,
          squareFeet,
          centerLat,
          centerLng,
          sunshineHours,
        });
        if (centerLat && centerLng) this.countSoloar();
      }
    };

    if (user.is_instant_estimate) {
      if (!user.avg_bill || user.avg_bill <= 0) {
        await actions.navigateTo('/calculate2');
      }

      await actions.calculateData();

      const getLatLongFromAddress = async () => {
        await geocodeByAddress(`${user.address}, ${user.city}, ${user.state}`)
          .then(async (results) => {
            const values = extractValuesFromPlace(results[0]);
            await actions.setUserData({ ...values });
            await getBuilding(
              values.rooftop_latitude,
              values.rooftop_longitude
            );
          })
          .catch((error) => console.error('Error', error));
      };

      if (user.rooftop_latitude && user.rooftop_longitude) {
        await getBuilding(user.rooftop_latitude, user.rooftop_longitude);
      } else if (!window.google) await loadGoogleMaps(getLatLongFromAddress);
      else await getLatLongFromAddress();
    } else {
      await getBuilding(user.rooftop_latitude, user.rooftop_longitude);
    }
  };

  handleNext = async () => {
    const { actions } = this.props;
    actions.setUserData({
      step: '/qualify/start',
      terms_consent: 1,
    });
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo('/qualify/start');
  };

  scrollToBottom = () => {
    this.divRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  handleScroll = (e) => {
    const { isScrolled } = this.state;
    if (isScrolled) return;
    if (e.target.scrollTop > 0 || e.target === window.document) {
      this.setState({ isScrolled: true });
    }
  };

  imageLoaded = (image, index, delX, delY) => {
    const deltaX = delX * image.getWidth();
    const deltaY = delY * image.getHeight();
    let count = 0;
    let startX = 0;
    let startY = 0;
    let endX = 0;
    let endY = 0;
    switch (index) {
      case 0:
        startX = image.getWidth() / 2 + deltaX;
        startY = image.getHeight() / 2 + deltaY;
        endX = image.getWidth();
        endY = image.getHeight();
        break;
      case 1:
        startX = 0;
        startY = image.getHeight() / 2 + deltaY;
        endX = image.getWidth() / 2 + deltaX;
        endY = image.getHeight();
        break;
      case 2:
        startX = image.getWidth() / 2 + deltaX;
        startY = 0;
        endX = image.getWidth();
        endY = image.getHeight() / 2 + deltaY;
        break;
      default:
        startX = 0;
        startY = 0;
        endX = image.getWidth() / 2 + deltaX;
        endY = image.getHeight() / 2 + deltaY;
        break;
    }

    for (let y = parseInt(startY); y < parseInt(endY); y += 3) {
      for (let x = parseInt(startX); x < parseInt(endX); x += 3) {
        const alpha = image.getAlphaComponent(x, y);
        if (alpha !== 0) {
          count++;
        }
      }
    }
    this.setState((prevState) => {
      return {
        solarCount: prevState.solarCount + count,
        existingSolarCount: prevState.existingSolarCount + 1,
      };
    });
  };

  countSoloar = async () => {
    const { centerLat, centerLng, zoom } = this.state;
    const image = [[], [], [], []];

    const proxyurl = 'https://cors-anywhere.demand-iq.com/?';
    const { images, deltaX, deltaY } = generateSolarMaps(
      centerLat,
      centerLng,
      zoom
    );
    images.map((imageUrl, index) => {
      image[index] = new MarvinImage();
      image[index].load(`${proxyurl}${imageUrl}`, () =>
        this.imageLoaded(image[index], index, deltaX, deltaY)
      );
      return false;
    });
  };

  render() {
    const { user, theme, saving, t } = this.props;

    const { loading, sunshineHours, squareFeet, isScrolled, variation } =
      this.state;

    const data = { ...user };

    const { is_instant_estimate } = data;

    const language = getLocalStorageItem('i18nextLng');
    const avatar = /^es\b/.test(language)
      ? theme.spanish_ai_avatar
      : theme.ai_avatar;

    const {
      location: { pathname },
    } = this.props;

    if (is_instant_estimate && loading && !prevLocation) {
      return <Loading />;
    }

    return (
      <>
        <Helmet>
          <script
            type="text/javascript"
            src="//storage.googleapis.com/instapage-assets/conversion-external.js"
          ></script>
        </Helmet>

        <variation.header />

        <div className="main-container" onMouseLeave={this.handleMouseLeave}>
          <div
            className="main"
            style={{
              height: !loading ? 'initial' : null,
              position: 'relative',
            }}
            onScroll={this.handleScroll}
          >
            {loading ? (
              <div className="content">
                <div className="form">
                  <div className="form-content">
                    <Chat
                      id={generateID('div-loading', pathname)}
                      mode="loading"
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <StyledResult3 className="content result-3-page">
                <div className="form">
                  <div className="introduction-section">
                    <div
                      id={generateID('div-avatar', pathname)}
                      className="avatar"
                      style={{
                        backgroundImage: `url(${avatar})`,
                      }}
                    >
                      <span className="avatar-status"></span>
                    </div>

                    <div
                      id={generateID('div-introduction', pathname)}
                      className="introduction-text"
                    >
                      {t(
                        'Here is your estimate for {{address}}, {{city}}, {{state}} {{zipcode}}',
                        {
                          address: data.address,
                          city: data.city,
                          state: data.state,
                          zipcode: data.zip_code,
                        }
                      )}
                    </div>
                  </div>

                  <div
                    id={generateID('div-estimated-cost-per-month', pathname)}
                    className="white-card"
                  >
                    <div className="text-title mb-8">
                      {t('Estimated Cost Per Month')}
                      <sup>1</sup>
                    </div>
                    <div className="text-value mb-8">
                      {theme.price_system_display || !theme.is_premier_account
                        ? `$${formatNumber(
                            Math.round(data.monthly_payment_min)
                          )}-${formatNumber(
                            Math.round(data.monthly_payment_max)
                          )}`
                        : data.monthly_payment_best_guess
                        ? `${formatNumber(
                            Math.round(data.monthly_payment_best_guess)
                          )}`
                        : `$${formatNumber(
                            Math.round(data.monthly_payment_min)
                          )}-${formatNumber(
                            Math.round(data.monthly_payment_max)
                          )}`}
                      <sup>2</sup>
                    </div>
                    <div className="text-description">
                      <sup>1. </sup>
                      Estimate only, based on average utility bill; actual
                      monthly savings may vary.
                      <br />
                      <sup>2.</sup>
                      {t(
                        '{{product_apr}}% APR financing for {{product_term}} years on approved credit with zero down.',
                        {
                          product_apr: theme.product_apr,
                          product_term: theme.product_term,
                        }
                      )}
                    </div>
                  </div>

                  {sunshineHours && (
                    <div
                      id={generateID('div-sunshine-hours', pathname)}
                      className="white-card"
                    >
                      <div className="text-title mb-8">
                        {t('Hours of Usable Sunlight Per Year')}
                      </div>
                      <div className="text-value mb-8">
                        {sunshineHours}
                        <small>Hours</small>
                      </div>
                      <div className="text-description">
                        <Trans
                          t={t}
                          i18nKey="Based on day-to-day analysis of weather patterns"
                        >
                          Based on day-to-day analysis of weather patterns
                        </Trans>
                      </div>
                    </div>
                  )}

                  <div
                    id={generateID('div-total-incentive', pathname)}
                    className="white-card"
                  >
                    <div className="text-title mb-8">
                      {t('Total Incentive Discounts')}
                      <sup>3</sup>
                    </div>
                    <div className="text-value mb-8">
                      ${`${formatNumber(data.incentive)}`}
                    </div>
                    <div className="text-title">
                      {t('Only while available!')}
                    </div>
                  </div>
                  <div
                    style={{
                      color: '#fff',
                      textAlign: 'center',
                      paddingTop: '15px',
                    }}
                  >
                    <sup>3.</sup>Discount based on estimated system size. actual
                    amount may vary.
                  </div>
                  <div
                    id={generateID('div-25-year-savings', pathname)}
                    className="white-card"
                  >
                    <div className="text-title mb-8">
                      {t('Estimated 25 Year Savings')}
                    </div>
                    <div className="text-value mb-16">
                      ${`${formatNumber(data.savings)}`}
                    </div>
                    <div className="chart-container">
                      <div className="chart-area">
                        <SavingChart
                          value1={data.avg_bill}
                          value2={formatNumber(
                            Math.round(data.avg_bill * 1.03 ** 10)
                          )}
                          value3={formatNumber(
                            Math.round(data.avg_bill * 1.03 ** 25)
                          )}
                        />
                      </div>
                    </div>

                    <div className="divider" />

                    <Expand
                      id={generateID('div-utility-rates', pathname)}
                      title={t('Why do utility rates go up?')}
                    >
                      {t(
                        'Utility rates in this market have historically increased by {{escalation}}% each year. This is based on a variety of market factors including inflation.',
                        {
                          escalation: theme.escalation || 3,
                        }
                      )}
                    </Expand>
                  </div>

                  {squareFeet && (
                    <div
                      id={generateID('div-square-feet', pathname)}
                      className="white-card"
                    >
                      <div className="text-title mb-8">
                        {t('Square Footage Available for Solar Panels')}
                      </div>
                      <div className="text-value mb-8">
                        {squareFeet}
                        <small>Sq. Ft.</small>
                      </div>
                      <div className="text-description">
                        <Trans
                          t={t}
                          i18nKey="Based on 3D modeling of your roof and nearby trees"
                        >
                          Based on 3D modeling of your roof and nearby trees
                        </Trans>
                      </div>
                    </div>
                  )}

                  <div
                    id={generateID('div-system-size', pathname)}
                    className="white-card"
                  >
                    <div className="text-title mb-8">
                      {t('Recommended System Size')}
                    </div>
                    <div className="text-value mb-8">
                      {theme.price_system_display ||
                      !theme.is_premier_account ? (
                        `${data.install_size_min}-${data.install_size_max}`
                      ) : (
                        <>{data.install_size}</>
                      )}
                      <small>kW</small>
                    </div>
                    <div className="text-description">
                      {t('{{panels_min}}-{{panels_max}} Panels', {
                        panels_min: data.panels_min,
                        panels_max: data.panels_max,
                      })}
                    </div>
                  </div>

                  <div
                    id={generateID('div-home-value', pathname)}
                    className="white-card"
                  >
                    <div className="text-title mb-8">
                      {t('Increase in Home Value')}
                    </div>
                    <div className="text-value mb-8">+4.1%</div>
                    <div className="text-description mb-16">
                      {t('According to a recent study from Zillow.')}
                    </div>

                    <div className="divider" />

                    <Expand
                      id={generateID('div-zillow-study', pathname)}
                      title={t('Learn about the Zillow study.')}
                    >
                      {t(
                        'Zillow calculated the solar premium by comparing homes with and without solar-energy systems that were listed for sale and sold from March 1, 2018 to February 28, 2019, controlling for observable attributes of the homes.'
                      )}
                    </Expand>
                  </div>

                  <div
                    id={generateID('div-environmental-impact', pathname)}
                    className="white-card"
                  >
                    <div className="text-title mb-16">
                      {t('Estimated Environmental Impact')}
                      <sub>5</sub>
                    </div>
                    <div className="list-value">
                      <EnvironmentItem
                        icon={<CarbonDioxideIcon />}
                        title={`${data.co2_tons}`}
                        content={t('Tons of CO2 avoided')}
                      />
                      <EnvironmentItem title="=" />
                      <EnvironmentItem
                        icon={<LeafIcon />}
                        title={`${data.tree_seedlings}`}
                        content={t('Trees grown for 10 years')}
                      />
                      <EnvironmentItem title="=" />
                      <EnvironmentItem
                        icon={<StationIcon />}
                        title={`${data.gal_of_gas}`}
                        content={t('Gallons of gas not used')}
                      />
                    </div>
                    <br />
                    <span>
                      <sup>5</sup>Estimated Environmental Impact based on the
                      EPA’s Greenhouse Gases Equivalencies Calculator.
                    </span>
                  </div>

                  <div
                    id={generateID('div-legal', pathname)}
                    className="transparent-card"
                  >
                    <div className="text-disclaimer">
                      <Trans
                        t={t}
                        i18nKey="*Note: Cost and savings estimates will vary based on individual circumstances. Factors such as tree cover, shade, roof materials and conditions, roof space and angle, usage patterns, weather patterns, direct sunlight hours, etc. may affect system costs and savings."
                      >
                        *Note: Cost and savings estimates will vary based on
                        individual circumstances. Factors such as tree cover,
                        shade, roof materials and conditions, roof space and
                        angle, usage patterns, weather patterns, direct sunlight
                        hours, etc. may affect system costs and savings.
                      </Trans>
                    </div>
                  </div>
                </div>
              </StyledResult3>
            )}

            {!loading && isScrolled && (
              <FixedBottomBar id={generateID('div-fixed-bottom-bar', pathname)}>
                <Button
                  id={generateID('btn-request-final-quote', pathname)}
                  full
                  onClick={this.handleNext}
                  className="pulse-button"
                  disabled={saving}
                  style={{
                    fontFamily: 'Effra',
                  }}
                >
                  {t('Request Final Quote')}
                </Button>
              </FixedBottomBar>
            )}
          </div>
        </div>

        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
              <iframe
              title="instapage-code"
              class="instapage-noscrap"
              src="//d3mwhxgzltpnyp.cloudfront.net/local-storage/index.html"
              name="instapage-local-storage"
              height="0px"
              width="0px"
              onLoad="(function() { InstapageExternalConversion(${theme.instapage_id}); })();"
              style="display: block;"
            ></iframe>
          `,
          }}
        ></div>
      </>
    );
  }
}

const mapStateToProps = ({ user: { data, saving }, ui: { theme } }) => ({
  user: data,
  theme,
  saving,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      calculateData,
      setUserData,
      saveSession,
      navigateTo,
      trackForwardProgress,
      trackFacebookPixel,
      trackGoogleLead,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Result3)
);
