import styled from 'styled-components';

const Footer = styled.div`
  background-color: #3d5170;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5rem;

  svg {
    width: 120px;
    height: 60px;
  }

  @media screen and (min-width: 550px) {
    padding: 0 2.5rem 0 1.75rem;

    svg {
      width: 160px;
      height: 60px;
    }
  }

  .powered-by {
    font-size: 0.625rem;
    font-weight: 300;
    color: #fff;
    font-family: Poppins, Roboto, Arial, Helvetica, sans-serif;

    @media screen and (min-width: 550px) {
      font-size: 0.75rem;
      letter-spacing: 0.02rem;
    }
  }
`;

export default Footer;
