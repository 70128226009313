import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import DefaultLayout from 'components/DefaultLayout';
import Button from 'components/Button';
import TextInput from 'components/Forms/TextInputNRGVariation';
import Chat from 'components/ChatNRGVariation';
import BackButton from 'components/BackButtonNRGVariation';
import { setUserData, saveSession, trackForwardProgress } from 'store/actions';
import { generateID, getNames } from 'common/utils';
import { withTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { verifyPhoneNumber, verifyEmail } from 'common/api.mock';
import FixedBottomBar from 'components/FixedBottomBar';
import StyledGetNotified1 from './styled';

const formSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
  phone: Yup.string()
    .required('Required')
    .matches(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/),
});

const CustomInput = ({ ...props }) => (
  <InputMask {...props}>{() => <TextInput {...props} />}</InputMask>
);

class GetNotified1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loaded: false,
      errors: null,
      jornaya_lead_id: window.jornaya_leadid_token,
      step: 0,
    };
  }

  handleBack = () => {
    const { history } = this.props;
    history.replace('/start');
  };

  handleShow = () => {
    this.setState({ step: 1 });
  };

  handleSubmit = async (values, { setFieldError }) => {
    this.setState({ loading: true, errors: null });
    const { email = '', name = '', phone = '' } = values;
    const { first: first_name, last: last_name } = getNames(name);
    const { history, actions, t } = this.props;

    const [phoneRes, emailRes] = await Promise.all([
      verifyPhoneNumber(phone),
      verifyEmail(email),
    ]);

    const errors = [];

    if (!first_name) {
      errors.push(t('Please provide your full name.'));
      setFieldError('name', 'Invalid');
    }
    if (!last_name) {
      errors.push(t('Please provide your last name.'));
      setFieldError('name', 'Invalid');
    }

    if (!phoneRes || !phoneRes.success) {
      errors.push(
        t('That number does not seem to be a valid mobile phone number.')
      );
      setFieldError('phone', 'Invalid');
    }
    if (!emailRes || !emailRes.success) {
      errors.push(t('That email does not seem to be a valid email.'));
      setFieldError('email', 'Invalid');
    }

    if (errors.length > 0) {
      this.setState({ loading: false, loaded: true, errors });
    } else {
      const { jornaya_lead_id } = this.state;
      actions.setUserData({
        first_name,
        last_name,
        email,
        phone,
        step: '/start',
        jornaya_lead_id,
        trustedform_cert_url: window.trustedForm.id
          ? `https://cert.trustedform.com/${window.trustedForm.id}`
          : '',
      });
      await actions.saveSession();
      actions.trackForwardProgress();
      await history.push('/getnotified2');
    }
  };

  render() {
    const { theme, t } = this.props;
    const { loading, loaded, errors, step } = this.state;
    const errorMsg = errors ? errors.join('\n') : '';

    const {
      location: { pathname },
    } = this.props;

    return (
      <DefaultLayout showFooter={false}>
        <Formik
          initialValues={{ name: '', email: '', phone: '' }}
          validationSchema={formSchema}
          onSubmit={this.handleSubmit}
          validateOnMount
        >
          {({ isValid, isSubmitting, submitForm }) => {
            return (
              <>
                <StyledGetNotified1 className="content get-notified-1-page">
                  <Form className="form">
                    <div className="form-content">
                      <Chat
                        id={generateID('chat-not-currently-service', pathname)}
                        mode={loaded || loading ? 'loading' : 'timeout'}
                        loading={loading}
                        onShow={this.handleShow}
                      >
                        {!loaded ? (
                          <div className="mb-16">
                            {t(
                              "We're sorry, we don't currently service your area. Want to get notified when we do?"
                            )}
                          </div>
                        ) : (
                          <div className="mb-16">{errorMsg}</div>
                        )}
                      </Chat>

                      {step > 0 && (
                        <div
                          id={generateID('div-card', pathname)}
                          className="card"
                        >
                          <div className="mb-16">
                            <Field
                              id={generateID('txt-name', pathname)}
                              name="name"
                              type="text"
                              placeholder={t('First and Last Name')}
                              theme={theme}
                              component={TextInput}
                              autoFocus
                              fullWidth
                            />
                          </div>
                          <div className="mb-16">
                            <Field
                              id={generateID('txt-email', pathname)}
                              name="email"
                              type="email"
                              placeholder={t('Email')}
                              theme={theme}
                              component={TextInput}
                              fullWidth
                            />
                          </div>
                          <div className="mb-16">
                            <Field name="phone">
                              {({ field, form }) => (
                                <CustomInput
                                  id={generateID('txt-phone', pathname)}
                                  mask="(999) 999-9999"
                                  type="tel"
                                  placeholder={t('Mobile phone number')}
                                  theme={theme}
                                  value={field.value.phone}
                                  onChange={field.onChange}
                                  field={field}
                                  form={form}
                                  fullWidth
                                  validateOnChange
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                      )}

                      {step > 0 && (
                        <div
                          id={generateID('div-legal', pathname)}
                          className="form-legal"
                        >
                          <label>
                            <input type="hidden" id="leadid_tcpa_disclosure" />
                            {t(
                              'By clicking the “Submit” button below, you authorize NRG, and its sales and installation partners to contact you for marketing purposes at the telephone, email address or mobile number you entered using automated telephone technology including auto-dialers and text messages, even if your telephone or mobile number is currently listed on any state, federal, or corporate “Do Not Call” list, for the purposes of discussing NRG’s home energy solutions. You also authorize NRG to share your contact information and monthly electricity consumption with our sales and installation partners for the purposes of discussing NRG’s home energy solutions. You are not required to give your consent as a condition of purchase.',
                              {
                                company: theme.company_name,
                              }
                            )}
                          </label>
                        </div>
                      )}
                    </div>

                    <div
                      id={generateID('div-actions', pathname)}
                      className="form-actions vertical"
                    >
                      <BackButton
                        id={generateID('btn-back', pathname)}
                        onClick={this.handleBack}
                      />
                    </div>
                  </Form>
                </StyledGetNotified1>
                <FixedBottomBar
                  id={generateID('div-fixed-bottom-bar', pathname)}
                >
                  <Button
                    id={generateID('btn-yes-notify-me-please', pathname)}
                    onClick={submitForm}
                    type="submit"
                    full
                    disabled={!isValid || isSubmitting}
                  >
                    {t('Yes, notify me please!')}
                  </Button>
                </FixedBottomBar>
              </>
            );
          }}
        </Formik>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ ui, user: { data } }) => ({
  user: data,
  theme: ui.theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(GetNotified1)
);
