import { combineReducers } from 'redux';

import ui from './ui';
import user from './user';
import appointment from './appointment';

export default combineReducers({
  ui,
  user,
  appointment,
});
