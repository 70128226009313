import styled from 'styled-components';
import { pxToRem } from 'common/utils';

export const Result2 = styled.div`
  &.content.result-2-page {
    min-height: calc(100% - ${pxToRem(76)});

    @media screen and (max-width: 374px) {
      min-height: calc(100% - 61px);
    }

    @media screen and (min-width: 767px) {
      min-height: calc(100% - ${pxToRem(76)} - 3rem);
    }

    .message {
      line-height: 30px;
    }

    .resend-btn {
      font-size: ${pxToRem(16)};
      color: #3f51b5;
      text-decoration: none;
      margin-top: ${pxToRem(20)};
    }
  }
`;

export const Legal = styled.div`
  &.form-legal {
    font-size: 0.75rem;
    color: #666666;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    margin: 0 1.25rem 1.25rem 1.25rem;
    text-align: justify;

    @media screen and (max-width: 767px) {
      margin: 0 1.25rem 1.25rem 1.25rem;
      font-size: 9px;
      line-height: 14px;
    }

    label {
      margin-bottom: 0;
    }
  }
`;

export const InputWrapper = styled.div`
  &.input-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 480px) {
      justify-content: flex-start;
    }
  }
  span {
    font-size: 16px;
    text-align: center;
    margin: 0 -16px 0 0;
    padding: 0;
    height: auto;
    cursor: pointer;
    color: ${(props) => props.theme.color_label};
  }
`;
