import styled from 'styled-components';

const Meeting1 = styled.div`
  .form-content {
    display: flex;
    flex-direction: column;
  }

  .form-datepicker {
    width: 100%;
    margin-top: 1rem;
    align-self: center;

    @media screen and (min-width: 768px) {
      margin-top: 0.5rem;
      max-width: 25rem;
    }
  }
`;

export default Meeting1;
