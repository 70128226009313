import {
  GET_APPOINTMENT_TIMES_REQUEST,
  GET_APPOINTMENT_TIMES_SUCCESS,
  GET_APPOINTMENT_TIMES_FAILURE,
  GET_AVAILABLE_CALENDAR_TIMES_REQUEST,
  GET_AVAILABLE_CALENDAR_TIMES_SUCCESS,
  GET_AVAILABLE_CALENDAR_TIMES_FAILURE,
  CHECK_DUPLICATION_REQUEST,
  CHECK_DUPLICATION_SUCCESS,
  CHECK_DUPLICATION_FAILURE,
  APPOINT_CALENDAR_TIME_REQUEST,
  APPOINT_CALENDAR_TIME_SUCCESS,
  APPOINT_CALENDAR_TIME_FAILURE,
} from '../actions/appointment';

const initialState = {
  appointments: [],
  availableTimes: [],
  is_duplicated: false,
  loading: 0,
  is_appointments_loaded: false,
  is_calendar_available_slots_loaded: false,
  validating: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_APPOINTMENT_TIMES_REQUEST:
      return {
        ...state,
        loading: state.loading + 1,
        is_appointments_loaded: false,
      };

    case GET_APPOINTMENT_TIMES_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        appointments: action.times.map((t) => t.appointment_datetime),
        is_appointments_loaded: true,
      };

    case GET_APPOINTMENT_TIMES_FAILURE:
      return {
        ...state,
        loading: state.loading - 1,
        appointments: [],
        is_appointments_loaded: true,
      };

    case GET_AVAILABLE_CALENDAR_TIMES_REQUEST:
      return {
        ...state,
        loading: state.loading + 1,
        is_calendar_available_slots_loaded: false,
      };

    case GET_AVAILABLE_CALENDAR_TIMES_SUCCESS:
      return {
        ...state,
        loading: state.loading - 1,
        availableTimes: action.times,
        is_calendar_available_slots_loaded: true,
      };

    case GET_AVAILABLE_CALENDAR_TIMES_FAILURE:
      return {
        ...state,
        loading: state.loading - 1,
        availableTimes: [],
        is_calendar_available_slots_loaded: true,
      };

    case CHECK_DUPLICATION_REQUEST:
      return {
        ...state,
        loading: state.loading + 1,
        validating: true,
      };

    case CHECK_DUPLICATION_SUCCESS:
      return {
        ...state,
        validating: false,
        loading: state.loading - 1,
        is_duplicated: action.is_duplicated.is_duplicated,
      };

    case CHECK_DUPLICATION_FAILURE:
      return {
        ...state,
        loading: state.loading - 1,
        validating: false,
      };

    case APPOINT_CALENDAR_TIME_REQUEST:
      return state;

    case APPOINT_CALENDAR_TIME_SUCCESS:
      return state;

    case APPOINT_CALENDAR_TIME_FAILURE:
      return state;

    default:
      return state;
  }
};
