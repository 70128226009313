import styled from 'styled-components';
import Arrow from 'assets/images/icon-chevron-down.svg';
import { pxToRem } from 'common/utils';

const Meeting2 = styled.div`
  #form-meeting2 {
    @media screen and (min-width: 768px) {
      max-width: none;
      align-items: center;
      .form-content {
        width: 100%;
        max-width: ${pxToRem(555)};
      }
      .form-footer {
        width: 100%;
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
        padding-left: calc(50% - ${pxToRem(555 / 2)} + ${pxToRem(20)});
        padding-right: calc(50% - ${pxToRem(555 / 2)} + ${pxToRem(20)});
      }
    }
  }

  .form-title {
    font-family: StellaFont, Arial, Helvetica, sans-serif;
    font-size: 1.875rem;
    color: ${(props) => props.theme.color_scheme_dark};
    text-align: center;
  }

  .form-content {
    display: flex;
    flex-direction: column;

    .mb-32 {
      flex: 1;
    }
  }

  select {
    width: 100%;
    font-size: 0.875rem;
    border: none;
    appearance: none;
    outline: none;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    color: #666;
    box-sizing: border-box;
    padding: 0.625rem 0;
    background: url(${Arrow}) top 0.375rem right no-repeat transparent;
    /* padding-right: 1.25rem; */
    /* border: 1px solid ${(props) => props.theme.color_scheme_button}; */
    /* border-radius: 3px; */
  }

  .loading-overlay {
    position: unset;
  }
`;

export default Meeting2;
