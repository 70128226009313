import React from 'react';
import StyledEnvironmentItem from './styled';

const EnvironmentItem = ({ icon, title, content }) => (
  <StyledEnvironmentItem className="environment-item">
    <div className="environment-item-icon">{icon}</div>
    {title && <div className="environment-item-text">{title}</div>}
    {content && <div className="environment-item-subtext">{content}</div>}
  </StyledEnvironmentItem>
);

export default EnvironmentItem;
