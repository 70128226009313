import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Modal from 'components/Modal';
import { setUserData, saveSession } from 'store/actions';
import Header from 'components/Sunlight/Header';
import Stage from 'components/Sunlight/Stage';
import Question from 'components/Sunlight/Question';
import { VARIATION_OPTIONS } from 'pages/constants';
import { getLocalStorageItem } from 'common/localStorage';
import { ReactComponent as CloseIcon } from 'assets/images/icon-close-thin.svg';
import { generateID } from 'common/utils';
import StyledSunlight3 from './styled';

class Sunlight3 extends Component {
  constructor(props) {
    super(props);

    const variation = getLocalStorageItem('variation');

    this.state = {
      checked: false,
      variation: VARIATION_OPTIONS[variation],
      isOpen: false,
    };
  }

  componentDidMount() {
    const elem = document.getElementById('sunlight-eSign-text');
    if (elem) {
      elem.addEventListener('click', () => {
        this.setState({ isOpen: true });
      });
    }
  }

  handleCheck = (event) => {
    this.setState({ checked: event.target.checked });
  };

  handleClose = async () => {
    const {
      history,
      actions,
      user: { sunlight },
    } = this.props;

    let step;
    if (sunlight.step_from === '/result3') step = '/result3';
    else step = '/qualify/end';

    actions.setUserData({ step });
    await actions.saveSession();

    history.replace(step);
  };

  handleBack = async () => {
    const { history, actions } = this.props;

    actions.setUserData({
      step: '/prequalify/sunlight2',
    });
    await actions.saveSession();

    history.replace('/prequalify/sunlight2');
  };

  handleNext = async () => {
    const { history, actions } = this.props;

    actions.setUserData({
      step: '/prequalify/sunlight4',
    });
    await actions.saveSession();

    history.push('/prequalify/sunlight4');
  };

  handleCloseModal = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { t, theme } = this.props;
    const { variation, checked, isOpen } = this.state;
    const { sunlight } = theme;

    const submitForPrequal = sunlight?.disclosures?.find(
      ({ type }) => type === 'SubmitForPrequal'
    );
    const eSignSubmit = sunlight?.disclosures?.find(
      ({ type }) => type === 'ESignSubmit'
    );
    const eConsent = sunlight?.disclosures?.find(
      ({ type }) => type === 'eConsent'
    );

    const {
      location: { pathname },
    } = this.props;

    return (
      <>
        <variation.header />

        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <StyledSunlight3 className="content sunlight3-page">
              <div id={generateID('div-header', pathname)} className="mb-32">
                <Header onCloseClick={this.handleClose} />
              </div>

              <div id={generateID('div-stage', pathname)} className="mb-16">
                <Stage stage={3} onBackClick={this.handleBack} />
              </div>

              <Formik onSubmit={this.handleNext}>
                {() => {
                  return (
                    <Form className="form">
                      <div className="form-content">
                        <div
                          id={generateID('div-question', pathname)}
                          className="mb-16"
                        >
                          <Question>
                            {t(
                              'Can we check your credit score with a soft-pull of credit? In no way will this affect your credit score.'
                            )}
                          </Question>
                        </div>

                        <div
                          className="mb-32"
                          style={{ display: 'flex', flexDirection: 'row' }}
                        >
                          <Checkbox
                            id={generateID('checkbox-esign', pathname)}
                            onChange={this.handleCheck}
                          />

                          <div className="description">
                            <div className="mb-8">
                              {submitForPrequal?.disclosureText}
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: eSignSubmit?.disclosureText.replace(
                                  'E-Sign disclosure',
                                  '<span id="sunlight-eSign-text" class="eSign-disclosure">E-Sign disclosure</span>'
                                ),
                              }}
                            />
                          </div>
                        </div>

                        <div
                          id={generateID('div-actions', pathname)}
                          className="mb-8"
                        >
                          <Button
                            id={generateID('btn-submit', pathname)}
                            onClick={this.handleNext}
                            disabled={!checked}
                            full
                          >
                            {t('Submit')}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>

              {eConsent && (
                <Modal
                  id={generateID('modal-econsent', pathname)}
                  isOpen={isOpen}
                  showCloseIcon
                  onClose={this.handleCloseModal}
                  onBackgroundClick={this.handleCloseModal}
                  CloseIcon={CloseIcon}
                  className="sunlight-eConsent-modal"
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: eConsent?.disclosureText,
                    }}
                    className="sunlight-eConsent-modal-content"
                  />
                </Modal>
              )}
            </StyledSunlight3>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui }) => ({
  user: data,
  theme: ui.theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Sunlight3)
);
