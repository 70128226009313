/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import orderBy from 'lodash/orderBy';
import cloneDeep from 'lodash/cloneDeep';
import Button from 'components/Button';
import MaterialStepper from 'components/MaterialStepper';
import Chat from 'components/Chat';
import ButtonSelect from 'components/ButtonSelect';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/images/icon-arrow-right.svg';
import {
  setUserData,
  saveSession,
  answerQualifyQuestion,
  navigateTo,
  trackForwardProgress,
} from 'store/actions';
import DefaultLayout from 'components/DefaultLayout';
import TextInput from 'components/TextInput';
import { withTranslation } from 'react-i18next';
import {
  filterQuestionsByLanguage,
  generateID,
  getQuestionsCount,
} from 'common/utils';
import { OPEN_ENDED_QUESTION, SINGLE_CHOICE_QUESTION } from 'pages/constants';
import StyledQualifyPage from './styled';

class QualifyPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      questions_count: 0,
      answer: '',
    };
  }

  componentDidMount() {
    this.initData();
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (prevProps.match.params.id && id && prevProps.match.params.id !== id) {
      this.initData();
    }
  }

  initData = () => {
    const {
      match: {
        params: { id: questionId },
      },
      theme: { chosen_questions },
      user: { answers },
      actions,
    } = this.props;
    const { theme } = this.props;
    const filteredQuestions = filterQuestionsByLanguage(chosen_questions);

    if (!filteredQuestions || filteredQuestions.length === 0 || !questionId) {
      actions.navigateTo('/qualify/default2');
      return;
    }
    const question = orderBy(filteredQuestions, ['position'], ['asc'])[
      parseInt(questionId, 10) - 1
    ];
    if (!question) {
      actions.navigateTo('/qualify/default2');
    }

    let open_ended_answer = '';
    if (answers && answers.length) {
      answers.forEach((answer) => {
        if (answer.question.id !== question.id) return;
        open_ended_answer = answer.open_ended_answer;
      });
    }

    this.setState({
      question: cloneDeep(question),
      isFirst: questionId == 1,
      isLast: questionId == filteredQuestions.length,
      id: parseInt(questionId, 10),
      loading: false,
      questions_count: getQuestionsCount(theme),
      answer: open_ended_answer,
    });
  };

  handleBack = () => {
    const { actions } = this.props;
    actions.navigateTo(this.backStepUrl());
  };

  handleNext = async (value) => {
    const { actions } = this.props;
    const { question } = this.state;
    const step = this.nextStepUrl();
    const data = { step };
    this.setState({ loading: true });
    actions.setUserData(data);
    await actions.answerQualifyQuestion(question.id, value);
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo(step);
  };

  nextStepUrl = () => {
    const { id, isLast } = this.state;

    if (isLast) {
      return '/qualify/default2';
    }
    return `/qualify/${id + 1}`;
  };

  backStepUrl = () => {
    const { id, isFirst } = this.state;
    let step;
    if (isFirst) {
      step = '/qualify/default1';
    } else {
      step = `/qualify/${id - 1}`;
    }
    return step;
  };

  render() {
    const { question, loading, id, questions_count, answer } = this.state;
    const { t, theme, user } = this.props;
    const question_type = question?.question_type ?? SINGLE_CHOICE_QUESTION;
    const showNextButton = question_type === OPEN_ENDED_QUESTION && !loading;

    const {
      location: { pathname },
    } = this.props;

    return (
      <DefaultLayout>
        <StyledQualifyPage className="content qualify-page custom-stepper">
          <div className="form">
            <div
              id={generateID('div-material-stepper', pathname)}
              className="material-progress"
            >
              <MaterialStepper activeStep={1} variation={user.variation} />
            </div>

            <div className="form-content">
              <Chat
                id={generateID('chat-question-and-answer', pathname)}
                mode="loading"
                loading={loading}
              >
                {!loading && question && (
                  <>
                    <div className="question-number">
                      {t('QUESTION {{step}} OF {{questions_count}}', {
                        step: id + 1,
                        questions_count,
                      })}
                    </div>

                    <div className="mb-16">{t(question.question_desc)}</div>

                    {question_type === SINGLE_CHOICE_QUESTION && (
                      <div
                        id={generateID('div-answer', pathname)}
                        className="mb-8"
                      >
                        <ButtonSelect
                          options={orderBy(
                            question.choices,
                            'position',
                            'asc'
                          ).map((item) => ({
                            label: t(item.option),
                            value: item.id,
                          }))}
                          onChange={this.handleNext}
                        />
                      </div>
                    )}

                    {question_type === OPEN_ENDED_QUESTION && (
                      <div className="mb-8">
                        <TextInput
                          id={generateID('txt-answer', pathname)}
                          value={answer}
                          onChange={(e) =>
                            this.setState({ answer: e.target.value })
                          }
                          inputProps={{
                            onKeyDown: (e) =>
                              e.key === 'Enter' && this.handleNext(answer),
                          }}
                          placeholder={t(question.question_label)}
                          theme={theme}
                          autoFocus
                        />
                      </div>
                    )}
                  </>
                )}
              </Chat>
            </div>

            <div
              id={generateID('div-actions', pathname)}
              className="form-actions"
            >
              <Button
                id={generateID('btn-back', pathname)}
                outline
                borderless
                full
                left
                onClick={this.handleBack}
                className="pl-0 pr-0"
              >
                <BackIcon />
                {t('Back')}
              </Button>

              {showNextButton && (
                <Button
                  id={generateID('btn-next', pathname)}
                  onClick={() => this.handleNext(answer)}
                  full
                >
                  {answer ? t('Next') : t('Skip')}
                  <ArrowRightIcon />
                </Button>
              )}
            </div>
          </div>
        </StyledQualifyPage>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui }) => ({
  user: data,
  theme: ui.theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      answerQualifyQuestion,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(QualifyPage)
);
