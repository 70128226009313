import React from 'react';
import MediaQuery from 'react-responsive';
import SlickSlider from 'components/SlickSlider';
import { ReactComponent as BatteryIcon } from 'assets/images/battery-2-charge-line.svg';
import StyledWrapper from './styled';

const BatteryGroup = ({ value, options, onAdd, onRemove }) => {
  const sliderRef = React.useRef();
  const selected = options.findIndex((option) => option.title === value?.name);

  React.useEffect(() => {
    if (selected === -1) return;

    const delta = options.length > 1 ? 1 : 0;
    sliderRef.current.slickGoTo(Math.max(selected - delta, 0));
  }, []);

  return (
    <StyledWrapper>
      <MediaQuery minWidth={555}>
        {(matches) => (
          <SlickSlider
            dots
            variableWidth={false}
            slidesToShow={matches ? Math.min(options.length, 2) : 1}
            showArrows={false}
            sliderRef={sliderRef}
          >
            {options.map((option, index) => (
              <div key={index} className="container">
                <Battery
                  title={option.title}
                  description={option.description}
                  cost={option.cost}
                  isSelected={index === selected}
                  onAdd={onAdd}
                  onRemove={onRemove}
                />
              </div>
            ))}
          </SlickSlider>
        )}
      </MediaQuery>
    </StyledWrapper>
  );
};

const Battery = ({ title, description, cost, isSelected, onAdd, onRemove }) => (
  <div className={`battery ${isSelected ? 'selected' : ''}`}>
    <div className="info">
      <BatteryIcon />
      <div className="name">{title}</div>
      <div className="description">{description}</div>
      <div className="price">
        <span>+${cost}/</span>mo.
      </div>
    </div>
    <div
      className="action"
      onClick={() =>
        isSelected
          ? onRemove()
          : onAdd({
              name: title,
              description,
              price: cost,
            })
      }
    >
      {isSelected ? 'Remove' : 'Add'}
    </div>
  </div>
);

export default BatteryGroup;
