import styled from 'styled-components';
import Arrow from 'assets/images/icon-caret-down.svg';

const Meeting2 = styled.div`
  font-size: 0.875rem;
  color: #231f20;

  @media screen and (min-width: 768px) {
    font-size: 1rem;
  }

  .timezone {
    font-family: 'Effra Light';
    margin: 2.25rem auto 1rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    svg {
      color: ${(props) => props.theme.color_scheme_font};
      margin-right: 1rem;
    }
  }

  select {
    cursor: pointer;
    width: 100%;
    border: none;
    appearance: none;
    outline: none;
    box-sizing: border-box;
    padding: 0.625rem 0;
    background: url(${Arrow}) top 0.475rem right no-repeat transparent;

    @media screen and (min-width: 768px) {
      background: url(${Arrow}) top 0.375rem right no-repeat transparent;
    }
  }

  .button-container {
    display: flex;
    flex-direction: column;

    .btn {
      background-color: #fff;
      color: ${(props) => props.theme.color_scheme_font};
      text-transform: uppercase;
      font-family: 'Effra';
      font-size: 1.125rem;
      font-weight: normal;
      border-bottom: 1px solid #e8e8e8;
      border-radius: 0;

      &.active {
        background-color: ${(props) => props.theme.color_scheme_button_hover};
        color: ${(props) => props.theme.color_scheme_button_hover_text};
      }
    }
  }
`;

export default Meeting2;
