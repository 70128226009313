import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loading from 'components/Loading';
import imgSunroof from 'assets/images/logo-project-sunroof-grey.png';
import {
  setUserData,
  createSession,
  saveSession,
  navigateTo,
  trackForwardProgress,
} from 'store/actions';
import {
  checkIfImageIsLoaded,
  extractValuesFromPlace,
  generateID,
  loadGoogleMaps,
} from 'common/utils';
import { verifyZipCode } from 'common/api.mock';
import Header from 'components/Header';
import Footer from 'components/Footer';
import { withTranslation } from 'react-i18next';
import GoogleZipCode from 'components/Forms/GoogleZipCode';
import { getLocalStorageItem } from 'common/localStorage';
import StyledStartVariation2 from './styled';

class StartVariation2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zipcode: '',
      loading: true,
    };
  }

  componentDidMount() {
    const { user, theme, actions } = this.props;
    if (user.session_id === undefined) {
      actions.createSession(theme.variation);
    }
    const paramVariation = getLocalStorageItem('variation');
    if (user.session_id && (user.variation || 'current') !== paramVariation) {
      actions.createSession(paramVariation);
    }
    this.initData();
  }

  initData = async () => {
    const { actions } = this.props;
    const images = ['/assets/images/map.jpg'];
    await checkIfImageIsLoaded(images);
    this.setState({ loading: false });
    actions.setUserData({
      solarCount: 0,
    });
  };

  handleSave = async (event, place) => {
    if (!place) {
      return;
    }
    this.setState({ zipcode: event.target.value });
    const {
      actions,
      theme: { is_best_company_enabled },
    } = this.props;
    let step = is_best_company_enabled ? '/bestcompany1' : '/getnotified1';

    let values = extractValuesFromPlace(place);

    const getAddressFromLocation = async (location) => {
      const goecoder = new window.google.maps.Geocoder();
      await goecoder.geocode(
        {
          location,
        },
        async (results, geocoderStatus) => {
          if (geocoderStatus === window.google.maps.GeocoderStatus.OK) {
            const result = results[0];
            values = extractValuesFromPlace(result);

            // verify that zip_code is in service
            if (values.zip_code) {
              const { status, is_coverage_area } = await verifyZipCode(
                values.zip_code
              );
              step = status
                ? '/calculate19'
                : is_best_company_enabled
                ? '/bestcompany1'
                : '/getnotified1';
              if (status && is_coverage_area) step = '/calculate2';
              actions.setUserData({
                ...values,
                step,
              });
              await actions.saveSession();
              actions.trackForwardProgress();
              actions.navigateTo(step);
            }
          }
        }
      );
    };
    if (values && values.zip_code === undefined) {
      if (window.google === undefined || window.google.maps === undefined)
        await loadGoogleMaps(getAddressFromLocation);
      else
        await getAddressFromLocation({
          lat: values.rooftop_latitude,
          lng: values.rooftop_longitude,
        });
    } else {
      const { status, is_coverage_area } = await verifyZipCode(values.zip_code);
      step = status
        ? '/calculate19'
        : is_best_company_enabled
        ? '/bestcompany1'
        : '/getnotified1';
      if (status && is_coverage_area) step = '/calculate2';
      actions.setUserData({
        ...values,
        step,
      });
      await actions.saveSession();
      actions.trackForwardProgress();
      actions.navigateTo(step);
    }
  };

  handleChange = (event) => {
    this.setState({ zipcode: event.target.value });
  };

  render() {
    const { theme, t } = this.props;
    const { zipcode, loading } = this.state;
    if (loading) {
      return <Loading />;
    }

    const language = getLocalStorageItem('i18nextLng');
    const ai_name = /^es\b/.test(language)
      ? theme.spanish_ai_name
      : theme.ai_name;
    let welcome_statement = /^es\b/.test(language)
      ? theme.spanish_welcome_statement
      : theme.welcome_statement;
    welcome_statement = welcome_statement.replace('{{ai_name}}', ai_name);

    const {
      location: { pathname },
    } = this.props;

    return (
      <>
        <Header showRestartButton={false} />
        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <StyledStartVariation2 className="content start-page">
              <div className="form">
                <div className="form-content">
                  <div
                    id={generateID('div-map-section', pathname)}
                    className="map-section"
                  >
                    <div className="map-block"></div>
                    <div
                      className="avatar"
                      style={{
                        backgroundImage: `url(${theme.ai_avatar})`,
                      }}
                    >
                      <span className="avatar-status"></span>
                    </div>
                  </div>
                  <div
                    id={generateID('div-ai-section', pathname)}
                    className="ai-section"
                  >
                    <div>
                      <div className="">
                        <span className="ai-greeting">
                          {welcome_statement ||
                            t(
                              "Hey! I'm {{ai_name}}. I'll get you a custom solar estimate in seconds using satellites!",
                              { ai_name }
                            )}
                        </span>
                      </div>
                      <GoogleZipCode
                        theme={theme}
                        value={zipcode}
                        onChange={this.handleChange}
                        onSelect={this.handleSave}
                        placeholder={t('Enter your zip code')}
                        name="zipcode"
                      />
                      <div
                        id={generateID('div-powered-by', pathname)}
                        className="power-text"
                      >
                        <div className="power-text-inner">
                          <span>{t('Powered by')}</span>
                          <img src={imgSunroof} alt="sunroof" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </StyledStartVariation2>
            <Footer />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui: { theme } }) => ({
  user: data,
  theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      createSession,
      saveSession,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(StartVariation2)
);
