import React, { useRef } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { ReactComponent as WarningCircleIcon } from 'assets/images/icon-warning-circle.svg';
import { useOutlinedTextFieldStyles } from './styled';

const TextInputNRGVariation = ({
  field,
  form,
  theme,
  InputProps,
  validateOnChange = false,
  ...props
}) => {
  const classes = useOutlinedTextFieldStyles({ theme });
  const input = useRef();

  const [errorState, setErrorState] = React.useState(false);
  const error = form.errors[field.name];
  const touched = form.touched[field.name];
  const isError = validateOnChange
    ? Boolean(!form.isSubmitting && touched && error)
    : errorState;

  const checkValidation = () => {
    setErrorState(Boolean(error));
  };

  return (
    <TextField
      error={isError}
      {...field}
      {...props}
      variant="outlined"
      className={classes.outlinedTextField}
      InputProps={{
        ...InputProps,
        endAdornment: isError ? (
          <InputAdornment position="end">
            <WarningCircleIcon />
          </InputAdornment>
        ) : (
          InputProps?.endAdornment
        ),
      }}
      ref={input}
      onBlur={checkValidation}
    />
  );
};

export default TextInputNRGVariation;
