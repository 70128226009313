import styled from 'styled-components';
import { pxToRem } from 'common/utils';

const Chat = styled.div`
  font-family: 'Effra';
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 1.25rem;

  .chat-avatar {
    margin-right: 0.75rem;
  }

  .chat-content {
    padding: 1rem 1.4375rem 1rem;
    background-color: ${(props) => props.theme.color_scheme_neutral};
    border-radius: 1rem 1rem 1rem 0;
    display: flex;
    flex-direction: column;
    font-size: 1.25rem;
    color: #fff;
    flex-grow: 1;
    max-width: calc(100% - 52px);
    position: relative;

    @media screen and (max-width: 767px) {
      font-size: ${pxToRem(17)};
      line-height: ${pxToRem(22)};
    }

    input {
      background-color: #fff;
    }

    textarea {
      background-color: #fff;
    }

    .question-number {
      font-size: ${pxToRem(12)};
      margin-bottom: ${pxToRem(12)};

      @media screen and (min-width: 768px) {
        font-size: ${pxToRem(16)};
      }
    }
  }

  .chat-typing {
    margin: 0 0 0.25rem 0.25rem;
  }
`;

export default Chat;
