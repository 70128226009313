import React, { Component } from 'react';
import { connect } from 'react-redux';
import Footer from 'components/FooterBestCompany';
import { ReactComponent as CheckIcon } from 'assets/images/icon-check.svg';
import { withTranslation } from 'react-i18next';
import { VARIATION_OPTIONS } from 'pages/constants';
import { getLocalStorageItem } from 'common/localStorage';
import { generateID } from 'common/utils';
import StyledBestCompany2, { Wrapper } from './styled';

class GetNotified2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      variation: VARIATION_OPTIONS[getLocalStorageItem('variation')],
    };
  }

  render() {
    const { t } = this.props;
    const { variation } = this.state;

    const {
      location: { pathname },
    } = this.props;

    return (
      <Wrapper>
        <variation.header showRestartButton={false} />
        <div className="main-container">
          <div className="main">
            <StyledBestCompany2 className="content best-company-2-page">
              <div className="form">
                <div
                  id={generateID('div-thanks', pathname)}
                  className="form-content text-center"
                >
                  <div className="mb-32">
                    <CheckIcon />
                  </div>
                  <div className="form-title mb-32">
                    {t('Thanks for your interest!')}
                  </div>
                  <div className="form-subtext pb-32 mb-32">
                    {t(
                      'We don’t operate in your area yet, but we’ll be in touch when we can help you go solar.'
                    )}
                  </div>
                </div>
              </div>
            </StyledBestCompany2>
            <Footer />
          </div>
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = ({ ui: { theme } }) => ({
  theme,
});

export default withTranslation('translation')(
  connect(mapStateToProps)(GetNotified2)
);
