import React from 'react';
import { ReactComponent as PlusIcon } from 'assets/images/icon-expand-plus-nrg.svg';
import { ReactComponent as MinusIcon } from 'assets/images/icon-expand-minus-nrg.svg';
import StyledExpand from './styled';

const Expand = ({ id = null, title, children, isExpanded = false }) => {
  const [expanded, setExpanded] = React.useState(isExpanded);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <StyledExpand id={id ?? null} className="expand-block">
      <div className="expand-block-title">
        {title}
        {expanded ? (
          <MinusIcon onClick={toggleExpand} />
        ) : (
          <PlusIcon onClick={toggleExpand} />
        )}
      </div>
      {expanded && <div className="expand-block-body">{children}</div>}
    </StyledExpand>
  );
};

export default Expand;
