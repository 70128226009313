import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Button from 'components/Button';
import Chat from 'components/ChatNRGVariation';
import ProgressBar from 'components/ProgressBarNRGVariation';
import BackButton from 'components/BackButtonNRGVariation';
import FixedBottomBar from 'components/FixedBottomBar';
import DefaultLayout from 'components/DefaultLayout';
import { confirmVerificationCode, sendVerificationCode } from 'common/api.mock';
import useDigitInput from 'react-digit-input';
import StyledDigitInput from 'components/Forms/DigitInput/styled';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { generateID } from 'common/utils';
import {
  setUserData,
  saveSession,
  trackFacebookPixel,
  navigateTo,
  trackForwardProgress,
} from '../../../store/actions';
import { Result2 as StyledResult2, InputWrapper } from './styled';

const formSchema = Yup.object().shape({});

function PhoneVerify(props) {
  const location = useLocation();
  const [state, setState] = React.useState({
    loading: false,
    loaded: false,
    error: false,
  });

  const { loading, error } = state;

  const [firstDigit, setFirstDigit] = React.useState(null);
  const [t] = useTranslation('translation');
  React.useEffect(() => {
    if (firstDigit) {
      firstDigit.focus();
    }
  }, [firstDigit]);

  const [code, setCode] = React.useState('');

  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 4,
    value: code,
    onChange: setCode,
  });

  digits[0].ref = (input) => {
    setFirstDigit(input);
  };

  const handleBack = () => {
    const { actions } = props;
    actions.navigateTo('/result2');
  };

  const handleNext = async () => {
    const { actions, user } = props;
    setState({ ...state, loading: true, loaded: true });

    const result = await confirmVerificationCode({
      phone: user.phone,
      code,
    });
    if (result) {
      actions.setUserData({
        ...user,
        step: '/result3',
        is_phone_verified: true,
      });
      actions.trackForwardProgress();
      await actions.saveSession();

      setState({ ...state, loading: false, loaded: true });
      actions.navigateTo('/result3');
    } else {
      setState({ ...state, loading: false, loaded: true, error: true });
    }
  };

  const handleSendCode = async (e) => {
    const { user } = props;
    e.preventDefault();
    setState({ ...state, loading: true, loaded: true });
    try {
      setCode('');
      await sendVerificationCode({ phone: user.phone });
      setState({ ...state, loading: false, loaded: true, error: false });
    } catch (exception) {
      setState({ ...state, loading: false, loaded: true, error: true });
    }
  };

  return (
    <DefaultLayout showFooter={false}>
      <Formik
        initialValues={{}}
        validationSchema={formSchema}
        onSubmit={handleNext}
        validateOnMount
      >
        {({ submitForm }) => (
          <>
            <StyledResult2 className="content result-2-page">
              <Form className="form">
                <div className="form-content">
                  <div
                    id={generateID('div-progress-bar', location.pathname)}
                    className="mb-32 w100"
                  >
                    <ProgressBar value={100} step={4} />
                  </div>
                  <Chat
                    id={generateID(
                      'chat-four-digit-verification',
                      location.pathname
                    )}
                    mode="loading"
                    loading={loading}
                  >
                    <div className="mb-16">
                      {!error ? (
                        <span>
                          {t(
                            'Type the four digit verification code sent to your phone.'
                          )}
                        </span>
                      ) : (
                        <span>
                          {t(
                            'That verification code was incorrect, please try again.'
                          )}
                        </span>
                      )}
                    </div>
                  </Chat>

                  <div
                    id={generateID('div-card', location.pathname)}
                    className="card"
                  >
                    <InputWrapper className="input-wrapper">
                      <StyledDigitInput
                        id={generateID('txt-digit-1', location.pathname)}
                        className="digit first-digit"
                        inputMode="decimal"
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus
                        pattern="\d*"
                        ref={firstDigit}
                        {...digits[0]}
                      />
                      <StyledDigitInput
                        id={generateID('txt-digit-2', location.pathname)}
                        className="digit"
                        inputMode="decimal"
                        pattern="\d*"
                        {...digits[1]}
                      />
                      <StyledDigitInput
                        id={generateID('txt-digit-3', location.pathname)}
                        className="digit"
                        inputMode="decimal"
                        pattern="\d*"
                        {...digits[2]}
                      />
                      <StyledDigitInput
                        id={generateID('txt-digit-4', location.pathname)}
                        className="digit"
                        inputMode="decimal"
                        pattern="\d*"
                        {...digits[3]}
                      />
                    </InputWrapper>
                    <div
                      id={generateID('btn-resend-code', location.pathname)}
                      className="resend-btn"
                      onClick={handleSendCode}
                    >
                      {t('Resend Code')}
                    </div>
                  </div>
                </div>
                <div
                  id={generateID('div-actions', location.pathname)}
                  className="form-actions vertical"
                >
                  <BackButton
                    id={generateID('btn-back', location.pathname)}
                    onClick={handleBack}
                  />
                </div>
              </Form>
            </StyledResult2>

            <FixedBottomBar
              id={generateID('div-fixed-bottom-bar', location.pathname)}
            >
              <Button
                id={generateID('btn-unlock-estimate', location.pathname)}
                type="submit"
                full
                disabled={code.trim().length < 4}
                onClick={submitForm}
              >
                {t('Unlock Estimate')}
              </Button>
            </FixedBottomBar>
          </>
        )}
      </Formik>
    </DefaultLayout>
  );
}

const mapStateToProps = ({ user: { data }, ui: { theme } }) => ({
  user: data,
  theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      trackFacebookPixel,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneVerify);
