import styled from 'styled-components';

const FixedBottomBar = styled.div`
  position: sticky;
  padding: 1.125rem 2rem;
  background: ${(props) => props.theme.color_scheme_background};
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
  bottom: 0;
  width: 100%;
  min-height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default FixedBottomBar;
