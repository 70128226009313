import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from 'components/Button';
import Divider from 'components/Divider';
import MaterialStepper from 'components/MaterialStepper';
import { ReactComponent as CameraIcon } from 'assets/images/icon-camera.svg';
import { trackForwardProgress, trackGoogleLead } from 'store/actions';
import DefaultLayout from 'components/DefaultLayout';
import { withTranslation } from 'react-i18next';
import { generateID } from 'common/utils';
import StyledBills1 from './styled';

class Bills1AppointmentRequestVariation extends Component {
  constructor(props) {
    super(props);

    props.actions.trackGoogleLead('bills1', props.user.variation);
  }

  handleNext = async () => {
    const { history, actions } = this.props;
    actions.trackForwardProgress();
    await history.push('/bills2');
  };

  handleUpload = async () => {
    const { history, actions } = this.props;
    actions.trackForwardProgress();
    history.push('/upload-bills');
  };

  render() {
    const { theme, t, user } = this.props;

    const {
      location: { pathname },
    } = this.props;

    return (
      <DefaultLayout>
        <StyledBills1 className="content bills-1-page custom-stepper">
          <div className="form">
            <div
              id={generateID('div-material-stepper', pathname)}
              className="material-progress"
            >
              <MaterialStepper activeStep={2} variation={user.variation} />
            </div>

            <div
              id={generateID('div-bill-option', pathname)}
              className="form-content text-center"
            >
              {(!theme.is_calendly_on || !theme.is_premier_account) && (
                <>
                  <div className="form-title mb-8">
                    {t('Appointment Requested')}
                  </div>
                  <div className="form-subtitle mb-16">
                    {t('Now the Last Step!')}
                  </div>
                </>
              )}

              {theme.is_calendly_on && theme.is_premier_account && (
                <div className="form-title mb-8">{t('Now the Last Step!')}</div>
              )}

              <div className="form-subtext mb-24">
                {t(
                  'To accurately size your custom solar panel system, we will need copies of your last electric bills.'
                )}
              </div>

              <Button
                id={generateID('btn-upload-your-bills', pathname)}
                full
                className="camera-btn"
                onClick={this.handleUpload}
              >
                <CameraIcon />
                {t('Upload your Bills')}
              </Button>

              <div className="m-24">
                <Divider text={t('or')} />
              </div>

              <Button
                id={generateID('btn-opt-in-to-share-utility-data', pathname)}
                outline
                full
                onClick={this.handleNext}
              >
                {t('Opt in to share utility data')}
              </Button>
            </div>
          </div>
        </StyledBills1>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui: { theme } }) => ({
  user: data,
  theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      trackForwardProgress,
      trackGoogleLead,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Bills1AppointmentRequestVariation)
);
