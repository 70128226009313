import styled from 'styled-components';

const Commercial3 = styled.div`
  .form-title {
    font-family: StellaFont, Arial, Helvetica, sans-serif;
    font-size: 1.875rem;
    color: ${(props) => props.theme.color_scheme_dark};
  }

  .form-subtext {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 1rem;
    color: #666;
  }

  .btn-svg {
    position: relative;

    svg {
      position: absolute;
      left: 16px;
    }
  }
`;

export default Commercial3;
