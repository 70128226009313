import styled from 'styled-components';
import Color from 'color';
import { pxToRem } from 'common/utils';

const StyledWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 324px;
  @media screen and (min-width: 768px) {
    margin-left: 0;
  }

  .google-address-input {
    display: block;
    width: 100%;
    height: ${pxToRem(52)};
    font-size: 1rem;
    color: ${(props) => props.theme.color_scheme_dark};
    border: 1px solid
      ${(props) => (props.error ? '#f44336' : props.theme.color_scheme_dark)};
    border-radius: ${(props) =>
      props.theme.button_shape === 'square' || props.link
        ? 0
        : props.theme.button_shape === 'semiround'
        ? '3px'
        : '1.25rem'};
    background-color: ${(props) =>
      Color(props.theme.color_scheme_neutral).alpha(0.95).toString()};
    padding: 0.625rem 1rem;
    text-align: left;

    &:focus {
      outline: none;
    }

    &.has-error {
      border-color: #f44336;
    }

    &::-webkit-input-placeholder {
      color: ${(props) => props.theme.color_scheme_label};
      font-family: Roboto, Arial, Helvetica, sans-serif;
      opacity: 1;
      text-align: center;
    }
    &:-moz-placeholder {
      /* FF 4-18 */
      color: ${(props) => props.theme.color_scheme_label};
      font-family: Roboto, Arial, Helvetica, sans-serif;
      opacity: 1;
      text-align: center;
    }
    &::-moz-placeholder {
      /* FF 19+ */
      color: ${(props) => props.theme.color_scheme_label};
      font-family: Roboto, Arial, Helvetica, sans-serif;
      opacity: 1;
      text-align: center;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: ${(props) => props.theme.color_scheme_label};
      font-family: Roboto, Arial, Helvetica, sans-serif;
      opacity: 1;
      text-align: center;
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${(props) => props.theme.color_scheme_label};
      font-family: Roboto, Arial, Helvetica, sans-serif;
      opacity: 1;
      text-align: center;
    }
    &::placeholder {
      /* modern browser */
      color: ${(props) => props.theme.color_scheme_label};
      font-family: Roboto, Arial, Helvetica, sans-serif;
      opacity: 1;
      text-align: center;
    }

    &:placeholder-shown {
      text-align: left;
    }

    @media screen and (min-width: 768px) {
      height: ${pxToRem(42)};
      padding: 0.625rem 1.25rem;
      text-align: left;
    }

    &--focused {
      outline: none;
    }

    &::-ms-clear {
      display: none;
    }

    &--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .google-address-dropdown-container {
    display: none;

    &--open {
      display: block;
      position: absolute;
      top: calc(3.5rem - 3px);
      width: 100%;
      border: 1px solid ${(props) => props.theme.color_scheme_dark};
      background-color: #e8e8e8;
      font-family: Roboto, Arial, Helvetica, sans-serif;
      font-size: 0.875rem;
      line-height: 1rem;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      z-index: 2;
      max-height: 9rem;
      overflow: auto;
    }
  }

  .google-address-suggestion-item {
    cursor: pointer;
    padding: 0.625rem 0.75rem;
    color: #717171;

    &--active {
      background-color: ${(props) => props.theme.color_scheme_dark};
      color: #fff;
    }
  }

  .google-address-suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &.input-group {
    width: 100%;

    @media screen and (max-width: 767px) {
      margin-bottom: ${pxToRem(23)};
    }

    & > div {
      flex-grow: 1;
      width: 100%;
      margin-top: ${pxToRem(13)};
    }

    button {
      font-size: 1rem;
      opacity: 1;
      line-height: 1.125rem;
      width: initial;
      flex-shrink: 0;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
      height: ${pxToRem(52)};

      svg {
        fill: #fff;
        stroke: #fff;
      }

      @media screen and (max-width: 767px) {
        height: ${pxToRem(47)};
      }
    }
  }
`;

export default StyledWrapper;
