import styled from 'styled-components';

const StyledWrapper = styled.div`
  .sunroof-container {
    display: flex;
    flex-direction: column;
    margin: 2.25rem auto;

    .card {
      background: #fff;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.03);
      border-radius: 8px;
      padding: 1rem;
    }

    .sunny {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      span {
        font-family: 'Effra Light';
        font-size: 0.75rem;
        color: #000;
        text-transform: capitalize;
        line-height: 22px;
      }

      &-bar {
        height: 0.5rem;
        flex-grow: 1;
        margin: 0 0.5rem;
        background-color: #f5f5f5;
        background-image: linear-gradient(
          90deg,
          rgba(82, 36, 117, 1) 10%,
          rgba(243, 139, 34, 1) 35%,
          rgba(251, 255, 0, 1) 65%,
          rgba(253, 255, 242, 1) 90%
        );
        border-radius: 2px;
      }
    }

    .MuiLink-root {
      font-family: 'Effra';
      align-self: flex-end;
      font-size: 0.75rem;
      color: ${(props) => props.theme.color_scheme_button};
      margin-top: 4px;
    }

    .MuiLink-root:hover {
      text-decoration: underline;
      color: ${(props) => props.theme.color_scheme_button};
    }
  }

  .sunroof-image-wrapper {
    @media screen and (max-width: 768px) {
      .sunroof-image-main {
        svg {
          border-radius: 0.5rem;
        }
      }
    }

    @media screen and (min-width: 768px) {
      padding: 0 35px;

      .sunroof-image-main {
        min-height: 180px;

        svg {
          border-radius: 0.5rem;
        }
      }
    }
  }
`;

export default StyledWrapper;
