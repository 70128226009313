import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { noop } from 'common/utils';
import { ReactComponent as CloseIcon } from 'assets/images/icon-close-thin.svg';
import StyledHeader from './styled';

const Header = ({ onCloseClick }) => {
  const [t] = useTranslation('translation');

  return (
    <StyledHeader className="sungage-header">
      <div className="title">{t('Credit prequalification request.')}</div>

      <div className="close" onClick={onCloseClick}>
        <CloseIcon />
      </div>
    </StyledHeader>
  );
};

Header.propTypes = {
  onCloseClick: PropTypes.func,
};

Header.defaultProps = {
  onCloseClick: noop,
};

export default Header;
