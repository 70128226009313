import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DefaultLayout from 'components/DefaultLayout';
import Button from 'components/Button';
import ProgressBar from 'components/ProgressBarNRGVariation';
import Chat from 'components/ChatNRGVariation';
import { formatNumber, generateID } from 'common/utils';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackForwardProgress,
} from 'store/actions';
import { withTranslation } from 'react-i18next';
import BackButton from 'components/BackButtonNRGVariation';
import FixedBottomBar from 'components/FixedBottomBar';
import StyledResult1 from './styled';

class Result1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  handleBack = () => {
    const { actions } = this.props;
    actions.navigateTo('/calculate2');
  };

  handleNext = async () => {
    const { actions } = this.props;
    actions.setUserData({ step: '/result2' });
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo('/result2');
  };

  render() {
    const { user: data, saving, theme, t } = this.props;
    const { show } = this.state;

    const {
      location: { pathname },
    } = this.props;

    return (
      <DefaultLayout showFooter={false}>
        <StyledResult1 className="content result-1-page">
          <div className="form">
            <div className="form-content">
              <div
                id={generateID('div-progress-bar', pathname)}
                className="mb-32 w100"
              >
                <ProgressBar value={75} step={3} />
              </div>

              <Chat
                id={generateID('chat-ready-to-see-ballpark', pathname)}
                onShow={() => this.setState({ show: true })}
              >
                {t('Check out these savings!')}{' '}
                {t('Ready to see ballpark pricing?')}
              </Chat>

              {show && (
                <div id={generateID('div-card', pathname)} className="card">
                  <div className="grid-container">
                    <div className="grid-item">
                      <div className="grid-item-top">
                        {t('Estimated Savings')}
                      </div>
                      <div className="grid-item-middle">
                        {`$${formatNumber(data.savings)}`}
                      </div>
                      <div className="grid-item-bottom">
                        {t('Over 25 years')}
                      </div>
                    </div>

                    <div className="grid-item">
                      <div className="grid-item-top">
                        {t('Increase in Home Value')}
                      </div>
                      <div className="grid-item-middle">4.1%</div>
                      <div className="grid-item-bottom">
                        {t('According to Zillow')}
                      </div>
                    </div>

                    <div className="grid-item">
                      <div className="grid-item-top">
                        {t('Estimated Environmental Impact')}
                      </div>
                      <div className="grid-item-middle">{`${data.tree_seedlings}`}</div>
                      <div className="grid-item-bottom">
                        {t('Trees grown for 10 yrs')}
                      </div>
                    </div>

                    <div className="grid-item">
                      <div className="grid-item-top">
                        {t('Recommended System')}
                      </div>
                      <div className="grid-item-middle">
                        {theme.price_system_display || !theme.is_premier_account
                          ? `${data.install_size_min}-${data.install_size_max} kW`
                          : `${data.install_size} kW`}
                      </div>
                      <div className="grid-item-bottom">
                        {t('{{panels_min}}-{{panels_max}} panels', {
                          panels_min: data.panels_min,
                          panels_max: data.panels_max,
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {show && (
              <div
                id={generateID('div-actions', pathname)}
                className="form-actions"
              >
                <BackButton
                  id={generateID('btn-back', pathname)}
                  onClick={this.handleBack}
                />
              </div>
            )}
          </div>
        </StyledResult1>

        <FixedBottomBar id={generateID('div-fixed-bottom-bar', pathname)}>
          <Button
            id={generateID('btn-i-am-ready', pathname)}
            full
            disabled={!show || saving}
            onClick={this.handleNext}
            style={{
              textTransform: 'capitalize',
              fontWeight: 'normal',
            }}
          >
            {t("I'm ready!")}
          </Button>
        </FixedBottomBar>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ user: { data, saving }, ui: { theme } }) => ({
  user: data,
  saving,
  theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Result1)
);
