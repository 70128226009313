import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import ButtonSelect from 'components/ButtonSelect';
import { setUserData, saveSession } from 'store/actions';
import Header from 'components/Sunlight/Header';
import Stage from 'components/Sunlight/Stage';
import Question from 'components/Sunlight/Question';
import { VARIATION_OPTIONS } from 'pages/constants';
import { getLocalStorageItem } from 'common/localStorage';
import * as APIMock from 'common/api.mock';
import { ReactComponent as TooltipIcon } from 'assets/images/icon-tooltip.svg';
import Tooltip from 'components/Tooltip';
import { generateID } from 'common/utils';
import StyledSunlight1 from './styled';

class Sunlight1 extends Component {
  constructor(props) {
    super(props);

    const variation = getLocalStorageItem('variation');

    this.state = {
      income: props?.sunlight?.income || null,
      variation: VARIATION_OPTIONS[variation],
    };
  }

  handleClose = async () => {
    const {
      history,
      actions,
      user: { sunlight },
    } = this.props;

    let step;
    if (sunlight.step_from === '/result3') step = '/result3';
    else step = '/qualify/end';

    actions.setUserData({ step });
    await actions.saveSession();

    history.replace(step);
  };

  handleNext = async (income) => {
    const { history, actions } = this.props;

    this.setState({ income });

    const res = await APIMock.storeSunlight({ income });
    if (!res) return;

    const step = '/prequalify/sunlight2';
    actions.setUserData({ step });
    await actions.saveSession();
    history.push(step);
  };

  render() {
    const { t, theme } = this.props;
    const { income, variation } = this.state;
    const { sunlight } = theme;

    const incomeList = [
      { value: 23750, label: '$17,500 - $29,999' },
      { value: 35000, label: '$30,000 - $39,999' },
      { value: 45000, label: '$40,000 - $49,999' },
      { value: 65000, label: '$50,000 - $79,999' },
      { value: 95000, label: '$80,000 - $109,999' },
      { value: 120000, label: '$110,000+' },
    ];

    const incomeDisclosre = sunlight?.disclosures?.find(
      ({ type }) => type === 'Income'
    );

    const {
      location: { pathname },
    } = this.props;

    return (
      <>
        <variation.header />

        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <StyledSunlight1 className="content sunlight1-page">
              <div id={generateID('div-header', pathname)} className="mb-32">
                <Header onCloseClick={this.handleClose} />
              </div>

              <div id={generateID('div-stage', pathname)} className="mb-16">
                <Stage stage={1} />
              </div>

              <Formik>
                {() => {
                  return (
                    <Form className="form">
                      <div className="form-content">
                        <div
                          id={generateID('div-question', pathname)}
                          className="mb-16"
                        >
                          <Question>
                            {t('What is your gross annual income?')}
                            {sunlight.available && (
                              <span style={{ marginLeft: '1rem' }}>
                                <TooltipIcon
                                  data-tip
                                  data-for="income-tooltip"
                                />
                              </span>
                            )}
                          </Question>
                        </div>

                        <div
                          id={generateID('div-answer', pathname)}
                          className="mb-32"
                        >
                          <ButtonSelect
                            options={incomeList}
                            onChange={this.handleNext}
                            value={income}
                          />
                          <div className="sunlight-income-disclosure">
                            {incomeDisclosre?.disclosureText || ''}
                          </div>

                          <Tooltip id="income-tooltip">
                            <div className="tooltip-content">
                              Examples: Salary, wages, interest, dividends,
                              rental income, retirement benefits. If you are 21
                              or older, you may include income from others that
                              you can reasonably access to pay your bills.
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </StyledSunlight1>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui }) => ({
  user: data,
  theme: ui.theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Sunlight1)
);
