import styled from 'styled-components';

const Bills2 = styled.div`
  .form-title {
    font-family: StellaFont, Arial, Helvetica, sans-serif;
    font-size: 1.875rem;
    color: ${(props) => props.theme.color_scheme_dark};
  }

  .form-subtext {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 1rem;
    color: #666;
    text-align: justify;
  }

  .form-link {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 0.75rem;
    text-align: right;
    color: #000aff;
    display: block;
    margin-top: 0.25rem;
  }

  .form-label {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 0.875rem;
    color: #666;
  }

  .actions {
    display: flex;
    flex-direction: column;
  }

  .protection {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 0.875rem;
    text-align: center;
    color: ${(props) => props.theme.color_scheme_dark};

    svg {
      margin-right: 0.375rem;
    }
  }

  .fieldset {
    padding: 1.25rem;
    background-color: ${(props) => props.theme.color_scheme_neutral};
    border-radius: 5px;

    input {
      background-color: #fff;
    }
  }

  .form-actions {
    .btn-back {
      @media screen and (max-width: 767px) {
        padding: 0;
      }
    }
  }
`;

export default Bills2;
