import React from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { ReactComponent as WarningCircleIcon } from 'assets/images/icon-warning-circle.svg';
import { useOutlinedTextFieldStyles } from '../Forms/TextInputNRGVariation/styled';

const TextInputNRGVariation = ({ error, theme, InputProps, ...props }) => {
  const classes = useOutlinedTextFieldStyles({ theme });

  return (
    <TextField
      variant="outlined"
      className={classes.outlinedTextField}
      error={error}
      InputProps={{
        ...InputProps,
        endAdornment: error ? (
          <InputAdornment position="end">
            <WarningCircleIcon />
          </InputAdornment>
        ) : (
          InputProps?.endAdornment
        ),
      }}
      {...props}
    />
  );
};

export default TextInputNRGVariation;
