import styled from 'styled-components';

const Calculate21 = styled.div`
  .card {
    margin: 2.25rem auto;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    padding: 2.25rem 2rem;
  }

  .link-text {
    color: ${(props) => props.theme.color_scheme_button};
    text-decoration: underline;
  }
`;

export default Calculate21;
