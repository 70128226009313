import styled from 'styled-components';

export const Pages = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .main {
    background: ${(props) => props.theme.color_scheme_background};
  }
`;
