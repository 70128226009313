import styled from 'styled-components';

const GetNotified1 = styled.div`
  .card {
    margin: 2.25rem auto;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    padding: 2.25rem 2rem;
  }

  .form-legal {
    font-size: 0.75rem;
    color: #666666;
    margin: 1.25rem 0;
    text-align: justify;

    @media screen and (min-width: 768px) {
      font-size: 0.875rem;
    }

    label {
      margin-bottom: 0;
    }
  }
`;

export default GetNotified1;
