import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .info-item {
    display: flex;
    align-items: center;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    margin-bottom: 2rem;

    .info-icon {
      height: 2.25rem;
      width: 2.25rem;
      flex-shrink: 0;
    }

    .info-right {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;

      .info-title {
        font-size: 1rem;
        margin-bottom: 0.5rem;
        text-align: left;
        white-space: nowrap;
      }

      .info-desc {
        font-size: 0.75rem;
        text-align: left;
        white-space: nowrap;
      }
    }
  }
`;

export default StyledWrapper;
