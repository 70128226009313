import React from 'react';
import PropTypes from 'prop-types';
import { VARIATION_OPTIONS } from 'pages/constants';
import { getLocalStorageItem } from 'common/localStorage';

export default function DefaultLayout({
  children,
  showRestartButton,
  showFooter,
}) {
  const variation = VARIATION_OPTIONS[getLocalStorageItem('variation')];
  return (
    <>
      <variation.header showRestartButton={showRestartButton} />
      <div className="main-container">
        <div className="main">
          {children}
          {showFooter && <variation.footer />}
        </div>
      </div>
    </>
  );
}

DefaultLayout.propTypes = {
  showRestartButton: PropTypes.bool,
  showFooter: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  showRestartButton: true,
  showFooter: true,
};
