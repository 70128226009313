import styled from 'styled-components';
import { pxToRem } from 'common/utils';

const Header = styled.div`
  &.sunlight-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    column-gap: 2rem;

    .title {
      font-weight: 700;
      font-size: 1rem;
      line-height: 1;
    }

    .close {
      cursor: pointer;

      svg {
        width: 1rem;
        height: 1rem;
      }
    }

    @media screen and (min-width: 768px) {
      width: ${pxToRem(555)};
    }
  }
`;

export default Header;
