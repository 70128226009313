import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .main-container {
    .main {
      @media screen and (min-width: 768px) {
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
        border-radius: 24px;
      }
    }
  }
`;

const StyledBestCompany1 = styled.div`
  &.content.best-company-1-page {
    padding-top: 3.75rem;

    .form-content {
      flex-grow: unset;
    }

    .form-actions {
      justify-content: center;
    }

    .chat-content {
      font-size: 0.925rem;
      line-height: 1.5rem;
      padding: 1.25rem 1.5rem 1rem;

      font-family: Poppins;
      letter-spacing: 0.01rem;

      @media screen and (min-width: 550px) {
        font-size: 1.125rem;
      }

      @media screen and (min-width: 768px) {
        font-size: 1.25rem;
        line-height: 1.75rem;
        padding: 1.75rem 2rem 1.25rem;
      }
    }
  }

  .form-legal {
    font-size: 0.625rem;
    font-weight: 300;
    line-height: 1rem;
    color: #000;
    font-family: Poppins, Roboto, Arial, Helvetica, sans-serif;
    margin: 1.75rem 0.25rem 0 3rem;
    text-align: justify;

    @media screen and (min-width: 550px) {
      font-size: 0.75rem;
    }

    label {
      margin-bottom: 0;
    }
  }

  .btn {
    background: #625df5;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    font-size: 18px;
    width: 100%;
    margin-left: 3rem;
    height: 56px;
    font-family: 'Poppins';
    letter-spacing: 0.08rem;
    color: #fff;

    @media screen and (min-width: 768px) {
      margin-left: 0;
      width: 284px;
    }

    &:hover:not(.btn-disabled):not(:disabled) {
      color: #fff;
    }
  }

  .btn-disabled {
    background: #666;
  }
`;

export default StyledBestCompany1;
