import * as APIMock from 'common/api.mock';
import * as Sentry from '@sentry/react';
/**
 * Action Types
 */
export const GET_APPOINTMENT_TIMES_REQUEST = 'GET_APPOINTMENT_TIMES_REQUEST';
export const GET_APPOINTMENT_TIMES_SUCCESS = 'GET_APPOINTMENT_TIMES_SUCCESS';
export const GET_APPOINTMENT_TIMES_FAILURE = 'GET_APPOINTMENT_TIMES_FAILURE';

export const GET_AVAILABLE_CALENDAR_TIMES_REQUEST =
  'GET_AVAILABLE_CALENDAR_TIMES_REQUEST';
export const GET_AVAILABLE_CALENDAR_TIMES_SUCCESS =
  'GET_AVAILABLE_CALENDAR_TIMES_SUCCESS';
export const GET_AVAILABLE_CALENDAR_TIMES_FAILURE =
  'GET_AVAILABLE_CALENDAR_TIMES_FAILURE';

export const CHECK_DUPLICATION_REQUEST = 'CHECK_DUPLICATION_REQUEST';
export const CHECK_DUPLICATION_SUCCESS = 'CHECK_DUPLICATION_SUCCESS';
export const CHECK_DUPLICATION_FAILURE = 'CHECK_DUPLICATION_FAILURE';

export const APPOINT_CALENDAR_TIME_REQUEST = 'APPOINT_CALENDAR_TIME_REQUEST';
export const APPOINT_CALENDAR_TIME_SUCCESS = 'APPOINT_CALENDAR_TIME_SUCCESS';
export const APPOINT_CALENDAR_TIME_FAILURE = 'APPOINT_CALENDAR_TIME_FAILURE';

/**
 * Action Creators
 */

export const setAppointmentTimes = (times) => ({
  type: GET_APPOINTMENT_TIMES_SUCCESS,
  times,
});

export const setAvailableCalendarTimes = (times) => ({
  type: GET_AVAILABLE_CALENDAR_TIMES_SUCCESS,
  times,
});

export const checkDuplicationTime = (payload) => ({
  type: CHECK_DUPLICATION_SUCCESS,
  is_duplicated: payload,
});

export const appointCalendar = () => ({
  type: APPOINT_CALENDAR_TIME_SUCCESS,
});

export const appointmentTimes = (param = null) => {
  return async (dispatch) => {
    dispatch({ type: GET_APPOINTMENT_TIMES_REQUEST });
    try {
      const response = await APIMock.appointmentTimes(param);
      dispatch(setAppointmentTimes(response));
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      dispatch({ type: GET_APPOINTMENT_TIMES_FAILURE });
    }
  };
};

export const availableCalendarTimes = (data) => {
  return async (dispatch) => {
    dispatch({ type: GET_AVAILABLE_CALENDAR_TIMES_REQUEST });
    try {
      const { availabilities } = await APIMock.availableCalendarTimes(data);
      dispatch(setAvailableCalendarTimes(availabilities));
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      dispatch({ type: GET_AVAILABLE_CALENDAR_TIMES_FAILURE });
    }
  };
};

export const duplicationTime = () => {
  return async (dispatch, getState) => {
    dispatch({ type: CHECK_DUPLICATION_REQUEST });
    const {
      user: { data },
    } = getState();
    if (data.meeting_time && data.meeting_timezone) {
      try {
        const response = await APIMock.validateAppointmentTime({
          meeting_time: data.meeting_time,
          meeting_timezone: data.meeting_timezone,
        });
        dispatch(checkDuplicationTime(response));
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
        dispatch({ type: CHECK_DUPLICATION_FAILURE });
      }
    }
  };
};

export const appointCalendarTime = () => {
  return async (dispatch, getState) => {
    dispatch({ type: APPOINT_CALENDAR_TIME_REQUEST });
    const {
      user: { data },
    } = getState();
    if (data.meeting_time && data.meeting_timezone) {
      try {
        const response = await APIMock.appointCalendarTime({
          meeting_time: data.meeting_time,
          meeting_timezone: data.meeting_timezone,
        });
        dispatch(appointCalendar(response));
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
        dispatch({ type: APPOINT_CALENDAR_TIME_FAILURE });
      }
    }
  };
};
