import styled from 'styled-components';
import { pxToRem } from 'common/utils';

const StyledSunlight4 = styled.div`
  &.content.sunlight4-page {
    @media screen and (min-width: 768px) {
      max-width: none;
      align-items: center;
      .form-content {
        width: 100%;
        max-width: ${pxToRem(555)};
      }
    }

    .form {
      overflow: hidden;
    }

    .card-container {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .sunlight-form-content {
      display: flex;
      flex-direction: column;
    }

    .sunlight-logo {
      display: flex;
      justify-content: center;
      padding: 2rem;
    }
  }
`;

export default StyledSunlight4;
