import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField } from '@material-ui/core';
import Button from 'components/Button';
import MaterialStepper from 'components/MaterialStepper';
import Chat from 'components/Chat';
import { useOutlinedTextFieldStyles } from 'components/Forms/TextInput/styled';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/images/icon-arrow-right.svg';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackForwardProgress,
} from 'store/actions';
import DefaultLayout from 'components/DefaultLayout';
import { withTranslation } from 'react-i18next';
import {
  filterQuestionsByLanguage,
  generateID,
  getQuestionsCount,
} from 'common/utils';
import { getLocalStorageItem } from 'common/localStorage';
import StyledQualify10 from './styled';

const formSchema = Yup.object().shape({
  project_detail_referral: Yup.string(),
});

const CHARACTER_LIMIT = 250;

class Qualify10 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questions_count: 0,
    };
  }

  componentDidMount() {
    this.initData();
  }

  initData = async () => {
    const { theme } = this.props;

    this.setState({
      questions_count: getQuestionsCount(theme),
    });
  };

  handleBack = () => {
    const {
      actions,
      theme: { chosen_questions },
    } = this.props;
    const filteredQuestions = filterQuestionsByLanguage(chosen_questions);
    const go_step =
      filteredQuestions && filteredQuestions.length > 0
        ? '/qualify/default2'
        : '/qualify/default1';
    actions.navigateTo(go_step);
  };

  handleNext = async (values) => {
    const { actions } = this.props;
    const { step, go_step } = this.nextStepUrl();

    actions.setUserData({
      project_detail_referral: values.project_detail_referral,
      step,
    });
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo(go_step);
  };

  nextStepUrl = () => {
    const { user } = this.props;
    const variation = getLocalStorageItem('variation');

    if (user.is_disqualified) {
      return { step: '/start', go_step: '/disqualify' };
    }

    return variation === 'current' &&
      ((user.is_sungage_available && user.sungage?.step_from !== '/result3') ||
        (user.is_sunlight_available && user.sunlight?.step_from !== '/result3'))
      ? { step: '/prequalify', go_step: '/prequalify' }
      : { step: '/qualify/end', go_step: '/qualify/end' };
  };

  render() {
    const { t, theme } = this.props;
    const { questions_count } = this.state;

    const {
      location: { pathname },
    } = this.props;

    return (
      <DefaultLayout>
        <StyledQualify10 className="content qualify-2-page custom-stepper">
          <Formik
            initialValues={{ project_detail_referral: '' }}
            validationSchema={formSchema}
            onSubmit={this.handleNext}
            validateOnMount
          >
            {({ isValid, isSubmitting }) => {
              return (
                <Form className="form">
                  <div
                    id={generateID('div-material-stepper', pathname)}
                    className="material-progress"
                  >
                    <MaterialStepper activeStep={1} />
                  </div>

                  <div className="form-content">
                    <Chat
                      id={generateID('chat-project-detail-referral', pathname)}
                    >
                      <div className="question-number">
                        {t('QUESTION {{step}} OF {{questions_count}}', {
                          step: questions_count,
                          questions_count,
                        })}
                      </div>

                      <div className="mb-16">
                        {t("Is there anything else you'd like us to know?")}
                      </div>

                      <div className="mb-16">
                        <Field name="project_detail_referral">
                          {({ field }) => {
                            const classes = useOutlinedTextFieldStyles({
                              theme,
                            });
                            return (
                              <TextField
                                id={generateID(
                                  'txt-project-detail-referral',
                                  pathname
                                )}
                                variant="outlined"
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                name={field.name}
                                placeholder={t(
                                  'Please enter project details, referrals, or special requests.'
                                )}
                                multiline
                                rows={5}
                                rowsMax={6}
                                className={classes.outlinedTextField}
                                helperText={`${field.value.length}/${CHARACTER_LIMIT} characters`}
                                FormHelperTextProps={{
                                  style: {
                                    textAlign: 'right',
                                    fontStyle: 'italic',
                                  },
                                }}
                                autoFocus
                                inputProps={{ maxLength: CHARACTER_LIMIT }}
                              />
                            );
                          }}
                        </Field>
                      </div>
                    </Chat>
                  </div>

                  <div
                    id={generateID('div-actions', pathname)}
                    className="form-actions"
                  >
                    <Button
                      id={generateID('btn-back', pathname)}
                      outline
                      borderless
                      full
                      left
                      onClick={this.handleBack}
                      className="pl-0 pr-0"
                    >
                      <BackIcon />
                      {t('Back')}
                    </Button>

                    <Button
                      id={generateID('btn-next', pathname)}
                      type="submit"
                      full
                      disabled={!isValid || isSubmitting}
                    >
                      {t('Next')}
                      <ArrowRightIcon />
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </StyledQualify10>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui }) => ({
  user: data,
  theme: ui.theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Qualify10)
);
