import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from 'components/Button';
import { ReactComponent as ThankyouIcon } from 'assets/images/icon-thankyou.svg';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackGoogleLead,
} from 'store/actions';
import DefaultLayout from 'components/DefaultLayout';
import { withTranslation } from 'react-i18next';
import { getLocalStorageItem } from 'common/localStorage';
import { getPreviewPageName } from 'common/api.mock';
import { generateID } from 'common/utils';
import StyledThankyou from './styled';

class ThankyouNoAppointmentVariation extends Component {
  constructor(props) {
    super(props);

    const { user } = this.props;
    if (user.skippedBills) return;
    props.actions.trackGoogleLead('thankyou', props.user.variation);
  }

  handleNext = () => {
    const previewPage = getPreviewPageName();
    if (previewPage === 'thankyou') return;
    const {
      theme: { testimonials_url, spanish_testimonials_url },
    } = this.props;
    const language = getLocalStorageItem('i18nextLng');
    const url = /^es\b/.test(language)
      ? spanish_testimonials_url
      : testimonials_url;
    if (!url) {
      return;
    }
    window.open(url, '_top');
  };

  handleRestart = async () => {
    const { actions, user } = this.props;
    await this.handleClose();
    actions.setUserData({
      back_step: '/',
      step: user.is_instant_estimate ? '/result3' : '/start',
    });
    await actions.saveSession();
    await actions.navigateTo(user.is_instant_estimate ? '/result3' : '/start');
  };

  render() {
    const { t, theme } = this.props;
    const language = getLocalStorageItem('i18nextLng');
    const caption = /^es\b/.test(language)
      ? theme.spanish_thanks_caption
      : theme.thanks_caption;
    const statement = /^es\b/.test(language)
      ? theme.spanish_thanks_statement
      : theme.thanks_statement;

    const {
      location: { pathname },
    } = this.props;

    return (
      <DefaultLayout showRestartButton={false}>
        <StyledThankyou className="content thankyou-page">
          <div className="form">
            <div
              id={generateID('div-thanks', pathname)}
              className="form-content text-center"
            >
              <div className="mb-32">
                <ThankyouIcon />
              </div>

              <div className="form-title mb-16">
                {caption || t('Your Quote is Being Prepared!')}
              </div>

              <div className="form-subtext mb-32 px-8">{statement}</div>

              <Button
                id={generateID('btn-back-to-our-homepage', pathname)}
                full
                outline
                onClick={this.handleNext}
              >
                {t('BACK TO OUR HOMEPAGE')}
              </Button>
            </div>
          </div>
        </StyledThankyou>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  theme: state.ui.theme,
  user: state.user.data,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      navigateTo,
      trackGoogleLead,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(ThankyouNoAppointmentVariation)
);
