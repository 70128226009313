import { createBrowserHistory } from 'history';

// eslint-disable-next-line import/no-mutable-exports
export let prevLocation = null;
let curLocation = null;

const history = createBrowserHistory();

history.listen((nextLocation) => {
  prevLocation = curLocation;
  curLocation = nextLocation;
});

export default history;
