import React from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import { CLIENT_RATINGS } from 'common/constants';
import { ReactComponent as StarIcon } from 'assets/images/icon-gold-star.svg';
import { useTranslation } from 'react-i18next';
import StyledRating from './styled';

const Rating = ({ value }) => {
  const [t] = useTranslation('translation');
  const option = orderBy(
    CLIENT_RATINGS.filter((item) => item.star - 1 <= value),
    ['star'],
    ['desc']
  )[0];
  const { label } = option;

  return (
    <StyledRating>
      <div className="stars">
        {[...Array(option.star)].map((_, i) => (
          <StarIcon key={i} />
        ))}
      </div>
      <div className="number">
        {t('Our customers say')} <strong>{t(label)}</strong>
      </div>
    </StyledRating>
  );
};

Rating.propTypes = {
  value: PropTypes.number,
};

Rating.defaultProps = {
  value: 5,
};

export default Rating;
