import styled from 'styled-components';

const EnvironmentItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Roboto, Arial, Helvetica, sans-serif;

  .environment-item-icon {
    display: inline-flex;
    height: 40px;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .environment-item-text {
    font-size: 1.875rem;
    color: #303030;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  .environment-item-subtext {
    color: #666;
    font-size: 1rem;
  }
`;

export default EnvironmentItem;
