import styled from 'styled-components';
import { pxToRem } from 'common/utils';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  height: 4.5rem;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  padding: 0.375rem 0.75rem;
  position: relative;
  background-color: #fff;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1020;
  flex-shrink: 0;

  @media screen and (min-width: 768px) {
    padding: 0.375rem 2.25rem;
  }

  .header-left,
  .header-right {
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
  }

  .header-left {
    justify-content: flex-start;
    z-index: 1022;
  }

  .header-right {
    justify-content: flex-end;
    z-index: 1023;
  }

  .header-center {
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1021;
    display: flex;

    .loading {
      width: ${pxToRem(25)};
      cursor: pointer;
    }

    @media screen and (max-width: 480px) {
      position: static;
    }
  }

  .logo {
    height: 2.5rem;
    cursor: pointer;

    @media screen and (min-width: 768px) {
      height: 3.5rem;
    }

    @media screen and (max-width: 480px) {
      max-width: 180px;
      height: auto;
      max-height: 2.5rem;
    }
  }

  .phone-number {
    font-size: 1.125rem;
    font-family: StellaFont, Arial, Helvetica, sans-serif;
    display: flex;
    font-weight: bold;

    > svg {
      margin-left: 0;
      margin-right: 0.5rem;
    }

    @media screen and (max-width: 767px) {
      font-size: 0.875rem;
      height: 2rem;
      padding: 0;
      padding-left: 10px;
      padding-right: 10px;
      width: fit-content;
    }
  }
`;

export default Header;
