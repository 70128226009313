import React from 'react';

const SavingChart = ({ value1 = 0, value2 = 0, value3 = 0 }) => {
  return (
    <svg viewBox="0 0 338 238" fill="none" xmlns="http://www.w3.org/2000/svg">
      <text
        fill="#231F20"
        x="101"
        y="13"
        style={{
          fontFamily: 'Effra Light, arial',
          fontSize: 17,
        }}
      >
        Your Bill in 25 Years
      </text>

      <text
        fill="#231F20"
        x="52"
        y="234"
        style={{
          fontFamily: 'Effra Light, arial',
          fontSize: 14,
        }}
      >
        Now
      </text>

      <text
        fill="#231F20"
        x="175"
        y="234"
        style={{
          fontFamily: 'Effra Light, arial',
          fontSize: 14,
        }}
      >
        10 yrs
      </text>

      <text
        fill="#231F20"
        x="292"
        y="234"
        style={{
          fontFamily: 'Effra Light, arial',
          fontSize: 14,
        }}
      >
        25 yrs
      </text>

      <circle cx="82" cy="45" r="6" fill="#332892" />
      <text
        fill="#231F20"
        x="97"
        y="50"
        style={{
          fontFamily: 'Effra Light, arial',
          fontSize: 14,
        }}
      >
        Solar Cost
      </text>

      <circle cx="203" cy="45" r="6" fill="#00B9F2" />
      <text
        fill="#231F20"
        x="216"
        y="50"
        style={{
          fontFamily: 'Effra Light, arial',
          fontSize: 14,
        }}
      >
        Utility Cost
      </text>

      <text
        fill="#666666"
        x="6"
        y="88"
        style={{ fontFamily: 'Effra Light, arial', fontSize: 14 }}
      >
        ${value3}
      </text>
      <line
        x1="323"
        y1="95.5"
        x2="-4.37114e-08"
        y2="95.5"
        stroke="#BDBDBD"
        strokeDasharray="2 2"
      />

      <text
        fill="#666666"
        x="6"
        y="133"
        style={{ fontFamily: 'Effra Light, arial', fontSize: 14 }}
      >
        ${value2}
      </text>
      <line
        x1="183"
        y1="141.5"
        y2="141.5"
        stroke="#BDBDBD"
        strokeDasharray="2 2"
      />

      <text
        fill="#666666"
        x="6"
        y="179"
        style={{ fontFamily: 'Effra Light, arial', fontSize: 14 }}
      >
        ${value1}
      </text>
      <line
        x1="45"
        y1="187.5"
        y2="187.5"
        stroke="#BDBDBD"
        strokeDasharray="2 2"
      />

      <path d="M336 189H52L336 95V189Z" fill="#00B9F2" />
      <rect x="52" y="189" width="284" height="18" fill="#332892" />
    </svg>
  );
};

export default SavingChart;
