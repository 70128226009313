/* eslint-disable prefer-destructuring */
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { getLocalStorageItem, setLocalStorageItem } from 'common/localStorage';
import { queryToJson, extractHostname, getCookie } from './utils';

let documentReferrer = '';
let queryStringObj = null;
let resumeKey = null;
let defaultAddressData = {};
let defaultZipcode = {};
let proposalKey = null;
let previewMode = false;
let previewPage = '';
let visibleHeader = true;
let visibleFooterLinks = true;

export const sessionAPIInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '',
  withCredentials: true,
});

export const getAppUrl = () => {
  const q = queryToJson(window.location.search);
  let appUrl = '';

  if (q && q.app_url) {
    appUrl = decodeURIComponent(q.app_url);
  } else {
    appUrl = document.referrer;
  }

  if (process.env.REACT_APP_TRACKING_ENV === 'local') {
    appUrl =
      'https://demand-iq-719.calculator-dev.demand-iq.com?referring_url=https://localhost:3000?this=test&what=where';
  }

  return appUrl;
};

export const getWidgetType = () => {
  const appUrl = getAppUrl();

  if (appUrl.indexOf('get-estimate-by-address') >= 0) return 'address';

  if (appUrl.indexOf('get-estimate-by-zipcode') >= 0) return 'zipcode';

  if (appUrl.indexOf('get-estimate') >= 0) return 'banner';

  return '';
};

export const isBannerExperimentApplied = () => {
  const widget_type = getWidgetType();

  if (widget_type === 'banner') {
    const appUrl = getAppUrl();

    if (appUrl.indexOf('be_applied=True') >= 0) return true;
  }

  return false;
};

export const setIframeInfo = () => {
  const q = queryToJson(window.location.search);
  const appUrl = getAppUrl();
  let referringUrl = '';

  if (q && q.preview) {
    previewMode = true;
  }
  if (q && q.page) previewPage = q.page;

  if (q && q.hide_header) {
    visibleHeader = q.hide_header !== 'true';
  }
  if (q && q.hide_footer_links) {
    visibleFooterLinks = q.hide_footer_links !== 'true';
  }

  let utmParams;
  if (q && q.referring_url) {
    referringUrl = decodeURIComponent(q.referring_url);
    const referringUrlObj = new URL(referringUrl);
    utmParams = queryToJson(referringUrlObj.search);
  }

  documentReferrer = extractHostname(appUrl);
  if (documentReferrer) {
    sessionAPIInstance.defaults.headers.common['STELLA-APP-URL'] =
      documentReferrer;
  }

  // migrate users from cookie to jwt token
  if (!getLocalStorageItem('sessionId') && getCookie('sessionid')) {
    setLocalStorageItem('sessionId', getCookie('sessionid'));
  }

  const sessionId = getLocalStorageItem('sessionId');
  if (sessionId)
    sessionAPIInstance.defaults.headers.common['STELLA-SESSION-ID'] = sessionId;

  if (appUrl) {
    const urlObj = new URL(appUrl);
    let pathname = urlObj.pathname || '';
    if (pathname && pathname.substr(-1) === '/') {
      pathname = pathname.substr(0, pathname.length - 1);
    }
    pathname = pathname.split('/');
    if (pathname.length === 3 && pathname[1] === 'resume') {
      resumeKey = pathname[2];
    } else if (pathname.length === 3 && pathname[1] === 'proposals') {
      proposalKey = pathname[2];
    } else if (
      pathname.length >= 2 &&
      (pathname[1] === 'get-estimate' ||
        pathname[1] === 'get-estimate-by-address')
    ) {
      defaultAddressData = {
        address: urlObj.searchParams.get('padr'),
        placeId: urlObj.searchParams.get('ppid'),
      };
    } else if (
      pathname.length >= 2 &&
      pathname[1] === 'get-estimate-by-zipcode'
    ) {
      defaultZipcode = {
        zipcode: urlObj.searchParams.get('pzc'),
        lat: urlObj.searchParams.get('plat'),
        lng: urlObj.searchParams.get('plng'),
        lang: urlObj.searchParams.get('lang') ?? 'en',
      };
    }

    // urlObj.search includes original_source param
    utmParams = {
      ...utmParams,
      ...queryToJson(urlObj.search),
    };
  }

  utmParams.utm_source = utmParams.utm_source || utmParams.request_id;

  queryStringObj = {
    ...utmParams,
    referring_url: referringUrl,
    widget_type: getWidgetType(),
    banner_experiment_applied: isBannerExperimentApplied(),
  };
};

const lambdaAPIInstance = axios.create({
  baseURL: 'https://co99g5c344.execute-api.us-west-2.amazonaws.com/test',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getAddresses = async (value) => {
  try {
    const { data } = await lambdaAPIInstance({
      url: '/lookup-address',
      method: 'post',
      data: { search: value },
    });
    return { data: data.data };
  } catch (error) {
    console.error(error);
    return { data: [] };
  }
};

export const getGeoFromIp = async () => {
  try {
    const { data } = await axios.get(
      `https://ipapi.co/json?key=${process.env.REACT_APP_IPAPI_KEY}`
    );
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const verifyEmail = async (email) => {
  try {
    const { data } = await lambdaAPIInstance({
      url: '/verify-email',
      method: 'post',
      data: {
        email,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
  return false;
};

export const verifyPhoneNumber = async (phone_number) => {
  try {
    const { data } = await lambdaAPIInstance({
      url: '/verify-phone',
      method: 'post',
      data: {
        phone_number,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
  return false;
};

export const calculate = async (params) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-api-key': 'uVNr3uVcKHa620Gh0ND3Y5rk4L2sBBDE69a5ThCo',
  };
  return lambdaAPIInstance({
    url: '/calculate',
    method: 'post',
    data: params,
    headers,
  });
};

export const saveSessionToStorage = (params) => {
  setLocalStorageItem('__stella_user_session__', JSON.stringify(params));
  return true;
};

export const getSessionFromStorage = () => {
  try {
    const data = getLocalStorageItem('__stella_user_session__') || '{}';
    return JSON.parse(data);
  } catch (error) {
    return null;
  }
};

export const createSession = async (variation = null) => {
  if (previewMode) return false;
  try {
    if (sessionAPIInstance.defaults.headers.common['STELLA-SESSION-ID']) {
      delete sessionAPIInstance.defaults.headers.common['STELLA-SESSION-ID'];
    }

    const queryString = { ...queryStringObj };
    if ('fbclid' in queryString) {
      queryString.fbclid = [queryString.fbclid];
    }

    if ('gclid' in queryString) {
      queryString.gclid = [queryString.gclid];
    }

    const { data } = await sessionAPIInstance.post('/api/progresses/me/', {
      ...queryString,
      variation,
    });

    setLocalStorageItem('sessionId', data.session_id);
    sessionAPIInstance.defaults.headers.common['STELLA-SESSION-ID'] =
      data.session_id;

    return true;
  } catch (error) {
    console.error(error);
  }
  return false;
};

export const resumeSession = async () => {
  if (previewMode) return false;
  try {
    if (resumeKey) {
      const { data } = await sessionAPIInstance.get(
        `/api/progresses/${resumeKey}/session-id/`
      );

      setLocalStorageItem('sessionId', data.session_id);
      sessionAPIInstance.defaults.headers.common['STELLA-SESSION-ID'] =
        data.session_id;

      return true;
    }
    return false;
  } catch (error) {
    console.error(error);
  }
  return false;
};

export const loadSession = async () => {
  if (previewMode) return false;
  try {
    const { data } = await sessionAPIInstance.get('/api/progresses/me/');
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const storeSession = async (params) => {
  if (previewMode) return false;
  try {
    const sessionData = { ...params };
    const queryString = { ...queryStringObj };

    if ('fbclid' in queryString) {
      if (Array.isArray(sessionData.fbclid)) {
        if (sessionData.fbclid.indexOf(queryString.fbclid) === -1) {
          sessionData.fbclid.push(queryString.fbclid);
        }
      } else {
        sessionData.fbclid = [queryString.fbclid];
      }
    }

    if ('gclid' in queryString) {
      if (Array.isArray(sessionData.gclid)) {
        if (sessionData.gclid.indexOf(queryString.gclid) === -1) {
          sessionData.gclid.push(queryString.gclid);
        }
      } else {
        sessionData.gclid = [queryString.gclid];
      }
    }

    const { data } = await sessionAPIInstance.patch(
      '/api/progresses/me/',
      sessionData
    );

    return data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const uploadFiles = async (params) => {
  if (previewMode) return null;
  try {
    const { data } = await sessionAPIInstance.post(
      '/api/progresses/me/utility-bills/',
      params,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendUtilityBillEmail = async () => {
  if (previewMode) return null;
  try {
    const { data } = await sessionAPIInstance.post(
      '/api/progresses/me/utility-bills/send-email/'
    );
    return data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const getProviders = async (value) => {
  try {
    const { data } = await axios({
      url: 'https://urjanet-lookup-provider.demand-iq.com',
      method: 'get',
      params: {
        q: value,
      },
    });
    return { data: data.data };
  } catch (error) {
    console.error(error);
    return { data: [] };
  }
};

export const loadSolarCompany = async () => {
  try {
    const companyHost = documentReferrer
      .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
      .split('/')[0];

    // const { data } = await axios({
    //   url: `/api/solarcompanies/${companyHost}/`,
    //   method: 'get',
    // });
    const baseApiUrl = process.env.REACT_APP_API_URL
      ? process.env.REACT_APP_API_URL
      : '';
    const response = await fetch(
      `${baseApiUrl}/api/solarcompanies/${companyHost}/`,
      {
        method: 'get',
      }
    );
    if (!response.ok) {
      throw Error(response.statusText);
    }
    const data = response.json();
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const loadVariations = async () => {
  try {
    const { data } = await sessionAPIInstance.get('/api/variations/');
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const notifyOfNewLead = () => {
  if (previewMode) return;
  // sessionAPIInstance.post('/api/notify-of-new-lead/');
  // const sessionId = getLocalStorageItem('sessionId');
  const sessionId =
    sessionAPIInstance.defaults.headers.common['STELLA-SESSION-ID'];
  navigator.sendBeacon(
    `/api/unload/?session_id=${sessionId}&app_url=${documentReferrer}`
  );
};

export const getResumeKey = () => !!resumeKey;

export const getProposalKey = () => !!proposalKey;

export const getProposal = async () => {
  if (previewMode) return null;
  if (!proposalKey) {
    return null;
  }
  try {
    const { data } = await sessionAPIInstance.get(
      `/api/proposals/${proposalKey}/`
    );
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const answerQualifyQuestion = async (questionId, answer) => {
  if (previewMode) return null;
  try {
    const { data } = await sessionAPIInstance.post(
      `/api/progresses/me/questions/${questionId}/answer/`,
      {
        answer,
      }
    );
    return data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const fetchUtilityBills = async (params) => {
  if (previewMode) return null;
  try {
    const { data } = await sessionAPIInstance.post(
      '/api/urjanet/fetch-utility-bill/',
      params
    );
    return data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const engineSolarGetInfo = async ({ latitude, longitude }, apiKey) => {
  try {
    const { data } = await axios({
      url: `${process.env.REACT_APP_GOOGLE_EARTH_API_URL}/dataLayers:get`,
      method: 'get',
      params: {
        'location.latitude': latitude,
        'location.longitude': longitude,
        radiusMeters: 100,
        key: process.env.REACT_APP_GOOGLE_MAP_KEY,
      },
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

let solarData;
let lat;
let long;

export const engineSolarFindBuilding = async (
  { latitude, longitude },
  apiKey
) => {
  if (solarData && lat === latitude && long === longitude) {
    return solarData;
  }
  try {
    const { data } = await axios({
      url: `${process.env.REACT_APP_GOOGLE_EARTH_API_URL}/buildingInsights:findClosest`,
      method: 'get',
      params: {
        'location.latitude': latitude,
        'location.longitude': longitude,
        key: process.env.REACT_APP_GOOGLE_MAP_KEY,
      },
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
    });
    solarData = { success: true, data };
  } catch (error) {
    Sentry.configureScope((scope) => {
      scope.setTag('googleapi', 'sunroof');
      if (error.response) {
        scope.setExtra('responseHeader', error.response.headers);
        scope.setExtra('responseData', error.response.data);
      }
      Sentry.captureException(error);
    });
    solarData = { success: false, error };
  }
  lat = latitude;
  long = longitude;
  return solarData;
};

export const trackForwardProgress = async () => {
  if (previewMode) return null;
  try {
    await sessionAPIInstance.post('/api/progresses/me/track-activity/', {
      activity_type: 'forward_step',
    });
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const trackPhoneClick = async () => {
  if (previewMode) return null;
  try {
    await sessionAPIInstance.post('/api/progresses/me/track-activity/', {
      activity_type: 'phone_clicks',
    });
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const getDefaultAddressData = () => defaultAddressData;

export const setDefaultAddressData = (data) => {
  defaultAddressData = data;
};

export const getDefaultZipcode = () => defaultZipcode;

export const setDefaultZipcode = (data) => {
  defaultZipcode = data;
};

export const verifyZipCode = async (zipCode) => {
  try {
    const response = await sessionAPIInstance.get(
      `/api/verify-zip-code/?zip_code=${zipCode}`
    );
    const data = {
      status: true,
      is_coverage_area: response.data.is_zip_code_outside_of_sunroof_coverage,
    };
    return data;
  } catch (error) {
    console.error(error);
    return {
      status: false,
      is_coverage_area: false,
    };
  }
};

export const getCustomPricePerWatt = async (state) => {
  try {
    const { data } = await sessionAPIInstance.get(
      `/api/price-per-watt/?state=${state}`
    );
    return data;
  } catch (e) {
    return {};
  }
};

export const getCustomGridRate = async (state) => {
  try {
    const { data } = await sessionAPIInstance.get(
      `/api/grid-rate/?state=${state}`
    );
    return data;
  } catch (e) {
    return {};
  }
};

export const getCompanyHost = () => {
  return documentReferrer
    .replace(/^(?:https?:\/\/)?(?:www\.)?/i, '')
    .split('/')[0];
};

export const confirmVerificationCode = async (params) => {
  if (previewMode) return null;
  try {
    const { data } = await sessionAPIInstance.post(
      '/api/confirm-phone-verification-code/',
      params
    );
    return data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const sendVerificationCode = async (params) => {
  if (previewMode) return { detail: false };
  try {
    const { data } = await sessionAPIInstance.post(
      '/api/send-phone-verification-code/',
      params
    );
    return data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const appointmentTimes = async (param) => {
  if (previewMode) return null;

  try {
    let queryParams = '';
    if (param) {
      const { start_at = null, end_at = null } = param;

      if (start_at && end_at) {
        queryParams = `?start_at=${start_at}&end_at=${end_at}`;
      }
    }

    const { data } = await sessionAPIInstance.get(
      `/api/progresses/me/appointment-times/${queryParams}`
    );
    return data;
  } catch (error) {
    console.error(error);
  }

  return null;
};

export const availableCalendarTimes = async (params) => {
  if (previewMode) return null;
  try {
    const { data } = await sessionAPIInstance.post(
      '/api/progresses/me/calendar-available-slots/',
      params
    );
    return data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const validateAppointmentTime = async (param) => {
  if (previewMode) return false;
  try {
    const { data } = await sessionAPIInstance.post(
      '/api/progresses/me/check-duplication-meeting-time/',
      param
    );
    return data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const appointCalendarTime = async (param) => {
  if (previewMode) return false;
  try {
    const { data } = await sessionAPIInstance.post(
      '/api/progresses/me/appoint-calendar-time/',
      param
    );
    return data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const getEventData = async (solarCompany, param) => {
  try {
    const { data } = await sessionAPIInstance.post(
      `/api/solarcompanies/${solarCompany}/invitee/`,
      param
    );
    return data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const getReferrerUrl = () => {
  return queryStringObj.referring_url;
};

export const getOriginalSource = () => {
  return queryStringObj.original_source;
};

export const getCustomEscalation = (state) =>
  sessionAPIInstance.get(`/api/custom-escalation/?state=${state}`);

export const getCustomIncentive = (zipCode) =>
  sessionAPIInstance.get(`/api/custom-incentive/?zip_code=${zipCode}`);

export const trackFacebookPixelFromServer = async (param) => {
  try {
    const body = {
      ...param,
      fbc: queryStringObj.fbclid,
    };
    const { data } = await sessionAPIInstance.post(
      '/api/progresses/me/facebook-pixel/track/',
      body
    );
    return data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const getPreviewPageName = () => previewPage;

export const getExitIntent = async () => {
  try {
    const { data } = await sessionAPIInstance.get(
      '/api/progresses/exit-intent/'
    );
    return data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const createExitIntent = async (params) => {
  try {
    const response = await sessionAPIInstance.post(
      '/api/progresses/exit-intent/',
      params
    );
    return response;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const lookupPhoneNumber = async (params) => {
  try {
    const { data } = await sessionAPIInstance.post(
      '/api/lookup-phone-number/',
      params
    );
    return data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const getCustomScriptsCode = async () => {
  try {
    const { data } = await sessionAPIInstance.get(
      '/api/progresses/me/custom-script-codes-fired/'
    );
    return data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const setCustomScriptsCode = async (params) => {
  try {
    const { data } = await sessionAPIInstance.patch(
      '/api/progresses/me/custom-script-codes-fired/',
      params
    );
    return data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

const sungageAPIInstance = axios.create({
  // baseURL: process.env.REACT_APP_SUNGAGE_API_URL,
  baseURL:
    'https://stg-sungage.cs213.force.com/apply/services/apexrest/ProjectApi/v01',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getSungageProject = async (params) => {
  try {
    const res = await sungageAPIInstance({
      url: '/project?ak=ab90ed93581251ff6627d99749be93dd&ue=ming.shi@demand-iq.com',
      method: 'get',
      params: { ...params },
    });
    return res;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const createSungageProject = async (data) => {
  try {
    const res = await sungageAPIInstance({
      url: '/project?ak=ab90ed93581251ff6627d99749be93dd&ue=ming.shi@demand-iq.com',
      method: 'post',
      data: { ...data },
    });
    return res.data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const updateSungageProject = async (data) => {
  try {
    const res = await sungageAPIInstance({
      url: '/project?ak=ab90ed93581251ff6627d99749be93dd&ue=ming.shi@demand-iq.com',
      method: 'put',
      data: { ...data },
    });
    return res.data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const startSungageCredit = async (data) => {
  try {
    const res = await sungageAPIInstance({
      url: '/application?ak=ab90ed93581251ff6627d99749be93dd&ue=ming.shi@demand-iq.com',
      method: 'post',
      data: { ...data },
    });
    return res.data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const checkSungageCredit = async (data) => {
  try {
    const res = await sungageAPIInstance({
      url: '/application?ak=ab90ed93581251ff6627d99749be93dd&ue=ming.shi@demand-iq.com',
      method: 'put',
      data: { ...data },
    });
    return res.data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const prequalSungageCredit = async (data) => {
  try {
    const res = await sungageAPIInstance({
      url: '/application?ak=ab90ed93581251ff6627d99749be93dd&ue=ming.shi@demand-iq.com',
      method: 'put',
      data: { ...data },
    });
    return res.data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const createSungage = async () => {
  try {
    const res = await sessionAPIInstance.post('/api/financial/sungage');
    return res.data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const storeSungage = async (data) => {
  try {
    const res = await sessionAPIInstance.patch('/api/financial/sungage', data);
    return res.data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const createSunlight = async (data) => {
  try {
    const res = await sessionAPIInstance.post('/api/financial/sunlight', data);
    return res.data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const storeSunlight = async (data) => {
  try {
    const res = await sessionAPIInstance.patch('/api/financial/sunlight', data);
    return res.data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const prequalSunlight = async () => {
  try {
    const res = await sessionAPIInstance.get(
      '/api/financial/sunlight/pre-qualify'
    );
    return res.data;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const loadSunlightDisclosures = async () => {
  try {
    const res = await sessionAPIInstance.get(
      '/api/financial/sunlight/disclosures'
    );
    return res.data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export const getVisibleHeader = () => visibleHeader;
export const getVisibleFooterLinks = () => visibleFooterLinks;
