import styled from 'styled-components';

const Retry = styled.div`
  .link-text {
    color: ${(props) => props.theme.color_scheme_button};
    text-decoration: underline;
  }
`;

export default Retry;
