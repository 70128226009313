import styled from 'styled-components';
import { pxToRem } from 'common/utils';

const MeetingCalendly = styled.div`
  .form-title {
    font-family: StellaFont, Arial, Helvetica, sans-serif;
    font-size: 1.875rem;
    color: ${(props) => props.theme.color_scheme_dark};
    line-height: 1;
  }

  .form-subtext {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 1rem;
    color: #666;
  }

  .form-note {
    font-family: Roboto, arial;
    font-weight: bold;
    font-size: 0.75;
    text-align: center;
    color: #000000;
  }

  .form-datepicker {
    width: 100%;
    margin: 0 auto;
    height: 100%;

    @media screen and (min-width: 768px) {
      // max-width: 25rem;
    }
  }

  .form-content {
    display: flex;
    flex-direction: column;
  }

  #pickerWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  #form-meeting1 {
    @media screen and (min-width: 768px) {
      max-width: none;
      align-items: center;
      .form-content {
        width: 100%;
        max-width: ${pxToRem(555)};
      }
      .form-footer {
        width: 100%;
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
        padding-left: calc(50% - ${pxToRem(555 / 2)} + ${pxToRem(20)});
        padding-right: calc(50% - ${pxToRem(555 / 2)} + ${pxToRem(20)});
      }
    }
  }

  .loading-overlay {
    position: unset;
    height: 100px;
  }

  .event-list {
    text-align: left;
  }

  .event-group {
  }

  .event-group-title {
    font-size: 20px;
    color: #ccc;
    margin: 32px 0 16px;
  }

  .event-item {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin-top: 8px;
    cursor: pointer;
    transition: all 0.2s;
    user-select: none;
    &:hover {
      background-color: #f2f2f2;
    }
    &:active {
      background-color: #fff;
    }
  }

  .back-event-list {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin-top: 8px;
    cursor: pointer;
    transition: all 0.2s;
    user-select: none;
    margin-bottom: 8px;
    &:hover {
      background-color: #f2f2f2;
    }
    &:active {
      background-color: #fff;
    }
  }
`;

export default MeetingCalendly;
