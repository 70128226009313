import styled from 'styled-components';

const Calculate2 = styled.div`
  .card {
    margin: 2.25rem auto;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    padding: 2.25rem 2rem;

    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 1rem;

    .average-bill-text {
      font-family: 'Effra Light';
      font-size: 17px;
      line-height: 18px;
    }

    .average-bill {
      font-size: 2.625rem;
      font-weight: 500;
      color: #231f20;
    }
  }
`;

export const TooltipTitle = styled.div`
  font-size: 0.75rem;
  font-weight: 700;
  color: #fff;
  background-color: ${(props) => props.theme.color_scheme_light};
  padding: 0.25rem;
`;

export const TooltipContent = styled.div`
  margin: 0.25rem 0;
`;

export const TooltipSubContent = styled.div`
  font-size: 0.75rem;
  color: #666;
  margin: 0.25rem 0;
`;

export default Calculate2;
