import React from 'react';
import StyledCheckbox from './styled';

const Checkbox = ({ id = null, onChange, children }) => (
  <StyledCheckbox className="checkbox">
    {children}
    <input id={id ?? null} type="checkbox" onChange={onChange} />
    <span className="checkmark"></span>
  </StyledCheckbox>
);

export default Checkbox;
