import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'common/utils';
import { TextField } from '@material-ui/core';
import { useOutlinedTextFieldStyles } from 'components/Forms/TextInput/styled';

const TextInput = ({ type, theme, value, onChange, placeholder, ...props }) => {
  const classes = useOutlinedTextFieldStyles({ theme });
  return (
    <TextField
      variant="outlined"
      className={classes.outlinedTextField}
      type={type}
      onChange={onChange}
      label={placeholder}
      value={value}
      {...props}
    />
  );
};

TextInput.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  theme: PropTypes.object,
};

TextInput.defaultProps = {
  type: 'text',
  placeholder: 'Enter a text',
  onChange: noop,
  value: '',
  theme: null,
};

export default TextInput;
