import styled from 'styled-components';

const Button = styled.button`
  display: ${(props) => (props.full ? 'flex' : 'inline-flex')};
  width: ${(props) => (props.full ? '100%' : 'initial')};
  font-weight: 700;
  text-align: center;
  white-space: normal;
  min-width: 3.125rem;
  text-transform: ${(props) => (props.link ? 'capitalize' : 'uppercase')};
  justify-content: ${(props) => (props.left ? 'flex-start' : 'center')};
  align-items: center;
  border-radius: ${(props) =>
    props.theme.button_shape === 'square' || props.link
      ? 0
      : props.theme.button_shape === 'semiround'
      ? 3
      : 100}px;
  border: 1px solid
    ${(props) =>
      props.borderless || !props.outline
        ? 'transparent'
        : props.theme.color_scheme_button};
  color: ${(props) =>
    props.outline || props.link
      ? props.theme.color_scheme_button
      : props.theme.color_scheme_button_text};
  background-color: ${(props) =>
    props.outline || props.link ? '#ffffff' : props.theme.color_scheme_button};
  font-size: 1.125rem;
  line-height: 1.25rem;
  height: initial;
  padding: ${(props) => (props.link ? '0' : '0.75rem 1rem')};
  font-family: ${(props) => (props.link ? 'Roboto' : 'inherit')};
  text-decoration: ${(props) => (props.link ? 'underline' : 'initial')};
  position: relative;
  outline: none;

  &:not(.btn-disabled):not(:disabled) {
    cursor: pointer;
  }

  &.btn-disabled,
  &:disabled {
    opacity: 0.65;
  }

  &:focus {
    outline: none;
  }

  &:hover:not(.btn-disabled):not(:disabled) {
    border: 1px solid
      ${(props) =>
        props.borderless || !props.outline
          ? 'transparent'
          : props.theme.color_scheme_button_hover};
    color: ${(props) =>
      props.outline || props.link
        ? props.theme.color_scheme_button_hover
        : props.theme.color_scheme_button_hover_text};
    background-color: ${(props) =>
      props.outline || props.link
        ? '#ffffff'
        : props.theme.color_scheme_button_hover};
  }

  @media screen and (min-width: 768px) {
    font-size: 1.375rem;
    line-height: 1.5rem;
    padding: ${(props) => (props.link ? '0' : '0.625rem 1rem')};
  }

  & > svg {
    max-width: 1.125rem;
    max-height: 1.125rem;
    flex-shrink: 0;
    margin: 0 0.625rem;
    color: ${(props) =>
      props.outline || props.link
        ? props.theme.color_scheme_button
        : props.theme.color_scheme_button_text};
    fill: ${(props) =>
      props.outline || props.link
        ? props.theme.color_scheme_button
        : '#ffffff'};

    line {
      stroke: ${(props) =>
        props.outline || props.link
          ? props.theme.color_scheme_button
          : props.theme.color_scheme_button_text};
    }

    path {
      fill: ${(props) =>
        props.outline || props.link
          ? props.theme.color_scheme_button
          : props.theme.color_scheme_button_text};
    }
  }

  &:hover:not(.btn-disabled):not(:disabled) > svg {
    color: ${(props) =>
      props.outline || props.link
        ? props.theme.color_scheme_button_hover
        : props.theme.color_scheme_button_hover_text};
    fill: ${(props) =>
      props.outline || props.link
        ? props.theme.color_scheme_button_hover
        : '#fff'};

    line {
      stroke: ${(props) =>
        props.outline || props.link
          ? props.theme.color_scheme_button_hover
          : props.theme.color_scheme_button_hover_text};
    }

    path {
      fill: ${(props) =>
        props.outline || props.link
          ? props.theme.color_scheme_button_hover
          : props.theme.color_scheme_button_hover_text};
    }
  }
`;

export default Button;
