import React from 'react';
import { getLocalStorageItem } from 'common/localStorage';
import StyledSliderCard from './styled';

const SliderCard = ({ title, content, subcontent }) => {
  const appLanguage = getLocalStorageItem('i18nextLng');
  return (
    <StyledSliderCard className="slider-card" lang={appLanguage}>
      {title && <div className="slider-card-title">{title}</div>}
      {content && <div className="slider-card-content">{content}</div>}
      {subcontent && <div className="slider-card-subcontent">{subcontent}</div>}
    </StyledSliderCard>
  );
};

export default SliderCard;
