import styled from 'styled-components';

const StyledWrapper = styled.div`
  position: relative;
  margin: 0 auto;

  .google-address-dropdown-container {
    display: none;

    &--open {
      display: block;
      position: absolute;
      top: calc(2.5rem - 1px);
      width: 100%;
      font-size: 0.875rem;
      line-height: 1rem;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      z-index: 2;
      max-height: 9rem;
      overflow: auto;
    }
  }

  .google-address-suggestion-item {
    cursor: pointer;
    padding: 0.625rem 0.75rem;
    color: #717171;

    &--active {
      background-color: ${(props) => props.theme.color_scheme_dark};
      color: #fff;
    }
  }

  .google-address-suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &.input-group {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export default StyledWrapper;
