import styled from 'styled-components';

const StyledDigitInput = styled.input`
  display: block;
  width: 54px;
  font-size: 32px;
  border-radius: 8px;
  text-align: center;
  color: ${(props) => props.theme.color_scheme_font};
  border: 1px solid ${(props) => props.theme.color_scheme_dark};
  background-color: ${(props) => props.theme.color_scheme_neutral};
  padding: 0.625rem 1rem;
  margin-right: 15px;

  &:focus {
    outline: none;
  }

  &.has-error {
    border-color: #f44336;
  }

  &::-webkit-input-placeholder {
    color: ${(props) => props.theme.color_scheme_label};
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-style: italic;
    opacity: 1;
  }
  &:-moz-placeholder {
    /* FF 4-18 */
    color: ${(props) => props.theme.color_scheme_label};
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-style: italic;
    opacity: 1;
  }
  &::-moz-placeholder {
    /* FF 19+ */
    color: ${(props) => props.theme.color_scheme_label};
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-style: italic;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: ${(props) => props.theme.color_scheme_label};
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-style: italic;
    opacity: 1;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) => props.theme.color_scheme_label};
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-style: italic;
    opacity: 1;
  }
  &::placeholder {
    /* modern browser */
    color: ${(props) => props.theme.color_scheme_label};
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-style: italic;
    opacity: 1;
  }

  @media screen and (max-width: 479px) {
    width: 45px;
    font-size: 25px;
    border-radius: 8px;
    padding: 0.625rem 0.5rem;
  }

  @media screen and (max-width: 374px) {
    width: 36px;
    font-size: 20px;
    padding: 0.625rem 0.5rem;
    margin-right: 10px;
  }
`;

export default StyledDigitInput;
