import React, { useState } from 'react';
import { connect } from 'react-redux';
import TermsModal from 'components/TermsModal';
import moment from 'moment';
import { generateID } from 'common/utils';
import StyledFooter from './styled';

const Footer = ({ theme }) => {
  const [showTerm, setShowTerm] = useState(false);

  const openWithNewWindow = (url) => {
    if (!url) {
      return;
    }
    window.open(url, '_new');
  };

  return (
    <>
      <StyledFooter id={generateID('div-footer', 'footer')}>
        <div className="privacy">
          Experience by Demand IQ | &copy;{moment().format('YYYY')} | Use is
          subject to&nbsp;
          <span
            id={generateID('btn-terms', 'footer')}
            onClick={() => setShowTerm(true)}
          >
            Terms
          </span>
          &nbsp;&amp;&nbsp;
          <span
            id={generateID('btn-privacy-policy', 'footer')}
            onClick={() => openWithNewWindow(theme.privacy_policy)}
          >
            Privacy Policy
          </span>
        </div>
        {showTerm && <TermsModal onClose={() => setShowTerm(false)} />}
      </StyledFooter>
    </>
  );
};

const mapStateToProps = ({ ui: { theme } }) => ({
  theme,
});

export default connect(mapStateToProps)(Footer);
