import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DefaultLayout from 'components/DefaultLayout';
import MaterialStepper from 'components/MaterialStepper';
import Chat from 'components/Chat';
import ButtonSelect from 'components/ButtonSelect';
import { setUserData, saveSession } from 'store/actions';
import { withTranslation } from 'react-i18next';
import * as APIMock from 'common/api.mock';
import { generateID } from 'common/utils';
import StyledPreQualify from './styled';

class PreQualify extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1000);
  }

  handleNext = async (step = '/qualify/end') => {
    const { history, actions } = this.props;
    actions.setUserData({ step });
    await actions.saveSession();
    history.push(step);
  };

  handleSelect = async (value) => {
    if (value !== 'yes') {
      this.handleNext();
      return;
    }

    const {
      user: { sunlight, sungage },
      theme: { pre_qualification_partner },
    } = this.props;

    let res;
    if (pre_qualification_partner === 'sunlight') {
      res = sunlight
        ? await APIMock.storeSunlight({ step_from: '/prequalify' })
        : await APIMock.createSunlight({ step_from: '/prequalify' });

      if (res) this.handleNext('/prequalify/sunlight1');
    } else if (pre_qualification_partner === 'sungage') {
      res = sungage
        ? await APIMock.storeSungage({ step_from: '/prequalify' })
        : await APIMock.createSungage({ step_from: '/prequalify' });

      if (res) this.handleNext('/prequalify/sungage1');
    }
  };

  render() {
    const { t } = this.props;
    const { loading } = this.state;

    const {
      location: { pathname },
    } = this.props;

    return (
      <DefaultLayout>
        <StyledPreQualify className="content custom-stepper">
          <div className="form">
            <div
              id={generateID('div-material-stepper', pathname)}
              className="material-progress"
            >
              <MaterialStepper activeStep={1} />
            </div>

            <div className="form-content">
              <Chat
                id={generateID('chat-prequalify-question', pathname)}
                mode="loading"
                loading={loading}
              >
                {!loading && (
                  <>
                    <div className="mb-16">
                      {t(
                        'Would you like to get pre-qualified for $0 down financing?'
                      )}
                    </div>
                    <div
                      id={generateID('btn-prequalify-answer', pathname)}
                      className="mb-16"
                    >
                      <ButtonSelect
                        options={[
                          { label: t('Yes'), value: 'yes' },
                          { label: t("I'm a cash buyer"), value: 'buyer' },
                          { label: t('Skip for now'), value: 'skip' },
                        ]}
                        onChange={this.handleSelect}
                      />
                    </div>
                  </>
                )}
              </Chat>
            </div>
          </div>
        </StyledPreQualify>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui }) => ({
  user: data,
  theme: ui.theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(PreQualify)
);
