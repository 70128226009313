import styled from 'styled-components';

const Avatar = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  width: ${(props) => props.size / 16}rem;
  height: ${(props) => props.size / 16}rem;
  border-radius: 50%;
  padding: ${(props) => Math.floor(props.size / 20)}px;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;

  .avatar-img {
    width: ${(props) => (props.size - Math.floor(props.size / 20) * 2) / 16}rem;
    height: ${(props) =>
      (props.size - Math.floor(props.size / 20) * 2) / 16}rem;
    border-radius: 50%;
    overflow: hidden;
  }
`;

export default Avatar;
