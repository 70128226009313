import React from 'react';

const Loading = ({ id = null }) => (
  <div id={id ?? null} className="loading-overlay">
    <div id="loading">
      <span className="dot"></span>
      <span className="dot"></span>
      <span className="dot"></span>
    </div>
  </div>
);

export default Loading;
