import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DefaultLayout from 'components/DefaultLayout';
import Button from 'components/Button';
import ProgressBar from 'components/ProgressBar';
import Chat from 'components/Chat';
import SlickSlider from 'components/SlickSlider';
import SliderCard from 'components/SliderCard';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import { formatNumber, generateID } from 'common/utils';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackForwardProgress,
} from 'store/actions';
import { withTranslation } from 'react-i18next';
import StyledResult1 from './styled';

class Result1SpanishVariation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      show: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ step: 2 });
    }, 2000);
  }

  handleBack = () => {
    const { actions } = this.props;
    actions.navigateTo('/calculate2');
  };

  handleNext = async () => {
    const { actions } = this.props;
    actions.setUserData({ step: '/result2' });
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo('/result2');
  };

  render() {
    const { user: data, saving, t } = this.props;
    const { step, show } = this.state;

    const {
      location: { pathname },
    } = this.props;

    return (
      <DefaultLayout>
        <StyledResult1 className="content result-1-page">
          <div className="form">
            <div className="form-content">
              <div
                id={generateID('div-progress-bar', pathname)}
                className="mb-20 w100"
              >
                <ProgressBar value={75} step={3} />
              </div>

              <Chat id={generateID('chat-check-out-these-savings', pathname)}>
                <div className="mb-16">{t('Check out these savings!')}</div>
                <div>
                  <SlickSlider>
                    <SliderCard
                      title={t('Approximate Lifetime Savings')}
                      content={`$${formatNumber(data.savings)}`}
                      subcontent={t('Over 25 years')}
                    />
                    <SliderCard
                      title={t('Increase in Home Value')}
                      content="4.1%"
                      subcontent={t('According to Zillow')}
                    />
                    <SliderCard
                      title={t('Estimated Environmental Impact')}
                      content={`${data.tree_seedlings}`}
                      subcontent={t('Trees grown for 10 yrs')}
                    />
                    <SliderCard
                      title={t('Recommended System')}
                      content={`${data.install_size_min}-${data.install_size_max} kW`}
                      subcontent={t('({{panels_min}}-{{panels_max}} panels)', {
                        panels_min: data.panels_min,
                        panels_max: data.panels_max,
                      })}
                    />
                  </SlickSlider>
                </div>
              </Chat>

              {step > 1 && (
                <Chat
                  id={generateID('chat-ready-to-see-ballpark', pathname)}
                  onShow={() => this.setState({ show: true })}
                >
                  {t('Ready to see ballpark pricing?')}
                </Chat>
              )}
            </div>

            <div
              id={generateID('div-actions', pathname)}
              className="form-actions"
            >
              <Button
                id={generateID('btn-back', pathname)}
                outline
                onClick={this.handleBack}
              >
                <BackIcon />
              </Button>
              <Button
                id={generateID('btn-i-am-ready', pathname)}
                full
                disabled={!show || saving}
                onClick={this.handleNext}
              >
                {t("I'm ready!")}
              </Button>
            </div>
          </div>
        </StyledResult1>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ user: { data, saving } }) => ({
  user: data,
  saving,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Result1SpanishVariation)
);
