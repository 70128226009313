import React from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';
import StyledProgressBar from './styled';

const ProgressBar = ({ value, step }) => {
  const labels = ['Your Address', 'Average Bill', 'Savings', 'Pricing'];

  return (
    <StyledProgressBar className="progress-bar">
      <div className="progress-bar-label-container">
        {labels.map((label, index) => (
          <div
            key={index}
            className={cls('progress-bar-label', {
              'progress-bar-label--inactive': index >= step,
            })}
          >
            {label}
          </div>
        ))}
      </div>
      <div className="progress-bar-bg">
        <div
          className="progress-bar-active"
          style={{ width: `${value}%` }}
        ></div>
      </div>
    </StyledProgressBar>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number,
  step: PropTypes.number,
};

ProgressBar.defaultProps = {
  value: 0,
  step: 1,
};

export default ProgressBar;
