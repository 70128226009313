import styled from 'styled-components';
import { pxToRem } from 'common/utils';

const StyledSunlightFailure = styled.div`
  &.content.sunlight-failure-page {
    min-height: calc(100% - ${pxToRem(76)});

    @media screen and (max-width: 374px) {
      min-height: calc(100% - 61px);
    }

    @media screen and (min-width: 767px) {
      min-height: calc(100% - ${pxToRem(76)} - 3rem);
    }

    .main-content {
      @media screen and (min-width: 768px) {
        max-width: ${pxToRem(555)};
      }
    }
  }
`;

export default StyledSunlightFailure;
