import styled from 'styled-components';
import { pxToRem } from 'common/utils';

export const Sungage3 = styled.div`
  &.content.sungage3-page {
    min-height: calc(100% - ${pxToRem(76)});

    @media screen and (max-width: 374px) {
      min-height: calc(100% - 61px);
    }

    @media screen and (min-width: 767px) {
      min-height: calc(100% - ${pxToRem(76)} - 3rem);
    }

    .birth-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 0.875rem;
    }

    .social-container {
      width: 33%;
    }

    .error-message {
      color: red;
      font-weight: 600;
    }
  }
`;

export default Sungage3;
