import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import { mainTimezones, mainTimezonesEsLocale } from 'common/data';
import { generateID, getTheAvailableTimeFrames, roundDate } from 'common/utils';
import DefaultLayout from 'components/DefaultLayout';
import BackButton from 'components/BackButtonNRGVariation';
import MaterialStepper from 'components/MaterialStepperNRGVariation';
import Chat from 'components/ChatNRGVariation';
import cls from 'classnames';
import { ReactComponent as ClockIcon } from 'assets/images/icon-clock.svg';
import { BUFFER_TIME, GAP_TIME } from 'common/constants';
import { getLocalStorageItem } from 'common/localStorage';
import { withTranslation } from 'react-i18next';

import Button from 'components/Button';
import {
  setUserData,
  saveSession,
  navigateTo,
  appointmentTimes,
  availableCalendarTimes,
  trackForwardProgress,
  duplicationTime,
  appointCalendarTime,
} from '../../../store/actions';
import StyledMeeting2 from './styled';

class Meeting2 extends Component {
  constructor(props) {
    super(props);
    const { actions, theme } = props;

    const appLanguage = getLocalStorageItem('i18nextLng');
    const timezones =
      appLanguage === 'es'
        ? mainTimezonesEsLocale.map((t) => ({
            ...t,
            offset: moment.tz.zone(t.value).utcOffset(new Date()),
          }))
        : mainTimezones.map((t) => ({
            ...t,
            offset: moment.tz.zone(t.value).utcOffset(new Date()),
          }));

    const offset = moment.tz.zone(moment.tz.guess(true)).utcOffset(new Date());

    let timezone = timezones.filter((t) => t.offset === offset)[0];
    if (!timezone) {
      // eslint-disable-next-line prefer-destructuring
      timezone = timezones[3];
    }
    timezone = timezone.value;

    let gapTime = theme.appointment_gap_time;
    if (
      theme.appointment_gap_time === null ||
      theme.appointment_gap_time === undefined
    )
      gapTime = GAP_TIME;
    if (theme.is_duplication_meeting_prevented) {
      actions.appointmentTimes();

      if (theme.appointment_method === 'CRONOFY' && theme.appointment_cronofy) {
        actions.availableCalendarTimes({
          timezone: props.user.meeting_timezone || timezone,
          meeting_buffer_time: gapTime,
        });
      }
    }

    this.state = {
      timezone: props.user.meeting_timezone || timezone,
      timezones,
      meeting_time: props.user.meeting_time,
      saving: false,
    };
  }

  handleBack = () => {
    const { actions } = this.props;
    actions.navigateTo('/meeting1');
  };

  handleClick = async (value) => {
    this.setState({ meeting_time: value });

    const { actions, theme, user } = this.props;
    const { timezone } = this.state;
    if (!value) {
      return;
    }

    this.setState({ saving: true });

    actions.setUserData({
      timeframe: 'asap',
      meeting_time: value,
      meeting_timezone: timezone,
    });
    await actions.saveSession();

    if (theme.is_duplication_meeting_prevented) {
      await actions.duplicationTime({
        meeting_time: user.meeting_time,
        meeting_timezone: user.meeting_timezone,
      });
    } else if (
      theme.appointment_method === 'CRONOFY' &&
      theme.appointment_cronofy
    ) {
      await actions.appointCalendarTime({
        meeting_time: user.meeting_time,
        meeting_timezone: user.meeting_timezone,
      });
    }
    actions.setUserData({
      is_confirmed: true,
      timeframe: 'asap',
      step: user.is_instant_estimate ? '/result3' : '/start',
    });

    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo('/thankyou');
  };

  handleChange = (event) => {
    this.setState({ timezone: event.target.value });
  };

  getOptions = () => {
    const { theme } = this.props;

    const {
      user: { meeting_date },
      appointments,
      availableTimes,
    } = this.props;
    const { timezone } = this.state;
    const { appointment_timezone } = theme;

    let gapTime = theme.appointment_gap_time;
    if (
      theme.appointment_gap_time === null ||
      theme.appointment_gap_time === undefined
    )
      gapTime = GAP_TIME;

    const curTime = roundDate(
      moment().add(theme.appointment_time_buffer || BUFFER_TIME, 'hours')
    );
    const tz =
      !appointment_timezone ||
      appointment_timezone === 'custom_homeowner_timezone'
        ? 'UTC'
        : timezone;

    const frames = getTheAvailableTimeFrames(
      theme.appointment_schedule,
      {
        start: moment.max(moment.tz(meeting_date, tz).startOf('day'), curTime),
        end: moment.tz(meeting_date, tz).endOf('day'),
      },
      tz
    );

    const options = [];

    frames.forEach((frame) => {
      const start = moment.tz(frame.start, 'YYYY-MM-DDTHH:mm:ss', timezone);
      while (
        start.isBefore(moment.tz(frame.end, 'YYYY-MM-DDTHH:mm:ss', timezone))
      ) {
        let booked = false;
        let isAvailable =
          !theme.is_duplication_meeting_prevented ||
          theme.appointment_method !== 'CRONOFY' ||
          !theme.appointment_cronofy;
        appointments.forEach((time) => {
          if (time) {
            const appointmentStart = moment(time)
              .subtract(gapTime, 'minutes')
              .tz(timezone);
            const appointmentEnd = moment(time)
              .add(theme.appointment_duration, 'minutes')
              .add(gapTime, 'minutes')
              .tz(timezone);
            const startTime = start.clone();
            if (
              (startTime.isSameOrAfter(appointmentStart) &&
                startTime.isBefore(appointmentEnd)) ||
              (appointmentStart.isSameOrAfter(startTime) &&
                appointmentStart.isBefore(
                  startTime.add(theme.appointment_duration, 'minutes')
                ))
            ) {
              booked = true;
            }
          }
        });

        availableTimes.forEach((time) => {
          const appointmentStart = moment(time.start).tz(timezone);
          const appointmentEnd = moment(time.end).tz(timezone);
          const startTime = start.clone();
          if (
            startTime.isSameOrAfter(appointmentStart) &&
            startTime.isSameOrBefore(appointmentEnd)
          ) {
            isAvailable = true;
          }
        });
        if (!booked && isAvailable) {
          options.push({
            label: start.format('h:mm a'),
            value: start.format('HH:mm:00'),
          });
        }
        start.add('30', 'minutes');
      }
    });

    return options;
  };

  render() {
    const { timezone, timezones, meeting_time, saving } = this.state;
    const { loading, t } = this.props;

    const {
      location: { pathname },
    } = this.props;

    return (
      <>
        <DefaultLayout>
          <StyledMeeting2 className="content meeting-2-page custom-stepper">
            <div className="form">
              <div
                id={generateID('div-material-stepper', pathname)}
                className="material-progress"
              >
                <MaterialStepper step={2} value={95} />
              </div>

              <div
                id={generateID('div-timepicker-container', pathname)}
                className="form-content"
              >
                <Chat mode="loading" loading={!!loading || saving}>
                  {t("What's your preferred time?")}
                </Chat>

                {!loading && !saving && (
                  <div className="timezone">
                    <ClockIcon />
                    <select
                      id={generateID('select-timezone', pathname)}
                      value={timezone}
                      onChange={this.handleChange}
                    >
                      {timezones.map((tz) => (
                        <option key={tz.value} value={tz.value}>
                          {tz.name} - (
                          {moment.tz(new Date(), tz.value).format('h:mm a')})
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {!loading && !saving && (
                  <div
                    id={generateID('div-timepicker', pathname)}
                    className="button-container"
                  >
                    {this.getOptions().map(({ value, label }) => (
                      <Button
                        key={value}
                        full
                        onClick={() => this.handleClick(value)}
                        className={cls({ active: meeting_time === value })}
                      >
                        {label}
                      </Button>
                    ))}
                  </div>
                )}
              </div>

              {!loading && !saving && (
                <div
                  id={generateID('div-actions', pathname)}
                  className="form-actions vertical"
                >
                  <BackButton
                    id={generateID('btn-back', pathname)}
                    onClick={this.handleBack}
                  />
                </div>
              )}
            </div>
          </StyledMeeting2>
        </DefaultLayout>
      </>
    );
  }
}

const mapStateToProps = ({
  user: { data, saving },
  ui: { theme },
  appointment: { appointments, loading, availableTimes },
}) => ({
  user: data,
  saving,
  theme,
  appointments,
  availableTimes,
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      navigateTo,
      trackForwardProgress,
      appointmentTimes,
      availableCalendarTimes,
      duplicationTime,
      appointCalendarTime,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Meeting2)
);
