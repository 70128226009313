import styled from 'styled-components';
import { pxToRem } from 'common/utils';

const Processing = styled.div`
  &.sunlight-processing {
    display: flex;
    align-items: center;
    justify-content: center;

    .processing {
      font-weight: 700;
      font-size: 1.875rem;
      line-height: 1.875;
    }

    @media screen and (min-width: 768px) {
      width: ${pxToRem(555)};
    }
  }
`;

export default Processing;
