import styled from 'styled-components';

const TypingIndicator = styled.div`
  background-color: ${(props) => props.theme.color_scheme_neutral};
  will-change: transform;
  width: auto;
  border-radius: 50px;
  padding: 0.875rem;
  display: table;
  position: relative;
  animation: 2s bulge infinite ease-out;
  flex-shrink: 0;

  span {
    background-color: #fff;
    height: 10px;
    width: 10px;
    float: left;
    margin: 0 0.125rem;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
  }
`;

export default TypingIndicator;
