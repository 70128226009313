import styled from 'styled-components';

const Thankyou = styled.div`
  .form-content {
    font-size: 17px;
    line-height: 22px;

    @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1.5rem;
    }

    @media screen and (max-width: 375px) and (max-height: 568px) {
      svg {
        width: 75px;
        height: 75px;
      }
    }
  }

  .form-title {
    font-family: 'Effra Light';
    margin-top: 2rem;
    font-size: 2rem;
    line-height: 100%;
    color: ${(props) => props.theme.color_scheme_dark};

    @media screen and (max-width: 767px) {
      margin-top: 1rem;
      font-size: 1.5rem;
    }

    @media screen and (max-width: 375px) and (max-height: 568px) {
      margin-top: 0.875rem;
      font-size: 1.25rem;
    }
  }

  .form-subtitle {
    font-family: 'Effra Light';
    margin: 1.5rem auto 1.25rem;
    color: ${(props) => props.theme.color_scheme_dark};

    @media screen and (max-width: 767px) {
      margin: 1rem auto;
      font-size: 1rem;
    }

    @media screen and (max-width: 375px) and (max-height: 568px) {
      margin: 0.75rem auto;
    }
  }

  .form-text {
    font-family: 'Effra Light';
    color: ${(props) => props.theme.color_scheme_dark};
    margin-top: 2rem;

    @media screen and (max-width: 767px) {
      margin-top: 1rem;
      font-size: 1rem;
    }

    @media screen and (max-width: 375px) and (max-height: 568px) {
      margin-top: 0.875rem;
      font-size: 0.875rem;
    }
  }

  .card {
    font-family: 'Effra Light';
    width: 100%;
    padding: 1.25rem 0 1.375rem;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    color: #231f20;

    @media screen and (max-width: 375px) and (max-height: 568px) {
      padding: 1.125rem auto;
    }

    * + * {
      margin-top: 0.5rem;

      @media screen and (max-width: 375px) and (max-height: 568px) {
        margin-top: 0.3125rem;
      }
    }

    .meeting-date {
      font-family: 'Effra';
      font-weight: 500;
      font-size: 42px;
      line-height: 100%;

      @media screen and (max-width: 767px) {
        font-size: 32px;
      }

      @media screen and (max-width: 375px) and (max-height: 568px) {
        font-size: 24px;
      }
    }
  }
`;

export default Thankyou;
