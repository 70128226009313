import React from 'react';
import { ReactComponent as BestCompanyLogo } from 'assets/images/logo-best-company.svg';
import { generateID } from 'common/utils';
import StyledFooter from './styled';

const Footer = () => {
  return (
    <>
      <StyledFooter id={generateID('div-footer', 'footer')}>
        <BestCompanyLogo />
        <div id={generateID('div-powered-by', 'footer')} className="powered-by">
          Powered by BestCompany.com LLC
        </div>
      </StyledFooter>
    </>
  );
};

export default Footer;
