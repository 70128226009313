import styled from 'styled-components';
import Modal from 'styled-react-modal';

const StyledModal = Modal.styled`
  width: calc(100% - 40px);
  max-width: 336px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  flex-direction: column;
  margin-top: 3.75rem;
  position: relative;

  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .modal-title {
    font-size: 2.375rem;
    color: #000;
    font-family: StellaFont, Arial;
    margin-bottom: 1rem;
    text-align: center;
  }

  .modal-content {
    font-family: Roboto, Arial;
    font-size: 1rem;
    color: #666;
    line-height: 1.5;

    a {
      color: #666;
      text-decoration: underline;
    }

    button {
      font-family: StellaFont, Arial;
    }
  }

  @media screen and (min-width: 768px) {
    max-width: 450px;
    padding: 2rem;
    margin-top: 0;
  }
`;

export default StyledModal;

export const ModalBackground = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1030;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
`;
