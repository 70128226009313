import styled from 'styled-components';
import { pxToRem } from 'common/utils';

const Meeting1 = styled.div`
  .form-title {
    font-family: StellaFont, Arial, Helvetica, sans-serif;
    font-size: 1.875rem;
    color: ${(props) => props.theme.color_scheme_dark};
    line-height: 1;
  }

  .form-subtext {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 1rem;
    color: #666;
  }

  .form-content {
    display: flex;
    flex-direction: column;

    .mb-32 {
      flex: 1;
    }
  }

  .form-note {
    font-family: Roboto, arial;
    font-weight: bold;
    font-size: 0.75;
    text-align: center;
    color: #000000;
  }

  .form-datepicker {
    width: 100%;
    margin: 0 auto;

    @media screen and (min-width: 768px) {
      max-width: 25rem;
    }
  }

  #form-meeting1 {
    @media screen and (min-width: 768px) {
      max-width: none;
      align-items: center;
      .form-content {
        width: 100%;
        max-width: ${pxToRem(555)};
      }
      .form-footer {
        width: 100%;
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
        padding-left: calc(50% - ${pxToRem(555 / 2)} + ${pxToRem(20)});
        padding-right: calc(50% - ${pxToRem(555 / 2)} + ${pxToRem(20)});
      }
    }
  }

  .loading-overlay {
    position: unset;
  }
`;

export default Meeting1;
