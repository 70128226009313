import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import { noop } from 'common/utils';
import StyledStage from './styled';

const Stage = ({ stage, onBackClick }) => {
  const [t] = useTranslation('translation');

  const stageNames = [
    t('Income'),
    t('Mortgage Payment'),
    t('Verify'),
    t('Verify'),
    t('Run Credit'),
  ];

  return (
    <StyledStage className="sungage-stage">
      {stage > 1 && (
        <div className="back mr-8" onClick={onBackClick}>
          <BackIcon />
        </div>
      )}

      <div className="stage">{stage}/5:</div>

      <div className="name">{stageNames[stage - 1]}</div>

      {stage === 1 && (
        <div className="back" style={{ visibility: 'hidden' }}>
          <BackIcon />
        </div>
      )}
    </StyledStage>
  );
};

Stage.propTypes = {
  stage: PropTypes.number,
  onBackClick: PropTypes.func,
};

Stage.defaultProps = {
  stage: 1,
  onBackClick: noop,
};

export default Stage;
