import styled from 'styled-components';
import { pxToRem } from 'common/utils';

const StyledValidateZipCode = styled.div`
  &.content.validate-zipcode-page {
    min-height: calc(100% - ${pxToRem(76)});

    @media screen and (max-width: 374px) {
      min-height: calc(100% - 61px);
    }

    @media screen and (min-width: 767px) {
      min-height: calc(100% - ${pxToRem(76)} - 3rem);
    }

    .message {
      line-height: 30px;
    }
  }
`;

export const TooltipTitle = styled.div`
  font-size: 0.75rem;
  font-weight: 700;
  color: #fff;
  background-color: ${(props) => props.theme.color_scheme_light};
  padding: 0.25rem;
`;

export const TooltipContent = styled.div`
  margin: 0.25rem 0;
`;

export const TooltipSubContent = styled.div`
  font-size: 0.75rem;
  color: #666;
  margin: 0.25rem 0;
`;

export default StyledValidateZipCode;
