import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import Button from 'components/Button';
import { MeetingTextInput as TextInput } from 'components/Forms/TextInput';
import MaterialStepper from 'components/MaterialStepper';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/images/icon-arrow-right.svg';
import InputMask from 'react-input-mask';
import {
  setUserData,
  saveSession,
  trackFacebookPixel,
  navigateTo,
  trackForwardProgress,
  duplicationTime,
  appointCalendarTime,
} from 'store/actions';
import Modal from 'components/Modal';
import { withTranslation } from 'react-i18next';
import { verifyPhoneNumber, verifyEmail } from 'common/api.mock';
import { getLocalStorageItem } from 'common/localStorage';
import { VARIATION_OPTIONS } from 'pages/constants';
import { generateID } from 'common/utils';
import StyledMeeting3 from './styled';

const formSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  address2: Yup.string(),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  zip_code: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
});

const CustomInput = ({ ...props }) => (
  <InputMask {...props}>{() => <TextInput {...props} />}</InputMask>
);

class Meeting3AppointmentRequestVariation extends Component {
  constructor(props) {
    super(props);

    const variation = getLocalStorageItem('variation');
    this.state = {
      showError: false,
      error: null,
      showCloseIcon: true,
      variation: VARIATION_OPTIONS[variation],
    };
    this.handle = null;
  }

  handleBack = () => {
    const { actions, theme } = this.props;
    let step = '/meeting2';
    if (theme.appointment_method === 'CALENDLY') {
      step = '/meetingcalendly';
    }
    actions.navigateTo(step);
  };

  handleNext = async (values, { setFieldError }) => {
    const { actions, theme, user, t } = this.props;
    const step = 'bills1';
    const formValue = { ...values };

    if (!user.is_confirmed) {
      actions.trackFacebookPixel('bills1', user.variation);
    }

    if (theme.is_duplication_meeting_prevented) {
      await actions.duplicationTime({
        meeting_time: user.meeting_time,
        meeting_timezone: user.meeting_timezone,
      });
    } else if (theme.is_cronofy_connected && theme.is_calendar_active) {
      await actions.appointCalendarTime({
        meeting_time: user.meeting_time,
        meeting_timezone: user.meeting_timezone,
      });
    }

    const errors = [];

    if (formValue.phone && formValue.phone.trim() !== user.phone) {
      const phoneRes = await verifyPhoneNumber(formValue.phone.trim());

      if (!phoneRes || !phoneRes.success) {
        const msg = t(
          'That number does not seem to be a valid mobile phone number.'
        );
        errors.push(msg);
        setFieldError('phone', msg);
      } else {
        formValue.phone = phoneRes.data.national_format;
      }
    }

    if (formValue.email && formValue.email.trim() !== user.email) {
      const emailRes = await verifyEmail(formValue.email);

      if (!emailRes || !emailRes.success) {
        const msg = t('That email does not seem to be a valid email.');
        errors.push(msg);
        setFieldError('email', msg);
      }
    }

    if (!errors.length) {
      const { is_duplicated, validating } = this.props;
      if (!validating && !is_duplicated) {
        actions.setUserData({
          ...formValue,
          is_confirmed: true,
          step,
        });

        await actions.saveSession();
        actions.trackForwardProgress();
        await actions.navigateTo(step);
      } else {
        this.setState({
          showError: true,
          error: 'Someone else already book at this time.',
        });
      }
    }
  };

  handleClose = () => {
    this.setState({
      showError: false,
    });
  };

  render() {
    const { user, theme, t } = this.props;
    const { showError, error, showCloseIcon, variation } = this.state;

    const {
      location: { pathname },
    } = this.props;

    return (
      <>
        <variation.header />
        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <StyledMeeting3 className="content meeting-3-page custom-stepper">
              <Formik
                initialValues={{
                  first_name: user.first_name || '',
                  last_name: user.last_name || '',
                  phone: user.phone || '',
                  email: user.email || '',
                  address: user.address || '',
                  address2: user.address2 || '',
                  city: user.city || '',
                  state: user.state || '',
                  zip_code: user.zip_code || '',
                }}
                validationSchema={formSchema}
                onSubmit={this.handleNext}
                validateOnMount
              >
                {({ isValid, isSubmitting }) => {
                  return (
                    <Form className="form" id="form-meeting3">
                      <div
                        id={generateID('div-material-stepper', pathname)}
                        className="material-progress"
                      >
                        <MaterialStepper
                          activeStep={1}
                          variation={user.variation}
                        />
                      </div>

                      <div
                        id={generateID('div-meeting-information', pathname)}
                        className="form-content"
                      >
                        <div className="form-title mb-16">
                          {t(
                            'Please confirm the requested meeting time & your information.'
                          )}
                        </div>
                        <div className="form-subtext mb-24">
                          <span style={{ textTransform: 'capitalize' }}>
                            {moment(user.meeting_date, 'YYYY-MM-DD').format(
                              'dddd, MMMM D, YYYY'
                            )}
                            ,&nbsp;
                          </span>
                          <span>
                            {moment(user.meeting_time, 'HH:mm:ss').format(
                              'h:mm A'
                            )}
                            ,&nbsp;
                            {t(user.meeting_timezone)}
                          </span>
                        </div>
                        <div className="mb-32">
                          <div className="mb-16">
                            <Field
                              id={generateID('txt-first-name', pathname)}
                              type="text"
                              name="first_name"
                              placeholder={t('First Name')}
                              theme={theme}
                              component={TextInput}
                              autoFocus
                            />
                          </div>
                          <div className="mb-16">
                            <Field
                              id={generateID('txt-last-name', pathname)}
                              type="text"
                              name="last_name"
                              placeholder={t('Last Name')}
                              theme={theme}
                              component={TextInput}
                            />
                          </div>
                          <div className="mb-16">
                            <Field>
                              {({ field, form }) => (
                                <CustomInput
                                  id={generateID('txt-phone', pathname)}
                                  mask="(999) 999-9999"
                                  name="phone"
                                  type="tel"
                                  placeholder={t('Mobile phone number')}
                                  theme={theme}
                                  value={field.value.phone}
                                  onChange={field.onChange}
                                  field={field}
                                  form={form}
                                />
                              )}
                            </Field>
                          </div>
                          <ErrorMessage
                            name="phone"
                            render={(msg) => (
                              <div className="form-error mb-16">{msg}</div>
                            )}
                          />
                          <div className="mb-16">
                            <Field
                              id={generateID('txt-email', pathname)}
                              type="email"
                              name="email"
                              placeholder={t('Email')}
                              theme={theme}
                              component={TextInput}
                            />
                          </div>
                          <ErrorMessage
                            name="email"
                            render={(msg) => (
                              <div className="form-error mb-16">{msg}</div>
                            )}
                          />
                          <div className="mb-16">
                            <Field
                              id={generateID('txt-address', pathname)}
                              type="text"
                              name="address"
                              placeholder={t('Address')}
                              theme={theme}
                              component={TextInput}
                            />
                          </div>
                          <div className="mb-16">
                            <Field
                              id={generateID('txt-address2', pathname)}
                              type="text"
                              name="address2"
                              placeholder={t('Apartment/Unit number')}
                              theme={theme}
                              component={TextInput}
                            />
                          </div>
                          <div className="mb-16">
                            <Field
                              id={generateID('txt-city', pathname)}
                              name="city"
                              placeholder={t('City')}
                              theme={theme}
                              component={TextInput}
                            />
                          </div>
                          <div className="mb-16">
                            <Field
                              id={generateID('txt-state', pathname)}
                              name="state"
                              placeholder={t('State')}
                              theme={theme}
                              component={TextInput}
                            />
                          </div>
                          <div className="">
                            <Field
                              id={generateID('txt-zip-code', pathname)}
                              type="number"
                              name="zip_code"
                              placeholder={t('Zip code')}
                              theme={theme}
                              component={TextInput}
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        id={generateID('div-actions', pathname)}
                        className="form-actions form-footer"
                        style={{ zIndex: 100 }}
                      >
                        <Button
                          id={generateID('btn-back', pathname)}
                          outline
                          onClick={this.handleBack}
                        >
                          <BackIcon />
                        </Button>
                        <Button
                          id={generateID('btn-confirm', pathname)}
                          type="submit"
                          full
                          disabled={!isValid || isSubmitting}
                        >
                          {t('Confirm')}
                          <ArrowRightIcon />
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>

              <Modal
                id={generateID('modal-appointment-error', pathname)}
                showCloseIcon={showCloseIcon}
                onClose={this.handleClose}
                isOpen={showError}
              >
                <div className="modal-content">
                  <div className="mb-32 text-center">{error}</div>
                  <div className="mb-32 modal-btns-group">
                    <Button
                      id={generateID(
                        'btn-choose-appointment',
                        `${pathname}/modal-appointment-error`
                      )}
                      left
                      onClick={this.handleBack}
                    >
                      <BackIcon />
                      {t('Choose appointment')}
                    </Button>
                    <Button
                      id={generateID(
                        'btn-cancel',
                        `${pathname}/modal-appointment-error`
                      )}
                      outline
                      borderless
                      onClick={this.handleClose}
                    >
                      {t('Cancel')}
                    </Button>
                  </div>
                </div>
              </Modal>
            </StyledMeeting3>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  user: { data },
  ui: { theme },
  appointment: { is_duplicated, validating },
}) => ({
  user: data,
  theme,
  validating,
  is_duplicated,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      trackFacebookPixel,
      navigateTo,
      trackForwardProgress,
      duplicationTime,
      appointCalendarTime,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Meeting3AppointmentRequestVariation)
);
