import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { extractHostname } from 'common/utils';
import initStore from './store/config';
import 'typeface-roboto';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/app.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { getAppUrl, setIframeInfo } from './common/api.mock';
import './i18n';

Sentry.init({
  dsn: 'https://04cc99613ac5442ea8708be5a06443d7@o424711.ingest.sentry.io/5356866',
  environment: process.env.REACT_APP_TRACKING_ENV,
  integrations: [new Sentry.Integrations.Breadcrumbs({ console: false })],
});

Sentry.setTag('app-url', extractHostname(getAppUrl()));

setIframeInfo();

const store = initStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
