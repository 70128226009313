/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from 'components/Button';
import ProgressBar from 'components/ProgressBar';
import Chat from 'components/Chat';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackForwardProgress,
  saveFinancialAnalyses,
} from 'store/actions';
import DefaultLayout from 'components/DefaultLayout';
import iconGoldStar from 'assets/images/icon-gold-star.svg';
import { ReactComponent as SunroofLogo } from 'assets/images/logo-project-sunroof.svg';
import SolarMapVariation2 from 'components/SolarMapVariation2';
import { withTranslation } from 'react-i18next';
import GoogleMapContainer, {
  MapContainer,
} from 'components/GoogleMapContainer';
import {
  generateSolarMaps,
  checkIfImageIsLoaded,
  generateID,
} from 'common/utils';
import { MarvinImage } from '@rarebearsoft/marvin';
import { parseInt } from 'lodash';
import StyledWrapper from './styled';

const ZOOM = 12;

class Calculate19 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      centerLat: null,
      centerLng: null,
      error: null,
      imageLoading: false,
      step: 0,
      saving: false,
      zoom: ZOOM,
      deltaX: null,
      deltaY: null,
      solarImages: [],
      imageLoaded: false,
    };
  }

  componentDidMount() {
    this.initData();
  }

  componentDidUpdate() {
    const { deltaX, deltaY, solarImages, imageLoaded } = this.state;
    const { actions } = this.props;
    if (imageLoaded) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ imageLoaded: false });
      if (solarImages.length < 4) {
        this.handleNext();
        return;
      }
      const countFunc = async () => {
        let count = 0;
        await solarImages.map(async (image, index) => {
          const result = await this.countGoneSolar(
            image,
            index,
            deltaX,
            deltaY
          );
          count += result;
          // eslint-disable-next-line no-console
          console.log('Count result', result);
        });
        if (count < 15) {
          this.handleNext();
          return;
        }

        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          solarCount: count,
          loading: false,
          step: 1,
        });
        await actions.setUserData({
          solarCount: count,
        });
      };
      countFunc();
    }
  }

  initData = () => {
    const { user } = this.props;
    const centerLat = user.rooftop_latitude;
    const centerLng = user.rooftop_longitude;
    this.solarImageLoad(user.rooftop_latitude, user.rooftop_longitude);
    this.setState({
      centerLat,
      centerLng,
    });
  };

  handleBack = () => {
    const { actions } = this.props;
    actions.navigateTo('/start');
  };

  handleNext = async () => {
    const { actions } = this.props;
    const step = '/calculate2';
    actions.setUserData({
      step,
    });
    this.setState({ saving: true });
    await actions.saveSession();
    this.setState({ saving: false });
    actions.trackForwardProgress();
    await actions.navigateTo(step);
  };

  handleShow = () => {
    const { error, loading } = this.state;
    if (!loading && error) {
      this.handleNext();
    }
  };

  solarImageLoad = async (latitude, longitude) => {
    const { solarImages } = this.state;
    const zoom = 12;
    const image = [[], [], [], []];

    const proxyurl = 'https://cors-anywhere.demand-iq.com/?';
    const { images } = generateSolarMaps(latitude, longitude, zoom);
    // eslint-disable-next-line array-callback-return
    await images.map((imageUrl, index) => {
      image[index] = new MarvinImage();
      image[index].load(`${proxyurl}${imageUrl}`, () => {
        solarImages.push(image[index]);
        this.setState({ solarImages });
      });
    });
    await checkIfImageIsLoaded(images);
    setTimeout(() => {
      this.setState({ imageLoaded: true });
    }, 4000);
  };

  countGoneSolar = (image, index, delX, delY) => {
    const deltaX = delX * image.getWidth();
    const deltaY = delY * image.getHeight();
    let count = 0;
    let startX = 0;
    let startY = 0;
    let endX = 0;
    let endY = 0;
    switch (index) {
      case 0:
        startX = image.getWidth() / 2 + deltaX;
        startY = image.getHeight() / 2 + deltaY;
        endX = image.getWidth();
        endY = image.getHeight();
        break;
      case 1:
        startX = 0;
        startY = image.getHeight() / 2 + deltaY;
        endX = image.getWidth() / 2 + deltaX;
        endY = image.getHeight();
        break;
      case 2:
        startX = image.getWidth() / 2 + deltaX;
        startY = 0;
        endX = image.getWidth();
        endY = image.getHeight() / 2 + deltaY;
        break;
      default:
        startX = 0;
        startY = 0;
        endX = image.getWidth() / 2 + deltaX;
        endY = image.getHeight() / 2 + deltaY;
        break;
    }

    for (let y = parseInt(startY); y < parseInt(endY); y += 3) {
      for (let x = parseInt(startX); x < parseInt(endX); x += 3) {
        const alpha = image.getAlphaComponent(x, y);
        if (alpha !== 0) {
          count++;
        }
      }
    }
    return count;
  };

  render() {
    const {
      loading,
      centerLat,
      centerLng,
      error,
      imageLoading,
      step,
      saving,
      zoom,
      solarCount,
    } = this.state;
    const { t } = this.props;

    const {
      location: { pathname },
    } = this.props;

    return (
      <DefaultLayout>
        <StyledWrapper className="content calculate-12-page">
          <div className="form">
            <div className="form-content">
              <div
                id={generateID('div-progress-bar', pathname)}
                className="mb-20 w100"
              >
                <ProgressBar value={25} step={1} />
              </div>

              <Chat
                id={generateID('chat-already-gone-solar', pathname)}
                loading={loading || !!error}
                mode="loading"
                onShow={this.handleShow}
              >
                {!error && (
                  <div>
                    {t(
                      'Wow! {{solarCount}} of your neighbors have already gone solar!',
                      { solarCount }
                    )}
                  </div>
                )}
              </Chat>

              {step > 0 && (
                <Chat
                  id={generateID('chat-map', pathname)}
                  loading={imageLoading || !!error}
                  mode="loading"
                  onShow={this.handleShow}
                >
                  {!error && centerLat && centerLng && (
                    <div className="google-map-wrapper">
                      <div className="map-container">
                        {window.google ? (
                          <MapContainer
                            lat={centerLat}
                            lng={centerLng}
                            zoom={12}
                            mapStyles={{
                              width: '100%',
                              height: 'calc(100% - 24px)',
                              opacity: 0.35,
                              paddingTop: '-30px',
                            }}
                            google={window.google}
                          />
                        ) : (
                          <GoogleMapContainer
                            lat={centerLat}
                            lng={centerLng}
                            zoom={12}
                            mapStyles={{
                              width: '100%',
                              height: 'calc(100% - 24px)',
                              opacity: 0.35,
                              paddingTop: '-30px',
                            }}
                          />
                        )}
                        <SolarMapVariation2
                          lat={centerLat}
                          lng={centerLng}
                          zoom={zoom}
                        />
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <SunroofLogo className="logo-sunroof mt-8" />
                        </div>
                      </div>
                      <div className="map-description">
                        <div className="section-content">
                          <div className="icon-mark">
                            <img
                              src={iconGoldStar}
                              alt="icon-gold-star"
                              style={{
                                height: 18,
                                marginRight: 5,
                                verticalAlign: 'center',
                              }}
                            />
                            {t('Your Zip Code')}
                          </div>
                          <div className="icon-mark">
                            <svg
                              width="15"
                              height="15"
                              style={{
                                verticalAlign: 'center',
                                marginTop: -2,
                                marginRight: 5,
                              }}
                            >
                              <rect
                                width="15"
                                height="15"
                                style={{
                                  fill: '#f00',
                                  strokeWidth: 10,
                                }}
                              />
                            </svg>
                            {t('Solar Install')}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Chat>
              )}
            </div>

            {!loading && !error && (
              <div
                id={generateID('div-actions', pathname)}
                className="form-actions"
              >
                <Button
                  id={generateID('btn-back', pathname)}
                  outline
                  onClick={this.handleBack}
                >
                  <BackIcon />
                </Button>
                <Button
                  id={generateID('btn-continue', pathname)}
                  type="submit"
                  full
                  onClick={this.handleNext}
                  disabled={loading || imageLoading || saving}
                >
                  {t('Continue')}
                </Button>
              </div>
            )}
          </div>
        </StyledWrapper>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ ui, user: { data } }) => ({
  user: data,
  theme: ui.theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      navigateTo,
      trackForwardProgress,
      saveFinancialAnalyses,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Calculate19)
);
