import React, { useRef, useState, useEffect } from 'react';
import imgSun from 'assets/images/sun.png';
import { ReactComponent as BuildingIcon } from 'assets/images/icon-building.svg';
import { getFontSize } from 'common/utils';
import { useTranslation, Trans } from 'react-i18next';
import StyledWrapper from './styled';

const useResize = (myRef) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWidth(myRef.current.offsetWidth);
      setHeight(myRef.current.offsetHeight);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef]);

  return { width, height };
};

const SunroofInfo = ({ sunshineHours, squareFeet, className }) => {
  const itemRef = useRef();
  const iconRef = useRef();
  const hourRef = useRef();
  const squareRef = useRef();
  const itemSize = useResize(itemRef);
  const iconSize = useResize(iconRef);
  const hourSize = useResize(hourRef);
  const squareSize = useResize(squareRef);
  const [t] = useTranslation('translation');

  const maxCurrentWidth = Math.max(hourSize.width, squareSize.width);
  const currentFontSize = getFontSize();
  const restWidth = itemSize.width - iconSize.width - currentFontSize;

  const fontSize =
    maxCurrentWidth > 0
      ? (restWidth * currentFontSize) / maxCurrentWidth
      : undefined;

  return (
    <StyledWrapper className={className}>
      {sunshineHours && (
        <div className="info-item" ref={itemRef}>
          <img src={imgSun} className="info-icon" alt="sun" ref={iconRef} />
          <div className="info-right" ref={hourRef}>
            <div className="info-title" style={{ fontSize }}>
              <Trans
                t={t}
                i18nKey="{{sunshineHours}} hours of usable sunlight per year"
              >
                {{ sunshineHours }} hours of usable sunlight per year
              </Trans>
            </div>
            <div
              className="info-desc"
              style={{ fontSize: fontSize ? fontSize * 0.75 : undefined }}
            >
              <Trans
                t={t}
                i18nKey="Based on day-to-day analysis of weather patterns"
              >
                Based on day-to-day analysis of weather patterns
              </Trans>
            </div>
          </div>
        </div>
      )}
      {squareFeet && (
        <div className="info-item">
          <BuildingIcon className="info-icon" />
          <div className="info-right" ref={squareRef}>
            <div className="info-title" style={{ fontSize }}>
              <Trans
                t={t}
                i18nKey="{{squareFeet}} sq feet available for solar panels"
              >
                {{ squareFeet }} sq feet available for solar panels
              </Trans>
            </div>
            <div
              className="info-desc"
              style={{ fontSize: fontSize ? fontSize * 0.75 : undefined }}
            >
              <Trans
                t={t}
                i18nKey="Based on 3D modeling of your roof and nearby trees"
              >
                Based on 3D modeling of your roof and nearby trees
              </Trans>
            </div>
          </div>
        </div>
      )}
    </StyledWrapper>
  );
};

export default SunroofInfo;
