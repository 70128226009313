import styled from 'styled-components';
import { pxToRem } from 'common/utils';

const Stage = styled.div`
  &.sunlight-stage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 0.375rem;

    .back {
      margin-left: 0.3rem;
      cursor: pointer;

      svg {
        color: #000;
        fill: #000;

        line {
          stroke: #000;
        }

        path {
          fill: #000;
        }

        margin-bottom: 0.11rem;
        height: 0.875rem;
      }
    }

    .stage {
      font-weight: 400;
      font-size: 0.625rem;
      line-height: 1;
    }

    .name {
      font-weight: 400;
      font-size: 0.625rem;
      line-height: 1;
      text-transform: uppercase;
    }

    @media screen and (min-width: 768px) {
      width: ${pxToRem(555)};
    }
  }
`;

export default Stage;
