import styled from 'styled-components';

const ButtonSelect = styled.div`
  display: flex;
  flex-direction: column;

  .btn {
    margin-bottom: 0.625rem;

    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (max-width: 374px) {
      padding: ${(props) => (props.link ? '0' : '0.5rem 1rem')};
      margin-bottom: 0.5rem;
    }
  }

  .btn-selected {
    color: ${(props) => props.theme.color_scheme_button_text};
    background-color: ${(props) => props.theme.color_scheme_button};

    &:hover {
      color: ${(props) => props.theme.color_scheme_button_hover_text};
      background-color: ${(props) => props.theme.color_scheme_button_hover};
    }
  }
`;

export default ButtonSelect;
