import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from 'components/Button';
import { setUserData, saveSession } from 'store/actions';
import TextInput from 'components/Forms/TextInput';
import { VARIATION_OPTIONS } from 'pages/constants';
import { getLocalStorageItem } from 'common/localStorage';
import Header from 'components/Sungage/Header';
import Stage from 'components/Sungage/Stage';
import Question from 'components/Sungage/Question';
import { generateID } from 'common/utils';
import StyledSungage4 from './styled';

const formSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
});

class Sungage4 extends Component {
  constructor(props) {
    super(props);

    const variation = getLocalStorageItem('variation');

    this.state = {
      variation: VARIATION_OPTIONS[variation],
    };
  }

  handleClose = async () => {
    const { history, actions, user } = this.props;

    let step;
    if (user.sungage?.prequalification_skipped) step = '/result3';
    else step = '/qualify/end';

    actions.setUserData({ step });
    await actions.saveSession();

    history.replace(step);
  };

  handleBack = async () => {
    const { history, actions } = this.props;

    actions.setUserData({
      step: '/prequalify/sungage3',
    });
    await actions.saveSession();

    history.replace('/prequalify/sungage3');
  };

  handleNext = async (values) => {
    const { history, actions } = this.props;

    actions.setUserData({
      step: '/prequalify/sungage5',

      first_name: values.first_name,
      last_name: values.last_name,
      address: values.address,
      city: values.city,
      state: values.state,
    });
    await actions.saveSession();

    history.push('/prequalify/sungage5');
  };

  render() {
    const { user, theme, t } = this.props;
    const { variation } = this.state;

    const {
      location: { pathname },
    } = this.props;

    return (
      <>
        <variation.header />

        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <StyledSungage4 className="content sungage4-page">
              <div id={generateID('div-header', pathname)} className="mb-32">
                <Header onCloseClick={this.handleClose} />
              </div>

              <div id={generateID('div-stage', pathname)} className="mb-16">
                <Stage stage={4} onBackClick={this.handleBack} />
              </div>

              <Formik
                initialValues={{
                  first_name: user.first_name || '',
                  last_name: user.last_name || '',
                  address: user.address || '',
                  city: user.city || '',
                  state: user.state || '',
                }}
                validationSchema={formSchema}
                onSubmit={this.handleNext}
                validateOnMount
              >
                {({ isValid, isSubmitting }) => {
                  return (
                    <Form className="form">
                      <div className="form-content">
                        <div
                          id={generateID('div-question', pathname)}
                          className="mb-16"
                        >
                          <Question>
                            {t('Please confirm the information is correct.')}
                          </Question>
                        </div>

                        <div className="mb-16">
                          <Field
                            id={generateID('txt-first-name', pathname)}
                            name="first_name"
                            type="text"
                            placeholder={t('First Name')}
                            theme={theme}
                            component={TextInput}
                            autoFocus
                          />
                        </div>
                        <div className="mb-16">
                          <Field
                            id={generateID('txt-last-name', pathname)}
                            name="last_name"
                            type="text"
                            placeholder={t('Last Name')}
                            theme={theme}
                            component={TextInput}
                          />
                        </div>
                        <div className="mb-16">
                          <Field
                            id={generateID('txt-address', pathname)}
                            name="address"
                            type="text"
                            placeholder={t('Address')}
                            theme={theme}
                            component={TextInput}
                          />
                        </div>
                        <div className="mb-16">
                          <Field
                            id={generateID('txt-city', pathname)}
                            name="city"
                            type="text"
                            placeholder={t('City')}
                            theme={theme}
                            component={TextInput}
                          />
                        </div>
                        <div className="mb-32">
                          <Field
                            id={generateID('txt-state', pathname)}
                            name="state"
                            type="text"
                            placeholder={t('State')}
                            theme={theme}
                            component={TextInput}
                          />
                        </div>
                        <div
                          id={generateID('div-actions', pathname)}
                          className="form-actions"
                        >
                          <Button
                            id={generateID('btn-looks-good', pathname)}
                            type="submit"
                            disabled={!isValid || isSubmitting}
                            full
                          >
                            {t('Looks Good')}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </StyledSungage4>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui }) => ({
  user: data,
  theme: ui.theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Sungage4)
);
