import React from 'react';
import './style.scss';
import StyledTypingIndicator from './styled';

const TypingIndicator = () => (
  <StyledTypingIndicator className="typing-indicator">
    <span></span>
    <span></span>
    <span></span>
  </StyledTypingIndicator>
);

export default TypingIndicator;
