/* eslint-disable import/order */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from 'components/Button';
import ProgressBar from 'components/ProgressBarNRGVariation';
import TextInput from 'components/Forms/TextNumberInputNRGVariation';
import Chat from 'components/ChatNRGVariation';
import BackButton from 'components/BackButtonNRGVariation';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackForwardProgress,
} from 'store/actions';
import DefaultLayout from 'components/DefaultLayout';
import FixedBottomBar from 'components/FixedBottomBar';
import StyledCalculate21 from './styled';
import { withTranslation, Trans } from 'react-i18next';
import { generateID } from 'common/utils';

const formSchema = Yup.object().shape({
  bill: Yup.number().max(1500).moreThan(30).required('Required'),
});

class Calculate21 extends Component {
  constructor(props) {
    super(props);

    this.state = { step: 0 };
  }

  handleBack = () => {
    const { actions } = this.props;
    actions.navigateTo('/calculate2');
  };

  handleSubmit = async ({ bill }) => {
    const { actions } = this.props;
    const step = '/calculate3';
    actions.setUserData({ avg_bill: bill, step });
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo(step);
  };

  handleShow = () => {
    this.setState({ step: 1 });
  };

  render() {
    const {
      user: { avg_bill },
      theme,
      t,
    } = this.props;
    const { step } = this.state;

    const {
      location: { pathname },
    } = this.props;

    return (
      <DefaultLayout showFooter={false}>
        <Formik
          initialValues={{ bill: avg_bill }}
          validationSchema={formSchema}
          onSubmit={this.handleSubmit}
          validateOnMount
        >
          {({ isValid, isSubmitting, values, submitForm }) => {
            return (
              <>
                <StyledCalculate21 className="content calculate-2-1-page">
                  <Form className="form">
                    <div className="form-content">
                      <div
                        id={generateID('div-progress-bar', pathname)}
                        className="mb-32 w-100"
                      >
                        <ProgressBar value={50} step={2} />
                      </div>

                      <Chat
                        id={generateID('chat-bill-seems-large', pathname)}
                        onShow={this.handleShow}
                      >
                        <div className="mb-16">
                          {values.bill >= 950 && (
                            <Trans
                              t={t}
                              i18nKey="That bill seems large. Are you interested in solar for a commercial building? If so, click <1>here</1>"
                            >
                              That bill seems large. Are you interested in solar
                              for a commercial building? If so, click{' '}
                              <Link
                                id={generateID('btn-go-commercial2', pathname)}
                                to="/commercial2"
                                className="link-text"
                              >
                                here
                              </Link>
                            </Trans>
                          )}
                          {values.bill <= 30 &&
                            t(
                              'Uh oh! That bill seems a little small. Try another amount.'
                            )}
                          {values.bill > 30 &&
                            values.bill < 950 &&
                            t('That bill seems OK.')}
                        </div>
                      </Chat>

                      {step >= 1 && (
                        <div className="card">
                          <Field
                            id={generateID('txt-bill', pathname)}
                            name="bill"
                            placeholder={t('Re-enter electric bill')}
                            type="number"
                            theme={theme}
                            component={TextInput}
                            fullWidth
                            autoFocus
                          />
                        </div>
                      )}
                    </div>

                    <div
                      id={generateID('div-actions', pathname)}
                      className="form-actions vertical"
                    >
                      <BackButton
                        id={generateID('btn-back', pathname)}
                        onClick={this.handleBack}
                      />
                    </div>
                  </Form>
                </StyledCalculate21>

                <FixedBottomBar
                  id={generateID('div-fixed-bottom-bar', pathname)}
                >
                  <Button
                    id={generateID('btn-run-estimate', pathname)}
                    onClick={submitForm}
                    type="submit"
                    full
                    disabled={!isValid || isSubmitting}
                  >
                    {values.bill > 950
                      ? t('Run estimate')
                      : t('Calculate savings')}
                  </Button>
                </FixedBottomBar>
              </>
            );
          }}
        </Formik>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ ui, user: { data } }) => ({
  user: data,
  theme: ui.theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Calculate21)
);
