import styled from 'styled-components';

const Rating = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .review {
    font-weight: 700;
    font-style: italic;
    margin-right: 0.25rem;
    font-size: 0.875rem;
    color: ${(props) => props.theme.color_scheme_button};

    &:hover {
      color: ${(props) => props.theme.color_scheme_button_hover};
    }
  }

  .stars {
    display: flex;
  }

  .number {
    font-size: 0.75rem;
    font-style: italic;
    color: #fff;
    margin-right: 0.125rem;
  }
`;

export default Rating;
