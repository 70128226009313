import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;

  .battery {
    width: 18.125rem !important;
    min-height: 17.5rem;

    border: 1px solid #dcdcdc;
    border-radius: 10px;
    box-shadow: 0 10px 24px rgba(0, 0, 0, 0.1);

    display: flex !important;
    flex-direction: column;
    align-items: center;

    margin: 1rem;

    background: ${(props) => props.theme.color_scheme_light};

    .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 20px 0 20px;

      .name {
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0.07rem;
        text-align: center;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.87);
        margin: 1rem 0;
      }

      .description {
        flex: 1;
        font-size: 0.875rem;
        line-height: 1.25rem;
        text-align: center;
        color: rgba(0, 0, 0, 0.87);

        span {
          font-weight: 600;
        }
      }

      .price {
        font-size: 0.75rem;
        font-weight: 600;
        text-align: center;
        color: rgba(0, 0, 0, 0.87);
        margin: 1rem 0;

        span {
          font-size: 0.875rem;
        }
      }
    }

    .action {
      width: 100%;
      padding: 12px 0;
      border-top: 1px solid #ededed;
      font-size: 0.875rem;
      font-weight: 600;
      text-align: center;

      svg {
        font-size: 16px;
        font-weight: 600;
        margin-right: 4px;
      }
    }
  }

  @media screen and (max-width: 374px) {
    width: calc(100vw - 5rem);
  }
`;

export default StyledWrapper;
