import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import { pxToRem } from 'common/utils';

const TextInput = styled.input`
  display: block;
  width: 100%;
  font-size: 16px;
  color: ${(props) => props.theme.color_scheme_font};
  border: 1px solid ${(props) => props.theme.color_scheme_dark};
  border-radius: ${(props) =>
    props.theme.button_shape === 'square' || props.link
      ? 0
      : props.theme.button_shape === 'semiround'
      ? 3
      : 100}px;
  background-color: ${(props) => props.theme.color_scheme_neutral};
  padding: 0.625rem 1rem;

  &:focus {
    outline: none;
  }

  &.has-error {
    border-color: #f44336;
  }

  &::-webkit-input-placeholder {
    color: ${(props) => props.theme.color_scheme_label};
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-style: italic;
    opacity: 1;
  }
  &:-moz-placeholder {
    /* FF 4-18 */
    color: ${(props) => props.theme.color_scheme_label};
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-style: italic;
    opacity: 1;
  }
  &::-moz-placeholder {
    /* FF 19+ */
    color: ${(props) => props.theme.color_scheme_label};
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-style: italic;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: ${(props) => props.theme.color_scheme_label};
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-style: italic;
    opacity: 1;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${(props) => props.theme.color_scheme_label};
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-style: italic;
    opacity: 1;
  }
  &::placeholder {
    /* modern browser */
    color: ${(props) => props.theme.color_scheme_label};
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-style: italic;
    opacity: 1;
  }

  @media screen and (min-width: 768px) {
    padding: 0.625rem 1.25rem;
  }
`;

export default TextInput;

export const useOutlinedTextFieldStyles = makeStyles(() => ({
  outlinedTextField: {
    width: '100%',
    '& div > input': {
      height: 5,
      borderRadius: (props) =>
        props.theme.button_shape === 'square' || props.link
          ? 0
          : props.theme.button_shape === 'semiround'
          ? 3
          : 100,
    },
    '& > label': {
      transform: 'translate(14px, 14px) scale(1)',
      fontFamily: 'Arial',
      fontSize: pxToRem(16),
      fontStyle: 'italic',
      fontWeight: 400,
    },
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      backgroundColor: 'white',
      borderRadius: (props) =>
        props.theme.button_shape === 'square' || props.link
          ? 0
          : props.theme.button_shape === 'semiround'
          ? 3
          : 100,
    },
    '& > .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 2,
      borderColor: (props) => props.theme.color_scheme_dark,
    },
    '& > div > fieldset': {
      borderColor: (props) => props.theme.color_scheme_dark,
    },
    '& > .MuiFormLabel-root.Mui-focused': {
      color: (props) => props.theme.color_scheme_label,
    },
  },
}));
