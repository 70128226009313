import { changeDateOfEvents } from 'common/utils';
import moment from 'moment';
import {
  LOAD_THEME_REQUEST,
  LOAD_THEME_SUCCESS,
  LOAD_THEME_FAILURE,
  LOAD_VARIATIONS_REQUEST,
  LOAD_VARIATIONS_SUCCESS,
  LOAD_VARIATIONS_FAILURE,
  PREVIEW_THEME,
} from '../actions/ui';

const initialState = {
  loading: false,
  theme: null,
  variations: [],
};

const enhanceData = (data) => {
  let appointment_schedule = [];
  try {
    appointment_schedule = JSON.parse(data.appointment_schedule) || [];
  } catch (e) {
    console.error(e);
  }

  appointment_schedule.sort((a, b) => moment(a.start).diff(moment(b.start)));
  const timezone =
    !data.appointment_timezone ||
    data.appointment_timezone === 'custom_homeowner_timezone'
      ? 'UTC'
      : data.appointment_timezone;

  const currentTimezoneOffset = moment.tz(timezone).utcOffset();

  appointment_schedule = appointment_schedule.map((event) => {
    const diff =
      moment.tz(event.start, timezone).utcOffset() - currentTimezoneOffset;
    return {
      ...event,
      start: moment
        .tz(event.start, 'YYYY-MM-DDTHH:mm:ss', timezone)
        .add(diff, 'minutes')
        .format(),
      end: moment
        .tz(event.end, 'YYYY-MM-DDTHH:mm:ss', timezone)
        .add(diff, 'minutes')
        .format(),
    };
  });
  appointment_schedule = changeDateOfEvents(appointment_schedule, moment());

  if (data.appointment_timezone === 'custom_homeowner_timezone') {
    appointment_schedule = appointment_schedule.map((event) => ({
      ...event,
      start: moment(event.start).utc().format('YYYY-MM-DD HH:mm:ss'),
      end: moment(event.end).utc().format('YYYY-MM-DD HH:mm:ss'),
    }));
  }

  return { ...data, appointment_schedule };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_THEME_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case LOAD_THEME_SUCCESS:
      return {
        ...state,
        loading: false,
        theme: enhanceData(action.theme),
      };

    case LOAD_THEME_FAILURE:
      return {
        ...state,
        loading: false,
        theme: initialState.theme,
      };
    case LOAD_VARIATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case LOAD_VARIATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        variations: action.variations,
      };

    case LOAD_VARIATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        variations: initialState.variations,
      };

    case PREVIEW_THEME:
      return {
        ...state,
        loading: false,
        theme: enhanceData(action.theme),
      };

    default:
      return state;
  }
};
