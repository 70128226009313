import styled from 'styled-components';
import { pxToRem } from 'common/utils';

export const Pages = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: Effra;

  .main-container {
    display: flex;
    justify-content: center;
    flex-grow: 1;

    @media screen and (min-width: 768px) {
      overflow-y: auto;
      padding: 2rem;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-image: url(${(props) => props.theme.background});
    }
  }

  .main {
    background: ${(props) => props.theme.color_scheme_background};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @media screen and (min-width: 768px) {
      overflow-y: auto;
      border-radius: 5px;
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
      max-width: 836px;
      max-height: 700px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1.25rem 1.75rem 1rem;
    flex: 1 0 auto;

    .form {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex-grow: 1;

      &-content {
        flex-grow: 1;
      }

      &-actions {
        display: flex;
        justify-content: space-between;
        margin-top: 1.625rem;

        button + button {
          margin-left: 0.625rem;
        }

        &.vertical {
          flex-direction: column;
          gap: 1rem;

          button + button {
            margin: unset;
          }
        }
      }

      @media screen and (min-width: 768px) {
        max-width: ${pxToRem(555)};
        align-self: center;
      }
    }

    @media screen and (min-width: 768px) {
      align-items: center;
      padding-top: 2.625rem;
    }

    &.custom-stepper {
      padding: 1.25rem 1.75rem 1rem;

      .form-content {
        padding: 0 0 1rem 0;
      }

      .form-actions {
        padding: 0;
      }

      .form-actions.form-footer {
        position: sticky;
        bottom: 0;
        padding: ${pxToRem(20)};
        background: ${(props) => props.theme.color_scheme_background};
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
        min-height: ${pxToRem(92)};

        @media screen and (min-width: 768px) {
          box-shadow: none;
          min-height: auto;
        }
      }

      .material-progress {
        padding: 0;
        width: 100%;
        margin-bottom: 2rem;

        @media screen and (min-width: 768px) {
          border-bottom: none;
          align-self: center;
          width: 100%;
          margin-top: 1rem;
        }
        @media screen and (min-width: 900px) {
          border-bottom: none;
          align-self: center;
          width: 100%;
          margin-top: 1rem;
        }
      }
    }
  }

  button {
    font-weight: 400;
    text-transform: capitalize;
  }

  button.btn-disabled,
  button:disabled {
    background-color: #e4e4e4;
  }
`;
