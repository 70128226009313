import React from 'react';
import PropTypes from 'prop-types';
import { noop, escapeRegexCharacters } from 'common/utils';
import Autosuggest from 'react-autosuggest';
import IsolatedScroll from 'react-isolated-scroll';

import StyledAutoComplete from './styled';

class AutoComplete extends React.Component {
  constructor() {
    super();

    this.state = {
      suggestions: [],
    };
  }

  onChange = (_, { newValue }) => {
    const { onChange, name } = this.props;
    onChange({ target: { value: newValue, name } });
  };

  onBlur = () => {
    const { onBlur, name } = this.props;
    onBlur({ target: { name } });
  };

  onSuggestionsFetchRequested = async ({ value }) => {
    const { loadItems, noSuggestionMessage, valueId, valueKey } = this.props;
    try {
      const data = await loadItems(value);

      if (Array.isArray(data) && data.length === 0 && noSuggestionMessage) {
        // If there are no suggestions, show error message option
        const errorOption = {
          [valueId]: -1,
          [valueKey]: noSuggestionMessage,
        };
        this.setState({ suggestions: [errorOption] });
        return;
      }
      this.setState({
        suggestions: this.getMatching(data, value),
      });
    } catch (error) {
      console.error(error);
      this.setState({ suggestions: [] });
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  getSuggestionValue = (suggestion) => {
    const { valueKey } = this.props;
    if (valueKey) {
      return suggestion[valueKey];
    }
    if (typeof suggestion === 'string') {
      return suggestion;
    }
    return suggestion.text;
  };

  getMatching = (data, value) => {
    const escapedValue = escapeRegexCharacters(value.trim());

    if (escapedValue === '') {
      return [];
    }

    const regex = new RegExp(escapedValue, 'i');

    return data.filter((item) => regex.test(this.getSuggestionValue(item)));
  };

  renderSuggestion = (suggestion) => {
    const label = this.getSuggestionValue(suggestion);
    return <div>{label}</div>;
  };

  renderSuggestionsContainer = ({ containerProps, children }) => {
    const { ref, ...restContainerProps } = containerProps;
    const callRef = (isolatedScroll) => {
      if (isolatedScroll !== null) {
        ref(isolatedScroll.component);
      }
    };

    return (
      <IsolatedScroll ref={callRef} {...restContainerProps}>
        {children}
      </IsolatedScroll>
    );
  };

  render() {
    const { suggestions } = this.state;
    const { value, placeholder, name, error, onSelect, id = null } = this.props;

    const inputProps = {
      placeholder,
      value,
      onChange: this.onChange,
      onBlur: this.onBlur,
      name,
      autoFocus: true,
      id,
    };

    return (
      <StyledAutoComplete error={error}>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
          onSuggestionSelected={onSelect}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
        />
      </StyledAutoComplete>
    );
  }
}

AutoComplete.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  valueKey: PropTypes.string,
  error: PropTypes.bool,
  loadItems: PropTypes.func,
  onSelect: PropTypes.func,
};

AutoComplete.defaultProps = {
  name: 'autocomplete',
  value: '',
  placeholder: 'Enter a text',
  onChange: noop,
  onBlur: noop,
  valueKey: 'text',
  error: false,
  loadItems: noop,
  onSelect: noop,
};

export default AutoComplete;
