import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import { noop } from 'common/utils';
import StyledButtonSelect from './styled';

const ButtonSelect = ({ options, onChange, value }) => (
  <StyledButtonSelect>
    {options
      .filter((item) => !item.disabled)
      .map((item, index) => (
        <Button
          full
          outline
          disabled={item.disabled}
          selected={value === item.value}
          key={index}
          onClick={() => onChange(item.value, item)}
        >
          {item.label}
        </Button>
      ))}
  </StyledButtonSelect>
);

ButtonSelect.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

ButtonSelect.defaultProps = {
  options: [],
  onChange: noop,
  value: null,
};

export default ButtonSelect;
