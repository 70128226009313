import React from 'react';
import DefaultLayout from 'components/DefaultLayout';
import ProgressBar from 'components/ProgressBar';
import Chat from 'components/Chat';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { generateID } from 'common/utils';

const Retry = () => {
  const [t] = useTranslation('translation');
  const location = useLocation();

  return (
    <DefaultLayout>
      <div className="content calculate-2-page">
        <div className="form">
          <div className="form-content">
            <div
              id={generateID('div-progress-bar', location.pathname)}
              className="mb-20 w100"
            >
              <ProgressBar value={50} step={2} />
            </div>
            <Chat id={generateID('chat-something-wrong', location.pathname)}>
              <div>
                <Trans
                  t={t}
                  i18nKey="There is something wrong while estimation. Please try again. If so, please click <1>here.</1>"
                >
                  There is something wrong while estimation. Please try again.
                  If so, please click&nbsp;
                  <Link
                    id={generateID('btn-go-calculate3', location.pathname)}
                    to="/calculate3"
                    className="link-text"
                  >
                    here.
                  </Link>
                </Trans>
                <br />
                <br />
                <Trans
                  t={t}
                  i18nKey="If you see the screen again, please click <1>here.</1>"
                >
                  If you see the screen again, please click&nbsp;
                  <Link
                    id={generateID(
                      'btn-go-inactive-company',
                      location.pathname
                    )}
                    to="/inactive-company"
                    className="link-text"
                  >
                    here.
                  </Link>
                </Trans>
              </div>
            </Chat>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Retry;
