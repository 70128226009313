import styled from 'styled-components';

const StyledWrapper = styled.div`
  .sunny {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-family: Roboto, Arial, Helvetica, sans-serif;
      font-size: 0.625rem;
      color: #666;
      text-transform: capitalize;
      line-height: 24px;
    }

    &-bar {
      height: 0.5rem;
      flex-grow: 1;
      margin: 0 0.5rem;
      background-color: #f5f5f5;
      background-image: linear-gradient(
        90deg,
        rgba(82, 36, 117, 1) 10%,
        rgba(243, 139, 34, 1) 35%,
        rgba(251, 255, 0, 1) 65%,
        rgba(253, 255, 242, 1) 90%
      );
      border-radius: 2px;
    }
  }
  .sunroof-image-wrapper {
    @media screen and (min-width: 768px) {
      padding: 0 35px;
    }

    .MuiLink-root {
      color: #3a3a3a;
    }

    .MuiLink-root:hover {
      text-decoration: underline;
      color: #3a3a3a;
    }
  }
`;

export default StyledWrapper;
