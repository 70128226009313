import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import ButtonSelect from 'components/ButtonSelect';
import { setUserData, saveSession } from 'store/actions';
import Header from 'components/Sungage/Header';
import Stage from 'components/Sungage/Stage';
import Question from 'components/Sungage/Question';
import { VARIATION_OPTIONS } from 'pages/constants';
import { getLocalStorageItem } from 'common/localStorage';
import { generateID } from 'common/utils';
import StyledSungage2 from './styled';

class Sungage2 extends Component {
  constructor(props) {
    super(props);

    const variation = getLocalStorageItem('variation');

    this.state = {
      mortgage: null,
      variation: VARIATION_OPTIONS[variation],
    };
  }

  componentDidMount() {
    this.initData();
  }

  initData = () => {
    const {
      user: { sungage },
    } = this.props;
    if (sungage?.mortgage) this.setState({ mortgage: sungage?.mortgage });
  };

  handleClose = async () => {
    const { history, actions, user } = this.props;

    let step;
    if (user.sungage?.prequalification_skipped) step = '/result3';
    else step = '/qualify/end';

    actions.setUserData({ step });
    await actions.saveSession();

    history.replace(step);
  };

  handleBack = async () => {
    const { history, actions } = this.props;

    actions.setUserData({
      step: '/prequalify/sungage1',
    });
    await actions.saveSession();

    history.replace('/prequalify/sungage1');
  };

  handleNext = async (mortgage) => {
    const { history, actions } = this.props;

    this.setState({ mortgage });

    actions.setUserData({
      step: '/prequalify/sungage3',
      sungage: { mortgage },
    });
    await actions.saveSession();

    history.push('/prequalify/sungage3');
  };

  render() {
    const { t } = this.props;
    const { mortgage, variation } = this.state;

    const mortgageList = [
      { value: 'less than 500', label: t('Zero or less than $500 / mo.') },
      { value: '500-1000', label: t('$500 - $1,000 / mo.') },
      { value: '1500-2000', label: t('$1,500 - $2,000 / mo.') },
      { value: '2000-2500', label: t('$2,000 - $2,500 / mo.') },
      { value: '2500-3500  ', label: t('$2,500 - $3,500 / mo.') },
      { value: 'more than 3500', label: t('More than $3,500 / mo.') },
    ];

    const {
      location: { pathname },
    } = this.props;

    return (
      <>
        <variation.header />

        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <StyledSungage2 className="content sungage2-page">
              <div id={generateID('div-header', pathname)} className="mb-32">
                <Header onCloseClick={this.handleClose} />
              </div>

              <div id={generateID('div-stage', pathname)} className="mb-16">
                <Stage stage={2} onBackClick={this.handleBack} />
              </div>

              <Formik onSubmit={this.handleNext}>
                {() => {
                  return (
                    <Form className="form">
                      <div className="form-content">
                        <div
                          id={generateID('div-question', pathname)}
                          className="mb-16"
                        >
                          <Question>
                            {t('What is your current mortgage payment?')}
                          </Question>
                        </div>

                        <div
                          id={generateID('div-answer', pathname)}
                          className="mb-32"
                        >
                          <ButtonSelect
                            options={mortgageList}
                            onChange={this.handleNext}
                            value={mortgage}
                          />
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </StyledSungage2>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui }) => ({
  user: data,
  theme: ui.theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Sungage2)
);
