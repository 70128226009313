/* eslint-disable react/no-unused-state */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Chat from 'components/ChatNRGVariation';
import EnvironmentItem from 'components/EnvironmentItemNRGVariation';
import SavingChart from 'components/SavingChartNRGVariation';
import Expand from 'components/ExpandNRGVariation';
import { ReactComponent as CarbonDioxideIcon } from 'assets/images/icon-carbon-dioxide.svg';
import { ReactComponent as LeafIcon } from 'assets/images/icon-leaf.svg';
import { ReactComponent as StationIcon } from 'assets/images/icon-station.svg';
import { engineSolarFindBuilding } from 'common/api.mock';
import { formatNumber, generateID } from 'common/utils';
import { withTranslation, Trans } from 'react-i18next';
import { getLocalStorageItem } from 'common/localStorage';
import { VARIATION_OPTIONS } from 'pages/constants';
import StyledProposals from './styled';

class Proposals extends Component {
  constructor(props) {
    super(props);

    const variation = getLocalStorageItem('variation');
    this.state = {
      loading: true,
      squareFeet: null,
      sunshineHours: null,
      error: null,
      variation: VARIATION_OPTIONS[variation],
    };

    this.divRef = React.createRef();
  }

  componentDidMount() {
    this.initData();
  }

  initData = async () => {
    const { theme, user } = this.props;
    if (!theme.is_sunroof_enabled) {
      this.setState({ loading: false, error: 'Skip sunroof' });
      return;
    }
    const { data: building } = await engineSolarFindBuilding(
      { latitude: user.rooftop_latitude, longitude: user.rooftop_longitude },
      theme.google_api_gateway_key
    );
    if (!building) {
      this.setState({
        loading: false,
        error: 'Requested location is not supported by Google Sunroof API.',
      });
    } else {
      const squareFeet = formatNumber(
        Math.round(
          get(building, 'solarPotential.maxArrayAreaMeters2', 0) * 10.764
        )
      );
      const sunshineHours = formatNumber(
        Math.round(get(building, 'solarPotential.maxSunshineHoursPerYear', 0))
      );
      this.setState({
        loading: false,
        squareFeet,
        sunshineHours,
      });
    }
  };

  render() {
    const { user, theme, t } = this.props;
    const { loading, sunshineHours, squareFeet, variation } = this.state;
    const data = { ...user };

    const language = getLocalStorageItem('i18nextLng');
    const avatar = /^es\b/.test(language)
      ? theme.spanish_ai_avatar
      : theme.ai_avatar;

    const {
      location: { pathname },
    } = this.props;

    return (
      <>
        <variation.header />

        <div className="main-container">
          <div
            className="main"
            style={{
              height: !loading ? 'initial' : null,
              position: 'relative',
            }}
          >
            {loading ? (
              <div className="content">
                <div className="form">
                  <div className="form-content">
                    <Chat
                      id={generateID('chat-loading', pathname)}
                      mode="loading"
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <StyledProposals className="content proposals-page">
                <div className="form">
                  <div className="introduction-section">
                    <div
                      id={generateID('div-avatar', pathname)}
                      className="avatar"
                      style={{
                        backgroundImage: `url(${avatar})`,
                      }}
                    >
                      <span className="avatar-status"></span>
                    </div>

                    <div
                      id={generateID('div-introduction', pathname)}
                      className="introduction-text"
                    >
                      {t(
                        'Here is your estimate for {{address}}, {{city}}, {{state}} {{zipcode}}',
                        {
                          address: data.address,
                          city: data.city,
                          state: data.state,
                          zipcode: data.zip_code,
                        }
                      )}
                    </div>
                  </div>

                  <div
                    id={generateID('div-monthly-payment', pathname)}
                    className="white-card"
                  >
                    <div className="text-title mb-8">
                      {t('Estimated Cost Per Month')}
                    </div>
                    <div className="text-value mb-8">
                      {theme.price_system_display || !theme.is_premier_account
                        ? `$${formatNumber(
                            Math.round(data.monthly_payment_min)
                          )}-${formatNumber(
                            Math.round(data.monthly_payment_max)
                          )}`
                        : data.monthly_payment_best_guess
                        ? `${formatNumber(
                            Math.round(data.monthly_payment_best_guess)
                          )}`
                        : `$${formatNumber(
                            Math.round(data.monthly_payment_min)
                          )}-${formatNumber(
                            Math.round(data.monthly_payment_max)
                          )}`}
                      <sup>*</sup>
                    </div>
                    <div className="text-description">
                      {t(
                        '*{{product_apr}}% APR financing for {{product_term}} years on approved credit with zero down.',
                        {
                          product_apr: theme.product_apr,
                          product_term: theme.product_term,
                        }
                      )}
                    </div>
                  </div>

                  {sunshineHours && (
                    <div
                      id={generateID('div-sunshine-hours', pathname)}
                      className="white-card"
                    >
                      <div className="text-title mb-8">
                        {t('Hours of Usable Sunlight Per Year')}
                      </div>
                      <div className="text-value mb-8">
                        {sunshineHours}
                        <small>Hours</small>
                      </div>
                      <div className="text-description">
                        <Trans
                          t={t}
                          i18nKey="Based on day-to-day analysis of weather patterns"
                        >
                          Based on day-to-day analysis of weather patterns
                        </Trans>
                      </div>
                    </div>
                  )}

                  <div
                    id={generateID('div-total-incentive', pathname)}
                    className="white-card"
                  >
                    <div className="text-title mb-8">
                      {t('Total Incentive Discounts')}
                    </div>
                    <div className="text-value mb-8">
                      ${`${formatNumber(data.incentive)}`}
                      <sup>*</sup>
                    </div>
                    <div className="text-title">
                      {t('Only while available!')}
                    </div>
                  </div>

                  <div
                    id={generateID('div-25-year-savings', pathname)}
                    className="white-card"
                  >
                    <div className="text-title mb-8">
                      {t('Estimated 25 Year Savings')}
                    </div>
                    <div className="text-value mb-16">
                      ${`${formatNumber(data.savings)}`}
                      <sup>*</sup>
                    </div>
                    <div className="chart-container">
                      <div className="chart-area">
                        <SavingChart
                          value1={data.avg_bill}
                          value2={formatNumber(
                            Math.round(data.avg_bill * 1.03 ** 10)
                          )}
                          value3={formatNumber(
                            Math.round(data.avg_bill * 1.03 ** 25)
                          )}
                        />
                      </div>
                    </div>

                    <div className="divider" />

                    <Expand title={t('Why do utility rates go up?')}>
                      {t(
                        'Utility rates in this market have historically increased by {{escalation}}% each year. This is based on a variety of market factors including inflation.',
                        {
                          escalation: theme.escalation || 3,
                        }
                      )}
                    </Expand>
                  </div>

                  {squareFeet && (
                    <div
                      id={generateID('div-square-feet', pathname)}
                      className="white-card"
                    >
                      <div className="text-title mb-8">
                        {t('Square Footage Available for Solar Panels')}
                      </div>
                      <div className="text-value mb-8">
                        {squareFeet}
                        <small>Sq. Ft.</small>
                      </div>
                      <div className="text-description">
                        <Trans
                          t={t}
                          i18nKey="Based on 3D modeling of your roof and nearby trees"
                        >
                          Based on 3D modeling of your roof and nearby trees
                        </Trans>
                      </div>
                    </div>
                  )}

                  <div
                    id={generateID('div-system-size', pathname)}
                    className="white-card"
                  >
                    <div className="text-title mb-8">
                      {t('Recommended System Size')}
                    </div>
                    <div className="text-value mb-8">
                      {theme.price_system_display ||
                      !theme.is_premier_account ? (
                        `${data.install_size_min}-${data.install_size_max}`
                      ) : (
                        <>{data.install_size}</>
                      )}
                      <small>kW</small>
                    </div>
                    <div className="text-description">
                      {t('{{panels_min}}-{{panels_max}} Panels', {
                        panels_min: data.panels_min,
                        panels_max: data.panels_max,
                      })}
                    </div>
                  </div>

                  <div
                    id={generateID('div-home-value', pathname)}
                    className="white-card"
                  >
                    <div className="text-title mb-8">
                      {t('Increase in Home Value')}
                    </div>
                    <div className="text-value mb-8">+4.1%</div>
                    <div className="text-description mb-16">
                      {t('According to a recent study from Zillow.*')}
                    </div>

                    <div className="divider" />

                    <Expand title={t('Learn about the Zillow study.')}>
                      {t(
                        'Zillow calculated the solar premium by comparing homes with and without solar-energy systems that were listed for sale and sold from March 1, 2018 to February 28, 2019, controlling for observable attributes of the homes.'
                      )}
                    </Expand>
                  </div>

                  <div
                    id={generateID('div-environmental-impact', pathname)}
                    className="white-card"
                  >
                    <div className="text-title mb-16">
                      {t('Estimated Environmental Impact')}
                    </div>
                    <div className="list-value">
                      <EnvironmentItem
                        icon={<CarbonDioxideIcon />}
                        title={`${data.co2_tons}`}
                        content={t('Tons of CO2 avoided')}
                      />
                      <EnvironmentItem title="=" />
                      <EnvironmentItem
                        icon={<LeafIcon />}
                        title={`${data.tree_seedlings}`}
                        content={t('Trees grown for 10 years')}
                      />
                      <EnvironmentItem title="=" />
                      <EnvironmentItem
                        icon={<StationIcon />}
                        title={`${data.gal_of_gas}`}
                        content={t('Gallons of gas not used')}
                      />
                    </div>
                  </div>

                  <div
                    id={generateID('div-legal', pathname)}
                    className="transparent-card"
                  >
                    <div className="text-disclaimer">
                      <Trans
                        t={t}
                        i18nKey="*Note: Cost and savings estimates will vary based on individual circumstances. Factors such as tree cover, shade, roof materials and conditions, roof space and angle, usage patterns, weather patterns, direct sunlight hours, etc. may affect system costs and savings."
                      >
                        *Note: Cost and savings estimates will vary based on
                        individual circumstances. Factors such as tree cover,
                        shade, roof materials and conditions, roof space and
                        angle, usage patterns, weather patterns, direct sunlight
                        hours, etc. may affect system costs and savings.
                      </Trans>
                    </div>
                  </div>
                </div>
              </StyledProposals>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui: { theme } }) => ({
  user: data,
  theme,
});

export default withTranslation('translation')(
  connect(mapStateToProps, null)(Proposals)
);
