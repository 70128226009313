import styled from 'styled-components';

const Calculate2 = styled.div``;

export const TooltipTitle = styled.div`
  font-size: 0.75rem;
  font-weight: 700;
  color: #fff;
  background-color: ${(props) => props.theme.color_scheme_light};
  padding: 0.25rem;
`;

export const TooltipContent = styled.div`
  margin: 0.25rem 0;
`;

export const TooltipSubContent = styled.div`
  font-size: 0.75rem;
  color: #666;
  margin: 0.25rem 0;
`;

export default Calculate2;
