import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Button from 'components/Button';
import TextInput from 'components/Forms/TextInput';
import Chat from 'components/Chat';
import Tooltip from 'components/Tooltip';
import { ReactComponent as TooltipIcon } from 'assets/images/icon-tooltip.svg';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/images/icon-arrow-right.svg';
import {
  verifyPhoneNumber,
  verifyEmail,
  sendVerificationCode,
  lookupPhoneNumber,
} from 'common/api.mock';
import { generateID, getNames } from 'common/utils';
import MaterialStepper from 'components/MaterialStepper';
import { withTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import {
  setUserData,
  saveSession,
  trackFacebookPixel,
  trackGoogleLead,
  navigateTo,
  trackForwardProgress,
} from '../../../store/actions';
import { Result2 as StyledResult2, Legal } from './styled';

const formSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  // .matches(/^[a-zA-Z]([-']?[a-zA-Z]+)*( [a-zA-Z]([-']?[a-zA-Z]+)*)+$/),
  email: Yup.string().strict(false).trim().email().required('Required'),
  phone: Yup.string().strict(false).trim().required('Required'),
});

const CustomInput = ({ ...props }) => (
  <InputMask {...props}>{() => <TextInput {...props} />}</InputMask>
);

class Result2Variation1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loaded: false,
      errors: null,
      jornaya_lead_id: window.jornaya_leadid_token,
    };
  }

  handleBack = () => {
    const {
      actions,
      theme: { is_project_detail_referral_on },
    } = this.props;
    const go_step = is_project_detail_referral_on
      ? '/qualify/default3'
      : '/qualify/default2';
    actions.navigateTo(go_step);
  };

  handleNext = async (values, { setFieldError, setValues }) => {
    let { phone, email, name } = values;
    const { actions, theme, user, t } = this.props;
    const {
      is_phone_verification_required,
      is_strict_phone_number_validation_enabled,
      is_strict_email_validation_enabled,
    } = theme;

    // trim phone, email before submitting
    phone = phone.trim();
    email = email.trim();
    name = name.trim();
    setValues({ phone, email, name });

    const { first: first_name, last: last_name } = getNames(name);

    const { jornaya_lead_id } = this.state;
    this.setState({ loading: true, errors: null });

    let phoneRes;
    let emailRes;
    let lookupRes;

    if (is_strict_phone_number_validation_enabled) {
      phoneRes = await verifyPhoneNumber(phone);
      lookupRes = await lookupPhoneNumber({ phone });
    }
    if (is_strict_email_validation_enabled) {
      emailRes = await verifyEmail(email);
    }
    const errors = [];

    if (!first_name) {
      errors.push(t('Please provide your full name.'));
      setFieldError('name', 'Invalid');
    }
    if (!last_name) {
      errors.push(t('Please provide your last name.'));
      setFieldError('name', 'Invalid');
    }

    if (
      is_strict_phone_number_validation_enabled &&
      !phoneRes?.success &&
      !lookupRes
    ) {
      errors.push(
        t('That number does not seem to be a valid mobile phone number.')
      );
      setFieldError('phone', 'Invalid');
    }
    if (is_strict_email_validation_enabled && !emailRes?.success) {
      errors.push(t('That email does not seem to be a valid email.'));
      setFieldError('email', 'Invalid');
    }
    if (errors.length > 0) {
      this.setState({ loading: false, loaded: true, errors });
    } else {
      actions.trackFacebookPixel('result2', user.variation);
      actions.trackGoogleLead('result2', user.variation);

      actions.setUserData({
        first_name,
        last_name,
        email,
        phone: phoneRes ? phoneRes.data.national_format : phone,
        jornaya_lead_id,
        trustedform_cert_url: window.trustedForm.id
          ? `https://cert.trustedform.com/${window.trustedForm.id}`
          : '',
      });

      await actions.saveSession();

      const { user: verified_user } = this.props;

      let go_step = '/disqualify';
      if (verified_user.is_disqualified) {
        const step = '/start';

        actions.setUserData({
          step,
        });
      } else {
        const { is_phone_verified } = verified_user;

        if (is_phone_verification_required && !is_phone_verified) {
          await sendVerificationCode({ phone });
          go_step = '/phoneverify';
        } else {
          go_step = '/qualify/end';
        }

        actions.setUserData({
          step: is_phone_verification_required
            ? '/phoneverify'
            : '/qualify/end',
        });
      }

      actions.trackForwardProgress();
      await actions.saveSession();
      await actions.navigateTo(go_step);
    }
  };

  getFullName = () => {
    const { user } = this.props;
    const name = [];
    if (user.first_name) {
      name.push(user.first_name);
    }
    if (user.last_name) {
      name.push(user.last_name);
    }
    return name.join(' ');
  };

  render() {
    const { user, theme, t } = this.props;
    const { loading, loaded, errors } = this.state;
    const errorMsg = errors ? errors.join('\n') : '';

    const {
      location: { pathname },
    } = this.props;

    return (
      <>
        <Header />
        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <StyledResult2 className="content result-2-page custom-stepper">
              <div
                id={generateID('div-progress-bar', pathname)}
                className="material-progress"
              >
                <MaterialStepper activeStep={1} variation={user.variation} />
              </div>

              <Formik
                initialValues={{
                  name: this.getFullName() || '',
                  email: user.email || '',
                  phone: user.phone || '',
                }}
                validationSchema={formSchema}
                onSubmit={this.handleNext}
                validateOnMount
              >
                {({ isValid, isSubmitting }) => {
                  return (
                    <Form className="form">
                      <div className="form-content">
                        <Chat
                          id={generateID('chat-unlock-live-pricing', pathname)}
                          mode={loaded || loading ? 'loading' : 'timeout'}
                          loading={loading}
                        >
                          <div className="mb-16">
                            {!loaded ? (
                              <span>
                                {t(
                                  'Where can we contact you with your finalized quote?'
                                )}
                              </span>
                            ) : (
                              errorMsg
                            )}
                            &nbsp;
                            <TooltipIcon data-for="tooltip" data-tip />
                            <Tooltip id="tooltip" place="bottom">
                              <div className="tooltip-title">
                                {t('We do not sell your information.')}
                              </div>
                              <div className="tooltip-content">
                                {t(
                                  'Our online savings estimates are conservative. Typically, we can improve your savings estimate with more info about your project.'
                                )}
                              </div>
                            </Tooltip>
                          </div>
                          <div className="mb-16">
                            <Field
                              id={generateID('txt-name', pathname)}
                              name="name"
                              type="text"
                              placeholder={t('First and Last Name')}
                              theme={theme}
                              component={TextInput}
                              autoFocus
                            />
                          </div>
                          <div className="mb-16">
                            <Field
                              id={generateID('txt-email', pathname)}
                              name="email"
                              type="email"
                              placeholder={t('Email')}
                              theme={theme}
                              component={TextInput}
                            />
                          </div>
                          <div className="mb-16">
                            <Field>
                              {({ field, form }) => (
                                <CustomInput
                                  id={generateID('txt-phone', pathname)}
                                  mask="(999) 999-9999"
                                  name="phone"
                                  type="tel"
                                  placeholder={t('Mobile phone number')}
                                  theme={theme}
                                  value={field.value.phone}
                                  onChange={field.onChange}
                                  field={field}
                                  form={form}
                                />
                              )}
                            </Field>
                          </div>
                        </Chat>
                      </div>

                      {!isSubmitting && (
                        <div
                          id={generateID('div-actions', pathname)}
                          className="form-actions"
                        >
                          <Button
                            id={generateID('btn-back', pathname)}
                            outline
                            onClick={this.handleBack}
                          >
                            <BackIcon />
                          </Button>
                          <Button
                            id={generateID('btn-see-estimate', pathname)}
                            type="submit"
                            full
                            disabled={!isValid || isSubmitting}
                          >
                            {t('See Estimate')}
                            <ArrowRightIcon />
                          </Button>
                        </div>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </StyledResult2>

            {!loading && (
              <Legal
                id={generateID('div-legal', pathname)}
                className="form-legal"
              >
                <label>
                  <input type="hidden" id="leadid_tcpa_disclosure" />
                  {t(theme.legal)}
                </label>
              </Legal>
            )}
            <Footer showLegal />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui: { theme } }) => ({
  user: data,
  theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      trackFacebookPixel,
      navigateTo,
      trackForwardProgress,
      trackGoogleLead,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Result2Variation1)
);
