import React from 'react';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import { noop } from 'common/utils';
import StyledWrapper from './styled';

const MaterialSlider = ({ min, max, step, value, onChange, marks, scale }) => {
  const handleSliderChange = (event, newValue) => {
    onChange(newValue, Math.round(scale(newValue)));
  };

  return (
    <StyledWrapper className="material-slider">
      <Slider
        value={value}
        min={min}
        step={step}
        max={max}
        marks={marks}
        scale={scale}
        onChange={handleSliderChange}
      />
    </StyledWrapper>
  );
};

MaterialSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
  marks: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
};

MaterialSlider.defaultProps = {
  min: 0,
  max: 100,
  step: 1,
  value: 0,
  onChange: noop,
  marks: false,
};

export default MaterialSlider;
