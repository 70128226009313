import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DefaultLayout from 'components/DefaultLayout';
import Button from 'components/Button';
import Chat from 'components/Chat';
import MaterialStepper from 'components/MaterialStepper';
import { ReactComponent as ArrowRightIcon } from 'assets/images/icon-arrow-right.svg';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackForwardProgress,
  trackFacebookPixel,
  trackGoogleLead,
} from 'store/actions';
import { withTranslation } from 'react-i18next';
import { generateID } from 'common/utils';
import StyledQualify9 from './styled';

class Qualify9AppointmentRequestVariation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
    };

    props.actions.trackFacebookPixel('qualify9', props.user.variation);
    props.actions.trackGoogleLead('qualify9', props.user.variation);
  }

  handleNext = async () => {
    const { actions, theme } = this.props;

    let step = '/meeting1';
    if (theme.appointment_method === 'CALENDLY') {
      step = '/meetingcalendly';
    }
    actions.setUserData({ step });
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo(step);
  };

  render() {
    const { step } = this.state;
    const { saving, theme, t, user } = this.props;

    const {
      location: { pathname },
    } = this.props;

    return (
      <DefaultLayout>
        <StyledQualify9 className="content qualify-9-page custom-stepper">
          <div className="form">
            <div
              id={generateID('div-material-stepper', pathname)}
              className="material-progress"
            >
              <MaterialStepper activeStep={1} variation={user.variation} />
            </div>

            <div className="form-content">
              <Chat
                id={generateID('chat-congratulations', pathname)}
                onShow={() => this.setState({ step: 2 })}
              >
                {t('Congratulations! You qualify!')}
              </Chat>

              {step >= 2 && (
                <Chat
                  id={generateID('chat-request-a-time-to-review', pathname)}
                  onShow={() => this.setState({ step: 3 })}
                >
                  {theme.is_calendly_on
                    ? t(
                        'Request a time to review your quote with a solar expert from our team.'
                      )
                    : t(
                        'Request a time to review your quote with a solar expert from our team over the phone.'
                      )}
                </Chat>
              )}
            </div>

            {step >= 3 && (
              <div
                id={generateID('div-actions', pathname)}
                className="form-actions"
              >
                <Button
                  id={generateID('btn-schedule-now', pathname)}
                  full
                  onClick={this.handleNext}
                  disabled={saving}
                >
                  {t('Schedule Now')}
                  <ArrowRightIcon />
                </Button>
              </div>
            )}
          </div>
        </StyledQualify9>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ user: { data, saving }, ui }) => ({
  user: data,
  theme: ui.theme,
  saving,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      trackFacebookPixel,
      trackGoogleLead,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Qualify9AppointmentRequestVariation)
);
