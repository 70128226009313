import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 0.75rem;

  @media screen and (min-width: 768px) {
    padding: 0 3.75rem;
  }

  .MuiSlider-root {
    height: 1.25rem;
  }

  .MuiSlider-rail {
    height: 6px;
    background-color: #e4e4e4;
    border-radius: 15px;
  }

  .MuiSlider-track {
    height: 6px;
    background-color: #231f20;
    border-radius: 15px;
  }

  .MuiSlider-thumb {
    width: auto;
    height: auto;
    transition: none;
    box-shadow: none;
    margin-left: -1.0625rem;
    margin-top: -0.5rem;

    width: 24px;
    height: 24px;
    background-color: #231f20;
    margin-left: -0.75rem;

    &::after {
      display: none;
    }

    &:focus,
    &:hover,
    &:active {
      box-shadow: none;
    }
  }
`;

export default StyledWrapper;
