import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import imgSunlight from 'assets/images/sunlight.png';
import { setUserData, saveSession } from 'store/actions';
import Header from 'components/Sunlight/Header';
import Processing from 'components/Sunlight/Processing';
import Card from 'components/Sunlight/Card';
import { VARIATION_OPTIONS } from 'pages/constants';
import { getLocalStorageItem } from 'common/localStorage';
import imgSunlightFinanced from 'assets/images/sunlight-financed.png';
import imgSunlightEnergy from 'assets/images/sunlight-energy.png';
import imgSunlightCO2 from 'assets/images/sunlight-co2.png';
import { prequalSunlight } from 'common/api.mock';
import { generateID } from 'common/utils';
import StyledSunlight4 from './styled';

const TransitionWithCard = ({ components }) => {
  const [step, setStep] = React.useState(-1);
  const [exiting, setExiting] = React.useState(false);
  const [timerId, setTimerId] = React.useState(null);

  const appearTime = 500;
  const duration = 2000;

  const defaultStyle = {
    transition: `opacity ${appearTime}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  const handleEntered = () => {
    if (step + 1 === components.length) return;

    const id = setTimeout(() => {
      setExiting(true);
    }, duration);
    setTimerId(id);
  };

  const handleExited = () => {
    if (step < components.length) {
      setStep(step + 1);
      setExiting(false);
    }
  };

  React.useEffect(() => {
    setStep(0);
  }, []);

  React.useEffect(() => {
    return () => {
      clearTimeout(timerId);
    };
  });

  return (
    <div>
      {[
        ...components.map((component, index) => (
          <Transition
            key={index}
            in={step === index && !exiting}
            timeout={appearTime}
            onEntered={() => handleEntered()}
            onExited={() => handleExited()}
          >
            {(state) => (
              <div
                style={{
                  ...defaultStyle,
                  ...transitionStyles[state],
                  display: step === index ? 'block' : 'none',
                }}
              >
                {component}
              </div>
            )}
          </Transition>
        )),
      ]}
    </div>
  );
};

class Sunlight4 extends Component {
  constructor(props) {
    super(props);

    const variation = getLocalStorageItem('variation');
    this.state = {
      variation: VARIATION_OPTIONS[variation],
    };
  }

  async componentDidMount() {
    const timerPromise = new Promise((resolve) => {
      setTimeout(resolve, 10000);
    });
    Promise.allSettled([prequalSunlight(), timerPromise]).then((results) => {
      this.handleNext(results[0].value);
    });
  }

  handleClose = async () => {
    const {
      history,
      actions,
      user: { sunlight },
    } = this.props;

    let step;
    if (sunlight.step_from === '/result3') step = '/result3';
    else step = '/qualify/end';

    actions.setUserData({ step });
    await actions.saveSession();

    history.replace(step);
  };

  handleNext = async (result = null) => {
    const { history, actions } = this.props;

    let step;
    if (result.result === 'Auto Approved')
      step = '/prequalify/sunlight/success';
    else step = '/prequalify/sunlight/failure';

    actions.setUserData({ step });
    await actions.saveSession();
    history.push(step);
  };

  render() {
    const { t } = this.props;
    const { variation } = this.state;

    const {
      location: { pathname },
    } = this.props;

    return (
      <>
        <variation.header />

        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <StyledSunlight4 className="content sunlight4-page">
              <div id={generateID('div-header', pathname)} className="mb-32">
                <Header onCloseClick={this.handleClose} />
              </div>

              <div
                id={generateID('div-processing', pathname)}
                className="processing mt-16 mb-16"
              >
                <Processing />
              </div>

              <div
                id={generateID('div-cards', pathname)}
                className="card-container"
              >
                <TransitionWithCard
                  components={[
                    <Card
                      key={1}
                      logo={<img src={imgSunlightFinanced} alt="financed" />}
                      value="247,048"
                      text={t('Solar systems financed')}
                    />,
                    <Card
                      key={2}
                      logo={<img src={imgSunlightCO2} alt="co2" />}
                      value="25,470,995"
                      text={t('Metric tons of CO2 avoided')}
                    />,
                    <Card
                      key={3}
                      logo={<img src={imgSunlightEnergy} alt="energy" />}
                      value="37,057,291,500"
                      text={t('kWh of clean energy produced')}
                    />,
                  ]}
                />
              </div>
            </StyledSunlight4>

            <div
              id={generateID('div-sunlight-logo', pathname)}
              style={{ marginBottom: '4rem' }}
            >
              <div className="sunlight-logo mb-16 mt-16">
                <img src={imgSunlight} alt="sunlight" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui }) => ({
  user: data,
  theme: ui.theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Sunlight4)
);
