import React from 'react';
import { ReactComponent as IconArrowLeft } from 'assets/images/icon-arrow-left.svg';
import StyledBackButton from './styled';

const BackButton = (props) => {
  return (
    <StyledBackButton {...props}>
      <IconArrowLeft />
      Return to previous page
    </StyledBackButton>
  );
};

export default BackButton;
