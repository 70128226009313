import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'components/Button';
import { ReactComponent as SuccessIcon } from 'assets/images/icon-success.svg';
import { ReactComponent as PhoneIcon } from 'assets/images/icon-phone.svg';
import { ReactComponent as SendIcon } from 'assets/images/icon-send.svg';
import DefaultLayout from 'components/DefaultLayout';
import { withTranslation } from 'react-i18next';
import { generateID } from 'common/utils';
import StyledCommercial3 from './styled';

class Commercial3 extends Component {
  handleEmail = () => {
    const { theme } = this.props;
    window.location.href = `mailto:${theme.advertise_email}`;
  };

  handlePhone = () => {
    const { theme } = this.props;
    document.location.href = `${theme.advertise_phone}`;
  };

  render() {
    const { theme, t } = this.props;

    const {
      location: { pathname },
    } = this.props;

    return (
      <DefaultLayout showRestartButton={false}>
        <StyledCommercial3 className="content commercial-3-page">
          <div className="form">
            <div
              id={generateID('div-commercial3', pathname)}
              className="form-content text-center"
            >
              <div className="mb-32">
                <SuccessIcon />
              </div>
              <div className="form-title mb-16">
                {t('Thank you for your interest!')}
              </div>
              <div className="form-subtext">
                {t(
                  'A commercial solar expert from our team will be in touch with you shortly to help you explore your options for investing in solar energy.'
                )}
              </div>
              <div className="form-subtext">{t('Don’t want to wait?')}</div>
            </div>

            <div
              id={generateID('div-actions', pathname)}
              className="form-actions vertical"
            >
              <Button
                id={generateID('btn-call-us', pathname)}
                full
                onClick={this.handlePhone}
                className="btn-svg"
              >
                <PhoneIcon />{' '}
                {t('Call Us: {{advertise_phone}}', {
                  advertise_phone: theme.advertise_phone,
                })}
              </Button>
              <Button
                id={generateID('btn-send-us-an-email', pathname)}
                full
                outline
                className="btn-svg"
                onClick={this.handleEmail}
              >
                <SendIcon /> {t('Send us an email')}
              </Button>
            </div>
          </div>
        </StyledCommercial3>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ ui: { theme } }) => ({
  theme,
});

export default withTranslation('translation')(
  connect(mapStateToProps)(Commercial3)
);
