import React from 'react';
import StyledCard from './styled';

const Card = ({ logo, value, text }) => {
  return (
    <StyledCard className="sunlight-card">
      <div className="mb-16">{logo}</div>
      <div className="sunlight-value mb-16">{value}</div>
      <div className="sunlight-text">{text}</div>
    </StyledCard>
  );
};

export default Card;
