import styled, { keyframes } from 'styled-components';
import { pxToRem } from 'common/utils';

const Result3 = styled.div`
  &.result-3-page.content {
    padding: 0;
    background-color: #f5f5f5;

    .form {
      @media screen and (min-width: 768px) {
        max-width: initial;
      }

      .introduction-section {
        padding: 1.5rem 1.75rem 2.25rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .avatar {
          position: relative;
          width: 6rem;
          height: 6rem;
          border-radius: 50%;
          margin-bottom: 1.25rem;
          box-sizing: border-box;
          background-size: cover;
          background-position: center;

          .avatar-status {
            position: absolute;
            width: 1.75rem;
            height: 1.75rem;
            right: 0;
            bottom: 0;
            background: #1bff00;
            border: 0.25rem solid #f9f9f9;
            border-radius: 50%;
          }
        }

        .introduction-text {
          color: #231f20;
          font-family: 'Effra Light';
          font-weight: 400;
          font-size: ${pxToRem(17)};
          line-height: ${pxToRem(22)};
          text-align: center;
        }
      }

      .white-card {
        padding: 1.5rem 0.875rem;

        margin: 0 1.75rem 2.25rem;
        @media screen and (min-width: 768px) {
          margin: 0 7rem 2.25rem;
        }

        display: flex;
        flex-direction: column;
        justify-content: center;

        background: #ffffff;
        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.03);
        border-radius: 0.5rem;
      }

      .transparent-card {
        margin: 0 1.75rem 2.25rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .text-title {
        color: #000;
        font-family: 'Effra Light';
        font-weight: 400;
        font-size: ${pxToRem(17)};
        line-height: ${pxToRem(22)};
        text-align: center;
      }

      .text-value {
        color: #000;
        font-family: 'Effra';
        font-weight: 500;
        font-size: ${pxToRem(42)};
        line-height: ${pxToRem(42)};
        text-align: center;

        sup {
          top: -0.75rem;
          font-size: 1rem;
          line-height: ${pxToRem(42)};
        }

        small {
          margin-left: 0.25rem;
        }
      }

      .text-description {
        color: #58595b;
        font-family: 'Effra Light';
        font-weight: 400;
        font-size: ${pxToRem(12)};
        line-height: ${pxToRem(18)};
        text-align: center;
      }

      .text-disclaimer {
        color: #000;
        font-family: 'Effra Light';
        font-weight: 400;
        font-size: ${pxToRem(12)};
        line-height: ${pxToRem(18)};
        text-align: justify;
      }

      .chart-container {
        display: flex;
        justify-content: center;

        .chart-area {
          width: 34.6875rem;
          max-width: 34.6875rem;
        }
      }

      .divider {
        height: 1px;
        margin-top: 1rem;
        margin-bottom: 1rem;
        background-color: #f1f1f1;
      }

      .list-value {
        display: flex;
        justify-content: center;
      }
    }
  }
`;

export default Result3;

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 183px;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 3.21%, #000000 84.4%);
  opacity: 1;
  transition: opacity 0.5s, height 0.5s;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  @media screen and (min-width: 768px) {
    position: absolute;
  }
  &.hide {
    opacity: 0;
    height: 0;
  }
  .scroll-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  .header {
    font-family: 'Effra';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 10px;
  }
`;

const scrollAnime = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(26px);
  }
`;

export const MouseIndicator = styled.div`
  &.icon-scroll,
  &.icon-scroll:before {
    position: absolute;
    left: 50%;
  }
  &.icon-scroll {
    width: 30px;
    height: 50px;
    margin-left: -10px;
    bottom: 10px;
    margin-top: -35px;
    border: 2px solid #fff;
    border-radius: 25px;
  }
  &.icon-scroll:before {
    content: '';
    width: 8px;
    height: 8px;
    background: #fff;
    margin-left: -4px;
    top: 8px;
    border-radius: 4px;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: ${scrollAnime};
  }
`;

export const ModalImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${pxToRem(-95)};
  margin-bottom: ${pxToRem(40)};
  @media screen and (max-width: 767px) {
    margin-top: ${pxToRem(-80)};
  }
`;

export const ModalTitle = styled.div`
  font-family: 'Effra';
  font-size: ${pxToRem(20)};
  line-height: ${pxToRem(36)};
  font-weight: 500;
  color: #000;
  margin-bottom: 1rem;
  text-align: center;
  @media (min-width: 769px) {
    font-size: ${pxToRem(28)};
    line-height: ${pxToRem(48)};
  }
`;

export const ModalContent = styled.div`
  text-align: 'center';
  margin-right: ${pxToRem(16)};
  margin-left: ${pxToRem(16)};
  margin-bottom: ${pxToRem(16)};

  button {
    font-family: 'Effra';
    font-weight: 400;
  }
`;

export const ModalContentText = styled.div`
  font-family: 'Effra Light';
  font-style: normal;
  font-weight: normal;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(18)};
  text-align: center;
  padding-left: ${pxToRem(20)};
  padding-right: ${pxToRem(20)};
  margin-bottom: ${pxToRem(28)};
  color: #666666;
  @media (min-width: 769px) {
    font-size: ${pxToRem(24)};
    line-height: ${pxToRem(30)};
  }
`;
