import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .main-container {
    .main {
      @media screen and (min-width: 768px) {
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
        border-radius: 24px;
      }
    }
  }
`;

const StyledBestCompany2 = styled.div`
  .form-content {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    @media screen and (min-width: 767px) {
      margin-top: 0.5rem;
    }
  }

  .form-title {
    font-family: Poppins, Arial, Helvetica, sans-serif;
    font-size: 1.875rem;
    color: ${(props) => props.theme.color_scheme_dark};
  }

  .form-subtext {
    font-family: Poppins, Roboto, Arial, Helvetica, sans-serif;
    font-size: 1rem;
    color: #666;
  }

  .form-content {
    @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .btn {
    background: #625df5;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
    font-size: 18px;
    width: 392px;
    height: 56px;
    font-family: 'Poppins';
    letter-spacing: 0.08rem;
    color: #fff;
    align-self: center;

    &:hover:not(.btn-disabled):not(:disabled) {
      color: #fff;
    }
  }
`;

export default StyledBestCompany2;
