import styled from 'styled-components';
import { pxToRem } from 'common/utils';

export const Result2 = styled.div`
  &.content.result-2-page {
    .resend-btn {
      margin-top: ${pxToRem(20)};
      font-size: ${pxToRem(16)};
      color: #3f51b5;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .card {
    margin: 2.25rem auto;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    padding: 2.25rem 2rem;
  }
`;

export const InputWrapper = styled.div`
  &.input-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 480px) {
      justify-content: flex-start;
    }

    .digit {
      width: 64px;
      background-color: #fff;
      border: 1px solid ${(props) => props.theme.color_scheme_neutral};

      @media screen and (max-width: 479px) {
        width: 54px;
      }

      @media screen and (max-width: 374px) {
        width: 45px;
      }
    }
  }
  span {
    font-size: 16px;
    text-align: center;
    margin: 0 -16px 0 0;
    padding: 0;
    height: auto;
    cursor: pointer;
    color: ${(props) => props.theme.color_label};
  }
`;
