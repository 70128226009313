import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import Button from 'components/Button';
import { ReactComponent as ThankyouIcon } from 'assets/images/icon-sun.svg';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackGoogleLead,
} from 'store/actions';
import DefaultLayout from 'components/DefaultLayout';
import { withTranslation } from 'react-i18next';
import { getLocalStorageItem } from 'common/localStorage';
import { getPreviewPageName } from 'common/api.mock';
import { generateID } from 'common/utils';
import StyledThankyou from './styled';

class Thankyou extends Component {
  constructor(props) {
    super(props);

    const { user } = this.props;
    if (user.skippedBills) return;
    props.actions.trackGoogleLead('thankyou', props.user.variation);
  }

  handleNext = () => {
    const previewPage = getPreviewPageName();
    if (previewPage === 'thankyou') return;
    const {
      theme: { testimonials_url, spanish_testimonials_url },
    } = this.props;
    const language = getLocalStorageItem('i18nextLng');
    const url = /^es\b/.test(language)
      ? spanish_testimonials_url
      : testimonials_url;
    if (!url) {
      return;
    }
    window.open(url, '_top');
  };

  render() {
    const { t, user, theme } = this.props;
    const previewPage = getPreviewPageName();
    const language = getLocalStorageItem('i18nextLng');
    const caption = /^es\b/.test(language)
      ? theme.spanish_thanks_caption
      : theme.thanks_caption;
    const meeting_date =
      previewPage === 'thankyou' ? new Date() : user.meeting_date;
    const meeting_time =
      previewPage === 'thankyou' ? new Date() : user.meeting_time;

    const {
      location: { pathname },
    } = this.props;

    return (
      <DefaultLayout>
        <StyledThankyou className="content thankyou-page">
          <div className="form">
            <div
              id={generateID('div-thanks', pathname)}
              className="form-content text-center"
            >
              <ThankyouIcon />

              <div className="form-title">
                {caption || t('Your Quote is Being Prepared!')}
              </div>

              {meeting_date && meeting_time && (
                <>
                  <div className="form-subtitle">
                    {t('Appointment requested for:')}
                  </div>

                  <div className="card">
                    <div className="meeting-day">
                      {moment(meeting_date, 'YYYY-MM-DD').format('dddd')}
                    </div>
                    <div className="meeting-date">
                      {moment(meeting_date, 'YYYY-MM-DD').format('MMMM D')}
                    </div>
                    <div className="meeting-time">
                      {moment(meeting_time, 'HH:mm:ss').format('h:mm A')}
                    </div>
                  </div>
                </>
              )}

              <div className="form-text">
                {t(
                  'Our engineers are hard at work designing a custom solar panel system to fit your roof. If we have any questions we’ll reach out!'
                )}
              </div>
            </div>
            <div className="form-actions vertical">
              <Button
                id={generateID('btn-back-to-our-homepage', pathname)}
                full
                onClick={this.handleNext}
              >
                {t('Back To Our Homepage')}
              </Button>
            </div>
          </div>
        </StyledThankyou>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  theme: state.ui.theme,
  user: state.user.data,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      navigateTo,
      trackGoogleLead,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Thankyou)
);
