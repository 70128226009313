import styled from 'styled-components';

const SliderCard = styled.div`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: ${(props) => props.theme.color_scheme_dark};
  width: 11.875rem;
  text-align: center;
  margin: 0 0.625rem 0 0;

  .slider-card-title {
    background-color: ${(props) => props.theme.color_scheme_light};
    font-size: 0.75rem;
    font-weight: 700;
    text-align: center;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
  }

  .slider-card-content {
    font-size: 2.25rem;
    font-weight: 700;
    padding: 0.5rem;
  }

  .slider-card-subcontent {
    font-size: 0.875rem;
    color: #838383;
    padding: 0 0.5rem 0.5rem;
    ${(props) => props.lang === 'es' && { height: 40 }}
  }
`;

export default SliderCard;
