import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import { setUserData, saveSession } from 'store/actions';
import Header from 'components/Sungage/Header';
import Stage from 'components/Sungage/Stage';
import Question from 'components/Sungage/Question';
import { VARIATION_OPTIONS } from 'pages/constants';
import { getLocalStorageItem } from 'common/localStorage';
import { generateID } from 'common/utils';
import StyledSungage5 from './styled';

class Sungage5 extends Component {
  constructor(props) {
    super(props);

    const variation = getLocalStorageItem('variation');

    this.state = {
      checked: false,
      variation: VARIATION_OPTIONS[variation],
    };
  }

  handleCheck = (event) => {
    this.setState({ checked: event.target.checked });
  };

  handleClose = async () => {
    const { history, actions, user } = this.props;

    let step;
    if (user.sungage?.prequalification_skipped) step = '/result3';
    else step = '/qualify/end';

    actions.setUserData({ step });
    await actions.saveSession();

    history.replace(step);
  };

  handleBack = async () => {
    const { history, actions } = this.props;

    actions.setUserData({
      step: '/prequalify/sungage4',
    });
    await actions.saveSession();

    history.replace('/prequalify/sungage4');
  };

  handleNext = async () => {
    const { history, actions } = this.props;

    actions.setUserData({
      step: '/prequalify/sungage6',
    });
    await actions.saveSession();

    history.push('/prequalify/sungage6');
  };

  render() {
    const { t } = this.props;
    const { variation, checked } = this.state;

    const {
      location: { pathname },
    } = this.props;

    return (
      <>
        <variation.header />

        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <StyledSungage5 className="content sungage5-page">
              <div id={generateID('div-header', pathname)} className="mb-32">
                <Header onCloseClick={this.handleClose} />
              </div>

              <div id={generateID('div-stage', pathname)} className="mb-16">
                <Stage stage={5} onBackClick={this.handleBack} />
              </div>

              <Formik onSubmit={this.handleNext}>
                {() => {
                  return (
                    <Form className="form">
                      <div className="form-content">
                        <div
                          id={generateID('div-question', pathname)}
                          className="mb-16"
                        >
                          <Question>
                            {t(
                              'Can we check your credit score with a soft-pull of credit? In no way will this affect your credit score.'
                            )}
                          </Question>
                        </div>

                        <div className="mb-32">
                          <Checkbox
                            id={generateID('checkbox-complete-check', pathname)}
                            onChange={this.handleCheck}
                          >
                            <div className="description">
                              {t(
                                // eslint-disable-next-line no-multi-str
                                'By clicking “Complete Check” the borrower and \
                                co-borrower (if applicable) acknowledges that they are \
                                providing express written consent under the Fair Credit \
                                Reporting Act for Sungage Financial to obtain a consumer \
                                credit report from one or more consumer reporting \
                                agencies to process your pre-qualification request. This \
                                is a soft-pull of credit that in no way affects your \
                                credit score.'
                              )}
                            </div>
                          </Checkbox>
                        </div>

                        <div
                          id={generateID('div-actions', pathname)}
                          className="mb-8"
                        >
                          <Button
                            id={generateID('btn-complete-check', pathname)}
                            onClick={this.handleNext}
                            disabled={!checked}
                            full
                          >
                            {t('Complete Check')}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </StyledSungage5>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui }) => ({
  user: data,
  theme: ui.theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Sungage5)
);
