import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 6rem 4rem 0;

  .MuiSlider-root {
    height: 1.25rem;
  }

  .MuiSlider-rail {
    height: 1.25rem;
    border-radius: 0.75rem;
    border: 2px solid #e0e0e0;
    background-color: ${(props) => props.theme.color_scheme_neutral};
  }

  .MuiSlider-track {
    height: 1.25rem;
    border-radius: 0.75rem;
    border: 2px solid transparent;
    background-color: ${(props) => props.theme.color_scheme_light};
  }

  .MuiSlider-thumb {
    width: auto;
    height: auto;
    transition: none;
    box-shadow: none;
    background: transparent;
    margin-left: -1.0625rem;
    margin-top: -0.5rem;

    &::after {
      display: none;
    }

    &:focus,
    &:hover,
    &:active {
      box-shadow: none;
    }
  }

  .MuiSlider-mark {
    display: none;
  }

  .material-slider-thumb {
    height: 2.25rem;
    width: 2.125rem;
    border-radius: 0.25rem;
    background-color: #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    outline: none;

    &-line {
      height: 1.5rem;
      width: 1px;
      display: inline-flex;
      background-color: #717171;
      margin: 0 0.2rem;
    }
  }

  .material-slider-tooltip {
    position: absolute;
    border: 1px solid #e0e0e0;
    color: ${(props) => props.theme.color_scheme_dark};
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    border-radius: 0.25rem;
    background-color: #ffffff;
    bottom: 3.75rem;
    min-width: 7.5rem;

    &::after {
      content: ' ';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -0.75rem;
      border-width: 0.75rem;
      border-style: solid;
      border-color: #e0e0e0 transparent transparent transparent;
    }

    &-content {
      text-align: center;
    }
  }
`;

export const TooltipTitle = styled.div`
  font-size: 0.75rem;
  font-weight: 700;
  color: ${(props) => props.theme.color_scheme_dark};
  background-color: ${(props) => props.theme.color_scheme_light};
  padding: 0.25rem;
`;

export const TooltipContent = styled.div`
  margin: 0.5rem 0;
  font-size: 1rem;
  letter-spacing: -0.3px;
`;

export const TooltipSubContent = styled.div`
  font-size: 0.75rem;
  color: #666;
  margin: 0.25rem 0;
`;

export default StyledWrapper;
