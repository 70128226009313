import styled from 'styled-components';

const StyledBackButton = styled.div`
  color: ${(props) => props.theme.color_scheme_button};
  font-size: 0.75rem;
  line-height: 18px;
  text-decoration-line: underline;
  text-decoration-color: #ccc;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  & svg {
    margin-right: 6px;
  }

  &:hover {
    cursor: pointer;
  }

  @media screen and (min-width: 768px) {
    font-size: 1rem;
  }
`;

export default StyledBackButton;
