import React from 'react';
import { generateSolarMaps } from 'common/utils';
import iconGoldStar from 'assets/images/icon-gold-star.svg';
import './style.scss';

const SolarMapVariation2 = ({ lat, lng, zoom }) => {
  const svgWidth = 900;
  const svgHeight = 491;

  if (!lat || !lng) {
    return null;
  }

  const { images, pinX, pinY, centerX, centerY } = generateSolarMaps(
    lat,
    lng,
    zoom
  );
  const imageTags = [];
  let pos = 0;

  for (let i = centerY; i <= centerY + 1; i++) {
    for (let j = centerX; j <= centerX + 1; j++) {
      imageTags.push(
        <image
          key={`${i} ${j}`}
          width="256"
          height="256"
          x={j * 256}
          y={i * 256}
          href={images[pos++]}
          preserveAspectRatio="xMidYMid slice"
        />
      );
    }
  }

  return (
    <div className="solar-map-container">
      <div className="solar-map-main-v2">
        <div className="solar-map-overlay"></div>
        <svg
          viewBox="0 0 256 256"
          height={svgHeight}
          width={svgWidth}
          style={{ filter: 'contrast(200%) brightness(2)' }}
        >
          <g transform={`translate(${128 - pinX} ${128 - pinY})`}>
            {imageTags}
          </g>
        </svg>
        <img src={iconGoldStar} alt="marker" className="solar-map-marker" />
      </div>
    </div>
  );
};

export default SolarMapVariation2;
