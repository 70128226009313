import styled from 'styled-components';

const Divider = styled.div`
  border-top: 1px solid ${(props) => props.theme.color_scheme_dark};
  margin: 0.5rem 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .divider-text {
    position: absolute;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 0.75rem;
    font-weight: 700;
    background-color: ${(props) => props.color};
    padding: 0 0.625rem;
  }
`;

export default Divider;
