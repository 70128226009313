import styled from 'styled-components';
import color from 'color';

const Proposals = styled.div`
  &.proposals-page.content {
    padding: 0;

    .form {
      @media screen and (min-width: 768px) {
        max-width: initial;
      }
    }
  }

  .introduction {
    font-family: StellaFont, Arial, Helvetica, sans-serif;
    font-size: 2.5rem;
    text-align: center;
    line-height: 2.5rem;
  }

  .subtext {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 1rem;
    color: #666;
  }

  .download-estimate {
    color: ${(props) => props.theme.color_scheme_dark};
    font-size: 1rem;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    text-decoration: underline;
  }

  .icon-scroll {
    cursor: pointer;
  }

  .bold-text {
    font-size: 1rem;
    color: #666;
    font-weight: bold;
  }

  .white-section,
  .dark-section,
  .black-section,
  .expand-section {
    padding: 1.5rem 1.25rem;
    @media screen and (min-width: 768px) {
      .section-container {
        max-width: 555px;
        margin: 0 auto;
      }
    }
  }

  .dark-section {
    background-color: #f7f7f7;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
  }

  .black-section {
    background-color: ${(props) => props.theme.color_scheme_dark};
  }

  .expand-section {
    border-top: 1px solid #dfdfdf;

    &.dark {
      background-color: #f7f7f7;
      border-top-width: 0px;
      border-bottom: 1px solid #dfdfdf;
    }
  }

  .discount-block {
    display: flex;
    justify-content: center;
    align-items: center;

    .bg-circle-icon {
      background-color: ${(props) => {
        const hsl = color(props.theme.color_scheme_dark).hsl().array();
        hsl[2] = 20;
        return color.hsl(hsl).hex();
      }};
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.75rem;
      height: 3.75rem;
      border-radius: 50%;
      flex-shrink: 0;
      position: absolute;
      left: calc(50% - 12rem);

      @media screen and (max-width: 767px) {
        left: calc(50% - 11rem);
      }

      .dark-color {
        fill: ${(props) => props.theme.color_scheme_dark};
      }

      svg {
        width: 2.375rem;
        height: auto;
      }
    }

    .discount-title {
      font-size: 1.25rem;
      color: #fff;
      margin-bottom: 0.5rem;
      text-align: center;
    }

    .discount-value {
      background-color: ${(props) => {
        const hsl = color(props.theme.color_scheme_dark).hsl().array();
        hsl[2] = 20;
        return color.hsl(hsl).hex();
      }};
      border-radius: 0.375rem;
      height: 2.875rem;
      min-width: 13.75rem;
      font-size: 1.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #fff;
      margin-bottom: 1.2rem;

      sup {
        font-size: 1rem;
      }
    }

    .divider {
      border-top: 1px solid #fff;
      margin: 0.5rem 0;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .divider-text {
        position: absolute;
        font-family: Roboto, Arial, Helvetica, sans-serif;
        font-size: 1rem;
        font-weight: 700;
        background-color: ${(props) => props.theme.color_scheme_dark};
        padding: 0 0.625rem;
        color: #fff;
        text-transform: uppercase;
      }
    }
  }

  .section-title {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 1.6;
    color: #303030;
  }

  .section-title-variation {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 1.6;
    color: #303030;

    @media screen and (max-width: 767px) {
      font-size: 1.475rem;
    }
  }

  .section-description {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 3.5rem;
    line-height: 1.6;
    color: #303030;

    .exact-system-size-number {
      font-size: 6rem;
    }

    small {
      font-size: 1.25rem;
    }
  }

  .environmental-list {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    display: flex;
    justify-content: center;
  }

  .overpayment {
    color: #f00;
    font-weight: 700;
  }

  .sunroof-container {
    display: flex;
    flex-direction: column;

    .image-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      position: relative;

      .sunroof-image-main {
        @media screen and (min-width: 768px) {
          min-height: 217px;
        }
      }

      .img-fluid {
        max-width: 100%;
        height: auto;
        border: 5px solid ${(props) => props.theme.color_scheme_dark};
      }

      .iframe {
        max-width: 100%;
        height: 180px;

        @media screen and (min-width: 768px) {
          height: 350px;
        }
      }

      .logo-sunroof {
        height: 1.375rem;
      }
    }
  }

  .google-map-container {
    display: flex;
    flex-direction: column;

    .google-map-inner-container {
      display: block;
      position: relative;

      .iframe {
        max-width: 100%;
        height: 180px;

        @media screen and (min-width: 768px) {
          height: 350px;
        }
      }
    }
  }
`;

export const CircleText = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.color_scheme_light};

  .circle-text-main {
    font-size: 2.25rem;
    color: #303030;
    font-weight: 700;
    .exact-pricing-main-number {
      font-size: 4rem;
    }
  }

  .circle-text-small {
    font-size: 0.875rem;
    color: ${(props) => props.theme.color_scheme_dark};
    text-transform: uppercase;

    &.exact-pricing-main-number {
      font-size: 1.275rem;
    }
  }
`;

export default Proposals;
