import styled from 'styled-components';
import { pxToRem } from 'common/utils';

export const Result2 = styled.div`
  &.content.result-2-page {
    min-height: calc(100% - ${pxToRem(76)});

    @media screen and (max-width: 374px) {
      min-height: calc(100% - 61px);
    }

    @media screen and (min-width: 767px) {
      min-height: calc(100% - ${pxToRem(76)} - 3rem);
    }

    .message {
      line-height: 30px;
    }

    .sunlight-phone-number-solar-disclosure {
      font-size: 9px;
      line-height: 12px;
      color: #666666;
      margin: 0 4.25rem 1.75rem;
      text-align: justify;

      @media screen and (max-width: 767px) {
        margin: 0 3.5rem 0.75rem;
      }
    }
  }
`;

export const Legal = styled.div`
  &.form-legal {
    font-size: 13px;
    line-height: 15px;
    color: #666666;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    margin: 0 1.25rem 1.25rem 1.25rem;
    text-align: justify;

    @media screen and (max-width: 767px) {
      margin: 0 1.25rem 1.25rem 1.25rem;
      font-size: 13px;
      line-height: 15px;
    }

    label {
      margin-bottom: 0;
    }
  }
`;
