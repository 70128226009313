import styled from 'styled-components';

const AutoComplete = styled.div`
  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    display: block;
    width: 100%;
    font-size: 1rem;
    color: ${(props) => props.theme.color_scheme_dark};
    border: 1px solid
      ${(props) => (props.error ? '#f44336' : props.theme.color_scheme_dark)};
    border-radius: ${(props) =>
      props.theme.button_shape === 'square' || props.link
        ? 0
        : props.theme.button_shape === 'semiround'
        ? '3px'
        : '1.25rem'};
    background-color: ${(props) => props.theme.color_scheme_neutral};
    padding: 0.625rem 1rem;

    &:focus {
      outline: none;
    }

    &.has-error {
      border-color: #f44336;
    }

    &::-webkit-input-placeholder {
      color: ${(props) => props.theme.color_scheme_label};
      font-family: Roboto, Arial, Helvetica, sans-serif;
      font-style: italic;
      opacity: 1;
    }
    &:-moz-placeholder {
      /* FF 4-18 */
      color: ${(props) => props.theme.color_scheme_label};
      font-family: Roboto, Arial, Helvetica, sans-serif;
      font-style: italic;
      opacity: 1;
    }
    &::-moz-placeholder {
      /* FF 19+ */
      color: ${(props) => props.theme.color_scheme_label};
      font-family: Roboto, Arial, Helvetica, sans-serif;
      font-style: italic;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: ${(props) => props.theme.color_scheme_label};
      font-family: Roboto, Arial, Helvetica, sans-serif;
      font-style: italic;
      opacity: 1;
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${(props) => props.theme.color_scheme_label};
      font-family: Roboto, Arial, Helvetica, sans-serif;
      font-style: italic;
      opacity: 1;
    }
    &::placeholder {
      /* modern browser */
      color: ${(props) => props.theme.color_scheme_label};
      font-family: Roboto, Arial, Helvetica, sans-serif;
      font-style: italic;
      opacity: 1;
    }

    @media screen and (min-width: 768px) {
      padding: 0.625rem 1.25rem;
    }

    &--focused {
      outline: none;
    }

    &::-ms-clear {
      display: none;
    }

    &--open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .react-autosuggest__suggestions-container {
    display: none;

    &--open {
      display: block;
      position: absolute;
      top: calc(2.5rem - 1px);
      width: 100%;
      border: 1px solid ${(props) => props.theme.color_scheme_dark};
      background-color: #e8e8e8;
      font-family: Roboto, Arial, Helvetica, sans-serif;
      font-size: 0.875rem;
      line-height: 1rem;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      z-index: 2;
      max-height: 11.25rem;
      overflow: auto;
    }
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 0.625rem 0.75rem;
    color: #717171;

    &--highlighted {
      background-color: ${(props) => props.theme.color_scheme_dark};
      color: #fff;
    }
  }

  .react-autosuggest__suggestions {
    &-list {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }

  .react-autosuggest__section-container {
    border-top: 1px dashed #ccc;
  }

  .react-autosuggest__section-container--first {
    border-top: 0;
  }

  .react-autosuggest__section-title {
    padding: 0.625rem 0 0 0.625rem;
    font-size: 0.75rem;
    color: #777;
  }
`;

export default AutoComplete;
