import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { ReactComponent as NRGLogo } from 'assets/images/nrg-logo.svg';
import { ReactComponent as PhoneCellIcon } from 'assets/images/icon-phone-cell.svg';
import { trackPhoneClick } from 'store/actions';
import { getVisibleHeader } from 'common/api.mock';
import { generateID } from 'common/utils';
import StyledHeader from './styled';

const Header = () => {
  const theme = useSelector((state) => state.ui.theme);
  const dispatch = useDispatch();

  const handlePhoneClick = () => {
    if (!theme.advertise_phone) return;

    const phoneNumber = parsePhoneNumberFromString(theme.advertise_phone, 'US');
    const uri = phoneNumber?.getURI();
    if (uri) {
      dispatch(trackPhoneClick());
      window.open(uri, '_top');
    }
  };

  if (!getVisibleHeader()) {
    return <></>;
  }

  return (
    <StyledHeader id={generateID('div-header', 'header')}>
      <a
        id={generateID('logo', 'header')}
        href={theme.website_url}
        className="header-left"
        target="_blank"
        rel="noopener noreferrer"
      >
        <NRGLogo />
      </a>

      <div className="header-right">
        {theme.advertise_phone && (
          <div
            id={generateID('btn-phone', 'header')}
            className="phone-btn"
            onClick={handlePhoneClick}
          >
            <PhoneCellIcon />
          </div>
        )}
      </div>
    </StyledHeader>
  );
};

export default Header;
