import styled from 'styled-components';

const StyledWrapper = styled.div`
  overflow: hidden;
  @media screen and (min-width: 768px) {
    overflow: initial;
  }

  .material-stepper {
    padding: 0;
    margin: ${({ count }) =>
      `0 calc(${-100 / (count * 2 - 2)}% + ${0.75 * count}rem / ${
        count * 2 - 2
      })`};
    @media screen and (min-width: 768px) {
      margin: initial;
    }
    .MuiStep-alternativeLabel {
      padding: 0;
    }
    .MuiStepLabel-alternativeLabel {
      font-size: 0.6875rem;
      line-height: 1rem;
      font-family: Roboto, Arial, Helvetica, sans-serif;
      color: #b3b3b3;
      &.MuiStepLabel-active,
      &.MuiStepLabel-completed {
        color: #000;
      }
    }
    .material-stepper-label {
      margin-top: 0;
      font-size: 0.8rem;
      &--first {
        padding-left: 3rem;
      }
      &--last {
        padding-right: 2rem;
      }
      @media screen and (min-width: 768px) {
        margin-top: 0.25rem;
        font-size: 1rem;
        &--first {
          padding-left: initial;
        }
        &--last {
          padding-right: initial;
        }
      }
    }
  }

  .MuiStepConnector-active {
    .MuiStepConnector-line {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -moz-osx-font-smoothing: grayscale;
      position: relative;
      overflow: hidden;
      border: 0;
      &:before {
        right: 0;
      }
    }
  }

  .MuiStepConnector-line {
    color: ${({ color }) => color};
    display: block;
    font-weight: bold;
    margin-bottom: 20px;
    padding: 15px 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      box-shadow: 0;
    }

    @media screen and (min-width: 768px) {
      &:before {
        height: 6px !important;
      }
    }

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      right: 100%;
      top: 0;
      background-color: ${({ color }) => color};
      height: 4px;
      -webkit-transition-property: right;
      transition-property: right;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }
  }
`;

export default StyledWrapper;
