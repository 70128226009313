import styled from 'styled-components';
import { pxToRem } from 'common/utils';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 4.5rem;
  padding: 0 1.75rem;
  background-color: #fff;

  .header-right {
    margin-top: ${pxToRem(5)};

    .phone-btn {
      cursor: pointer;
    }
  }
`;

export default Header;
