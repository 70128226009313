// https://www.chromium.org/for-testers/bug-reporting-guidelines/uncaught-securityerror-failed-to-read-the-localstorage-property-from-window-access-is-denied-for-this-document

let globalStorage = {};

export const getLocalStorageItem = (key) => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    console.error(e);
    return globalStorage[key];
  }
};

export const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    console.error(e);
    globalStorage = { ...globalStorage, [key]: value };
  }
};

export const removeLocalStorageItem = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.error(e);
    delete globalStorage[key];
  }
};
