/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Chat from 'components/Chat';
import DefaultLayout from 'components/DefaultLayout';
import { withTranslation } from 'react-i18next';
import { generateID } from 'common/utils';
import StyledQualify82 from './styled';

class Qualify82 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
    };
  }

  render() {
    const {
      theme: { website_url },
      t,
    } = this.props;
    const { step } = this.state;

    const {
      location: { pathname },
    } = this.props;

    return (
      <DefaultLayout>
        <StyledQualify82 className="content qualify-8-2-page">
          <div className="form">
            <div className="form-content">
              <Chat
                id={generateID('chat-congratulations', pathname)}
                onShow={() => this.setState({ step: 2 })}
              >
                {t('Congratulations! You qualify!')}
              </Chat>

              {step >= 2 && (
                <Chat
                  id={generateID(
                    'chat-since-you-are-still-researching',
                    pathname
                  )}
                  onShow={() => this.setState({ step: 3 })}
                >
                  {t(
                    'Since you’re still researching, we’ll be in touch with more information to help you find your best option for solar.'
                  )}
                </Chat>
              )}
            </div>

            {step >= 3 && (
              <div
                id={generateID('div-actions', pathname)}
                className="form-actions"
              >
                <a
                  id={generateID('btn-visit-our-website', pathname)}
                  href={website_url}
                  target="_blank"
                  className="button"
                >
                  {t('Visit our website')}
                </a>
              </div>
            )}
          </div>
        </StyledQualify82>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ ui }) => ({
  theme: ui.theme,
});

export default withTranslation('translation')(
  connect(mapStateToProps)(Qualify82)
);
