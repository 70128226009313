import styled from 'styled-components';

const DisqualifyPage = styled.div`
  .form-actions {
    flex-direction: column;

    &-text {
      font-family: Roboto, arial;
      font-size: 1rem;
      line-height: 1.5;
      text-align: center;
      color: #666666;
    }
  }
`;

export default DisqualifyPage;
