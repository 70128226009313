import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from 'components/Button';
import TextInput from 'components/Forms/TextInputNRGVariation';
import { ReactComponent as MagicIcon } from 'assets/images/icon-magic.svg';
import { setUserData, saveSession, trackForwardProgress } from 'store/actions';
import ReCAPTCHA from 'react-google-recaptcha';
import __ from 'lodash';
import { withTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import DefaultLayout from 'components/DefaultLayout';
import FixedBottomBar from 'components/FixedBottomBar';
import { generateID } from 'common/utils';
import StyledInactiveCompany from './styled';

const formSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
});

const CustomInput = ({ ...props }) => (
  <InputMask {...props}>{() => <TextInput {...props} />}</InputMask>
);

class InactiveCompany extends Component {
  constructor(props) {
    super(props);

    this.state = {
      captcha: false,
      gRecaptchaToken: null,
    };
  }

  handleNext = async (values) => {
    const { history, actions, user } = this.props;
    actions.setUserData({
      ...values,
      jornaya_lead_id: window.jornaya_leadid_token,
      trustedform_cert_url: window.trustedForm.id
        ? `https://cert.trustedform.com/${window.trustedForm.id}`
        : '',
      step: user.is_instant_estimate ? '/result3' : '/start',
    });
    await actions.saveSession();
    actions.trackForwardProgress();
    history.push('/thankyou');
  };

  handleCaptcha = (key) => {
    this.setState({
      captcha: true,
      gRecaptchaToken: key,
    });
  };

  render() {
    const { user, theme, t } = this.props;
    const { captcha, gRecaptchaToken } = this.state;
    const {
      location: { pathname },
    } = this.props;

    return (
      <DefaultLayout showFooter={false}>
        <Formik
          initialValues={{
            first_name: user.first_name,
            last_name: user.last_name,
            phone: user.phone,
            email: user.email,
          }}
          validationSchema={formSchema}
          onSubmit={this.handleNext}
          validateOnMount
        >
          {({ isValid, isSubmitting, submitForm }) => {
            return (
              <>
                <StyledInactiveCompany className="content inactive-company-page">
                  <Form className="form">
                    <div
                      id={generateID('div-form', pathname)}
                      className="form-content"
                    >
                      <div className="mb-16 text-center">
                        <MagicIcon className="icon" />
                      </div>
                      <div className="form-title mb-16">
                        {t('Uh oh! Something went wrong.')}
                      </div>
                      <div className="form-subtext mb-24">
                        {t(
                          'Fill out the form below to speak with one of our experts about solar for your home or business.'
                        )}
                      </div>
                      <div className="mb-16">
                        <Field
                          id={generateID('txt-first-name', pathname)}
                          name="first_name"
                          type="text"
                          placeholder={t('First Name')}
                          theme={theme}
                          component={TextInput}
                          autoFocus
                          fullWidth
                        />
                      </div>
                      <div className="mb-16">
                        <Field
                          id={generateID('txt-last-name', pathname)}
                          name="last_name"
                          type="text"
                          placeholder={t('Last Name')}
                          theme={theme}
                          component={TextInput}
                          fullWidth
                        />
                      </div>
                      <div className="mb-16">
                        <Field>
                          {({ field, form }) => (
                            <CustomInput
                              id={generateID('txt-phone', pathname)}
                              name="phone"
                              mask="(999) 999-9999"
                              type="tel"
                              placeholder={t('Mobile phone number')}
                              theme={theme}
                              value={field.value.phone}
                              onChange={field.onChange}
                              field={field}
                              form={form}
                              fullWidth
                            />
                          )}
                        </Field>
                      </div>
                      <div className="mb-16">
                        <Field
                          id={generateID('txt-email', pathname)}
                          name="email"
                          type="email"
                          placeholder={t('Email')}
                          theme={theme}
                          component={TextInput}
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className="form-content">
                      <div
                        id={generateID('div-recaptcha', pathname)}
                        className="mb-16"
                      >
                        <ReCAPTCHA
                          onChange={this.handleCaptcha}
                          sitekey={
                            process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY
                          }
                        />
                      </div>
                    </div>
                    <div
                      id={generateID('div-legal', pathname)}
                      className="form-legal"
                    >
                      <label>
                        <input type="hidden" id="leadid_tcpa_disclosure" />
                        {t(
                          'To help you go solar, we need your approval to contact you. By clicking above, you agree that {{company}} may email and call you about {{company}} products at the phone number you entered above, using pre-recorded messages or an autodialer, even if your number is on a "Do Not Call" list. Consent for calls is optional. You can opt-out anytime.',
                          {
                            company: theme.company_name,
                          }
                        )}
                      </label>
                    </div>
                  </Form>
                </StyledInactiveCompany>
                <FixedBottomBar
                  id={generateID('div-fixed-bottom-bar', pathname)}
                >
                  <Button
                    id={generateID('btn-contact-us', pathname)}
                    type="submit"
                    onClick={submitForm}
                    full
                    disabled={
                      !isValid ||
                      isSubmitting ||
                      !captcha ||
                      __.isNil(gRecaptchaToken)
                    }
                  >
                    {t('Contact us')}
                  </Button>
                </FixedBottomBar>
              </>
            );
          }}
        </Formik>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ user: data, ui: { theme } }) => ({
  user: { data },
  theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(InactiveCompany)
);
