import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import ButtonSelect from 'components/ButtonSelect';
import { setUserData, saveSession } from 'store/actions';
import Header from 'components/Sungage/Header';
import Stage from 'components/Sungage/Stage';
import Question from 'components/Sungage/Question';
import { VARIATION_OPTIONS } from 'pages/constants';
import { getLocalStorageItem } from 'common/localStorage';
import { generateID } from 'common/utils';
import StyledSungage1 from './styled';

class Sungage1 extends Component {
  constructor(props) {
    super(props);

    const variation = getLocalStorageItem('variation');

    this.state = {
      income: null,
      variation: VARIATION_OPTIONS[variation],
    };
  }

  componentDidMount() {
    this.initData();
  }

  initData = () => {
    const {
      user: { sungage },
    } = this.props;
    if (sungage?.income) this.setState({ income: sungage?.income });
  };

  handleClose = async () => {
    const { history, actions, user } = this.props;

    let step;
    if (user.sungage?.prequalification_skipped) step = '/result3';
    else step = '/qualify/end';

    actions.setUserData({ step });
    await actions.saveSession();

    history.replace(step);
  };

  handleNext = async (income) => {
    const { history, actions } = this.props;

    this.setState({ income });

    actions.setUserData({
      step: '/prequalify/sungage2',
      sungage: { income },
    });
    await actions.saveSession();

    history.push('/prequalify/sungage2');
  };

  render() {
    const { t } = this.props;
    const { income, variation } = this.state;

    const incomeList = [
      { value: '17500-300000', label: '$17,500 - $30,000' },
      { value: '30000-40000', label: '$30,000 - $40,000' },
      { value: '40000-50000', label: '$40,000 - $50,000' },
      { value: '50000-80000', label: '$50,000 - $80,000' },
      { value: '80000-110000', label: '$80,000 - $110,000' },
      { value: '110000+', label: '$110,000+' },
    ];

    const {
      location: { pathname },
    } = this.props;

    return (
      <>
        <variation.header />

        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <StyledSungage1 className="content sungage1-page">
              <div id={generateID('div-header', pathname)} className="mb-32">
                <Header onCloseClick={this.handleClose} />
              </div>

              <div id={generateID('div-stage', pathname)} className="mb-16">
                <Stage stage={1} />
              </div>

              <Formik>
                {() => {
                  return (
                    <Form className="form">
                      <div className="form-content">
                        <div
                          id={generateID('div-question', pathname)}
                          className="mb-16"
                        >
                          <Question>
                            {t('What is your gross annual income?')}
                          </Question>
                        </div>

                        <div
                          id={generateID('div-answer', pathname)}
                          className="mb-32"
                        >
                          <ButtonSelect
                            options={incomeList}
                            onChange={this.handleNext}
                            value={income}
                          />
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </StyledSungage1>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui }) => ({
  user: data,
  theme: ui.theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Sungage1)
);
