import React from 'react';
import StyledQuestion from './styled';

const Question = ({ children }) => {
  return (
    <StyledQuestion className="sunlight-question">{children}</StyledQuestion>
  );
};

export default Question;
