import styled from 'styled-components';

const ProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  position: relative;

  .progress-bar-label {
    font-size: 0.75rem;
    color: #717171;
    padding-bottom: 5px;
  }

  .progress-bar-bg {
    display: flex;
    height: 10px;
    overflow: hidden;
    background-color: ${(props) => props.theme.color_scheme_neutral};
    border-radius: 15px;
  }

  .progress-bar-active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${(props) => props.theme.color_scheme_light};
    transition: width 0.6s ease;
    border-radius: 15px;
    height: 100%;
  }

  @media screen and (min-width: 768px) {
    .progress-bar-label {
      font-size: 1rem;
    }

    .progress-bar-bg {
      height: 15px;
    }
  }
`;

export default ProgressBar;
