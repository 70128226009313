import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { ReactComponent as CheckIcon } from 'assets/images/icon-green-check.svg';
import { stepperData, stepperDataNoUtilityBill } from 'common/data';
import { useTranslation } from 'react-i18next';
import { getLocalStorageItem, setLocalStorageItem } from 'common/localStorage';
import StyledWrapper from './styled';

const QontoConnector = withTheme(
  withStyles({
    alternativeLabel: {
      top: '0.625rem',
      left: 'calc(-50%)',
      right: 'calc(50%)',
    },
    active: {
      '& $line': {
        borderColor: (props) => props.theme.color_scheme_light,
      },
    },
    completed: {
      '& $line': {
        borderColor: (props) => props.theme.color_scheme_light,
      },
    },
    line: {
      borderColor: (props) => props.theme.color_scheme_neutral,
      borderTopWidth: 3,
      borderRadius: 5,

      '@media screen and (min-width: 768px)': {
        borderTopWidth: 6,
      },
    },
  })(StepConnector)
);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: (props) => props.theme.color_scheme_neutral,
    display: 'flex',
    height: '1.375rem',
    alignItems: 'center',
    zIndex: 1,
  },
  circle: {
    width: '0.75rem',
    height: '0.75rem',
    borderRadius: '50%',
    backgroundColor: (props) => props.theme.color_scheme_neutral,
    border: '1px solid currentColor',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all .3s linear',

    '@media screen and (min-width: 768px)': {
      width: '1.5rem',
      height: '1.5rem',
      border: '2px solid currentColor',
    },
  },
  active: {
    color: (props) => props.theme.color_scheme_light,
    backgroundColor: () => '#fff',
  },
  active1: {
    color: (props) => props.theme.color_scheme_light,
    backgroundColor: 'transparent',
  },
  completed: {
    backgroundColor: (props) => props.theme.color_scheme_light,
  },
  icon: {
    position: 'absolute',
    width: '0.5rem',
    height: '0.25rem',
    fill: '#fff',
    '@media screen and (min-width: 768px)': {
      width: '0.75rem',
      height: '0.75rem',
    },
  },
});

const QontoStepIcon = withTheme((props) => {
  const { active, completed, theme } = props;
  const classes = useQontoStepIconStyles({ theme });

  return (
    <div
      className={clsx(classes.root, {
        [classes.active1]: active || completed,
      })}
    >
      <div
        className={clsx(classes.circle, {
          [classes.completed]: completed,
          [classes.active]: active,
        })}
      >
        {completed && <CheckIcon className={classes.icon} />}
      </div>
    </div>
  );
});

const MaterialStepper = withTheme(({ activeStep, variation = null, theme }) => {
  let stepData = null;

  if (variation && variation === 'demand-iq-no-utility-bill-variation') {
    stepData = stepperDataNoUtilityBill;
  } else {
    stepData = stepperData;
  }

  const count = stepData && stepData.length > 0 ? stepData.length : 2;
  const [t] = useTranslation('translation');
  const lastStep = parseInt(getLocalStorageItem('progress-step'), 10);
  const [render, setRender] = useState(false);

  if (!lastStep || lastStep !== activeStep) {
    setLocalStorageItem('progress-step', activeStep);
    setTimeout(() => {
      // Start the timer
      setRender(true); // After 1 second, set render to true
    }, 500);
  }

  return (
    <StyledWrapper count={count} color={theme.color_scheme_light}>
      <Stepper
        alternativeLabel
        activeStep={
          !lastStep || lastStep !== activeStep
            ? render
              ? activeStep
              : activeStep !== 0
              ? activeStep - 1
              : 0
            : activeStep
        }
        connector={<QontoConnector />}
        className="material-stepper"
      >
        {stepData.map((label, index) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={QontoStepIcon}
              classes={{
                label: clsx('material-stepper-label', {
                  'material-stepper-label--first': index === 0,
                  'material-stepper-label--last': index === stepData.length - 1,
                }),
              }}
            >
              {t(label)}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </StyledWrapper>
  );
});

MaterialStepper.propTypes = {
  activeStep: PropTypes.number,
};

MaterialStepper.defaultProps = {
  activeStep: 0,
};

export default MaterialStepper;
