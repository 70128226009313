/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from 'components/Button';
import TextInput from 'components/Forms/TextInput';
import AutoComplete from 'components/Forms/AutoComplete';
import MaterialStepper from 'components/MaterialStepper';
import { ReactComponent as ArrowRightIcon } from 'assets/images/icon-arrow-right.svg';
import { ReactComponent as ProtectionIcon } from 'assets/images/icon-protection.svg';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import { getProviders } from 'common/api.mock';
import {
  setUserData,
  saveSession,
  fetchUtilityBills,
  navigateTo,
  trackForwardProgress,
} from 'store/actions';
import { generateID, scrollToTop } from 'common/utils';
import DefaultLayout from 'components/DefaultLayout';
import { withTranslation } from 'react-i18next';
import StyledBills2 from './styled';

const formSchema = Yup.object().shape({
  username: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});

class Bills2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      providerName: '',
      provider: null,
      step: 1,
    };
  }

  componentDidMount() {
    scrollToTop();
  }

  handleNext = async (values) => {
    const { user, actions } = this.props;
    actions.setUserData({
      step: user.is_instant_estimate ? '/result3' : '/start',
    });

    const { provider } = this.state;
    const { username = '', password = '' } = values;
    await actions.fetchUtilityBills({
      provider_id: provider.providerId,
      provider_name: provider.providerName,
      provider_website: provider.website,
      username,
      password,
    });

    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo('/thankyou', false);
  };

  handleBack = () => {
    const { actions } = this.props;
    actions.navigateTo('/bills1', false);
  };

  loadProvider = async (value) => {
    try {
      const { data } = await getProviders(value);
      return data;
    } catch (err) {
      console.error(err);
    }
    return [];
  };

  handleChange = (event) => {
    this.setState({ providerName: event.target.value, provider: null });
  };

  handleSelection = (_, { suggestion }) => {
    // When user searches for utility company in the list, show an "I don't see my utility" option if no results are found.
    // When clicking on this item go to bill upload page.
    if (suggestion.providerId === -1) {
      const { actions } = this.props;
      actions.navigateTo('/upload-bills');
      return;
    }

    this.setState({ provider: suggestion });
  };

  handleStepNext = async () => {
    const { step, provider } = this.state;
    const { actions } = this.props;
    if (!provider) {
      return;
    }
    scrollToTop();

    this.setState({ step: step + 1 });
    actions.trackForwardProgress();
  };

  handleStepBack = () => {
    const { step } = this.state;
    scrollToTop();
    this.setState({ step: step - 1 });
  };

  render() {
    const { theme, t, user } = this.props;
    const { providerName, provider, step } = this.state;

    const {
      location: { pathname },
    } = this.props;

    return (
      <DefaultLayout>
        <StyledBills2 className="content bills-2-page custom-stepper">
          {step === 1 && (
            <div className="form">
              <div
                id={generateID('div-material-stepper', `${pathname}/search`)}
                className="material-progress"
              >
                <MaterialStepper activeStep={2} variation={user.variation} />
              </div>

              <div
                id={generateID('div-provider', `${pathname}/search`)}
                className="form-content"
              >
                <div className="form-title mb-16 mt-24 text-center">
                  {t('Search for your electric utility provider')}
                </div>
                <div className="mb-16">
                  <div className="form-label mb-8">
                    {t("Electric utility provider's name")}
                  </div>
                  <AutoComplete
                    id={generateID(
                      'txt-utility-provider',
                      `${pathname}/search`
                    )}
                    loadItems={this.loadProvider}
                    value={providerName}
                    onChange={this.handleChange}
                    onSelect={this.handleSelection}
                    placeholder={t('Search for your electric utility provider')}
                    valueKey="providerName"
                    valueId="providerId"
                    name="providerName"
                    noSuggestionMessage={t("I don't see my utility provider")}
                  />
                </div>
              </div>

              <div
                id={generateID('div-actions', `${pathname}/search`)}
                className="form-actions"
              >
                <Button
                  id={generateID('btn-back', `${pathname}/search`)}
                  outline
                  onClick={this.handleBack}
                >
                  <BackIcon />
                </Button>
                <Button
                  id={generateID('btn-continue', `${pathname}/search`)}
                  full
                  disabled={!provider}
                  onClick={this.handleStepNext}
                >
                  {t('Continue')}
                  <ArrowRightIcon />
                </Button>
              </div>
            </div>
          )}

          {step === 2 && (
            <Formik
              initialValues={{ username: '', password: '' }}
              validationSchema={formSchema}
              onSubmit={this.handleNext}
              validateOnMount
            >
              {({ isValid, isSubmitting }) => {
                return (
                  <Form className="form">
                    <div
                      id={generateID(
                        'div-material-stepper',
                        `${pathname}/connect`
                      )}
                      className="material-progress"
                    >
                      <MaterialStepper
                        activeStep={2}
                        variation={user.variation}
                      />
                    </div>

                    <div
                      id={generateID(
                        'div-provider-information',
                        `${pathname}/connect`
                      )}
                      className="form-content"
                    >
                      <div className="form-title mb-16 text-center">
                        {t('{{providerName}} Account Connector', {
                          providerName: provider.providerName,
                        })}
                      </div>

                      <div className="form-subtext mb-24">
                        {t(
                          'Sign in to allow {{company_name}} access to your energy usage and data, which will only be use to ensure your design meets local rules and solar design codes.',
                          {
                            company_name: theme.company_name,
                          }
                        )}
                      </div>

                      <div className="fieldset mb-24">
                        <div className="mb-16">
                          <Field
                            id={generateID(
                              'txt-username',
                              `${pathname}/connect`
                            )}
                            name="username"
                            placeholder={t('Username')}
                            theme={theme}
                            component={TextInput}
                            autoFocus
                          />
                          <a
                            id={generateID(
                              'btn-forgot-username',
                              `${pathname}/connect`
                            )}
                            href={provider.website}
                            className="form-link"
                            target="_blank"
                          >
                            {t('Forgot username?')}
                          </a>
                        </div>
                        <div className="mb-16">
                          <Field
                            id={generateID(
                              'txt-password',
                              `${pathname}/connect`
                            )}
                            name="password"
                            type="password"
                            placeholder={t('Password')}
                            theme={theme}
                            component={TextInput}
                          />
                          <a
                            id={generateID(
                              'btn-forgot-password',
                              `${pathname}/connect`
                            )}
                            href={provider.website}
                            className="form-link"
                            target="_blank"
                          >
                            {t('Forgot password?')}
                          </a>
                        </div>

                        <div
                          id={generateID(
                            'div-connect-actions',
                            `${pathname}/connect`
                          )}
                          className="actions"
                        >
                          <Button
                            id={generateID(
                              'btn-connect-account',
                              `${pathname}/connect`
                            )}
                            type="submit"
                            full
                            disabled={!isValid || isSubmitting}
                          >
                            {t('Connect Account')}
                          </Button>
                          <div className="protection mt-16">
                            <ProtectionIcon />
                            {t('Your personal data is protected')}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id={generateID('div-actions', `${pathname}/connect`)}
                      className="form-actions"
                    >
                      <Button
                        id={generateID('btn-back', `${pathname}/connect`)}
                        outline
                        borderless
                        left
                        onClick={this.handleStepBack}
                        className="btn-back"
                      >
                        <BackIcon />
                        {t('Back')}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          )}
        </StyledBills2>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui }) => ({
  user: data,
  theme: ui.theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      fetchUtilityBills,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Bills2)
);
