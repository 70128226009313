import styled from 'styled-components';

const Result1 = styled.div`
  .card {
    padding: 1.875rem 1.25rem;
    margin: 2.25rem auto;
    color: #000;
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.03);

    .grid-container {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-column-gap: 1.5rem;
      grid-row-gap: 2rem;

      &::before {
        content: '';
        position: absolute;
        left: calc(50% - 0.5px);
        width: 1px;
        height: 100%;
        background-color: #eee;
      }

      &::after {
        content: '';
        position: absolute;
        top: calc(50% - 0.5px);
        width: 100%;
        height: 1px;
        background-color: #eee;
      }

      .grid-item {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        gap: 0.5rem;

        .grid-item-top {
          margin-bottom: 1rem;
          font-family: 'Effra';
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 1.125rem;
          text-align: center;
        }

        .grid-item-middle {
          font-family: 'Effra';
          font-weight: 500;
          font-size: 1.75rem;
          line-height: 2rem;
          text-align: center;
        }

        .grid-item-bottom {
          font-family: 'Effra Light';
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 1.125rem;
          text-align: center;
        }
      }
    }
  }

  .form-actions {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export default Result1;
