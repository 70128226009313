/* eslint-disable no-template-curly-in-string */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from 'components/Button';
import Header from 'components/Sunlight/Header';
import Result from 'components/Sunlight/Result';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackForwardProgress,
  trackFacebookPixel,
  trackGoogleLead,
} from 'store/actions';
import { withTranslation } from 'react-i18next';
import { VARIATION_OPTIONS } from 'pages/constants';
import { getLocalStorageItem } from 'common/localStorage';
import { generateID } from 'common/utils';
import StyledSunlightSuccess from './styled';

class SunlightSuccess extends Component {
  constructor(props) {
    super(props);

    const variation = getLocalStorageItem('variation');

    this.state = {
      variation: VARIATION_OPTIONS[variation],
    };
  }

  handleClose = async () => {
    const {
      history,
      actions,
      user: { sunlight },
      theme,
    } = this.props;

    let step;
    if (sunlight.step_from === '/result3') step = '/result3';
    else if (theme.is_calendly_on) step = '/meetingcalendly';
    else step = '/meeting1';

    actions.setUserData({ step });
    await actions.saveSession();

    history.replace(step);
  };

  handleNext = async () => {
    const { actions, theme, user } = this.props;

    let step;
    if (user.sunlight.step_from !== '/result3') {
      if (theme.is_calendly_on) step = '/meetingcalendly';
      else step = '/meeting1';
    } else step = '/qualify/start';

    actions.setUserData({ step });
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo(step);
  };

  render() {
    const {
      t,
      user: { sunlight },
    } = this.props;
    const { variation } = this.state;

    let response = {};
    try {
      response = JSON.parse(sunlight.response) || {};
    } catch (e) {
      console.error(e);
    }

    const applicantFirstName =
      response?.projects?.[0]?.applicants?.[0].firstName || '';
    const returnedLoanAmount =
      response.projects?.[0].prequals?.[0].prequalMaxApproved;

    const {
      location: { pathname },
    } = this.props;

    return (
      <>
        <variation.header />

        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <StyledSunlightSuccess className="content sunlight-success-page">
              <div id={generateID('div-header', pathname)} className="mb-32">
                <Header onCloseClick={this.handleClose} />
              </div>

              <div className="main-content">
                <div
                  id={generateID('div-result', pathname)}
                  className="mt-16 mb-32"
                >
                  <Result
                    title={t('Congratulations!')}
                    content={
                      sunlight.step_from === '/result3'
                        ? t(
                            'You are pre-qualified for zero down financing. Let’s quickly verify your home qualifies for solar.'
                          )
                        : t(
                            'You are pre-qualified for zero down financing. You are ready to book a time to review your quote with a solar expert from our team.'
                          )
                    }
                    description={t(
                      '{{applicantFirstName}} has been pre-qualified for a fixed rate solar loan for up to ${{returnedLoanAmount}}. A full credit pull of the applicant’s credit, which includes a full search of his/her credit history may change these results. The actual approved loan amount and interest rate may vary based on the terms of the loan product selected. The applicant may qualify for a higher or lower loan amount after a full credit pull of his/her credit.',
                      {
                        applicantFirstName,
                        returnedLoanAmount:
                          Intl.NumberFormat('en-US').format(returnedLoanAmount),
                      }
                    )}
                  />
                </div>

                <div
                  id={generateID('div-actions', pathname)}
                  className="form-actions"
                >
                  <Button
                    id={generateID('btn-book-or-qualify', pathname)}
                    full
                    onClick={this.handleNext}
                  >
                    {sunlight.step_from === '/result3'
                      ? t('Qualify my home')
                      : t('Book Appointment')}
                  </Button>
                </div>
              </div>
            </StyledSunlightSuccess>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user: { data, saving }, ui }) => ({
  user: data,
  theme: ui.theme,
  saving,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      trackFacebookPixel,
      trackGoogleLead,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(SunlightSuccess)
);
