import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'common/utils';
import Avatar from 'components/Avatar';
import TypingIndicator from 'components/TypingIndicator';
import StyledChat from './styled';

class Chat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
    this.handle = null;
  }

  componentDidMount() {
    const { onShow, timeout, mode } = this.props;
    if (mode === 'timeout') {
      this.handle = setTimeout(() => {
        this.setState({ show: true }, () => {
          onShow();
        });
      }, timeout);
    }
  }

  componentDidUpdate(prevProps) {
    const { mode, loading, onShow } = this.props;
    if (
      mode === 'loading' &&
      prevProps.loading !== loading &&
      loading === false
    ) {
      onShow();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.handle);
  }

  render() {
    const { children, render, loading, mode, id = null } = this.props;
    const { show } = this.state;
    const visible = mode === 'timeout' ? show : !loading;

    return (
      <StyledChat id={id ?? null} className="chat">
        <div className="chat-avatar">
          <Avatar />
        </div>
        {!visible ? (
          <div className="chat-typing">
            <TypingIndicator />
          </div>
        ) : render ? (
          render()
        ) : (
          <div className="chat-content">{children}</div>
        )}
      </StyledChat>
    );
  }
}

Chat.propTypes = {
  render: PropTypes.any,
  onShow: PropTypes.func,
  loading: PropTypes.bool,
  timeout: PropTypes.number,
  mode: PropTypes.string,
};

Chat.defaultProps = {
  render: null,
  onShow: noop,
  loading: false,
  timeout: 2000,
  mode: 'timeout',
};

export default Chat;
