import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cls from 'classnames';
import Rating from 'components/Rating';
import TermsModal from 'components/TermsModal';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { generateID } from 'common/utils';
import StyledFooter, { Legal } from './styled';
import { getVisibleFooterLinks } from 'common/api.mock';

const Footer = ({ showLegal, theme, customText, style }) => {
  const [showTerm, setShowTerm] = useState(false);
  const [t] = useTranslation('translation');

  const openWithNewWindow = (url) => {
    if (!url) {
      return;
    }
    window.open(url, '_new');
  };

  const visibleFooterLinks = getVisibleFooterLinks();

  return (
    <>
      {customText && <Legal className="legal-disclosure">{customText}</Legal>}
      <StyledFooter
        id={generateID('div-footer', 'footer')}
        className={cls('footer', { 'no-legal': !showLegal && !customText })}
        style={style}
      >
        <div className="client-review">
          <Rating value={5} />
          {visibleFooterLinks && (
            <div
              id={generateID('btn-read-our-reviews', 'footer')}
              className="read-reviews"
              onClick={() => openWithNewWindow(theme.reviews_url)}
            >
              {t('Read Our Reviews')}
            </div>
          )}
        </div>
        <div className="privacy">
          Experience by Demand IQ | &copy;{moment().format('YYYY')}
          {visibleFooterLinks && (
            <>
              &nbsp;| Use is subject to&nbsp;
              <span
                id={generateID('btn-terms', 'footer')}
                onClick={() => {
                  // openWithNewWindow('https://www.demand-iq.com/enduserterms')
                  setShowTerm(true);
                }}
              >
                Terms
              </span>
              &nbsp;&amp;&nbsp;
              <span
                id={generateID('btn-privacy-policy', 'footer')}
                onClick={() => openWithNewWindow(theme.privacy_policy)}
              >
                Privacy Policy
              </span>
            </>
          )}
        </div>

        {showTerm && <TermsModal onClose={() => setShowTerm(false)} />}
      </StyledFooter>
    </>
  );
};

Footer.propTypes = {
  showLegal: PropTypes.bool,
  customText: PropTypes.any,
  style: PropTypes.object,
};

Footer.defaultProps = {
  showLegal: false,
  customText: '',
  style: {},
};

const mapStateToProps = ({ ui: { theme } }) => ({
  theme,
});

export default connect(mapStateToProps)(Footer);
