import React from 'react';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import { noop, formatNumber } from 'common/utils';
import { useTranslation } from 'react-i18next';
import StyledWrapper, { TooltipTitle, TooltipContent } from './styled';

const ThumbComponent = ({ children, 'aria-valuenow': value, ...props }) => {
  const [t] = useTranslation('translation');
  return (
    <div {...props}>
      <div className="material-slider-tooltip">
        <div className="material-slider-tooltip-content">
          <TooltipTitle>{t('My electric bill')}</TooltipTitle>
          <TooltipContent>
            ${formatNumber(Math.round(value))} / {t('month')}
          </TooltipContent>
        </div>
      </div>
      <div className="material-slider-thumb">
        <div className="material-slider-thumb-line"></div>
        <div className="material-slider-thumb-line"></div>
        <div className="material-slider-thumb-line"></div>
      </div>
    </div>
  );
};

const MaterialSlider = ({ min, max, step, value, onChange, marks, scale }) => {
  const handleSliderChange = (event, newValue) => {
    onChange(newValue, Math.round(scale(newValue)));
  };

  return (
    <StyledWrapper className="material-slider">
      <Slider
        value={value}
        min={min}
        step={step}
        max={max}
        marks={marks}
        scale={scale}
        onChange={handleSliderChange}
        valueLabelDisplay="on"
        ThumbComponent={ThumbComponent}
      />
    </StyledWrapper>
  );
};

MaterialSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
  marks: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
};

MaterialSlider.defaultProps = {
  min: 0,
  max: 100,
  step: 1,
  value: 0,
  onChange: noop,
  marks: false,
};

export default MaterialSlider;
