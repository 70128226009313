import styled from 'styled-components';

export const StyledSlider = styled.div`
  .slick-dots {
    margin: 0 0 2rem 0;
    position: relative;
  }

  .slick-dots li button {
    width: 16px;
    height: 16px;
    background: #e0e0e0;
    border-radius: 50%;
  }

  .slick-dots li button:before {
    content: unset;
  }

  .slick-dots .slick-active button {
    background: ${(props) => props.theme.color_scheme_light};
  }
`;

export const PrevArrow = styled.div`
  width: 36px;
  height: 36px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  cursor: pointer;
  background-color: #fff;
  left: -30px;
  z-index: 1;

  &::before {
    display: none;
  }

  &:hover,
  &:focus {
    color: inherit;
    background: #fff;
  }

  svg {
    width: 8px;
    color: ${(props) => props.theme.color_scheme_button || '#000'};
    stroke: ${(props) => props.theme.color_scheme_button || '#000'};
  }

  svg:hover {
    color: ${(props) => props.theme.color_scheme_button_hover || '#000'};
    stroke: ${(props) => props.theme.color_scheme_button_hover || '#000'};
  }

  &.slick-disabled {
    display: none !important;
  }
`;

export const NextArrow = styled.div`
  width: 36px;
  height: 36px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  cursor: pointer;
  background-color: #fff;
  right: -30px;
  z-index: 1;

  &::before {
    display: none;
  }

  &:hover,
  &:focus {
    color: inherit;
    background: #fff;
  }

  svg {
    width: 8px;
    transform: rotate(180deg);
    color: ${(props) => props.theme.color_scheme_button || '#000'};
    stroke: ${(props) => props.theme.color_scheme_button || '#000'};
  }

  svg:hover {
    color: ${(props) => props.theme.color_scheme_button_hover || '#000'};
    stroke: ${(props) => props.theme.color_scheme_button_hover || '#000'};
  }

  &.slick-disabled {
    display: none !important;
  }
`;
