import styled from 'styled-components';

const StyledWrapper = styled.div`
  &.content.reset-home-address-page {
    padding: 1.25rem 1.75rem 1rem;
  }

  .progress-bar-container {
    margin-bottom: 36px;
  }

  .google-map-wrapper {
    margin-top: 2.25rem;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.03);
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 100%;
    position: relative;
    height: 300px;
  }

  .form-actions {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export default StyledWrapper;
