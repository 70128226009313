import React from 'react';
import PropTypes from 'prop-types';
import { ModalProvider } from 'styled-react-modal';
import { noop } from 'common/utils';
import { ReactComponent as RCloseIcon } from 'assets/images/icon-close.svg';
import StyledModal, { ModalBackground } from './styled';

const Modal = ({
  id = null,
  children,
  isOpen,
  className,
  onClose,
  showCloseIcon,
  onBackgroundClick,
  style,
  CloseIcon = RCloseIcon,
}) => (
  <ModalProvider backgroundComponent={ModalBackground}>
    <StyledModal
      id={id ?? null}
      isOpen={isOpen}
      className={className}
      onBackgroundClick={onBackgroundClick}
      style={style}
    >
      {showCloseIcon && (
        <div className="close-icon" onClick={onClose}>
          <CloseIcon />
        </div>
      )}
      {children}
    </StyledModal>
  </ModalProvider>
);

Modal.propTypes = {
  showCloseIcon: PropTypes.bool,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  onBackgroundClick: PropTypes.func,
  style: PropTypes.object,
};

Modal.defaultProps = {
  showCloseIcon: false,
  onClose: noop,
  isOpen: false,
  className: '',
  onBackgroundClick: noop,
  style: {},
};

export default Modal;
