import React from 'react';
import { ReactComponent as BatteryIcon } from 'assets/images/battery-2-charge-line.svg';
import { ReactComponent as Check2CircleIcon } from 'assets/images/icon-check-2-circle.svg';
import StyledWrapper from './styled';

const BatteryAssigned = ({ value }) => {
  return (
    <StyledWrapper>
      <div className="battery">
        <div className="info">
          <BatteryIcon />
          <div className="name">{value.name}</div>
          <div className="description">{value.description}</div>
          <div className="price">
            <span>+${value.price}/</span>mo.
          </div>
        </div>
        <div className="action">
          <Check2CircleIcon />
          Added to Estimate
        </div>
      </div>
    </StyledWrapper>
  );
};

export default BatteryAssigned;
