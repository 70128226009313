import { makeStyles } from '@material-ui/core';
import { pxToRem } from 'common/utils';

export const useOutlinedTextFieldStyles = makeStyles(() => ({
  outlinedTextField: {
    color: '#000',
    width: '100%',

    '& div > input': {
      height: 5,
      borderRadius: 16,
      border: 'none',
      fontFamily: 'Poppins',
      letterSpacing: '0.01rem',
    },
    '& input::placeholder': {
      fontSize: pxToRem(16),
      fontStyle: 'italic',
      fontFamily: 'Poppins',
      letterSpacing: '0.01rem',
      color: '#666',
      fontWeight: 400,
    },
    '& .MuiOutlinedInput-input': {
      padding: '21.5px 20px',
    },
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      backgroundColor: 'white',
      borderRadius: 16,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: (props) => (props.value ? '1px solid #625df5' : '1px solid #666'),
    },

    '& > .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
      borderColor: '#625df5',
    },
  },
}));
