import React, { useState } from 'react';
import Button from 'components/Button';
import { makeStyles } from '@material-ui/core';
import ProgressBar from 'components/ProgressBar';
import Chat from 'components/Chat';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import { setUserData, navigateTo } from 'store/actions';
import DefaultLayout from 'components/DefaultLayout';
import GoogleMapContainer from 'components/GoogleMapContainer';
import { Marker } from 'google-maps-react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  extractValuesFromPlace,
  generateID,
  loadGoogleMaps,
} from 'common/utils';
import { useLocation } from 'react-router-dom';
import StyledWrapper from './styled';

const useStyles = makeStyles({
  googleMapWrapper: {
    backgroundColor: '#ffffff',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '100%',
    position: 'relative',
    height: 300,
  },
});

const ResetHomeAddress = () => {
  const [t] = useTranslation('translation');
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);
  const [newPosition, setNewPosition] = useState({
    lat: user.rooftop_latitude,
    lng: user.rooftop_longitude,
  });
  const [checking, setChecking] = useState(false);
  const hLocation = useLocation();

  const handleBack = () => {
    dispatch(navigateTo('/calculate12'));
  };

  const handleNext = async () => {
    setChecking(true);
    const location = { lat: newPosition.lat, lng: newPosition.lng };
    const getAddressFromLocation = async () => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode(
        {
          location,
        },
        async (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            const result = results[0];
            const values = extractValuesFromPlace(result);
            const step = '/calculate12';
            dispatch(
              setUserData({
                ...values,
                step,
              })
            );
            setChecking(false);
            await dispatch(navigateTo('/calculate12'));
          } else {
            setChecking(false);
          }
        }
      );
    };
    if (!window.google) loadGoogleMaps(getAddressFromLocation);
    else getAddressFromLocation();
  };

  const setPositionAgain = (_props, map, event) => {
    setNewPosition({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  return (
    <DefaultLayout>
      <StyledWrapper className="content calculate-12-page">
        <div className="form">
          <div className="form-content">
            <div
              id={generateID('div-progress-bar', hLocation.pathname)}
              className="mb-20 w100"
            >
              <ProgressBar value={25} step={1} />
            </div>

            <Chat
              id={generateID('chat-sorry-about-that', hLocation.pathname)}
              timeout={0}
            >
              {t(
                "Sorry about that! Let's find your house! Just tap on your roof and drop a pin."
              )}
            </Chat>

            <div
              id={generateID('div-google-map', hLocation.pathname)}
              className={classes.googleMapWrapper}
            >
              <GoogleMapContainer
                lat={newPosition.lat}
                lng={newPosition.lng}
                zoom={18}
                fullscreenControl
                zoomControl
                streetViewControl
                saturation={false}
                mapStyles={{
                  width: '100%',
                  height: 'calc(100% - 24px)',
                }}
                onClick={setPositionAgain}
                mapType="satellite"
              >
                <Marker
                  position={{ lat: newPosition.lat, lng: newPosition.lng }}
                  draggable
                  onDragend={setPositionAgain}
                />
              </GoogleMapContainer>
            </div>

            <div
              id={generateID('div-actions', hLocation.pathname)}
              className="form-actions"
            >
              <Button
                id={generateID('btn-back', hLocation.pathname)}
                outline
                onClick={handleBack}
              >
                <BackIcon />
              </Button>
              <Button
                id={generateID('btn-that-is-my-house', hLocation.pathname)}
                type="submit"
                full
                onClick={handleNext}
                disabled={checking}
              >
                {t("That's my house")}
              </Button>
            </div>
          </div>
        </div>
      </StyledWrapper>
    </DefaultLayout>
  );
};

export default ResetHomeAddress;
