import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThemeProvider } from 'styled-components';
import isEqual from 'lodash/isEqual';
import ReactPixel from 'react-facebook-pixel';
import Loading from 'components/Loading';
import { addCustomScripts, getVariation, getCookie } from 'common/utils';
import { getLocalStorageItem, setLocalStorageItem } from 'common/localStorage';
import { ThemedGlobalStyle } from 'styles/theme';
import * as APIMock from 'common/api.mock';
import { v4 as uuidv4 } from 'uuid';
import Pages from './pages';
import {
  loadSolarCompany,
  loadSession,
  loadFromAddressWidget,
  loadFromZipcodeWidget,
  loadVariations,
  previewTheme,
} from './store/actions';
import {
  notifyOfNewLead,
  getProposalKey,
  getResumeKey,
  getDefaultAddressData,
  getDefaultZipcode,
  getReferrerUrl,
  getWidgetType,
  getOriginalSource,
} from './common/api.mock';
// import 'common/heapanalytics.js';
import 'common/jornaya';
import 'common/trustedform';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const { actions } = this.props;
    await actions.loadSolarCompany();

    const defaultAddressData = getDefaultAddressData();
    const defaultZipcode = getDefaultZipcode();
    if (defaultAddressData.placeId) {
      const { theme } = this.props;
      await actions.loadFromAddressWidget(theme);
    } else if (
      defaultZipcode.zipcode ||
      (defaultZipcode.lat && defaultZipcode.lng)
    ) {
      const { theme } = this.props;
      await actions.loadFromZipcodeWidget(theme);
    } else if (
      getLocalStorageItem('sessionId') ||
      getProposalKey() ||
      getResumeKey()
    ) {
      await actions.loadSession();
    }
    this.setState({ loading: false });

    window.addEventListener('beforeunload', this.handleNotify);
    window.addEventListener('message', this.previewTheme);

    if (!APIMock.getVisibleFooterLinks()) {
      document.body.classList.add('hide-footer-links');
    }
  }

  componentDidUpdate(prevProps) {
    const { theme } = this.props;
    if (!prevProps.theme && theme && !isEqual(prevProps.theme, theme)) {
      // add facebook pixel
      if (theme.facebook_pixel_id) {
        ReactPixel.init(theme.facebook_pixel_id);

        const eventID = getLocalStorageItem('eventID') || uuidv4();
        if (!getLocalStorageItem('eventID')) {
          setLocalStorageItem('eventID', eventID);
        }
        ReactPixel.fbq('track', 'PageView', {}, { eventID });

        const fbp = getCookie('_fbp') || getCookie('fbp');
        const param = {
          event_id: eventID,
          event_name: 'PageView',
          fbp,
        };
        APIMock.trackFacebookPixelFromServer(param);
      }

      // add custom tag
      if (theme.custom_tag) {
        addCustomScripts(theme.custom_tag);
      }

      // add google ads tag to GTM
      if (theme.google_ads_global_site_tag) {
        window.gtag('config', theme.google_ads_global_site_tag);
      }

      // add google analytics tag to GTM
      if (theme.google_analytics_site_tag) {
        const referrer = getOriginalSource() || getReferrerUrl();

        window.gtag('event', 'stellaTracker.set', {
          referrer,
          dimension1: theme.company_name,
          dimension2: getVariation(),
          dimension3: getWidgetType(),
          send_to: theme.google_analytics_site_tag,
        });
        window.gtag('event', 'page_view', {
          send_to: theme.google_analytics_site_tag,
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleNotify);
    window.removeEventListener('message', this.previewTheme);
  }

  handleNotify = () => {
    notifyOfNewLead();
  };

  previewTheme = (event) => {
    const prefix =
      process.env.REACT_APP_TRACKING_ENV === 'production'
        ? 'portal'
        : 'portal-dev';
    if (event.origin !== `https://${prefix}.demand-iq.com`) return;
    const { actions } = this.props;
    actions.previewTheme(event.data);
  };

  render() {
    const { theme, themeLoading, sessionLoading } = this.props;
    const { loading } = this.state;

    if (!theme || themeLoading || sessionLoading || loading) {
      return <Loading />;
    }

    return (
      <ThemeProvider theme={theme}>
        <ThemedGlobalStyle />
        <Pages />
      </ThemeProvider>
    );
  }
}

const mapStateToProps = ({ user, ui }) => ({
  theme: ui.theme,
  themeLoading: ui.loading,
  sessionLoading: user.loading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loadSolarCompany,
      loadSession,
      loadFromAddressWidget,
      loadFromZipcodeWidget,
      loadVariations,
      previewTheme,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
