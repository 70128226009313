import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import StyledButton from './styled';

const Button = ({ children, disabled, selected, className, ...props }) => {
  return (
    <StyledButton
      className={cls(
        'btn',
        { 'btn-disabled': disabled },
        { 'btn-selected': !disabled && selected },
        className
      )}
      {...props}
      disabled={disabled}
    >
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  full: PropTypes.bool,
  outline: PropTypes.bool,
  link: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  type: PropTypes.string,
};

Button.defaultProps = {
  type: 'button',
  disabled: false,
  selected: false,
};

export default Button;
