import React, { useRef } from 'react';
import { TextField } from '@material-ui/core';
import { useOutlinedTextFieldStyles } from '../TextInputNRGVariation/styled';

const TextInputNRGVariation = ({
  field,
  form,
  theme,
  InputProps,
  ...props
}) => {
  const classes = useOutlinedTextFieldStyles({ theme });
  const input = useRef();
  const error = form.errors[field.name];
  const touched = form.touched[field.name];

  return (
    <TextField
      error={Boolean(!form.isSubmitting && touched && error)}
      {...field}
      {...props}
      variant="outlined"
      className={classes.outlinedTextField}
      ref={input}
    />
  );
};

export default TextInputNRGVariation;
