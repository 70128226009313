import React from 'react';
import { useTranslation } from 'react-i18next';
import StyledProcessing from './styled';

const Processing = () => {
  const [step, setStep] = React.useState(0);
  const [t] = useTranslation('translation');

  React.useEffect(() => {
    const timerId = setTimeout(() => {
      setStep((step + 1) % 4);
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  });

  return (
    <StyledProcessing className="sunlight-processing">
      <div className="processing">
        {t('Processing')}
        <span style={{ visibility: step > 0 ? '' : 'hidden' }}>.</span>
        <span style={{ visibility: step > 1 ? '' : 'hidden' }}>.</span>
        <span style={{ visibility: step > 2 ? '' : 'hidden' }}>.</span>
      </div>
    </StyledProcessing>
  );
};

export default Processing;
