import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { navigateTo, setUserData } from 'store/actions';
import moment from 'moment-timezone';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { setLocalStorageItem } from 'common/localStorage';
import { getDefaultAddressData, getDefaultZipcode } from 'common/api.mock';
import { hotjar } from 'common/hotjar.js';
import { VARIATION_OPTIONS } from './constants';

class Pages extends Component {
  constructor(props) {
    super(props);
    const { theme, user, actions, i18n } = props;
    let variation = null;
    let lang = null;
    let appLanguage = 'en';
    const query = new URLSearchParams(window.location.search);

    if (!_.isEmpty(query.get('app_url'))) {
      const app = query.get('app_url');
      const incomingUrl = new URLSearchParams(new URL(app).search);
      variation = incomingUrl.get('variation');
      lang = incomingUrl.get('lang');
    }

    if (!variation) {
      if (!_.isEmpty(query.get('variation'))) {
        variation = query.get('variation');
      }
    }

    if (
      /^es\b/.test(navigator.language) &&
      theme.spanish_ai_name &&
      theme.spanish_ai_avatar
    ) {
      variation = 'demand-iq-spanish-variation';
    }

    if (!lang) {
      if (!_.isEmpty(query.get('lang'))) {
        lang = query.get('lang');
      }
    }

    if (lang && /^es\b/.test(lang)) {
      variation = 'demand-iq-spanish-variation';
    }

    if (!variation) {
      if (!_.isUndefined(user.variation)) {
        variation = user.variation;
      } else {
        variation = theme.variation;
      }
    }

    const { placeId } = getDefaultAddressData();
    const { zipcode, lat, lng, lang: zipcodeWidgetLang } = getDefaultZipcode();
    if (placeId || zipcode || lat || lng) {
      variation = user.variation || null;
    }

    setLocalStorageItem('variation', variation || 'current');

    this.state = {
      variation:
        variation in VARIATION_OPTIONS
          ? VARIATION_OPTIONS[variation || 'current']
          : VARIATION_OPTIONS.current,
    };
    const { variation: variationComponent } = this.state;

    if (placeId || zipcode || lat || lng) {
      if (zipcodeWidgetLang) {
        appLanguage =
          zipcodeWidgetLang && /^es\b/.test(zipcodeWidgetLang) ? 'es' : 'en';
      } else {
        appLanguage =
          (lang && /^es\b/.test(lang)) ||
          (/^es\b/.test(navigator.language) &&
            theme.spanish_ai_name &&
            theme.spanish_ai_avatar)
            ? 'es'
            : 'en';
      }
    } else {
      appLanguage = variationComponent.language;
    }
    actions.setUserData({
      lang: appLanguage,
    });

    if (appLanguage === 'es') {
      // eslint-disable-next-line global-require
      const esLocale = require('moment/locale/es');
      moment().locale('es', esLocale);
    }
    i18n.changeLanguage(appLanguage);

    // load hotjar conditionally
    if (variation !== 'demand-iq-nrg-variation') {
      hotjar()
    }
  }

  render() {
    const { variation } = this.state;
    return <variation.component />;
  }
}

const mapStateToProps = ({ user: { data }, ui: { theme } }) => ({
  user: data,
  theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      navigateTo,
      setUserData,
    },
    dispatch
  ),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Pages)
);
