import styled from 'styled-components';

const Footer = styled.div`
  font-family: 'Effra Light';
  font-size: 0.625rem;
  text-align: center;
  width: 100%;
  line-height: 1rem;

  @media screen and (min-width: 768px) {
    font-size: 0.75rem;
  }

  .privacy {
    margin-bottom: 1.5rem;

    @media screen and (min-width: 768px) {
      margin-top: 0.5rem;
    }

    a,
    span {
      color: inherit;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const Legal = styled.div`
  font-size: 0.5625rem;
  margin: 1.25rem;
  text-align: justify;

  @media screen and (min-width: 768px) {
    font-size: 0.75rem;
  }
`;

export default Footer;
