import styled from 'styled-components';

const StyledWrapper = styled.div`
  .google-map-wrapper {
    position: relative;
    display: flex;
    justify-content: left;
    overflow: hidden;

    .map-container {
      width: 100%;
    }

    .map-description {
      position: absolute;
      top: -10px;
      width: 100%;

      @media screen and (min-width: 530px) {
        padding-left: 10%;
        padding-right: 10%;
      }

      @media screen and (max-width: 530px) {
        padding-left: 0;
        padding-right: 0;
      }

      .section-content {
        background-color: #fff;
        margin-top: 10px;
        padding: 10px;
        width: 100%;
        border: 1px solid #dfdfdf;
        display: flex;
        justify-content: space-between;

        @media screen and (min-width: 530px) {
          padding: 10px 10%;
        }

        @media screen and (max-width: 530px) {
          padding: 10px;
        }

        @media screen and (max-width: 630px) {
          padding: 10px 5%;
        }

        @media screen and (max-width: 400px) {
          padding: 10px 2%;
        }

        .icon-mark {
          font-size: 20px;
          @media screen and (max-width: 808px) {
            font-size: 18px;
          }
          @media screen and (max-width: 500px) {
            font-size: 14px;
          }
        }
      }
    }
  }
`;

export default StyledWrapper;
