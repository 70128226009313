import styled from 'styled-components';

const FileUpload = styled.div`
  display: block;
  width: 100%;
  color: ${(props) => props.theme.color_scheme_dark};
  border-radius: 0;
  position: relative;
  overflow: visible;

  .btn-camera {
    margin: 0 0.625rem 0 0;
  }

  .btn-check {
    width: 21px;
    height: 21px;
    min-width: 21px;
    min-height: 21px;
    margin: 0 0;
    fill: #00a344;
    path {
      fill: #00a344;
    }
  }

  .btn-check-gray {
    fill: #888;
    path {
      fill: #888;
    }
  }

  .icon-error {
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    margin: 0;
    fill: #f00;
    path {
      fill: #f00;
    }
  }

  input[type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }

  &.has-error > .btn {
    border-color: #f44336;
  }

  .label {
    flex: 1;
    text-align: left;
    line-height: 21px;

    & .optional {
      text-transform: lowercase;
      margin-left: 0.5rem;
      font-size: 0.75rem;
      color: #b3b3b3;
    }
  }

  .error-text {
    position: absolute;
    margin: 5px 0 0 20px;
    color: red;
    text-align: left;
  }

  @media screen and (min-width: 768px) {
    padding: 0.625rem 1.25rem;
  }
`;

export default FileUpload;
