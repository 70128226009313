import React from 'react';
import PropTypes from 'prop-types';
import StyledResult from './styled';

const Result = ({ title, content = '', description }) => {
  return (
    <StyledResult className="sunlight-result">
      <div className="sunlight-result-title mb-32">{title}</div>

      <div className="sunlight-result-content">{content}</div>

      {description && (
        <div className="sunlight-result-description">{description}</div>
      )}
    </StyledResult>
  );
};

Result.defaultProps = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  description: PropTypes.string,
};

export default Result;
