import styled from 'styled-components';

const Qualify82 = styled.div`
  .button {
    display: flex;
    width: 100%;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    min-width: 3.125rem;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    border-radius: ${(props) =>
      props.theme.button_shape === 'square' || props.link
        ? 0
        : props.theme.button_shape === 'semiround'
        ? 3
        : 100}px;
    border: 1px solid
      ${(props) =>
        props.outline ? props.theme.color_scheme_button : 'transparent'};
    color: ${(props) =>
      props.outline || props.link
        ? props.theme.color_scheme_button
        : '#ffffff'};
    background-color: ${(props) =>
      props.outline || props.link
        ? '#ffffff'
        : props.theme.color_scheme_button};
    font-size: 1.125rem;
    line-height: 1.25rem;
    height: initial;
    padding: ${(props) => (props.link ? '0' : '0.75rem 1rem')};
    font-family: ${(props) => (props.link ? 'Roboto' : 'inherit')};
    text-decoration: ${(props) => (props.link ? 'underline' : 'initial')};
    position: relative;

    &:not(.btn-disabled):not(:disabled) {
      cursor: pointer;
    }

    &.btn-disabled,
    &:disabled {
      opacity: 0.65;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      border: 1px solid
        ${(props) =>
          props.borderless || !props.outline
            ? 'transparent'
            : props.theme.color_scheme_button_hover};
      color: ${(props) =>
        props.outline || props.link
          ? props.theme.color_scheme_button_hover
          : '#ffffff'};
      background-color: ${(props) =>
        props.outline || props.link
          ? '#ffffff'
          : props.theme.color_scheme_button_hover};
    }

    @media screen and (min-width: 768px) {
      font-size: 1.375rem;
      line-height: 1.5rem;
      padding: ${(props) => (props.link ? '0' : '0.625rem 1rem')};
    }

    svg {
      max-width: 1.25rem;
      max-height: 1.25rem;
    }
  }
`;

export default Qualify82;
