import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { ReactComponent as GreenCheckIcon } from 'assets/images/icon-thin-green-check.svg';
import { ReactComponent as RotateIcon } from 'assets/images/icon-rotate.svg';
import imgSungage from 'assets/images/sungage.png';
import imgRocket from 'assets/images/rocket.png';
import { setUserData, saveSession } from 'store/actions';
import { VARIATION_OPTIONS } from 'pages/constants';
import { getLocalStorageItem } from 'common/localStorage';
import { templateSungageProject, templateSungageCredit } from 'common/data';
import {
  getSungageProject,
  createSungageProject,
  updateSungageProject,
  startSungageCredit,
  checkSungageCredit,
  prequalSungageCredit,
} from 'common/api.mock';
import { generateID } from 'common/utils';
import StyledSungage6 from './styled';

const stepList = [
  'Verifying information',
  'Connecting with credit bureau',
  'Accessing credit information',
  'Finalizing report',
];

class Sungage6 extends Component {
  constructor(props) {
    super(props);

    const variation = getLocalStorageItem('variation');

    this.state = {
      variation: VARIATION_OPTIONS[variation],
    };
  }

  componentDidMount() {
    this.verify();
  }

  getProject = async (projectId, sungageId) => {
    if (!sungageId) return null;

    const data = await getSungageProject({
      id: projectId,
      sungageId,
    });
    if (data) return data.sungageId;
    return null;
  };

  createProject = async (project) => {
    const data = await createSungageProject({ ...project });
    if (data) return data.sungageId;
    return null;
  };

  updateProject = async (project) => {
    const data = await updateSungageProject({ ...project });
    if (data) return data.sungageId;
    return null;
  };

  startCredit = async (projectId, sungageId) => {
    const data = await startSungageCredit({
      id: projectId,
      sungageId,
      action: 'start',
    });
    if (data) return data.key;
    return null;
  };

  checkCredit = async (credit) => {
    const data = await checkSungageCredit({ ...credit, action: 'check' });
    if (data) return data;
    return null;
  };

  prequalCredit = async (credit) => {
    const data = await prequalSungageCredit({ ...credit, action: 'prequal' });
    if (data) return data;
    return null;
  };

  verify = async () => {
    const { user } = this.props;
    const { sungage } = user;

    const projectId = sungage.id;
    let sungageId = sungage.sungage_id;

    const project = {
      ...templateSungageProject,
      // primaryApplicant: {
      //   ...templateSungageProject.primaryApplicant,
      //   firstName: user.first_name,
      //   lastName: user.last_name,
      //   email: user.email,
      // },
      // projectAddress: {
      //   ...templateSungageProject.projectAddress,
      //   street: user.address,
      //   city: user.city,
      //   state: user.state,
      //   zip: user.zip_code,
      // },
      id: projectId,
    };

    const credit = {
      ...templateSungageCredit,
      // primaryApplicant: {
      //   ...templateSungageCredit.primaryApplicant,
      //   firstName: user.first_name,
      //   lastName: user.last_name,
      //   email: user.email,
      //   dob: sungage.birthday,
      // },
      // projectAddress: {
      //   ...templateSungageCredit.projectAddress,
      //   street: user.address,
      //   city: user.city,
      //   state: user.state,
      //   zip: user.zip_code,
      // },
      projectId,
    };

    if (!projectId) {
      this.handleNext();
      return;
    }
    this.setState({ step: 1 });

    sungageId = await this.getProject(projectId, sungageId);
    if (!sungageId) {
      sungageId = await this.createProject({ ...project });
    } else {
      sungageId = await this.updateProject({ ...project, sungageId });
    }
    if (!sungageId) {
      this.handleNext();
      return;
    }
    this.setState({ step: 2 });

    const key = await this.startCredit(projectId, sungageId);
    if (!key) {
      this.handleNext();
      return;
    }
    const checkRes = await this.checkCredit({ ...credit, sungageId, key });
    if (!checkRes) {
      this.handleNext();
      return;
    }
    this.setState({ step: 3 });

    const prequalRes = await this.prequalCredit({ ...credit, sungageId, key });
    if (
      !prequalRes ||
      prequalRes?.applicationDetails?.status !== 'Highly Likely'
    ) {
      this.handleNext();
      return;
    }

    this.setState({ step: 4 });
    this.handleNext(true);
  };

  handleNext = async (qualified = false) => {
    const { history, actions } = this.props;
    const { timerId } = this.state;
    clearInterval(timerId);

    let step;
    if (qualified) step = '/prequalify/sungage/success';
    else step = '/prequalify/sungage/failure';

    actions.setUserData({
      step,
    });
    await actions.saveSession();
    history.push(step);
  };

  render() {
    const { t } = this.props;
    const { variation, step } = this.state;

    const {
      location: { pathname },
    } = this.props;

    return (
      <>
        <variation.header />

        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <StyledSungage6 className="content sungage6-page">
              <div className="form">
                <div className="form-content sungage-form-content">
                  <div
                    id={generateID('div-sungage-logo', pathname)}
                    className="sungage-logo"
                  >
                    <img src={imgSungage} alt="sungage" />
                  </div>

                  <div id={generateID('div-retreiving', pathname)}>
                    <div className="verify-title mb-16">
                      {t('Retreiving your credit report and score')}
                    </div>
                    {stepList.map((item, index) => (
                      <div className="verify-content mb-8" key={index}>
                        <div className="verify-description">{t(item)}</div>
                        <div>
                          {step > index && <GreenCheckIcon />}
                          {step === index && (
                            <div className="rotate">
                              <RotateIcon />
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="sungage-rocket" id="sungage-rocket">
                    <img src={imgRocket} alt="rocket" />
                  </div>
                </div>
              </div>
            </StyledSungage6>

            <variation.footer showLegal />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui }) => ({
  user: data,
  theme: ui.theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Sungage6)
);
