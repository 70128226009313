/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import orderBy from 'lodash/orderBy';
import cloneDeep from 'lodash/cloneDeep';
import MaterialStepper from 'components/MaterialStepperNRGVariation';
import Chat from 'components/ChatNRGVariation';
import BackButton from 'components/BackButtonNRGVariation';
import {
  setUserData,
  saveSession,
  answerQualifyQuestion,
  navigateTo,
  trackForwardProgress,
} from 'store/actions';
import DefaultLayout from 'components/DefaultLayout';
import { withTranslation } from 'react-i18next';
import { filterQuestionsByLanguage, generateID } from 'common/utils';
import { OPEN_ENDED_QUESTION } from 'pages/constants';
import ButtonSelect from 'components/ButtonSelect';
import StyledQualifyPage from './styled';

class QualifyPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    setTimeout(() => this.initData(), 2000);
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (prevProps.match.params.id && id && prevProps.match.params.id !== id) {
      this.initData();
    }
  }

  initData = () => {
    const {
      match: {
        params: { id: questionId },
      },
      theme: { chosen_questions },
      actions,
    } = this.props;

    const filteredQuestions = filterQuestionsByLanguage(
      chosen_questions
    ).filter((question) => question.question_type !== OPEN_ENDED_QUESTION);

    if (!filteredQuestions || filteredQuestions.length === 0 || !questionId) {
      actions.navigateTo('/qualify/end');
      return;
    }
    const question = orderBy(filteredQuestions, ['position'], ['asc'])[
      parseInt(questionId, 10) - 1
    ];
    if (!question) {
      actions.navigateTo('/qualify/end');
    }

    this.setState({
      question: cloneDeep(question),
      isFirst: questionId == 1,
      isLast: questionId == filteredQuestions.length,
      id: parseInt(questionId, 10),
      loading: false,
    });
  };

  handleBack = () => {
    const { actions } = this.props;
    actions.navigateTo(this.backStepUrl());
  };

  handleNext = async (value) => {
    const { actions } = this.props;
    const { question } = this.state;

    this.setState({ loading: true });
    await actions.answerQualifyQuestion(question.id, value);
    await actions.saveSession();

    const { step, goStep } = this.nextStepUrl();
    const data = { step };
    actions.setUserData(data);
    await actions.saveSession();

    actions.trackForwardProgress();
    await actions.navigateTo(goStep);
  };

  nextStepUrl = () => {
    const { id, isLast } = this.state;

    if (isLast) {
      const { user } = this.props;
      const step = user.is_disqualified
        ? user.is_instant_estimate
          ? '/result3'
          : '/start'
        : '/qualify/end';
      const goStep = user.is_disqualified ? '/disqualify' : '/qualify/end';
      return { step, goStep };
    }

    return {
      step: `/qualify/${id + 1}`,
      goStep: `/qualify/${id + 1}`,
    };
  };

  backStepUrl = () => {
    const { id, isFirst } = this.state;
    let step;
    if (isFirst) {
      step = '/qualify/start';
    } else {
      step = `/qualify/${id - 1}`;
    }
    return step;
  };

  render() {
    const { question, loading } = this.state;
    const { t } = this.props;

    const {
      location: { pathname },
    } = this.props;

    return (
      <DefaultLayout>
        <StyledQualifyPage className="content qualify-page custom-stepper">
          <div className="form">
            <div
              id={generateID('div-material-stepper', pathname)}
              className="material-progress"
            >
              <MaterialStepper step={2} value={68} />
            </div>

            <div className="form-content">
              <Chat
                id={generateID('chat-loading', pathname)}
                mode="loading"
                loading={loading}
              >
                {!loading && question && <div>{t(question.question_desc)}</div>}
              </Chat>
            </div>

            {!loading && question && (
              <div
                id={generateID('div-actions', pathname)}
                className="form-actions vertical"
              >
                <div
                  id={generateID('div-answer', pathname)}
                  className="button-select-wrapper"
                >
                  <ButtonSelect
                    options={orderBy(question.choices, 'position', 'asc').map(
                      (item) => ({
                        label: t(item.option),
                        value: item.id,
                      })
                    )}
                    onChange={this.handleNext}
                  />
                </div>

                <BackButton
                  id={generateID('btn-back', pathname)}
                  onClick={this.handleBack}
                />
              </div>
            )}
          </div>
        </StyledQualifyPage>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui }) => ({
  user: data,
  theme: ui.theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      answerQualifyQuestion,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(QualifyPage)
);
