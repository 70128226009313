import get from 'lodash/get';
import moment from 'moment';
import { parseFullName } from 'parse-full-name';
import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import { mainTimezones, mainTimezonesEsLocale } from 'common/data';
import { getLocalStorageItem } from './localStorage';

export const noop = () => {};

export const escapeRegexCharacters = (str) =>
  str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const getViewportSize = () => {
  const size = { width: 0, height: 0 };
  const webkit = !(window.webkitConvertPointFromNodeToPage == null);

  if (webkit) {
    const vpwtest = document.createElement('div');
    vpwtest.style.cssText =
      'position: fixed; height: 100%; width:100% !important; margin:0 !important; padding:0 !important; border:none !important;';
    document.documentElement.insertBefore(
      vpwtest,
      document.documentElement.firstChild
    );
    size.width = vpwtest.offsetWidth;
    size.height = vpwtest.offsetHeight;
    document.documentElement.removeChild(vpwtest);
  } else if (window.innerWidth === undefined) {
    size.width = document.documentElement.clientWidth;
    size.height = document.documentElement.clientHeight;
  } else {
    size.width = window.innerWidth;
    size.height = window.innerHeight;
  }

  return size;
};

export const getFontSize = () => {
  const size = getViewportSize();
  let fontSize = 16;
  if (size.width < 375) {
    fontSize = 14;
  } else if (size.width < 520) {
    fontSize = 16;
  } else if (size.width < 625) {
    fontSize = 16;
  }
  return fontSize;
};

export const formatNumber = (x) => {
  const parts = (x || 0).toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const getLastMonths = (count = 4) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const currentMonth = new Date().getMonth();
  const lastMonths = [];
  for (let i = 0; i < count; i++) {
    let index = currentMonth - i - 1;
    if (index < 0) {
      index += 12;
    }
    lastMonths.push({ name: `file${i + 1}`, label: months[index] });
  }
  return lastMonths;
};

export const getNames = (str) => {
  return parseFullName(str);
};

export const queryToJson = (str) => {
  try {
    return Object.fromEntries(new URLSearchParams(str));
  } catch (err) {
    return null;
  }
};

export const extractHostname = (url) => {
  let hostname;

  if (url.indexOf('//') > -1) {
    const parts = url.split('/');
    hostname = `${parts[0]}//${parts[2]}`;
  } else {
    [hostname] = url.split('/');
  }

  [hostname] = hostname.split('?');

  return hostname;
};

export const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const scrollToTop = (yPos = 0) => {
  if (document && document.body) {
    document.body.scrollLeft = 0;
    document.body.scrollTop = yPos;
    document.body.scrollIntoView(true);
  }
  if (document && document.documentElement) {
    document.documentElement.scrollTop = yPos;
  }
  if (window && window.pageYOffset) {
    window.pageXOffset = 0;
    window.pageYOffset = yPos;
  }
};

export const addScript = (data, isUrl = true, async = false, defer = false) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    if (isUrl) {
      script.src = data;
      script.async = async;
      script.defer = defer;
    } else {
      script.innerHTML = data;
    }
    script.addEventListener('load', () => {
      resolve();
    });
    script.addEventListener('error', (e) => {
      reject(e);
    });
    document.body.appendChild(script);
  });
};

export const measureText = (pText, pFontSize = 16, fontFamily, pStyle) => {
  let lDiv = document.createElement('div');

  document.body.appendChild(lDiv);

  if (pStyle != null) {
    lDiv.style = pStyle;
  }
  lDiv.style.fontSize = `${pFontSize}px`;
  lDiv.style.position = 'absolute';
  lDiv.style.fontFamily = `${fontFamily}, Arial`;
  lDiv.style.left = -1000;
  lDiv.style.top = -1000;

  lDiv.innerHTML = pText;

  const lResult = {
    width: lDiv.clientWidth,
    height: lDiv.clientHeight,
  };

  document.body.removeChild(lDiv);
  lDiv = null;

  return lResult;
};

export const long2tile = (lon, zoom) => {
  return ((lon + 180) / 360) * 2 ** zoom;
};

export const lat2tile = (lat, zoom) => {
  return (
    ((1 -
      Math.log(
        Math.tan((lat * Math.PI) / 180) + 1 / Math.cos((lat * Math.PI) / 180)
      ) /
        Math.PI) /
      2) *
    2 ** zoom
  );
};

export const generateTiles = (lat, lng, zoom = 19) => ({
  xtile: long2tile(lng, zoom),
  ytile: lat2tile(lat, zoom),
});

export const generateTileImages = (lat, lng) => {
  const images = [];
  const zoom = 20;
  const TILE_WIDTH = 256;
  const tiles = generateTiles(lat, lng, zoom);
  const xtile = Math.floor(tiles.xtile);
  const ytile = Math.floor(tiles.ytile);
  const pinX = (tiles.xtile - xtile) * TILE_WIDTH;
  const pinY = (tiles.ytile - ytile) * TILE_WIDTH;
  for (let y = ytile - 1; y <= ytile + 1; y++) {
    for (let x = xtile - 1; x <= xtile + 1; x++) {
      images.push(
        `https://storage.googleapis.com/solar_tiles_all_2018q2/tile_${x}_${y}_${zoom}.png`
      );
    }
  }
  return { images, pinX, pinY };
};

export const generateSolarMaps = (lat, lng, zoom) => {
  const images = [];
  const TILE_WIDTH = 256;
  const tiles = generateTiles(lat, lng, zoom);
  let xtile = Math.floor(tiles.xtile);
  let ytile = Math.floor(tiles.ytile);
  let deltaX = tiles.xtile - xtile;
  let deltaY = tiles.ytile - ytile;

  const pinX = deltaX * TILE_WIDTH;
  const pinY = deltaY * TILE_WIDTH;
  let centerX = 0;
  let centerY = 0;

  if (deltaX <= 0.5 && deltaY <= 0.5) {
    xtile--;
    ytile--;
    centerX--;
    centerY--;
  } else if (deltaX > 0.5 && deltaY <= 0.5) {
    centerY--;
    ytile--;
    deltaX -= 1;
  } else if (deltaX <= 0.5 && deltaY > 0.5) {
    centerX--;
    xtile--;
    deltaY -= 1;
  } else {
    deltaX -= 1;
    deltaY -= 1;
  }
  for (let y = 0; y <= 1; y++) {
    for (let x = 0; x <= 1; x++) {
      images.push(
        `https://storage.googleapis.com/solar-tiles/existing2019Q1_${
          xtile + x
        }_${ytile + y}_${zoom}.png`
      );
    }
  }
  return { images, pinX, pinY, centerX, centerY, deltaX, deltaY };
};

export const checkIfImageIsLoaded = async (images = []) => {
  const promises = [];
  images.forEach((item) => {
    promises.push(
      new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          resolve(true);
        };
        img.onerror = () => {
          resolve(true);
        };
        img.src = item;
      })
    );
  });
  return Promise.all(promises);
};

export const checkIfSunroofIsLoaded = async (lat, lng) => {
  const { images } = generateTileImages(lat, lng);
  return checkIfImageIsLoaded(images);
};

export const findClosestFinancialDataIndex = (financialAnalyses, avg_bill) => {
  let minId = -1;

  if (financialAnalyses && financialAnalyses.length > 0) {
    // find closes value
    let minDiff = Number.POSITIVE_INFINITY;
    financialAnalyses.forEach((item, index) => {
      if (
        get(item, 'monthlyBill.units') &&
        Math.abs(Number(get(item, 'monthlyBill.units')) - avg_bill) < minDiff
      ) {
        minDiff = Math.abs(Number(get(item, 'monthlyBill.units')) - avg_bill);
        minId = index;
      }
    });
  }

  return minId;
};

const htmlFontSize = 16;
export const pxToRem = (val) => `${val / htmlFontSize}rem`;

export const extractValuesFromPlace = (place, api = 'google-address') => {
  // extract country, state, address, zip_code, rooftop_latitude, rooftop_longitude from Google place object
  const values = {};
  place.address_components.forEach((item) => {
    if (item.types.indexOf('street_number') > -1) {
      values.address = item.short_name;
    }
    if (item.types.indexOf('route') > -1) {
      if (!values.address) {
        values.address = item.long_name;
      } else {
        values.address = `${values.address} ${item.long_name}`;
      }
    }
    if (values.city === undefined && item.types.indexOf('neighborhood') > -1) {
      values.city = item.long_name;
    }
    if (
      item.types.indexOf('locality') > -1 ||
      item.types.indexOf('administrative_area_level_3') > -1
    ) {
      values.city = item.long_name;
    }
    if (item.types.indexOf('administrative_area_level_1') > -1) {
      values.state = item.short_name;
    }
    if (item.types.indexOf('country') > -1) {
      values.country = item.short_name;
    }
    if (item.types.indexOf('postal_code') > -1) {
      values.zip_code = item.short_name;
    }
  });
  values.rooftop_latitude =
    api === 'google-address'
      ? Math.round(place.geometry.location.lat() * 10 ** 11) / 10 ** 11
      : place.geometry.location.lat;
  values.rooftop_longitude =
    api === 'google-address'
      ? Math.round(place.geometry.location.lng() * 10 ** 11) / 10 ** 11
      : place.geometry.location.lng;
  if (!values.zip_code) {
    values.zip_code = '';
  }

  values.place_latitude = place.geometry.location.lat();
  values.place_longitude = place.geometry.location.lng();
  return values;
};

export const checkOtherGoogleAPIExist = (api) => {
  const scriptsElements = document.getElementsByTagName('script');
  let jsGoogleMapApiScript = null;
  for (let i = 0; i < scriptsElements.length; i++) {
    if (scriptsElements[i] !== null && scriptsElements[i].src.includes(api))
      jsGoogleMapApiScript = scriptsElements[i];
  }
  return jsGoogleMapApiScript;
};

const callbacks = [];
export const loadGoogleMaps = (callback) => {
  const existingScript = document.getElementById('googleMaps');

  const execute = () => {
    callbacks.forEach((c) => c());
    callbacks.length = 0;
  };

  callbacks.push(callback);

  const existingOtherScript = checkOtherGoogleAPIExist(
    'https://maps.googleapis.com/maps/api/js'
  );
  if (existingOtherScript !== null) {
    existingOtherScript.setAttribute('id', 'googleMaps');
    if (!existingOtherScript.src.includes('&callback=googleMapsCallback')) {
      existingOtherScript.src += '&callback=googleMapsCallback';
    }
    window.googleMapsCallback = execute;
    return;
  }

  if (!existingScript) {
    const url = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=places&callback=googleMapsCallback`;

    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.defer = true;
    script.id = 'googleMaps';
    document.body.appendChild(script);

    window.googleMapsCallback = execute;
  }
  if (existingScript && window.google) {
    execute();
  }
};

const cloneScript = (script) => {
  const s = document.createElement('script');

  // copy attributes
  const attrs = script.attributes;
  for (let i = 0; i < attrs.length; i++) {
    s.setAttribute(attrs[i].name, attrs[i].value);
  }

  // copy inner text of script
  s.text = script.text;

  return s;
};

export const addCustomScripts = (custom_tag) => {
  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(custom_tag, 'text/html');
    const collection = doc.head.children;
    Array.from(collection)
      .map((script) => cloneScript(script))
      .forEach((script) => document.body.appendChild(script));
  } catch (e) {
    console.error(e);
  }
};

// export const fireCustomScripts = (lead_status, theme) => {
//   if (!lead_status || !theme) return false;
//   let custom_code = null;
//   switch (lead_status) {
//     case leadStatusVisitor:
//       custom_code = theme.custom_code_visitor;
//       break;
//     case leadStatusLead:
//       custom_code = theme.custom_code_lead;
//       break;
//     case leadStatusDisqualifiedLead:
//       custom_code = theme.custom_code_disqualified_lead;
//       break;
//     case leadStatusQualifiedLead:
//       custom_code = theme.custom_code_qualified_lead;
//       break;
//     case leadStatusAppointment:
//       custom_code = theme.custom_code_appointment;
//       break;
//     case leadStatusAppointmentWithBill:
//       custom_code = theme.custom_code_utility_bill;
//       break;
//     default:
//   }
//   if (custom_code) {
//     addCustomScripts(custom_code);
//     return true;
//   }
//   return false;
// };

export const getAvailableDays = (events, tz = 'UTC') => {
  const evnts = cloneDeep(events);
  const days = new Array(7).fill(false);

  evnts.forEach((event) => {
    const start = moment.tz(event.start, tz).day();
    const end = moment.tz(event.start, tz).day();
    days.fill(true, start, end === start ? end + 1 : end);
  });

  return days;
};

export const getDefaultTimezone = () => {
  const appLanguage = getLocalStorageItem('i18nextLng');
  const timezones =
    appLanguage === 'es'
      ? mainTimezonesEsLocale.map((t) => ({
          ...t,
          offset: moment.tz.zone(t.value).utcOffset(new Date()),
        }))
      : mainTimezones.map((t) => ({
          ...t,
          offset: moment.tz.zone(t.value).utcOffset(new Date()),
        }));

  const offset = moment.tz.zone(moment.tz.guess(true)).utcOffset(new Date());

  let timezone = timezones.filter((t) => t.offset === offset)[0];
  if (!timezone) {
    // eslint-disable-next-line prefer-destructuring
    timezone = timezones[3];
  }
  timezone = timezone.value;

  return timezone;
};

export const changeDateOfEvents = (events, current, tz = 'UTC') => {
  const evnts = cloneDeep(events);
  if (evnts.length === 0) return [];
  const locale = getLocalStorageItem('i18nextLng');

  const diff =
    locale === 'es'
      ? moment
          .tz(current, tz)
          .add('1', 'days')
          .startOf('week')
          .diff(moment.tz(evnts[0].start, tz).add('1', 'days').startOf('week'))
      : moment
          .tz(current, tz)
          .startOf('week')
          .diff(moment.tz(evnts[0].start, tz).startOf('week'));

  return evnts.map((event) => ({
    ...event,
    start: moment.tz(event.start, tz).add(diff, 'milliseconds').format(),
    end: moment.tz(event.end, tz).add(diff, 'milliseconds').format(),
  }));
};

export const getTheNearestAvailableDate = (events, currentMoment, timezone) => {
  const evnts = cloneDeep(events);
  // find the first event that end date is later than now
  if (evnts.length === 0) return currentMoment.add('100', 'years').tz(timezone);

  const events1 = changeDateOfEvents(evnts, currentMoment, timezone);
  const t = events1.find((event) => currentMoment.diff(moment(event.end)) < 0);
  if (t) {
    return moment(t.start).diff(currentMoment) > 0
      ? moment(t.start).tz(timezone)
      : currentMoment.tz(timezone);
  }

  return moment(events1[0].start).add(1, 'week').tz(timezone);
};

export const getTheAvailableTimeFrames = (events, currentEvent, timezone) => {
  const t = [];
  const evnts = cloneDeep(events);
  if (evnts.length === 0) return [];

  const events1 = changeDateOfEvents(evnts, currentEvent.start, timezone);

  events1.forEach((event) => {
    const tt = {
      start: moment
        .max(
          moment.tz(event.start, timezone),
          moment.tz(currentEvent.start, timezone)
        )
        .format(),
      end: moment
        .min(
          moment.tz(event.end, timezone),
          moment.tz(currentEvent.end, timezone)
        )
        .format(),
    };

    if (moment(tt.start).isBefore(moment(tt.end))) t.push(tt);
  });

  return t;
};

export const roundDate = (d) => {
  const remainder = 30 - (moment(d).minute() % 30);

  return moment(d).add(remainder, 'minutes');
};

export const lsTest = () => {
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

export const getVariation = () => {
  let variation = null;
  let lang = null;
  const query = new URLSearchParams(window.location.search);

  if (!_.isEmpty(query.get('app_url'))) {
    const app = query.get('app_url');
    const incomingUrl = new URLSearchParams(new URL(app).search);
    variation = incomingUrl.get('variation');
    lang = incomingUrl.get('lang');
  }

  if (!variation) {
    if (!_.isEmpty(query.get('variation'))) {
      variation = query.get('variation');
    }
  }

  if (/^es\b/.test(navigator.language)) {
    variation = 'demand-iq-spanish-variation';
  }

  if (!lang) {
    if (!_.isEmpty(query.get('lang'))) {
      lang = query.get('lang');
    }
  }

  if (lang && /^es\b/.test(lang)) {
    variation = 'demand-iq-spanish-variation';
  }

  return variation || 'current';
};

export const filterQuestionsByLanguage = (questions) => {
  let language = getLocalStorageItem('i18nextLng');

  if (language && language.startsWith('es')) {
    language = 'es';
  } else {
    language = 'en';
  }
  return questions.filter(({ lang }) => lang === language);
};

export const getQuestionsCount = (theme) => {
  const { chosen_questions, is_project_detail_referral_on } = theme;
  const filteredQuestions = filterQuestionsByLanguage(chosen_questions);
  let questions_count = filteredQuestions.length + 2;
  if (is_project_detail_referral_on) questions_count++;

  return questions_count;
};

export const getOS = () => {
  const { userAgent } = window.navigator;
  const platform =
    window.navigator?.userAgentData?.platform ?? window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
};

export const generateID = (id, pathName = '', prefix = 'demand-iq-stella') => {
  if (pathName === null || pathName === undefined || pathName === '')
    console.error('pathname is empty!');

  let step = pathName ?? '';
  if (step.length > 0 && step[0] === '/') {
    step = step.substring(1).replaceAll('/', '-');
  }

  return `${prefix}_${step}_${id}`;
};
