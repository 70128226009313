import styled from 'styled-components';

const Result = styled.div`
  &.sungage-result {
    display: flex;
    flex-direction: column;
    text-align: center;

    .sungage-result-title {
      font-size: 1.875rem;
      line-height: 1.5625;
    }

    .sungage-result-content {
      font-size: 1.375rem;
      line-height: 1.5625;
    }
  }
`;

export default Result;
