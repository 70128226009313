import styled from 'styled-components';
import { pxToRem } from 'common/utils';

const Start = styled.div`
  &.content.start-page {
    padding: 0;

    .form {
      max-width: initial;
    }
  }

  .form-content {
    display: flex;
    flex-direction: column;

    .map-section {
      display: flex;
      flex-direction: column;
      position: relative;

      .map-block {
        background-image: url('${(props) => props.theme.hero_image_desktop}');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding-top: 30%;
        position: relative;

        @media screen and (max-height: 850px) {
          padding-top: 26%;
        }

        @media screen and (max-width: 767px) {
          background-image: url('${(props) => props.theme.hero_image_mobile}');
          padding-top: 58%;
        }

        @media screen and (max-width: 767px) and (min-height: 651px) and (max-height: 850px) {
          background-image: url('${(props) => props.theme.hero_image_mobile}');
          padding-top: 36%;
        }

        @media screen and (max-width: 467px) and (max-height: 850px) {
          background-image: url('${(props) => props.theme.hero_image_mobile}');
          padding-top: 40%;
        }
        @media screen and (max-width: 467px) and (max-height: 650px) {
          background-image: url('${(props) => props.theme.hero_image_mobile}');
          padding-top: 35%;
        }

        @media screen and (max-width: 375px) {
          background-image: url('${(props) => props.theme.hero_image_mobile}');
          padding-top: 46%;
        }

        @media screen and (max-width: 375px) and (max-height: 650px) {
          background-image: url('${(props) => props.theme.hero_image_mobile}');
          padding-top: 36%;
        }

        @media screen and (max-width: 375px) and (max-height: 550px) {
          background-image: url('${(props) => props.theme.hero_image_mobile}');
          padding-top: 24%;
        }
      }

      .avatar {
        position: absolute;
        width: ${pxToRem(166)};
        height: ${pxToRem(166)};
        bottom: ${pxToRem(-106)};
        box-sizing: border-box;
        border-radius: 50%;
        background-color: white;
        margin: 0;
        padding: 0;
        left: 50%;
        transform: translate(-50%, 0);
        border: 15px solid #f9f9f9;
        background-size: cover;
        background-position: center;

        .avatar-status {
          position: absolute;
          width: ${pxToRem(38)};
          height: ${pxToRem(38)};
          right: ${pxToRem(-8)};
          bottom: ${pxToRem(2)};
          background: #1bff00;
          border: 8px solid #f9f9f9;
          border-radius: 50%;
        }
      }
    }

    .ai-section-wrapper {
      display: flex;
      justify-content: center;
      flex: 1;
    }

    .ai-section {
      display: flex;
      padding: ${pxToRem(120)} 1.75rem 1rem;
      flex-direction: column;
      flex: 1;

      @media screen and (min-width: 768px) {
        max-width: ${pxToRem(555)};
      }

      .ai-great {
        font-size: ${pxToRem(20)};
        line-height: 100%;
        text-align: center;
        color: #231f20;
      }

      .ai-description {
        font-family: 'Effra Light';
        font-size: ${pxToRem(17)};
        line-height: ${pxToRem(22)};
        text-align: center;
        padding: ${pxToRem(18)} ${pxToRem(30)} ${pxToRem(18)};
      }

      .power-text {
        flex: 1;
        align-self: flex-end;
        position: relative;
        right: 3px;
        display: flex;
        font-size: 12px;

        .powered-by {
          margin-top: 17px;
          font-family: 'Effra Light';
          line-height: 14px;
        }
      }
    }
  }

  .form-actions {
    padding: 0 1.75rem 1rem;
    width: 100%;

    @media screen and (min-width: 768px) {
      max-width: ${pxToRem(555)};
      align-self: center;
    }
  }
`;

export default Start;
