import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from 'components/Button';
import TextInput from 'components/Forms/TextInputNRGVariation';
import BackButton from 'components/BackButtonNRGVariation';

import {
  setUserData,
  saveSession,
  navigateTo,
  trackForwardProgress,
} from 'store/actions';
import { withTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { getLocalStorageItem } from 'common/localStorage';
import { VARIATION_OPTIONS } from 'pages/constants';
import { verifyPhoneNumber, verifyEmail } from 'common/api.mock';
import FixedBottomBar from 'components/FixedBottomBar';
import { generateID } from 'common/utils';
import StyledCommercial2, { Legal } from './styled';

const formSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  zip_code: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
});

const CustomInput = ({ ...props }) => (
  <InputMask {...props}>{() => <TextInput {...props} />}</InputMask>
);

class Commercial2 extends Component {
  constructor(props) {
    super(props);

    const variation = getLocalStorageItem('variation');
    this.state = {
      jornaya_lead_id: window.jornaya_leadid_token,
      variation: VARIATION_OPTIONS[variation],
      errors: {},
    };
  }

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleNext = async (values) => {
    const { errors } = this.state;
    const { t } = this.props;
    let { phone, email } = values;
    phone = phone.trim();
    email = email.trim();
    const [phoneRes, emailRes] = await Promise.all([
      verifyPhoneNumber(phone),
      verifyEmail(email),
    ]);
    let phoneError = null;
    let emailError = null;

    phoneError =
      !phoneRes || !phoneRes.success
        ? t('That number does not seem to be a valid mobile phone number.')
        : null;

    emailError =
      !emailRes || !emailRes.success
        ? t('That email does not seem to be a valid email.')
        : null;

    this.setState({
      errors: {
        ...errors,
        phone: phoneError,
        email: emailError,
      },
    });
    if (!phoneError && !emailError) {
      const { actions } = this.props;
      const { jornaya_lead_id } = this.state;
      actions.setUserData({
        ...values,
        jornaya_lead_id,
        trustedform_cert_url: window.trustedForm.id
          ? `https://cert.trustedform.com/${window.trustedForm.id}`
          : '',
        is_commercial: true,
        step: '/',
      });
      await actions.saveSession();
      actions.trackForwardProgress();
      await actions.navigateTo('/commercial3', false);
    }
  };

  render() {
    const { user, theme, t } = this.props;
    const { variation, errors } = this.state;

    const {
      location: { pathname },
    } = this.props;

    return (
      <>
        <variation.header />
        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <Formik
              initialValues={{
                first_name: user.first_name || '',
                last_name: user.last_name || '',
                phone: user.phone || '',
                email: user.email || '',
                address: user.address || '',
                city: user.city || '',
                state: user.state || '',
                zip_code: user.zip_code || '',
              }}
              validationSchema={formSchema}
              onSubmit={this.handleNext}
              validateOnMount
            >
              {({ isValid, isSubmitting, submitForm }) => {
                return (
                  <>
                    <StyledCommercial2 className="content commercial-2-page">
                      <Form className="form">
                        <div
                          id={generateID('div-form', pathname)}
                          className="form-content"
                        >
                          <div className="form-title mb-16">
                            {t('Get a non-residential quote!')}
                          </div>
                          <div className="form-subtext mb-24">
                            {t(
                              'Fill out the form below to speak with one of commercial solar specialists.'
                            )}
                          </div>
                          <div className="card">
                            <div className="mb-16">
                              <Field
                                id={generateID('txt-first-name', pathname)}
                                name="first_name"
                                placeholder={t('First Name')}
                                theme={theme}
                                component={TextInput}
                                autoFocus
                                fullWidth
                              />
                            </div>
                            <div className="mb-16">
                              <Field
                                id={generateID('txt-last-name', pathname)}
                                name="last_name"
                                placeholder={t('Last Name')}
                                theme={theme}
                                component={TextInput}
                                fullWidth
                              />
                            </div>
                            <div className="mb-16">
                              <Field>
                                {({ field, form }) => (
                                  <CustomInput
                                    id={generateID('txt-phone', pathname)}
                                    mask="(999) 999-9999"
                                    name="phone"
                                    type="tel"
                                    placeholder={t('Mobile phone number')}
                                    theme={theme}
                                    value={field.value.phone}
                                    onChange={field.onChange}
                                    field={field}
                                    form={form}
                                    error={Boolean(errors.phone)}
                                    helperText={
                                      errors.phone ? errors.phone : ''
                                    }
                                    fullWidth
                                  />
                                )}
                              </Field>
                            </div>
                            <div className="mb-16">
                              <Field
                                id={generateID('txt-email', pathname)}
                                name="email"
                                placeholder={t('Work Email')}
                                theme={theme}
                                component={TextInput}
                                error={Boolean(errors.email)}
                                helperText={errors.email ? errors.email : ''}
                                fullWidth
                              />
                            </div>
                            <div className="mb-16">
                              <Field
                                id={generateID('txt-address', pathname)}
                                name="address"
                                placeholder={t('Address')}
                                theme={theme}
                                component={TextInput}
                                fullWidth
                              />
                            </div>
                            <div className="mb-16">
                              <Field
                                id={generateID('txt-city', pathname)}
                                name="city"
                                placeholder={t('City')}
                                theme={theme}
                                component={TextInput}
                                fullWidth
                              />
                            </div>
                            <div className="mb-16">
                              <Field
                                id={generateID('txt-state', pathname)}
                                name="state"
                                placeholder={t('State')}
                                theme={theme}
                                component={TextInput}
                                fullWidth
                              />
                            </div>
                            <div>
                              <Field
                                id={generateID('txt-zip-code', pathname)}
                                name="zip_code"
                                placeholder={t('Zip code')}
                                theme={theme}
                                component={TextInput}
                                fullWidth
                              />
                            </div>
                          </div>

                          <Legal
                            id={generateID('div-legal', pathname)}
                            className="form-legal"
                          >
                            <label>
                              <input
                                type="hidden"
                                id="leadid_tcpa_disclosure"
                              />
                              {theme.legal}
                            </label>
                          </Legal>
                        </div>

                        <div
                          id={generateID('div-actions', pathname)}
                          className="form-actions vertical"
                        >
                          <BackButton
                            id={generateID('btn-back', pathname)}
                            onClick={this.handleBack}
                          />
                        </div>
                      </Form>
                    </StyledCommercial2>

                    <FixedBottomBar
                      id={generateID('div-fixed-bottom-bar', pathname)}
                    >
                      <Button
                        id={generateID('btn-get-a-quote', pathname)}
                        handleClick={submitForm}
                        type="submit"
                        full
                        disabled={!isValid || isSubmitting}
                      >
                        {t('Get a quote')}
                      </Button>
                    </FixedBottomBar>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui: { theme } }) => ({
  user: data,
  theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Commercial2)
);
