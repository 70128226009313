import styled from 'styled-components';

const UploadBills = styled.div`
  .form-title {
    font-family: StellaFont, Arial, Helvetica, sans-serif;
    font-size: 1.875rem;
    color: ${(props) => props.theme.color_scheme_dark};
  }

  .form-subtext {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 1rem;
    color: #666;
  }

  .camera-btn {
    position: relative;

    svg {
      position: absolute;
      left: 32px;
    }
  }

  .fieldset {
    padding: 1.25rem;
    background-color: ${(props) => props.theme.color_scheme_neutral};
  }
`;

export default UploadBills;
