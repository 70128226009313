import styled from 'styled-components';

const Footer = styled.div`
  background-color: #eaeaea;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  color: #666666;
  text-align: center;
  width: 100%;
  line-height: 1rem;

  .client-review {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.color_scheme_dark};
    padding: 0.375rem 0;

    .google-icon {
      width: 42px;
      height: 42px;
      margin-right: 0.625rem;
    }

    .read-reviews {
      cursor: pointer;
      font-size: 0.875rem;
      font-weight: 500;
      background-color: #fff;
      color: ${(props) => props.theme.color_scheme_dark};
      border-radius: 30px;
      margin-left: 0.625rem;
      padding: 0.375rem 0.75rem;
    }
  }

  @media screen and (min-width: 768px) {
    &.no-legal {
      margin-top: 3rem;
    }
  }

  .privacy {
    font-size: 0.625rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    a,
    span {
      color: inherit;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const Legal = styled.div`
  font-size: 0.5625rem;
  color: #666666;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  margin: 1.25rem;
  text-align: justify;

  @media screen and (min-width: 768px) {
    font-size: 0.75rem;
  }
`;

export default Footer;
