import React, { Component } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import history from 'common/history';
import { navigateTo } from 'store/actions';
import { getProposalKey, getPreviewPageName } from 'common/api.mock';
import { PageView } from 'components/PageView';
import ResetHomeAddress from 'pages/shared/ResetHomeAddressNRGVariation';
import { getLocalStorageItem } from 'common/localStorage';
import Start from '../../shared/StartNRGVariation';
import Calculate12 from '../../shared/Calculate12NRGVariation';
import Calculate2 from '../../shared/Calculate2NRGVariation';
import Calculate3 from '../../shared/Calculate3NRGVariation';
import Result1 from '../../shared/Result1NRGVariation';
import Result2 from '../../shared/Result2NRGVariation';
import Result3 from '../../shared/Result3NRGVariation';
import Qualify9 from '../../shared/Qualify9NRGVariation';
import Meeting1 from '../../shared/Meeting1NRGVariation';
import Meeting2 from '../../shared/Meeting2NRGVariation';
import Thankyou from '../../shared/ThankyouNRGVariation';
import Calculate21 from '../../shared/Calculate21NRGVariation';
import Commercial2 from '../../shared/Commercial2NRGVariation';
import Commercial3 from '../../shared/Commercial3NRGVariation';
import GetNotified1 from '../../shared/GetNotified1NRGVariation';
import GetNotified2 from '../../shared/GetNotified2NRGVariation';
import QualifyPage from '../../shared/QualifyPageNRGVariation';
import InactiveCompany from '../../shared/InactiveCompanyNRGVariation';
import DisqualifyPage from '../../shared/DisqualifyPageNRGVariation';
import Proposals from '../../shared/ProposalsNRGVariation';
import QualifyStart from '../../shared/QualifyStartNRGVariation';
import Retry from '../../shared/RetryNRGVariation';
import PhoneVerify from '../../shared/PhoneVerifyNRGVariation';

import ValidateZipCode from '../../shared/ValidateZipCodeNRGVariation';
import { Pages as StyledVariation } from './styled';

class NRGVariation extends Component {
  constructor(props) {
    super(props);
    const { theme, user, actions } = props;
    const previewPage = getPreviewPageName() ? `/${getPreviewPageName()}` : '';
    if (getProposalKey()) {
      actions.navigateTo('/proposals', false);
    } else if (theme && theme.status && user) {
      if (previewPage) {
        actions.navigateTo(previewPage);
      } else if (
        (user.variation || 'current') !== getLocalStorageItem('variation')
      ) {
        actions.navigateTo(user.is_instant_estimate ? '/result3' : '/start');
      } else {
        actions.navigateTo(
          user.step || (user.is_instant_estimate ? '/result3' : '/start')
        );
      }
    } else {
      actions.navigateTo('/inactive-company', false);
    }
  }

  render() {
    const { user } = this.props;

    return (
      <StyledVariation>
        <Router history={history}>
          <PageView />
          <Switch>
            <Route exact path="/start" component={Start} />
            <Route exact path="/validatezipcode" component={ValidateZipCode} />
            <Route exact path="/getnotified1" component={GetNotified1} />
            <Route exact path="/getnotified2" component={GetNotified2} />
            <Route exact path="/calculate12" component={Calculate12} />
            <Route exact path="/calculate2" component={Calculate2} />
            <Route exact path="/calculate21" component={Calculate21} />
            <Route exact path="/commercial2" component={Commercial2} />
            <Route exact path="/commercial3" component={Commercial3} />
            <Route exact path="/calculate3" component={Calculate3} />
            <Route exact path="/result1" component={Result1} />
            <Route exact path="/result2" component={Result2} />
            <Route exact path="/result3" component={Result3} />
            <Route exact path="/qualify/start" component={QualifyStart} />
            <Route exact path="/qualify/end" component={Qualify9} />
            <Route exact path="/qualify/:id" component={QualifyPage} />
            <Route exact path="/disqualify" component={DisqualifyPage} />
            <Route exact path="/meeting1" component={Meeting1} />
            <Route exact path="/meeting2" component={Meeting2} />
            <Route exact path="/thankyou" component={Thankyou} />
            <Route exact path="/inactive-company" component={InactiveCompany} />
            <Route exact path="/proposals" component={Proposals} />
            <Route exact path="/retry" component={Retry} />
            <Route exact path="/phoneverify" component={PhoneVerify} />
            <Route exact path="/resethome" component={ResetHomeAddress} />
            <Redirect to={user.is_instant_estimate ? '/result3' : '/start'} />
          </Switch>
        </Router>
      </StyledVariation>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui: { theme } }) => ({
  user: data,
  theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      navigateTo,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(NRGVariation);
