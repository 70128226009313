import styled from 'styled-components';
import { pxToRem } from 'common/utils';

export const Sunlight3 = styled.div`
  &.content.sunlight3-page {
    min-height: calc(100% - ${pxToRem(76)});

    @media screen and (max-width: 374px) {
      min-height: calc(100% - 61px);
    }

    @media screen and (min-width: 767px) {
      min-height: calc(100% - ${pxToRem(76)} - 3rem);
    }

    .description {
      font-size: 0.6875rem;
      color: #666;
      font-family: Roboto, Arial, Helvetica, sans-serif;
      line-height: 1.25;
    }

    .eSign-disclosure {
      text-decoration: underline;
      cursor: pointer;
      color: #666;
    }
  }

  .sunlight-eConsent-modal {
    height: calc(100% - 100px);
    max-width: 600px;
    padding: 0;

    .close-icon {
      top: 20px;
      right: 25px;
    }

    .sunlight-eConsent-modal-content {
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      padding: 2rem;

      h5 {
        font-size: 18px;
        p {
          color: #222;
          line-height: 24px;
        }
      }

      p, table {
        color: #666;
        line-height: 20px;
      }

      table {
        max-width: 450px;
        width: 100% !important;
        margin-bottom: 2rem;
      }
    }
  }
`;

export default Sunlight3;
