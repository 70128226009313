import styled from 'styled-components';

const QualifyPage = styled.div`
  .button-select-wrapper {
    .btn {
      margin-bottom: 1rem !important;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
`;

export default QualifyPage;
