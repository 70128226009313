import styled from 'styled-components';

const StyledMaterialStepper = styled.div`
  .step-label-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.625rem;

    .step-label {
      font-size: 0.75rem;
    }
  }

  .step-bar {
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background-color: #ccc;
  }

  .step-active-bar {
    width: 50%;
    height: 100%;
    border-radius: 5px;
    background-color: ${(props) => props.theme.color_scheme_font};
  }
`;

export default StyledMaterialStepper;
