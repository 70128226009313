import styled from 'styled-components';
import { pxToRem } from 'common/utils';

const StyledValidateZipCode = styled.div`
  .card {
    margin: 2.25rem auto;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    padding: 2.25rem 2rem;
  }

  &.content.validate-zipcode-page {
    min-height: calc(100% - ${pxToRem(76)});

    @media screen and (max-width: 374px) {
      min-height: calc(100% - 61px);
    }

    @media screen and (min-width: 767px) {
      min-height: calc(100% - ${pxToRem(76)} - 3rem);
    }

    .message {
      line-height: 30px;
    }
  }
`;

export default StyledValidateZipCode;
