import React from 'react';
import { Map, GoogleApiWrapper } from 'google-maps-react';
import { useTranslation } from 'react-i18next';

const LoadingContainer = () => {
  const [t] = useTranslation();
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div>{t('Loading...')}</div>
    </div>
  );
};

export const MapContainer = ({
  google,
  lat,
  lng,
  zoom,
  mapStyles,
  mapTypeControl = false,
  fullscreenControl = false,
  zoomControl = false,
  streetViewControl = false,
  rotateControl = false,
  saturation = true,
  onClick,
  mapType = 'roadmap',
  zoomControlOptions = null,
  streetViewControlOptions = null,
  children,
  draggable = true,
}) => {
  const styles = saturation
    ? [
        {
          featureType: 'all',
          elementType: 'all',
          stylers: [{ saturation: -100 }],
        },
      ]
    : [];

  return (
    <Map
      google={google}
      zoom={zoom}
      style={mapStyles}
      initialCenter={{
        lat,
        lng,
      }}
      center={{
        lat,
        lng,
      }}
      mapTypeControl={mapTypeControl}
      fullscreenControl={fullscreenControl}
      zoomControl={zoomControl}
      streetViewControl={streetViewControl}
      rotateControl={rotateControl}
      zoomControlOptions={zoomControlOptions}
      streetViewControlOptions={streetViewControlOptions}
      styles={styles}
      onClick={onClick}
      mapType={mapType}
      draggable={draggable}
    >
      {children}
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  LoadingContainer,
})(MapContainer);
