import styled from 'styled-components';

const Commercial2 = styled.div`
  &.content.commercial-2-page {
    @media screen and (min-width: 768px) {
      .form {
        max-width: initial;

        .form-content,
        .form-actions {
          max-width: 555px;
          width: 100%;
          margin: 0 auto;
        }
      }
    }
  }

  .card {
    margin: 2.25rem auto;
  }

  .form-legal {
    font-size: 0.5625rem;
    color: #666666;
    margin: 1.25rem 0;
    text-align: justify;

    @media screen and (min-width: 768px) {
      font-size: 0.75rem;
    }

    label {
      margin-bottom: 0;
    }
  }

  .form-title {
    font-size: 1.875rem;
    color: ${(props) => props.theme.color_scheme_dark};
  }

  .form-subtext {
    font-size: 1rem;
    line-height: 1.2;
    color: #666;
  }

  .form-actions {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const Legal = styled.div`
  &.form-legal {
    margin: 0 0 1.25rem 0;
    color: #666666;
    font-family: 'Effra Light';
    font-size: 12px;
    line-height: 20px;
    text-align: justify;

    @media screen and (max-width: 767px) {
      margin: 0 0 1.25rem 0;
      font-size: 12px;
      line-height: 20px;
    }

    label {
      margin-bottom: 0;
    }
  }
`;

export default Commercial2;
