import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from 'components/Button';
import MaterialStepper from 'components/MaterialStepper';
import Chat from 'components/Chat';
import ButtonSelect from 'components/ButtonSelect';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackForwardProgress,
} from 'store/actions';
import DefaultLayout from 'components/DefaultLayout';
import { withTranslation } from 'react-i18next';
import {
  filterQuestionsByLanguage,
  generateID,
  getQuestionsCount,
} from 'common/utils';
import StyledQualify8 from './styled';

class Qualify8Variation1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions_count: 0,
    };
  }

  componentDidMount() {
    this.initData();
  }

  initData = () => {
    const { theme } = this.props;

    this.setState({
      questions_count: getQuestionsCount(theme),
    });
  };

  handleBack = () => {
    const {
      actions,
      theme: { chosen_questions },
    } = this.props;
    const filteredQuestions = filterQuestionsByLanguage(chosen_questions);
    const go_step =
      filteredQuestions && filteredQuestions.length > 0
        ? `/qualify/${filteredQuestions.length}`
        : '/qualify/default1';
    actions.navigateTo(go_step);
  };

  handleNext = async (value) => {
    const {
      actions,
      theme: { is_project_detail_referral_on },
    } = this.props;
    const step = is_project_detail_referral_on
      ? '/qualify/default3'
      : '/result2';
    const go_step = is_project_detail_referral_on
      ? '/qualify/default3'
      : '/result2';

    actions.setUserData({
      timeframe: value,
      step,
    });
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo(go_step);
  };

  render() {
    const {
      theme: { is_project_detail_referral_on },
      t,
      user,
    } = this.props;
    const { questions_count } = this.state;

    const {
      location: { pathname },
    } = this.props;

    return (
      <DefaultLayout>
        <StyledQualify8 className="content qualify-8-page custom-stepper">
          <div className="form">
            <div
              id={generateID('div-material-stepper', pathname)}
              className="material-progress"
            >
              <MaterialStepper activeStep={1} variation={user.variation} />
            </div>

            <div className="form-content">
              <Chat id={generateID('chat-work-completed', pathname)}>
                <div className="question-number">
                  {t('QUESTION {{step}} OF {{questions_count}}', {
                    step: is_project_detail_referral_on
                      ? questions_count - 1
                      : questions_count,
                    questions_count,
                  })}
                </div>

                <div className="mb-16">
                  {t('When would you like the work completed?')}
                </div>

                <div
                  id={generateID('div-complete-time', pathname)}
                  className="mb-8"
                >
                  <ButtonSelect
                    options={[
                      {
                        label: t('asap'),
                        value: 'asap',
                      },
                      {
                        label: t('next 6 months'),
                        value: 'next 6 months',
                      },
                      {
                        label: t('still researching'),
                        value: 'still researching',
                      },
                    ]}
                    onChange={this.handleNext}
                  />
                </div>
              </Chat>
            </div>

            <div
              id={generateID('div-actions', pathname)}
              className="form-actions"
            >
              <Button
                id={generateID('btn-back', pathname)}
                outline
                borderless
                left
                onClick={this.handleBack}
                className="pl-0 pr-0"
              >
                <BackIcon />
                {t('Back')}
              </Button>
            </div>
          </div>
        </StyledQualify8>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui }) => ({
  user: data,
  theme: ui.theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Qualify8Variation1)
);
