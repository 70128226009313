import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from 'components/Button';
import Header from 'components/Sungage/Header';
import Result from 'components/Sungage/Result';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackForwardProgress,
  trackFacebookPixel,
  trackGoogleLead,
} from 'store/actions';
import { withTranslation } from 'react-i18next';
import { VARIATION_OPTIONS } from 'pages/constants';
import { getLocalStorageItem } from 'common/localStorage';
import { generateID } from 'common/utils';
import StyledSungageFailure from './styled';

class SungageFailure extends Component {
  constructor(props) {
    super(props);

    const variation = getLocalStorageItem('variation');

    this.state = {
      variation: VARIATION_OPTIONS[variation],
    };
  }

  handleClose = async () => {
    const { history, actions, user, theme } = this.props;

    let step;
    if (user.sungage?.prequalification_skipped) step = '/result3';
    else if (theme.is_calendly_on) step = '/meetingcalendly';
    else step = '/meeting1';

    actions.setUserData({ step });
    await actions.saveSession();

    history.replace(step);
  };

  handleNext = async () => {
    const { actions, theme, user } = this.props;

    let step;
    if (!user.sungage?.prequalification_skipped) {
      if (theme.is_calendly_on) step = '/meetingcalendly';
      else step = '/meeting1';
    } else step = '/qualify/start';

    actions.setUserData({ step });
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo(step);
  };

  render() {
    const { t, user } = this.props;
    const { variation } = this.state;

    const {
      location: { pathname },
    } = this.props;

    return (
      <>
        <variation.header />

        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <StyledSungageFailure className="content sungage-failure-page">
              <div id={generateID('div-header', pathname)} className="mb-32">
                <Header onCloseClick={this.handleClose} />
              </div>

              <div className="main-content">
                <div
                  id={generateID('div-result', pathname)}
                  className="mt-16 mb-32"
                >
                  <Result
                    title={t(
                      'We were unable to pre-qualify you using this method.'
                    )}
                    content={
                      !user.sungage?.prequalification_skipped
                        ? t(
                            'You still may qualify for zero down financing. Book a time to review your quote with a solar expert from our team over the phone.'
                          )
                        : t(
                            'You still may qualify for zero down financing. Let’s quickly verify your home qualifies for solar.'
                          )
                    }
                  />
                </div>

                <div
                  id={generateID('div-actions', pathname)}
                  className="form-actions"
                >
                  <Button
                    id={generateID('btn-book-or-qualify', pathname)}
                    full
                    onClick={this.handleNext}
                  >
                    {!user.sungage?.prequalification_skipped
                      ? t('Book Appointment')
                      : t('Qualify my home')}
                  </Button>
                </div>
              </div>
            </StyledSungageFailure>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user: { data, saving }, ui }) => ({
  user: data,
  theme: ui.theme,
  saving,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      trackFacebookPixel,
      trackGoogleLead,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(SungageFailure)
);
