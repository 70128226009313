import styled from 'styled-components';

const Expand = styled.div`
  .expand-block-title {
    font-size: 1.25rem;
    color: #666;
    display: flex;
    align-items: center;

    svg {
      margin-left: 1rem;
      cursor: pointer;
    }
  }

  .expand-block-body {
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: justify;
    color: #666;
    margin-top: 1rem;
  }
`;

export default Expand;
