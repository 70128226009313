import styled from 'styled-components';
import { pxToRem } from 'common/utils';

const Start = styled.div`
  &.content.start-page {
    padding: 0;

    .form {
      max-width: initial;
    }
  }

  .form-content {
    display: flex;
    flex-direction: column;

    .map-section {
      display: flex;
      flex-direction: column;
      position: relative;

      .map-block {
        background-image: url('${(props) => props.theme.hero_image_desktop}');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding-top: 25%;
        position: relative;

        @media screen and (max-width: 767px) {
          background-image: url('${(props) => props.theme.hero_image_mobile}');
          padding-top: 42%;
        }
      }

      .avatar {
        position: absolute;
        width: ${pxToRem(114)};
        height: ${pxToRem(114)};
        box-sizing: border-box;
        bottom: ${pxToRem(-33)};
        border-radius: 50%;
        background-color: white;
        margin: 0;
        padding: 0;
        left: 50%;
        transform: translate(-50%, 0);
        border: 5px solid ${(props) => props.theme.color_scheme_light};
        background-size: cover;
        background-position: center;

        .avatar-status {
          position: absolute;
          width: ${pxToRem(22.8)};
          height: ${pxToRem(22.8)};
          right: ${pxToRem(6)};
          bottom: ${pxToRem(6)};
          background: #64dd2a;
          border: 1px solid #fff;
          border-radius: 50%;

          @media screen and (max-width: 767px) {
            width: ${pxToRem(17)};
            height: ${pxToRem(17)};
            right: ${pxToRem(4)};
            bottom: ${pxToRem(4)};
          }
        }

        @media screen and (max-width: 767px) {
          width: ${pxToRem(87)};
          height: ${pxToRem(87)};
          bottom: ${pxToRem(-46)};
        }
      }
    }

    .ai-section {
      display: flex;
      padding: ${pxToRem(47)} 1rem 0;
      flex-direction: column;
      flex: 1;

      @media screen and (max-width: 767px) {
        padding: ${pxToRem(55)} 1rem 1rem;
      }

      @media screen and (max-width: 375px) {
        padding: ${pxToRem(55)} 0.5rem 1rem;
      }

      .ai-greeting {
        margin: 0 auto ${pxToRem(30)};
        width: 100%;
        max-width: ${pxToRem(480)};
        min-height: ${pxToRem(64)};
        font-style: normal;
        font-weight: normal;
        font-size: ${pxToRem(25)};
        line-height: ${pxToRem(32)};
        display: flex;
        align-items: center;
        text-align: center;

        @media screen and (max-width: 767px) {
          max-width: ${pxToRem(400)};
          margin: 0 auto ${pxToRem(20)};
          font-size: ${pxToRem(20)};
          line-height: ${pxToRem(28)};
        }

        @media screen and (max-width: 375px) {
          font-size: ${pxToRem(19)};
        }

        @media screen and (max-width: 320px) {
          font-size: ${pxToRem(18)};
        }
      }

      .chat {
        margin-bottom: ${pxToRem(10)};

        .chat-avatar {
          margin-right: 2rem;

          .avatar {
            width: 5.625rem;
            height: 5.625rem;
            padding: 0.3125rem;

            .avatar-img {
              width: 5rem;
              height: 5rem;
            }
          }

          @media screen and (max-width: 767px) {
            margin-right: 1rem;

            .avatar {
              width: 2.5rem;
              height: 2.5rem;
              padding: 0.25rem;

              .avatar-img {
                width: 2rem;
                height: 2rem;
              }
            }
          }
        }

        .chat-content {
          padding: 1.25rem;

          &::after {
            content: ' ';
            position: absolute;
            width: 0;
            height: 0;
            left: -1.25rem;
            top: auto;
            bottom: 0;
            border-style: solid;
            border-width: 1.375rem;
            border-color: transparent transparent
              ${(props) => props.theme.color_scheme_neutral} transparent;
          }

          @media screen and (max-width: 767px) {
            &::after {
              border-width: 1rem;
              left: -1rem;
            }
          }
        }
      }
    }
  }

  .padding-h {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .sunny {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 5px;
    margin: 1.25rem;

    span {
      font-family: Roboto, Arial, Helvetica, sans-serif;
      font-size: 0.625rem;
      color: ${(props) => props.theme.color_scheme_dark};
      text-transform: capitalize;
    }

    &-bar {
      height: 0.5rem;
      flex-grow: 1;
      margin: 0 0.5rem;
      background: linear-gradient(
        90deg,
        #472e6d 0%,
        #d68e50 27.54%,
        #fefb7a 64.62%,
        #fefff5 100%
      );
      border: 0.5px solid #000000;
    }
  }

  .img-fluid {
    max-width: 100%;
    height: auto;
  }

  .power-text {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 0.625rem;
    color: #000;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    padding-right: ${pxToRem(3)};
    margin-top: ${pxToRem(31)};

    &-inner {
      display: flex;
      flex-direction: column;
    }

    span {
      margin-bottom: ${pxToRem(10)};
    }

    img {
      width: ${pxToRem(288)};
    }

    @media screen and (max-width: 768px) {
      margin-top: ${pxToRem(40)};

      span {
        text-align: center;
      }

      img {
        width: ${pxToRem(177)};
      }
    }
  }

  .form-title {
    font-family: StellaFont, Arial, Helvetica, sans-serif;
    font-weight: 500;
  }

  .form-subtitle {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 1rem;
    font-weight: 500;
    color: #666;
    margin-left: 2px;
  }

  @media screen and (min-width: 768px) {
    .condensed {
      max-width: 336px;
      width: 100%;
      margin: 0 auto;
    }
  }
`;

export default Start;
