import styled, { keyframes } from 'styled-components';
import color from 'color';
import { pxToRem } from 'common/utils';

const scrollDownAnime = keyframes`
  from {
    transform: translateY(-16px);
  }

  to {
    transform: translateY(0);
  }
`;

const Result3 = styled.div`
  &.result-3-page.content {
    padding: 0;

    .form {
      @media screen and (min-width: 768px) {
        max-width: initial;
      }
    }
  }

  .introduction {
    font-family: StellaFont, Arial, Helvetica, sans-serif;
    font-size: ${pxToRem(39)};
    text-align: center;
    line-height: 2.5rem;
  }

  .subtext {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 1rem;
    color: #666;
  }

  .download-estimate {
    color: ${(props) => props.theme.color_scheme_dark};
    font-size: 1rem;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    text-decoration: underline;
  }

  .icon-scroll {
    cursor: pointer;
  }

  .bold-text {
    font-size: 1rem;
    color: #666;
    font-weight: bold;
  }

  .white-section,
  .dark-section,
  .black-section,
  .expand-section {
    padding: 1.5rem 1.25rem;
    @media screen and (min-width: 768px) {
      .section-container {
        max-width: 555px;
        margin: 0 auto;
      }
    }
  }

  .dark-section {
    background-color: #f7f7f7;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
  }

  .black-section {
    background-color: ${(props) => props.theme.color_scheme_dark};
  }

  .expand-section {
    border-top: 1px solid #dfdfdf;

    &.dark {
      background-color: #f7f7f7;
      border-top-width: 0px;
      border-bottom: 1px solid #dfdfdf;
    }
  }

  .discount-block {
    display: flex;
    justify-content: center;
    align-items: center;

    .bg-circle-icon {
      background-color: ${(props) => {
        const hsl = color(props.theme.color_scheme_dark).hsl().array();
        hsl[2] = 20;
        return color.hsl(hsl).hex();
      }};
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.75rem;
      height: 3.75rem;
      border-radius: 50%;
      flex-shrink: 0;
      position: absolute;
      left: calc(50% - 12rem);

      @media screen and (max-width: 767px) {
        left: calc(50% - 11rem);
      }

      .dark-color {
        fill: ${(props) => props.theme.color_scheme_dark};
      }

      svg {
        width: 2.375rem;
        height: auto;
      }
    }

    .discount-title {
      font-size: 1.25rem;
      color: #fff;
      margin-bottom: 0.5rem;
      text-align: center;
    }

    .discount-value {
      background-color: ${(props) => {
        const hsl = color(props.theme.color_scheme_dark).hsl().array();
        hsl[2] = 20;
        return color.hsl(hsl).hex();
      }};
      border-radius: 0.375rem;
      height: 2.875rem;
      min-width: 13.75rem;
      font-size: 1.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: #fff;
      margin-bottom: 1.2rem;

      sup {
        font-size: 1rem;
      }
    }

    .divider {
      border-top: 1px solid #fff;
      margin: 0.5rem 0;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .divider-text {
        position: absolute;
        font-family: Roboto, Arial, Helvetica, sans-serif;
        font-size: 1rem;
        font-weight: 700;
        background-color: ${(props) => props.theme.color_scheme_dark};
        padding: 0 0.625rem;
        color: #fff;
        text-transform: uppercase;
      }
    }
  }

  .section-title {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 1.6;
    color: #303030;
  }

  .section-title-variation {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 1.6;
    color: #303030;

    @media screen and (max-width: 767px) {
      font-size: 1.475rem;
    }
  }

  .section-description {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 3.5rem;
    line-height: 1.6;
    color: #303030;

    .exact-system-size-number {
      font-size: 6rem;
    }

    small {
      font-size: 1.25rem;
    }
  }

  .environmental-list {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    display: flex;
    justify-content: center;
  }

  .overpayment {
    color: #f00;
    font-weight: 700;
  }

  .sunroof-container {
    display: flex;
    flex-direction: column;

    .image-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      position: relative;

      .sunroof-image-main {
        @media screen and (min-width: 768px) {
          min-height: 217px;
        }
      }

      .img-fluid {
        max-width: 100%;
        height: auto;
        border: 5px solid ${(props) => props.theme.color_scheme_dark};
      }

      .iframe {
        max-width: 100%;
        height: 180px;

        @media screen and (min-width: 768px) {
          height: 350px;
        }
      }

      .logo-sunroof {
        height: 1.375rem;
      }
    }
  }

  .google-map-container {
    display: flex;
    flex-direction: column;

    .google-map-inner-container {
      display: block;
      position: relative;

      .iframe {
        max-width: 100%;
        height: 180px;

        @media screen and (min-width: 768px) {
          height: 350px;
        }
      }
    }
  }

  .scroll-down-icon {
    width: 34px;
    height: 34px;
    animation: ${scrollDownAnime} 0.5s ease-in-out alternate infinite;
  }

  .google-map-wrapper {
    position: relative;
    display: flex;
    justify-content: left;
    overflow: hidden;

    .map-container {
      width: 100%;
    }

    .map-description {
      position: absolute;
      top: 15px;
      width: 100%;
      padding-left: 15%;
      padding-right: 15%;

      @media screen and (max-width: 768px) {
        padding-left: 5%;
        padding-right: 5%;
      }

      @media screen and (max-width: 550px) {
        padding-left: 0;
        padding-right: 0;
        top: 0;
      }

      .section-header {
        background-color: ${(props) => props.theme.color_scheme_light};
        border-radius: 5px;

        @media screen and (min-width: 550px) {
          padding: 15px 10%;
        }

        @media screen and (max-width: 550px) {
          padding: 15px;
        }

        .title {
          font-family: 'Roboto, Arial, Helvetica, sans-serif';
          font-weight: 700;
          font-size: 26px;
          line-height: 1.6;
          color: #303030;
          @media screen and (max-width: 808px) {
            font-size: 22px;
          }
        }

        .gone-soloar {
          font-size: 20px;
          @media screen and (max-width: 808px) {
            font-size: 18px;
          }

          span {
            font-weight: bold;
          }
        }
      }

      .section-content {
        background-color: #fff;
        margin-top: 10px;
        padding: 10px;
        width: 100%;
        border: 1px solid #dfdfdf;
        display: flex;
        flex-direction: row;

        @media screen and (min-width: 500px) {
          padding: 10px 10%;
        }

        @media screen and (max-width: 500px) {
          padding: 10px;
        }

        .icon-mark {
          width: 50%;
          font-size: 20px;
          @media screen and (max-width: 808px) {
            font-size: 18px;
          }
        }
      }
    }
  }
`;

export const CircleText = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 50%;
  background-color: ${(props) => props.theme.color_scheme_light};

  .circle-text-main {
    font-size: 2.25rem;
    color: #303030;
    font-weight: 700;
    .exact-pricing-main-number {
      font-size: 4rem;
    }
  }

  .circle-text-small {
    font-size: 0.875rem;
    color: ${(props) => props.theme.color_scheme_dark};
    text-transform: uppercase;

    &.exact-pricing-main-number {
      font-size: 1.275rem;
    }
  }
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 183px;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 3.21%, #000000 84.4%);
  opacity: 1;
  transition: opacity 0.5s, height 0.5s;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  @media screen and (min-width: 768px) {
    position: absolute;
  }
  &.hide {
    opacity: 0;
    height: 0;
  }
  .scroll-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  .header {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 10px;
  }
`;

export default Result3;

const scrollAnime = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(26px);
  }
`;

export const MouseIndicator = styled.div`
  &.icon-scroll,
  &.icon-scroll:before {
    position: absolute;
    left: 50%;
  }
  &.icon-scroll {
    width: 30px;
    height: 50px;
    margin-left: -10px;
    bottom: 10px;
    margin-top: -35px;
    border: 2px solid #fff;
    border-radius: 25px;
  }
  &.icon-scroll:before {
    content: '';
    width: 8px;
    height: 8px;
    background: #fff;
    margin-left: -4px;
    top: 8px;
    border-radius: 4px;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: ${scrollAnime};
  }
`;

export const ModalImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${pxToRem(-95)};
  margin-bottom: ${pxToRem(40)};
  @media screen and (max-width: 767px) {
    margin-top: ${pxToRem(-80)};
  }
`;

export const ModalTitle = styled.div`
  font-family: Roboto;
  font-size: ${pxToRem(20)};
  line-height: ${pxToRem(36)};
  font-weight: 500;
  color: #000;
  margin-bottom: 1rem;
  text-align: center;
  @media (min-width: 769px) {
    font-size: ${pxToRem(28)};
    line-height: ${pxToRem(48)};
  }
`;

export const ModalContent = styled.div`
  text-align: 'center';
  margin-right: ${pxToRem(16)};
  margin-left: ${pxToRem(16)};
  margin-bottom: ${pxToRem(16)};
`;

export const ModalContentText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: ${pxToRem(16)};
  line-height: ${pxToRem(18)};
  text-align: center;
  padding-left: ${pxToRem(20)};
  padding-right: ${pxToRem(20)};
  margin-bottom: ${pxToRem(28)};
  color: #666666;
  @media (min-width: 769px) {
    font-size: ${pxToRem(24)};
    line-height: ${pxToRem(30)};
  }
`;
