import styled from 'styled-components';

const ProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .progress-bar-label-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 9px;
    margin-left: -2px;

    .progress-bar-label {
      font-size: 0.75rem;

      &--inactive {
        color: #999;
      }
    }
  }

  .progress-bar-bg {
    display: flex;
    height: 5px;
    overflow: hidden;
    background-color: #ccc;
    border-radius: 15px;
  }

  .progress-bar-active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${(props) => props.theme.color_scheme_font};
    transition: width 0.6s ease;
    border-radius: 15px;
    height: 100%;
  }

  @media screen and (min-width: 768px) {
    .progress-bar-label {
      font-size: 1rem;
    }

    .progress-bar-bg {
      height: 10px;
    }
  }
`;

export default ProgressBar;
