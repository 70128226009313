import styled from 'styled-components';
import { pxToRem } from 'common/utils';

const Card = styled.div`
  &.sunlight-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      width: ${pxToRem(555)};
    }

    .sunlight-value {
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 1.25;
      text-align: center;
      letter-spacing: 0.05rem;
      color: #000000;
    }

    .sunlight-text {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.25;
      letter-spacing: 0.05rem;
    }
  }
`;

export default Card;
