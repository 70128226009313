import styled from 'styled-components';
import { pxToRem } from 'common/utils';

const StyledSungage6 = styled.div`
  &.content.sungage6-page {
    @media screen and (min-width: 768px) {
      max-width: none;
      align-items: center;
      .form-content {
        width: 100%;
        max-width: ${pxToRem(555)};
      }
    }

    .form {
      overflow: hidden;
    }

    .sungage-form-content {
      display: flex;
      flex-direction: column;
    }

    .sungage-logo {
      display: flex;
      justify-content: center;
      padding: 2rem;
    }

    .verify-title {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.625rem;
      letter-spacing: 0.05rem;
      line-height: 1rem;
    }

    .verify-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .verify-description {
      display: flex;
      align-items: center;
      font-size: 0.625rem;
      line-height: 1.25rem;
    }

    .sungage-rocket {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .sungage-rocket-animation {
      animation: rocketing 1500ms cubic-bezier(0.25, 1, 0.7, 1) forwards;
      @keyframes rocketing {
        0% {
          transform: translate(0px, 0px);
        }
        20% {
          transform: translate(-50px, 50px);
        }
        100% {
          transform: translate(500px, -500px);
        }
      }
    }

    .rotate {
      align-self: center;
      animation: effect 2000ms linear infinite;
      @keyframes effect {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(-360deg);
        }
      }
    }
  }
`;

export default StyledSungage6;
