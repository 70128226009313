import styled from 'styled-components';

const Commercial3 = styled.div`
  .form-content {
    font-size: 17px;
    line-height: 22px;
    margin-top: 2rem;

    @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media screen and (max-width: 375px) and (max-height: 650px) {
      margin-top: 0rem;
    }
  }

  .form-title {
    font-family: 'Effra Light';
    margin-top: 2.25rem;
    font-size: 2rem;
    line-height: 100%;
    color: ${(props) => props.theme.color_scheme_dark};

    @media screen and (max-width: 767px) {
      margin-top: 2rem;
      font-size: 1.75rem;
    }

    @media screen and (max-width: 375px) and (max-height: 650px) {
      margin-top: 1rem;
      font-size: 1.25rem;
    }
  }

  .form-subtext {
    font-family: 'Effra Light';
    font-size: 1rem;
    color: #666;
    margin-bottom: 2rem;

    @media screen and (max-width: 767px) {
      margin-bottom: 1.5rem;
    }

    @media screen and (max-width: 375px) and (max-height: 650px) {
      font-size: 0.875rem;
      margin-bottom: 1rem;
    }
  }

  .btn-svg {
    position: relative;

    svg {
      position: absolute;
      left: 16px;
    }
  }
`;

export default Commercial3;
