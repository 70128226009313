import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Button from 'components/Button';
import Chat from 'components/Chat';
import { trackPhoneClick, trackGoogleLead } from 'store/actions';
import DefaultLayout from 'components/DefaultLayout';
import { withTranslation } from 'react-i18next';
import { generateID } from 'common/utils';
import StyledDisqualifyPage from './styled';

class DisqualifyPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      dnqs: this.getDNQReason(),
    };
    props.actions.trackGoogleLead('disqualify', props.user.variation);
  }

  openPhone = () => {
    const { theme, actions } = this.props;
    const phoneObj = theme.advertise_phone
      ? parsePhoneNumberFromString(theme.advertise_phone, 'US')
      : null;
    const phoneUri = phoneObj ? phoneObj.getURI() : '';
    if (!phoneUri) {
      return;
    }
    actions.trackPhoneClick();
    window.open(phoneUri, '_top');
  };

  getDNQReason = () => {
    const { user } = this.props;
    const answers = user.answers.filter((e) => e.choice.is_disqualifier);

    const dnqs = [];
    for (let index = 0; index < answers.length; index++) {
      dnqs.push({
        id: answers[index].choice.id,
        answer: answers[index].choice.option,
        question: answers[index].question.question_desc,
      });
    }
    return dnqs;
  };

  render() {
    const { theme, t, user } = this.props;
    const { step, dnqs } = this.state;

    const {
      location: { pathname },
    } = this.props;

    return (
      <DefaultLayout>
        <StyledDisqualifyPage className="content disqualify-page">
          <div className="form">
            <div id={generateID('div-dnq', pathname)} className="form-content">
              <Chat
                id={generateID('chat-thank-you', pathname)}
                onShow={() => this.setState({ step: 2 })}
              >
                {t(
                  "Thank you for your interest! Unfortunately, it's unlikely that you qualify for solar."
                )}
              </Chat>

              {step >= 2 && user.disqualfied_reason === 'profanity_name' && (
                <Chat
                  id={generateID(`chat-${user.disqualfied_reason}`, pathname)}
                  onShow={() => this.setState({ step: 3 })}
                >
                  {t('Reason: You provided an inappropriate name.')}
                </Chat>
              )}

              {step >= 2 &&
                dnqs.map((dnq) => (
                  <Chat
                    id={generateID(`chat-${dnq.id}`, pathname)}
                    key={dnq.id}
                    onShow={() => this.setState({ step: 3 })}
                  >
                    {t(
                      'Reason: You answered "{{answer}}" for the question "{{question}}".',
                      {
                        answer: dnq.answer,
                        question: dnq.question,
                      }
                    )}
                  </Chat>
                ))}

              {step >= 3 && (
                <Chat
                  id={generateID('chat-for-more', pathname)}
                  onShow={() => this.setState({ step: 4 })}
                >
                  {t(
                    'For more information about qualification requirements, please call us.'
                  )}
                </Chat>
              )}
            </div>

            {step >= 4 && (
              <div
                id={generateID('div-actions', pathname)}
                className="form-actions"
              >
                {/* <div className="form-actions-text mb-16">
                  Don't want to wait?
                </div> */}
                <Button
                  id={generateID('btn-call-us', pathname)}
                  full
                  onClick={this.openPhone}
                >
                  {t('Call us! {{advertise_phone}}', {
                    advertise_phone: theme.advertise_phone,
                  })}
                </Button>
              </div>
            )}
          </div>
        </StyledDisqualifyPage>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ ui: { theme }, user: { data } }) => ({
  theme,
  user: data,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      trackPhoneClick,
      trackGoogleLead,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(DisqualifyPage)
);
