import styled from 'styled-components';

const Result = styled.div`
  &.sunlight-result {
    display: flex;
    flex-direction: column;
    text-align: center;

    .sunlight-result-title {
      font-size: 1.875rem;
      line-height: 1.5625;
    }

    .sunlight-result-content {
      font-size: 1.375rem;
      line-height: 1.5625;
    }

    .sunlight-result-description {
      margin-top: 2.25rem;
      font-size: 10px;
      text-align: justify;
      font-weight: 100;
      line-height: 12px;
    }
  }
`;

export default Result;
