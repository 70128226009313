import React, { useRef } from 'react';
import { TextField } from '@material-ui/core';
import { useOutlinedTextFieldStyles } from './styled';

const TextInput = ({ field, form, theme, ...props }) => {
  const classes = useOutlinedTextFieldStyles({ theme, value: field.value });
  const input = useRef();
  const [isError, setIsError] = React.useState(false);

  const checkValidation = () => {
    setIsError(Boolean(form.errors[field.name]));
  };

  return (
    <div>
      <TextField
        error={isError}
        {...field}
        {...props}
        variant="outlined"
        className={classes.outlinedTextField}
        validations="isEmail"
        ref={input}
        onBlur={checkValidation}
      />
    </div>
  );
};

export default TextInput;
