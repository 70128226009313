import React from 'react';
import PropTypes from 'prop-types';
import StyledDivider from './styled';

const Divider = ({ text, color }) => (
  <StyledDivider color={color}>
    {text && <span className="divider-text">{text}</span>}
  </StyledDivider>
);

Divider.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
};

Divider.defaultProps = {
  text: '',
  color: '#fff',
};

export default Divider;
