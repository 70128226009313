import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { ReactComponent as QuestionIcon } from 'assets/images/icon-question.svg';
import iconLoading from 'assets/images/icon-loading.svg';
import {
  setUserData,
  saveSession,
  navigateTo,
  initializeSession,
} from 'store/actions';
import { getVisibleHeader, notifyOfNewLead } from 'common/api.mock';
import { removeLocalStorageItem } from 'common/localStorage';
import { useTranslation } from 'react-i18next';
import { generateID } from 'common/utils';
import StyledHeader from './styled';

const HeaderD2DVariation = ({ showRestartButton }) => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.ui.theme);
  const [showDialog, setShowDialog] = useState(false);
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [t] = useTranslation('translation');

  const user = useSelector((state) => state.user.data);

  let restartAble = true;
  let restartStep = '/start';

  if (user.is_instant_estimate) {
    restartStep = '/result3';

    if (user.step === '/result3' || user.step === 'result3') {
      restartAble = false;
    }
  }

  const handleNext = () => {
    setShowDialog(true);
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleRestart = async () => {
    handleClose();
    dispatch(
      setUserData({
        back_step: '/',
        step: restartStep,
        urjanet_status: null,
      })
    );
    await dispatch(saveSession());
    dispatch(navigateTo(restartStep));
  };

  const handleSave = async () => {
    setShowSaveDialog(false);
    dispatch(initializeSession());
    removeLocalStorageItem('sessionId');
    notifyOfNewLead();
    dispatch(navigateTo(restartStep));
  };

  const visibleHeader = getVisibleHeader();

  if (!visibleHeader) {
    return <></>;
  }

  return (
    <>
      <StyledHeader id={generateID('div-header', 'header')} className="header">
        <a
          className="header-left"
          href={theme.website_url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            id={generateID('logo', 'header')}
            src={theme.logo}
            alt={theme.company_name}
            className="logo"
          />
        </a>
        {showRestartButton && restartAble && (
          <div className="header-center">
            <div
              id={generateID('btn-restart', 'header')}
              className=""
              onClick={handleNext}
            >
              <img src={iconLoading} alt="loading" className="loading" />
            </div>
          </div>
        )}

        <div className="header-right">
          {showRestartButton && (
            <Button
              id={generateID('btn-save', 'header')}
              className="phone-number"
              outline
              onClick={() => setShowSaveDialog(true)}
              type="button"
            >
              {t('Save')}
            </Button>
          )}
        </div>
      </StyledHeader>
      <Modal showCloseIcon onClose={handleClose} isOpen={showDialog}>
        <div className="my-32">
          <QuestionIcon />
        </div>
        <div className="modal-title text-center">
          {t("Are you sure you'd like to restart?")}
        </div>
        <div className="modal-content">
          <div className="mb-32 text-center">
            {t('Your progress will be lost.')}
          </div>
          <div className="mb-32">
            <Button
              id={generateID('btn-yes-start-over', 'modal-restart')}
              full
              onClick={handleRestart}
            >
              {t('Yes, start over')}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        showCloseIcon
        onClose={() => setShowSaveDialog(false)}
        isOpen={showSaveDialog && showRestartButton}
      >
        <div className="my-32">
          <QuestionIcon />
        </div>
        <div className="modal-title text-center">
          {t(
            "Are you sure you'd like to save this lead and go back to the start page?"
          )}
        </div>
        <div className="modal-content">
          <div className="mb-32" style={{ display: 'flex' }}>
            <Button
              id={generateID('btn-yes', 'modal-save')}
              onClick={handleSave}
              style={{ marginRight: 10, width: '50%' }}
            >
              &nbsp;{t('Yes')}&nbsp;&nbsp;
            </Button>
            <Button
              id={generateID('btn-cancel', 'modal-save')}
              outline
              onClick={() => setShowSaveDialog(false)}
              style={{ width: '50%' }}
            >
              {t('Cancel')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

HeaderD2DVariation.propTypes = {
  showRestartButton: PropTypes.bool,
};

HeaderD2DVariation.defaultProps = {
  showRestartButton: true,
};

export default HeaderD2DVariation;
