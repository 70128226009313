import styled from 'styled-components';

const Calculate21 = styled.div`
  .link-text {
    color: #529bd7;
    text-decoration: underline;
  }
`;

export default Calculate21;
