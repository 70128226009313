import Header from 'components/Header';
import HeaderD2DVariation from 'components/HeaderD2DVariation';
import HeaderNRGVariation from 'components/HeaderNRGVariation';
import Footer from 'components/Footer';
import FooterElevationNoReviewsVariation from 'components/FooterElevationNoReviewsVariation';
import FooterNRGVariation from 'components/FooterNRGVariation';
import CurrentVersion from './variations/current';
import Variation1 from './variations/variation1';
import Variation2 from './variations/variation2';
import Variation2Spanish from './variations/variation2-spanish';
import Variation3 from './variations/variation3';
import SpanishVersion from './variations/spanish-variation';
import D2DVariation from './variations/d2d-variation';
import BuleSelVariation from './variations/bulesel-variation';
import CapeFearSolarVariation from './variations/cfs-variation';
import SolarPowerProsVariation from './variations/solarpowerpros-variation';
import SolUpComplianceVariation from './variations/sol-up-compliance-variation';
import ElevationNoReviewsVariation from './variations/elevation-no-reviews-variation';
import NoUtilityBillVariation from './variations/no-utility-bill-variation';
import AppointmentRequestVariation from './variations/appointment-request-variation';
import LighthouseSolarAppointmentsVariation from './variations/lighthouse-solar-appointments';
import SolGenVariation from './variations/solgen-variation';
import NoAppointmentVariation from './variations/no-appointment-variation';
import NRGVariation from './variations/nrg-variation';

export const VARIATION_OPTIONS = {
  current: {
    component: CurrentVersion,
    language: 'en',
    header: Header,
    footer: Footer,
    facebookPixelStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'bills1',
        status: 'Schedule',
      },
    ],
    googleLeadStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'disqualify',
        status: 'Disqualified Lead',
      },
      {
        step: 'bills1',
        status: 'Appointment',
      },
      {
        step: 'thankyou',
        status: 'Appointment with Bill',
      },
    ],
  },
  'demand-iq-variation-1': {
    component: Variation1,
    language: 'en',
    header: Header,
    footer: Footer,
    facebookPixelStatus: [
      {
        step: 'result2',
        status: 'Contact',
      },
      {
        step: 'result2',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'bills1',
        status: 'Schedule',
      },
    ],
    googleLeadStatus: [
      {
        step: 'result2',
        status: 'Lead',
      },
      {
        step: 'disqualify',
        status: 'Disqualified Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'bills1',
        status: 'Appointment',
      },
      {
        step: 'thankyou',
        status: 'Appointment with Bill',
      },
    ],
  },
  'demand-iq-variation-2': {
    component: Variation2,
    language: 'en',
    header: Header,
    footer: Footer,
    facebookPixelStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'bills1',
        status: 'Schedule',
      },
    ],
    googleLeadStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'disqualify',
        status: 'Disqualified Lead',
      },
      {
        step: 'bills1',
        status: 'Appointment',
      },
      {
        step: 'thankyou',
        status: 'Appointment with Bill',
      },
    ],
  },
  'demand-iq-variation-2-spanish': {
    component: Variation2Spanish,
    language: 'es',
    header: Header,
    footer: Footer,
    facebookPixelStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'bills1',
        status: 'Schedule',
      },
    ],
    googleLeadStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'disqualify',
        status: 'Disqualified Lead',
      },
      {
        step: 'bills1',
        status: 'Appointment',
      },
      {
        step: 'thankyou',
        status: 'Appointment with Bill',
      },
    ],
  },
  'demand-iq-spanish-variation': {
    component: SpanishVersion,
    language: 'es',
    header: Header,
    footer: Footer,
    facebookPixelStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'bills1',
        status: 'Schedule',
      },
    ],
    googleLeadStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'disqualify',
        status: 'Disqualified Lead',
      },
      {
        step: 'bills1',
        status: 'Appointment',
      },
      {
        step: 'thankyou',
        status: 'Appointment with Bill',
      },
    ],
  },
  'demand-iq-d2d-variation': {
    component: D2DVariation,
    language: 'en',
    header: HeaderD2DVariation,
    footer: Footer,
    facebookPixelStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'bills1',
        status: 'Schedule',
      },
    ],
    googleLeadStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'disqualify',
        status: 'Disqualified Lead',
      },
      {
        step: 'bills1',
        status: 'Appointment',
      },
      {
        step: 'thankyou',
        status: 'Appointment with Bill',
      },
    ],
  },
  'demand-iq-variation-3': {
    component: Variation3,
    header: Header,
    footer: Footer,
    facebookPixelStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'bills1',
        status: 'Schedule',
      },
    ],
    googleLeadStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'disqualify',
        status: 'Disqualified Lead',
      },
      {
        step: 'bills1',
        status: 'Appointment',
      },
      {
        step: 'thankyou',
        status: 'Appointment with Bill',
      },
    ],
  },
  'demand-iq-bulesel-variation': {
    component: BuleSelVariation,
    language: 'en',
    header: Header,
    footer: Footer,
    facebookPixelStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'bills1',
        status: 'Schedule',
      },
    ],
    googleLeadStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'disqualify',
        status: 'Disqualified Lead',
      },
      {
        step: 'bills1',
        status: 'Appointment',
      },
      {
        step: 'thankyou',
        status: 'Appointment with Bill',
      },
    ],
  },
  'demand-iq-cfs-variation': {
    component: CapeFearSolarVariation,
    language: 'en',
    header: Header,
    footer: Footer,
    facebookPixelStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'bills1',
        status: 'Schedule',
      },
    ],
    googleLeadStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'disqualify',
        status: 'Disqualified Lead',
      },
      {
        step: 'bills1',
        status: 'Appointment',
      },
      {
        step: 'thankyou',
        status: 'Appointment with Bill',
      },
    ],
  },
  'demand-iq-solarpowerpros-variation': {
    component: SolarPowerProsVariation,
    language: 'en',
    header: Header,
    footer: Footer,
    facebookPixelStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'bills1',
        status: 'Schedule',
      },
    ],
    googleLeadStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'disqualify',
        status: 'Disqualified Lead',
      },
      {
        step: 'bills1',
        status: 'Appointment',
      },
      {
        step: 'thankyou',
        status: 'Appointment with Bill',
      },
    ],
  },
  'demand-iq-sol-up-compliance-variation': {
    component: SolUpComplianceVariation,
    language: 'en',
    header: Header,
    footer: Footer,
    facebookPixelStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'bills1',
        status: 'Schedule',
      },
    ],
    googleLeadStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'disqualify',
        status: 'Disqualified Lead',
      },
      {
        step: 'bills1',
        status: 'Appointment',
      },
      {
        step: 'thankyou',
        status: 'Appointment with Bill',
      },
    ],
  },
  'demand-iq-elevation-no-reviews-variation': {
    component: ElevationNoReviewsVariation,
    language: 'en',
    header: Header,
    footer: FooterElevationNoReviewsVariation,
    facebookPixelStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'bills1',
        status: 'Schedule',
      },
    ],
    googleLeadStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'disqualify',
        status: 'Disqualified Lead',
      },
      {
        step: 'bills1',
        status: 'Appointment',
      },
      {
        step: 'thankyou',
        status: 'Appointment with Bill',
      },
    ],
  },
  'demand-iq-no-utility-bill-variation': {
    component: NoUtilityBillVariation,
    language: 'en',
    header: Header,
    footer: Footer,
    facebookPixelStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'thankyou',
        status: 'Schedule',
      },
    ],
    googleLeadStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'disqualify',
        status: 'Disqualified Lead',
      },
      {
        step: 'thankyou',
        status: 'Appointment',
      },
    ],
  },
  'demand-iq-appointment-request-variation': {
    component: AppointmentRequestVariation,
    language: 'en',
    header: Header,
    footer: Footer,
    facebookPixelStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'bills1',
        status: 'Schedule',
      },
    ],
    googleLeadStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'disqualify',
        status: 'Disqualified Lead',
      },
      {
        step: 'bills1',
        status: 'Appointment',
      },
      {
        step: 'thankyou',
        status: 'Appointment with Bill',
      },
    ],
  },
  'demand-iq-lighthouse-solar-appointments-variation': {
    component: LighthouseSolarAppointmentsVariation,
    language: 'en',
    header: Header,
    footer: Footer,
    facebookPixelStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      {
        step: 'bills1',
        status: 'Schedule',
      },
    ],
    googleLeadStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      {
        step: 'disqualify',
        status: 'Disqualified Lead',
      },
      {
        step: 'bills1',
        status: 'Appointment',
      },
      {
        step: 'thankyou',
        status: 'Appointment with Bill',
      },
    ],
  },
  'demand-iq-solgen-variation': {
    component: SolGenVariation,
    language: 'en',
    header: Header,
    footer: Footer,
    facebookPixelStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      {
        step: 'bills1',
        status: 'Schedule',
      },
    ],
    googleLeadStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      {
        step: 'disqualify',
        status: 'Disqualified Lead',
      },
      {
        step: 'bills1',
        status: 'Appointment',
      },
      {
        step: 'thankyou',
        status: 'Appointment with Bill',
      },
    ],
  },
  'demand-iq-nrg-variation': {
    component: NRGVariation,
    language: 'en',
    header: HeaderNRGVariation,
    footer: FooterNRGVariation,
    facebookPixelStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'bills1',
        status: 'Schedule',
      },
    ],
    googleLeadStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'qualify9',
      //   status: 'Qualified Lead',
      // },
      {
        step: 'disqualify',
        status: 'Disqualified Lead',
      },
      {
        step: 'bills1',
        status: 'Appointment',
      },
      {
        step: 'thankyou',
        status: 'Appointment with Bill',
      },
    ],
  },
  'demand-iq-no-appointment-variation': {
    component: NoAppointmentVariation,
    language: 'en',
    header: Header,
    footer: Footer,
    facebookPixelStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      // {
      //   step: 'bills1',
      //   status: 'Schedule',
      // },
    ],
    googleLeadStatus: [
      {
        step: 'result3',
        status: 'Contact',
      },
      {
        step: 'result3',
        status: 'Lead',
      },
      {
        step: 'disqualify',
        status: 'Disqualified Lead',
      },
      // {
      //   step: 'bills1',
      //   status: 'Appointment',
      // },
      {
        step: 'thankyou',
        status: 'Appointment with Bill',
      },
    ],
  },
};

export const ALLOWED_ROLES = ['pro', 'premier', 'enterprise'];

export const SINGLE_CHOICE_QUESTION = 'single-choice';
export const OPEN_ENDED_QUESTION = 'open-ended';
