import React, { useState } from 'react';
import Button from 'components/Button';
import ProgressBar from 'components/ProgressBar';
import Chat from 'components/Chat';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import { ReactComponent as SunroofImage } from 'assets/images/sunroof-image.svg';
import { setUserData, navigateTo } from 'store/actions';
import DefaultLayout from 'components/DefaultLayout';
import { useSelector, useDispatch } from 'react-redux';
import { extractValuesFromPlace, generateID } from 'common/utils';
import GoogleAddressVariation2 from 'components/Forms/GoogleAddressVariation2';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import StyledWrapper from './styled';

const Calculate22Variation2 = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.ui.theme);
  const [t] = useTranslation('translation');
  const [address, setAddress] = useState('');
  const [checking, setChecking] = useState(false);
  const location = useLocation();

  const handleBack = () => {
    dispatch(navigateTo('/calculate2'));
  };

  const handleNext = async () => {
    setChecking(true);
    const step = '/calculate12';
    dispatch(
      setUserData({
        step,
      })
    );
    await dispatch(navigateTo(step));
  };

  const handleSelect = async (event, place) => {
    if (!place) {
      return;
    }
    setChecking(true);
    setAddress(event.target.value);

    const values = await extractValuesFromPlace(place);
    const step = '/calculate12';

    dispatch(
      setUserData({
        ...values,
        step,
      })
    );

    await dispatch(navigateTo(step));
  };

  const handleChange = (event) => {
    setChecking(true);
    setAddress(event.target.value);
  };

  return (
    <DefaultLayout>
      <StyledWrapper className="content calculate-12-page">
        <div className="form">
          <div className="form-content">
            <div
              id={generateID('div-progress-bar', location.pathname)}
              className="mb-20 w100"
            >
              <ProgressBar value={50} step={2} />
            </div>

            <Chat
              id={generateID('chat-enter-your-address', location.pathname)}
              timeout={0}
            >
              <div>
                {t('Enter your address to see if your roof is good for solar!')}
              </div>
              <GoogleAddressVariation2
                theme={theme}
                value={address}
                onChange={handleChange}
                onSelect={handleSelect}
                placeholder={t('Enter your address here')}
                name="address"
              />
            </Chat>

            <div
              id={generateID('div-sunroof-image', location.pathname)}
              className="mb-20"
            >
              <SunroofImage style={{ width: '100%', height: '100%' }} />
            </div>
          </div>

          <div
            id={generateID('div-actions', location.pathname)}
            className="form-actions"
          >
            <Button
              id={generateID('btn-back', location.pathname)}
              outline
              onClick={handleBack}
            >
              <BackIcon />
            </Button>
            <Button
              id={generateID('btn-check-my-roof', location.pathname)}
              type="submit"
              full
              onClick={handleNext}
              disabled={checking || address === ''}
            >
              {t('Check My Roof')}
            </Button>
          </div>
        </div>
      </StyledWrapper>
    </DefaultLayout>
  );
};

export default Calculate22Variation2;
