import cloneDeep from 'lodash/cloneDeep';
import assign from 'lodash/assign';
import {
  LOAD_SESSION_SUCCESS,
  LOAD_SESSION_REQUEST,
  LOAD_SESSION_FAILURE,
  SAVE_FINANCIAL_ANALYSES,
  SAVE_SESSION_REQUEST,
  SAVE_SESSION_SUCCESS,
  SAVE_SESSION_FAILURE,
  GET_EVENT_REQUEST,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAILURE,
  STORE_SESSION,
  INITIALIZE_SESSION,
} from '../actions/user';

const initialState = {
  data: {
    step: '/start',
    first_name: null,
    last_name: null,
    email: null,
    address: null,
    address2: null,
    city: null,
    state: null,
    zip_code: null,
    avg_bill: null,
    co2_tons: null,
    gal_of_gas: null,
    homes_electricty: null,
    inc_home_value: null,
    incentive: null,
    install_size: null,
    install_size_max: null,
    install_size_min: null,
    monthly_payment_best_guess: null,
    monthly_payment_max: null,
    monthly_payment_min: null,
    panels_max: null,
    panels_min: null,
    trash_bags: null,
    tree_seedlings: null,
    terms_consent: null,
    property_type: null,
    property_ownership: null,
    credit_score: null,
    meeting_date: null,
    meeting_time: null,
    meeting_timezone: null,
    property_living_period: null,
    property_roof_needed: null,
    household_taxable_income: null,
    savings: null,
    timeframe: null,
    phone: null,
    urjanet_status: null,
    rooftop_latitude: null,
    rooftop_longitude: null,
    language: 'en',
    solarCount: 0,
    skippedBills: false,
    username: null,
    battery_name: null,
    battery_description: null,
    battery_price: null,
    charger_description: null,
    charger_price: null,
  },
  calendly: {
    meeting_time: null,
    loading: false,
  },
  financialAnalyses: null,
  loading: false,
  saving: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SESSION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case LOAD_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: cloneDeep(assign(state.data, action.user)),
      };

    case LOAD_SESSION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case STORE_SESSION:
      return {
        ...state,
        data: cloneDeep(assign(state.data, action.user)),
      };
    case SAVE_SESSION_REQUEST:
      return {
        ...state,
        saving: true,
      };

    case SAVE_SESSION_SUCCESS:
      return {
        ...state,
        saving: false,
        loading: false,
        data: cloneDeep(assign(state.data, action.user)),
      };

    case SAVE_SESSION_FAILURE:
      return {
        ...state,
        saving: false,
      };

    case GET_EVENT_REQUEST:
      return {
        ...state,
        calendly: {
          ...state.calendly,
          loading: true,
        },
      };

    case GET_EVENT_SUCCESS:
      return {
        ...state,
        calendly: {
          ...state.calendly,
          loading: false,
          meeting_time: action.calendly.start_time,
        },
      };

    case GET_EVENT_FAILURE:
      return {
        ...state,
        calendly: {
          ...state.calendly,
          loading: false,
          meeting_time: null,
        },
      };

    case SAVE_FINANCIAL_ANALYSES:
      return {
        ...state,
        financialAnalyses: action.payload,
      };

    case INITIALIZE_SESSION:
      return {
        ...state,
        data: {},
      };

    default:
      return state;
  }
};
