import styled from 'styled-components';

const Checkbox = styled.label`
  display: inline-block;
  position: relative;
  padding-left: 1.875rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  font-size: 1rem;
  user-select: none;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  color: #666;
  line-height: 1.25;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 1px solid ${(props) => props.theme.color_scheme_button};
    border-radius: 2px;
  }

  &:hover input ~ .checkmark {
    border: 1px solid ${(props) => props.theme.color_scheme_button_hover};
  }

  input:checked ~ .checkmark {
    border: 1px solid ${(props) => props.theme.color_scheme_button};
  }

  &:hover input:checked ~ .checkmark {
    border: 1px solid ${(props) => props.theme.color_scheme_button_hover};
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid ${(props) => props.theme.color_scheme_button};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  &:hover input:checked ~ .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid ${(props) => props.theme.color_scheme_button_hover};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

export default Checkbox;
