export const defaultTheme = {
  logo: '/assets/images/logo.png',
  font: '/assets/fonts/perpetua.woff',
  background: '/assets/images/bg.jpg',
  color_scheme_light: '#F4BE7D',
  color_scheme_dark: '#000000',
  color_scheme_neutral: '#f9f9f9',
  color_scheme_font: '#3A3A3A',
  color_scheme_label: '#717171',
  color_scheme_button: '#000000',
  color_scheme_button_hover: '#333333',
  color_scheme_button_text: '#717171',
  color_scheme_button_hover_text: '#a4a4a4',
  color_scheme_background: '#ffffff',
  button_shape: 'semiround',
  app_step: 1,
  advertise_phone: '888-781-7074',
  rating: 4.97,
  legal:
    'To help you go solar, we need your approval to contact you. By clicking above, you agree that [SOLAR_COMPANY_NAME] may email and call you about [SOLAR_COMPANY_NAME] products at the phone number you entered above, using pre-recorded messages or an autodialer, even if your number is on a "Do Not Call" list. Consent for calls is optional. You can opt-out anytime.',
  disclosure:
    '*[APR]% APR financing for [TERM] years on approved credit with zero down. Estimate only based on average utility bill; actual monthly cost may vary.',
  advertise_email: 'test@test.com',
  ai_avatar: '/assets/images/avatar.jpg',
  hero_image_desktop: '/assets/images/map-desktop.png',
  hero_image_mobile: '/assets/images/map-mobile.png',
  product_apr: 3.99,
  product_term: 20,
  thanks_statement:
    'Our engineers are hard at work designing a custom solar panel system to fit your roof. If we have any questions we’ll reach out!',
  spanish_ai_avatar: '/assets/images/avatar.jpg',
  spanish_thanks_statement:
    'Nuestros ingenieros están trabajando para diseñar un sistema de paneles personalizado que se adapte a tu techo. Si tenemos alguna pregunta, te contactaremos.',
};

export const billData = [
  { name: 'Utility Bill', value: 250, color: '#6f6f6f' },
  { name: 'Utility Bill + Solar', value: 210, color: '#F4BE7D' },
];

export const sliderValues = [0, 100, 200, 500, 1000];

export const stepperData = [
  'Estimate',
  'Get Qualified',
  'Share Bills',
  'Quote',
];

export const stepperDataNoUtilityBill = [
  'Estimate',
  'Get Qualified',
  'Book Appointment',
  'Quote',
];

export const mainTimezones = [
  { name: 'Pacific Time - US & Canada', value: 'US/Pacific' },
  { name: 'Mountain Time - US & Canada', value: 'US/Mountain' },
  { name: 'Central Time - US & Canada', value: 'US/Central' },
  { name: 'Eastern Time - US & Canada', value: 'US/Eastern' },
  { name: 'Hawaii-Aleutian Time Zone', value: 'Pacific/Honolulu' },
  { name: 'Alaska Time Zone', value: 'US/Alaska' },
];

export const mainTimezonesEsLocale = [
  { name: 'Hora del Pacífico: EE. UU. Y Canadá', value: 'US/Pacific' },
  { name: 'Hora de la montaña: EE. UU. Y Canadá', value: 'US/Mountain' },
  { name: 'Hora central: EE. UU. Y Canadá', value: 'US/Central' },
  { name: 'Hora del este: EE. UU. Y Canadá', value: 'US/Eastern' },
  { name: 'Zona horario de Hawái-Aleutianas', value: 'Pacific/Honolulu' },
  { name: 'Zona horaria de Alaska', value: 'US/Alaska' },
];

export const urjanetStatuses = {
  failed: 'failed',
  pending: 'pending',
  success: 'success',
};

export const templateSungageProject = {
  id: 'r69j403840',
  primaryApplicant: {
    firstName: 'George',
    lastName: 'Delosa',
    email: 'swiftcc624@gmail.com',
  },
  projectAddress: {
    street: '2041 Pinetrail St',
    city: 'Las Cruces',
    state: 'NM',
    zip: 88012,
  },
  projectDetails: {
    solarCost: 35000,
    solarSize: 10,
  },
};

export const templateSungageCredit = {
  projectId: 'r69j403840',
  sungageId: '204040',
  key: 'fi51039',
  action: 'check',
  primaryApplicant: {
    firstName: 'George',
    lastName: 'Delosa',
    email: 'swiftcc624@gmail.com',
    ssn: '666325926',
    dob: '1955-12-26',
    phone: '444-555-1212',
    phoneType: 'Cell',
    income: {
      annualPretaxIncome: 136000,
      employmentStatus: 'Employed',
      employer: 'Amazon',
      title: 'Project Manager',
      employedDuration: 5,
    },
    propertyOwner: true,
    eSignatureConsent: true,
    disclosureConsent: true,
  },
  projectAddress: {
    street: '2041 Pinetrail St',
    city: 'Las Cruces',
    state: 'NM',
    zip: 88012,
  },
  propertyInfo: {
    ownership: 'Primary Applicant',
    reverseMortgage: false,
    propertyType: 'Single-Family',
    residency: 'Primary',
    yearsAtAddress: 10,
    mortgagePayment: 0,
  },
};
