/* eslint-disable */
if (process.env.REACT_APP_TRACKING_ENV === 'production') {
  (function () {
    var field = 'stellaTrustedFormCertUrl';
    var pingField = 'stellaTrustedFormPingUrl';
    var provideReferrer = false;
    var invertFieldSensitivity = false;
    var tf = document.createElement('script');
    tf.type = 'text/javascript';
    tf.async = true;
    tf.src =
      'http' +
      ('https:' == document.location.protocol ? 's' : '') +
      '://api.trustedform.com/trustedform.js?provide_referrer=' +
      escape(provideReferrer) +
      '&field=' +
      escape(field) +
      '&ping_field=' +
      escape(pingField) +
      '&l=' +
      new Date().getTime() +
      Math.random() +
      '&invert_field_sensitivity=' +
      invertFieldSensitivity;
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(tf, s);
  })();

  const body = document.getElementsByTagName('body')[0];
  var tfNoscript = document.createElement('noscript');
  var tfImg = document.createElement('img');
  tfImg.src = 'https://api.trustedform.com/ns.gif';
  tfNoscript.appendChild(tfImg);
  body.append(tfNoscript);
} else {
  window.trustedForm = { id: '' };
}
