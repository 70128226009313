import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from 'components/Button';
import Header from 'components/Sunlight/Header';
import Result from 'components/Sunlight/Result';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackForwardProgress,
  trackFacebookPixel,
  trackGoogleLead,
} from 'store/actions';
import { withTranslation } from 'react-i18next';
import { VARIATION_OPTIONS } from 'pages/constants';
import { getLocalStorageItem } from 'common/localStorage';
import { generateID } from 'common/utils';
import StyledSunlightFailure from './styled';

class SunlightFailure extends Component {
  constructor(props) {
    super(props);

    const variation = getLocalStorageItem('variation');

    this.state = {
      variation: VARIATION_OPTIONS[variation],
    };
  }

  handleClose = async () => {
    const { history, actions, user, theme } = this.props;

    let step;
    if (user.sunlight.step_from === '/result3') step = '/result3';
    else if (theme.is_calendly_on) step = '/meetingcalendly';
    else step = '/meeting1';

    actions.setUserData({ step });
    await actions.saveSession();

    history.replace(step);
  };

  handleNext = async () => {
    const {
      actions,
      theme,
      user: { sunlight },
    } = this.props;

    let step;
    if (sunlight.step_from !== '/result3') {
      if (theme.is_calendly_on) step = '/meetingcalendly';
      else step = '/meeting1';
    } else step = '/qualify/start';

    actions.setUserData({ step });
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo(step);
  };

  render() {
    const {
      t,
      user: { sunlight },
    } = this.props;
    const { variation } = this.state;
    const result = sunlight?.result;
    let title;
    let text;

    if (sunlight.step_from === '/result3') {
      if (result === 'Pending Review' || result === 'Error') {
        title = "We're still working on it...";
        text =
          'Your request is still being processed. Let’s continue by verifying your home qualifies for solar.';
      } else {
        title = 'We were unable to pre-qualify you.';
        text =
          'Good news! You still may qualify for zero-down financing from other lenders. Let’s continue by verifying your home qualifies for solar.';
      }
    } else if (result === 'Pending Review' || result === 'Error') {
      title = "We're still working on it...";
      text =
        'Your request is still being processed. Please book an appointment below and continue the credit application process with your sales representative';
    } else {
      title = 'We were unable to pre-qualify you.';
      text =
        'Good news! You still may qualify for zero-down financing from other lenders. Book a time to review your quote with a solar expert from our team over the phone.';
    }

    const {
      location: { pathname },
    } = this.props;

    return (
      <>
        <variation.header />

        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <StyledSunlightFailure className="content sunlight-failure-page">
              <div id={generateID('div-header', pathname)} className="mb-32">
                <Header onCloseClick={this.handleClose} />
              </div>

              <div className="main-content">
                <div
                  id={generateID('div-result', pathname)}
                  className="mt-16 mb-32"
                >
                  <Result title={title} content={text} />
                </div>

                <div
                  id={generateID('div-actions', pathname)}
                  className="form-actions"
                >
                  <Button
                    id={generateID('btn-book-or-qualify', pathname)}
                    full
                    onClick={this.handleNext}
                  >
                    {sunlight.step_from === '/result3'
                      ? t('Qualify my home')
                      : t('Book Appointment')}
                  </Button>
                </div>
              </div>
            </StyledSunlightFailure>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user: { data, saving }, ui }) => ({
  user: data,
  theme: ui.theme,
  saving,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      trackFacebookPixel,
      trackGoogleLead,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(SunlightFailure)
);
