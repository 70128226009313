import styled from 'styled-components';
import { pxToRem } from 'common/utils';

const Proposals = styled.div`
  &.proposals-page.content {
    padding: 0;
    background-color: #f5f5f5;

    .form {
      @media screen and (min-width: 768px) {
        max-width: initial;
      }

      .introduction-section {
        padding: 1.5rem 1.75rem 2.25rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .avatar {
          position: relative;
          width: 6rem;
          height: 6rem;
          border-radius: 50%;
          margin-bottom: 1.25rem;
          box-sizing: border-box;
          background-size: cover;
          background-position: center;

          .avatar-status {
            position: absolute;
            width: 1.75rem;
            height: 1.75rem;
            right: 0;
            bottom: 0;
            background: #1bff00;
            border: 0.25rem solid #f9f9f9;
            border-radius: 50%;
          }
        }

        .introduction-text {
          color: #231f20;
          font-family: 'Effra Light';
          font-weight: 400;
          font-size: ${pxToRem(17)};
          line-height: ${pxToRem(22)};
          text-align: center;
        }
      }

      .white-card {
        padding: 1.5rem 0.875rem;

        margin: 0 1.75rem 2.25rem;
        @media screen and (min-width: 768px) {
          margin: 0 7rem 2.25rem;
        }

        display: flex;
        flex-direction: column;
        justify-content: center;

        background: #ffffff;
        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.03);
        border-radius: 0.5rem;
      }

      .transparent-card {
        margin: 0 1.75rem 2.25rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .text-title {
        color: #000;
        font-family: 'Effra Light';
        font-weight: 400;
        font-size: ${pxToRem(17)};
        line-height: ${pxToRem(22)};
        text-align: center;
      }

      .text-value {
        color: #000;
        font-family: 'Effra';
        font-weight: 500;
        font-size: ${pxToRem(42)};
        line-height: ${pxToRem(42)};
        text-align: center;

        sup {
          top: -0.75rem;
          font-size: 1rem;
          line-height: ${pxToRem(42)};
        }

        small {
          margin-left: 0.25rem;
        }
      }

      .text-description {
        color: #58595b;
        font-family: 'Effra Light';
        font-weight: 400;
        font-size: ${pxToRem(12)};
        line-height: ${pxToRem(18)};
        text-align: center;
      }

      .text-disclaimer {
        color: #000;
        font-family: 'Effra Light';
        font-weight: 400;
        font-size: ${pxToRem(12)};
        line-height: ${pxToRem(18)};
        text-align: justify;
      }

      .chart-container {
        display: flex;
        justify-content: center;

        .chart-area {
          width: 34.6875rem;
          max-width: 34.6875rem;
        }
      }

      .divider {
        height: 1px;
        margin-top: 1rem;
        margin-bottom: 1rem;
        background-color: #f1f1f1;
      }

      .list-value {
        display: flex;
        justify-content: center;
      }
    }
  }
`;

export default Proposals;
