import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from 'components/Button';
import TextInput from 'components/Forms/TextInputBestCompany';
import Chat from 'components/Chat';
import Footer from 'components/FooterBestCompany';
import { setUserData, saveSession, trackForwardProgress } from 'store/actions';
import { generateID, getNames } from 'common/utils';
import { withTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import {
  verifyPhoneNumber,
  verifyEmail,
  lookupPhoneNumber,
} from 'common/api.mock';
import { getLocalStorageItem } from 'common/localStorage';
import { VARIATION_OPTIONS } from 'pages/constants';
import StyledBestCompany1, { Wrapper } from './styled';

const formSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
  phone: Yup.string()
    .required('Required')
    .matches(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/),
});

const CustomInput = ({ ...props }) => (
  <InputMask {...props}>{() => <TextInput {...props} />}</InputMask>
);

class GetNotified1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loaded: false,
      errors: null,
      jornaya_lead_id: window.jornaya_leadid_token,
      variation: VARIATION_OPTIONS[getLocalStorageItem('variation')],
    };
  }

  handleBack = () => {
    const { history } = this.props;
    history.replace('/start');
  };

  handleSubmit = async (values, { setFieldError }) => {
    this.setState({ loading: true, errors: null });
    const { email = '', name = '', phone = '' } = values;
    const { first: first_name, last: last_name } = getNames(name);
    const { history, actions, t } = this.props;

    const [phoneRes, emailRes, lookupRes] = await Promise.all([
      verifyPhoneNumber(phone),
      verifyEmail(email),
      lookupPhoneNumber({ phone }),
    ]);

    const errors = [];

    if (!first_name) {
      errors.push(t('Please provide your full name.'));
      setFieldError('name', 'Invalid');
    }
    if (!last_name) {
      errors.push(t('Please provide your last name.'));
      setFieldError('name', 'Invalid');
    }

    if (!phoneRes || !phoneRes.success || !lookupRes) {
      errors.push(
        t('That number does not seem to be a valid mobile phone number.')
      );
      setFieldError('phone', 'Invalid');
    }
    if (!emailRes || !emailRes.success) {
      errors.push(t('That email does not seem to be a valid email.'));
      setFieldError('email', 'Invalid');
    }

    if (errors.length > 0) {
      this.setState({ loading: false, errors });
    } else {
      const { jornaya_lead_id } = this.state;
      actions.setUserData({
        first_name,
        last_name,
        email,
        phone,
        step: '/start',
        jornaya_lead_id,
        trustedform_cert_url: window.trustedForm.id
          ? `https://cert.trustedform.com/${window.trustedForm.id}`
          : '',
      });
      await actions.saveSession();
      actions.trackForwardProgress();
      await history.push('/bestcompany2');
    }
  };

  render() {
    const { theme, t } = this.props;
    const { loading, loaded, errors, variation } = this.state;
    const errorMsg = errors ? errors.join('\n') : '';

    const {
      location: { pathname },
    } = this.props;

    return (
      <Wrapper>
        <variation.header showRestartButton={false} />
        <div className="main-container">
          <div className="main">
            <StyledBestCompany1 className="content best-company-1-page">
              <Formik
                initialValues={{ name: '', email: '', phone: '' }}
                validationSchema={formSchema}
                onSubmit={this.handleSubmit}
                validateOnMount
              >
                {({ isValid, isSubmitting }) => {
                  return (
                    <Form className="form">
                      <div className="form-content">
                        <Chat
                          id={generateID(
                            'chat-sorry-not-in-your-area',
                            pathname
                          )}
                          mode={loaded || loading ? 'loading' : 'timeout'}
                          loading={loading}
                          onShow={() => this.setState({ loaded: true })}
                        >
                          {!errorMsg ? (
                            <div className="mb-24">
                              {t('Sorry, we’re not in your area yet. ')}
                              <b>
                                {t(
                                  'Would you like to connect with one of our trusted solar partners instead? '
                                )}
                              </b>
                            </div>
                          ) : (
                            <div className="mb-24">{errorMsg}</div>
                          )}
                          <div className="mb-16">
                            <Field
                              id={generateID('txt-name', pathname)}
                              name="name"
                              type="text"
                              placeholder={t('First and Last Name')}
                              theme={theme}
                              component={TextInput}
                              autoFocus
                            />
                          </div>
                          <div className="mb-16">
                            <Field
                              id={generateID('txt-email', pathname)}
                              name="email"
                              type="email"
                              placeholder={t('Email')}
                              theme={theme}
                              component={TextInput}
                            />
                          </div>
                          <div className="mb-16">
                            <Field name="phone">
                              {({ field, form }) => (
                                <CustomInput
                                  id={generateID('txt-phone', pathname)}
                                  mask="(999) 999-9999"
                                  type="tel"
                                  placeholder={t('Mobile phone number')}
                                  theme={theme}
                                  value={field.value.phone}
                                  onChange={field.onChange}
                                  field={field}
                                  form={form}
                                />
                              )}
                            </Field>
                          </div>
                        </Chat>
                      </div>
                      {loaded && !loading && (
                        <>
                          <div
                            id={generateID('div-actions', pathname)}
                            className="form-actions"
                          >
                            <Button
                              id={generateID('btn-yes-connect-me', pathname)}
                              type="submit"
                              disabled={!isValid || isSubmitting}
                            >
                              {t('Yes, Connect me!')}
                            </Button>
                          </div>
                          <div
                            id={generateID('div-legal', pathname)}
                            className="form-legal"
                          >
                            <label>
                              <input
                                type="hidden"
                                id="leadid_tcpa_disclosure"
                              />
                              {t(
                                'To help you go solar, we need your approval to contact you. By clicking above, you agree that {{company}} may email and call you about {{company}} products at the phone number you entered above, using pre-recorded messages or an autodialer, even if your number is on a "Do Not Call" list. Consent for calls is optional. You can opt-out anytime.',
                                {
                                  company: theme.company_name,
                                }
                              )}
                            </label>
                          </div>
                        </>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </StyledBestCompany1>
            <Footer />
          </div>
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = ({ ui, user: { data } }) => ({
  user: data,
  theme: ui.theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(GetNotified1)
);
