import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'components/Button';
import { ReactComponent as CheckIcon } from 'assets/images/icon-check.svg';
import DefaultLayout from 'components/DefaultLayout';
import { withTranslation } from 'react-i18next';
import { generateID } from 'common/utils';
import StyledGetNotified2 from './styled';

class GetNotified2 extends Component {
  handleNext = () => {
    const {
      theme: { testimonials_url },
    } = this.props;
    if (!testimonials_url) {
      return;
    }
    window.open(testimonials_url, '_top');
  };

  render() {
    const { t } = this.props;
    const {
      location: { pathname },
    } = this.props;

    return (
      <DefaultLayout>
        <StyledGetNotified2 className="content get-notified-2-page">
          <div className="form">
            <div
              id={generateID('div-thanks', pathname)}
              className="form-content text-center mt-32"
            >
              <div className="mb-32">
                <CheckIcon />
              </div>
              <div className="form-title mb-16" style={{ fontSize: '1.65rem' }}>
                {t('Thank you for your interest!')}
              </div>
              <div className="form-subtext mb-32 pb-32">
                {t('We’ll be in touch when were avalible in your area.')}
              </div>
              <div className="form-subtext mb-16">
                {t('Read experiences of going solar.')}
              </div>
            </div>
            <div
              id={generateID('div-actions', pathname)}
              className="form-actions vertical"
            >
              <Button
                id={generateID('btn-learn-more', pathname)}
                full
                onClick={this.handleNext}
              >
                {t('LEARN MORE')}
              </Button>
            </div>
          </div>
        </StyledGetNotified2>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ ui: { theme } }) => ({
  theme,
});

export default withTranslation('translation')(
  connect(mapStateToProps)(GetNotified2)
);
