import React from 'react';
import cls from 'classnames';
import { isMobile } from 'react-device-detect';
import { ReactComponent as CameraIcon } from 'assets/images/icon-camera-fill.svg';
import { ReactComponent as GreenCheckIcon } from 'assets/images/icon-green-check.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/icon-error.svg';
import { ReactComponent as UploadIcon } from 'assets/images/icon-upload.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import Button from '../../Button';
import StyledFileUpload from './styled';

const FileUpload = ({
  id = null,
  field,
  form,
  label,
  optional,
  status,
  errorMessage,
  fileSelected,
}) => {
  const [t] = useTranslation('translation');
  return (
    <StyledFileUpload
      className={cls({
        'has-error':
          (field &&
            form &&
            form.touched[field.name] &&
            form.errors[field.name]) ||
          status === 'error',
      })}
    >
      <Button id={id ?? null} outline full className="btn-upload">
        {isMobile ? (
          <CameraIcon className="btn-camera" />
        ) : (
          <UploadIcon className="btn-camera" />
        )}
        <span className="label">
          {label}
          {optional && <span className="optional">({t('optional')})</span>}
        </span>

        {status === 'selected' && (
          <GreenCheckIcon className="btn-check btn-check-gray" />
        )}
        {status === 'success' && <GreenCheckIcon className="btn-check" />}
        {status === 'uploading' && (
          <CircularProgress size={25} color="inherit" />
        )}
        {status === 'error' && <ErrorIcon className="icon-error" />}
      </Button>
      <input
        name={field.name}
        type="file"
        onChange={(event) => {
          fileSelected(field.name, form, event);
        }}
      />
      {errorMessage && <p className="error-text">{errorMessage}</p>}
    </StyledFileUpload>
  );
};

export default FileUpload;
