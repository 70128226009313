import { pxToRem } from 'common/utils';
import styled from 'styled-components';

const Expand = styled.div`
  .expand-block-title {
    display: flex;
    align-items: center;
    justify-content: center;

    color: #332892;
    font-family: 'Effra Light';
    font-weight: 400;
    font-size: ${pxToRem(15)};
    line-height: ${pxToRem(18)};

    svg {
      margin-left: 1rem;
      cursor: pointer;
    }
  }

  .expand-block-body {
    margin-top: 1rem;
    color: #332892;
    font-family: 'Effra Light';
    font-size: ${pxToRem(12)};
    line-height: ${pxToRem(18)};
    text-align: justify;
  }
`;

export default Expand;
