import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import Button from 'components/Button';
import { ReactComponent as ThankyouIcon } from 'assets/images/icon-thankyou.svg';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackGoogleLead,
  initializeSession,
  notifyOfNewLead,
} from 'store/actions';
import DefaultLayout from 'components/DefaultLayout';
import {
  removeLocalStorageItem,
  getLocalStorageItem,
} from 'common/localStorage';
import { withTranslation } from 'react-i18next';
import { getPreviewPageName } from 'common/api.mock';
import { generateID } from 'common/utils';
import StyledThankyou from './styled';

class ThankyouD2DVariation extends Component {
  constructor(props) {
    super(props);

    const { user } = this.props;
    if (user.skippedBills) return;
    props.actions.trackGoogleLead('thankyou', props.user.variation);
  }

  handleSave = async () => {
    const previewPage = getPreviewPageName();
    if (previewPage === 'thankyou') return;
    const { actions } = this.props;
    actions.initializeSession();
    removeLocalStorageItem('sessionId');
    actions.notifyOfNewLead();
    actions.navigateTo('/start');
  };

  render() {
    const { user, t, theme } = this.props;
    const previewPage = getPreviewPageName();
    const language = getLocalStorageItem('i18nextLng');
    const caption = /^es\b/.test(language)
      ? theme.spanish_thanks_caption
      : theme.thanks_caption;
    const statement = /^es\b/.test(language)
      ? theme.spanish_thanks_statement
      : theme.thanks_statement;

    const {
      location: { pathname },
    } = this.props;

    return (
      <DefaultLayout showRestartButton={false}>
        <StyledThankyou className="content thankyou-page">
          <div className="form">
            <div
              id={generateID('div-thanks', pathname)}
              className="form-content text-center"
            >
              <div className="mb-32">
                {theme.thanks_icon ? (
                  <img
                    alt="Thanks icon"
                    src={theme.thanks_icon}
                    width={theme.thanks_icon_width}
                    height={theme.thanks_icon_height}
                  />
                ) : (
                  <ThankyouIcon />
                )}
              </div>

              <div className="form-title mb-16">
                {caption || t('Your Quote is Being Prepared!')}
              </div>

              <div className="form-subtext mb-32 px-8">{statement}</div>

              <div className="form-subtext meeting-text mb-16">
                {t('We look forward to speaking with you on')}
              </div>

              <div className="form-subtext meeting-text mb-32 bold black">
                <span>
                  {previewPage === 'thankyou'
                    ? moment().format('dddd, MMMM D')
                    : moment(user.meeting_date, 'YYYY-MM-DD').format(
                        'dddd, MMMM D'
                      )}
                </span>
                <span>{' at '}</span>
                <span>
                  {previewPage === 'thankyou'
                    ? moment().format('h:mm A')
                    : moment(user.meeting_time, 'HH:mm:ss').format('h:mm A')}
                </span>
              </div>

              <Button
                id={generateID('btn-save-and-restart', pathname)}
                full
                outline
                onClick={this.handleSave}
              >
                {t('Save and Restart')}
              </Button>
            </div>
          </div>
        </StyledThankyou>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  theme: state.ui.theme,
  user: state.user.data,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      navigateTo,
      trackGoogleLead,
      initializeSession,
      notifyOfNewLead,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(ThankyouD2DVariation)
);
