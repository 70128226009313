import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Button from 'components/Button';
import TextInput from 'components/Forms/TextInputNRGVariation';
import Chat from 'components/ChatNRGVariation';
import ProgressBar from 'components/ProgressBarNRGVariation';
import {
  verifyPhoneNumber,
  verifyEmail,
  sendVerificationCode,
  lookupPhoneNumber,
} from 'common/api.mock';
import { generateID, getNames } from 'common/utils';
import { withTranslation } from 'react-i18next';
import { getLocalStorageItem } from 'common/localStorage';
import { VARIATION_OPTIONS } from 'pages/constants';
import InputMask from 'react-input-mask';
import BackButton from 'components/BackButtonNRGVariation';
import FixedBottomBar from 'components/FixedBottomBar';
import {
  setUserData,
  saveSession,
  trackFacebookPixel,
  navigateTo,
  trackForwardProgress,
} from '../../../store/actions';
import { Result2 as StyledResult2, Legal } from './styled';

const formSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  // .matches(/^[a-zA-Z]([-']?[a-zA-Z]+)*( [a-zA-Z]([-']?[a-zA-Z]+)*)+$/),
  email: Yup.string().strict(false).trim().email().required('Required'),
  phone: Yup.string().strict(false).trim().required('Required'),
});

const CustomInput = ({ ...props }) => (
  <InputMask {...props}>{() => <TextInput {...props} />}</InputMask>
);

class Result2 extends Component {
  constructor(props) {
    super(props);

    const variation = getLocalStorageItem('variation');
    this.state = {
      loading: false,
      loaded: false,
      errors: null,
      jornaya_lead_id: window.jornaya_leadid_token,
      variation: VARIATION_OPTIONS[variation],
      stage: 0,
    };
  }

  handleBack = () => {
    const { actions } = this.props;
    actions.navigateTo('/result1');
  };

  handleNext = async (values, { setFieldError, setValues }) => {
    let { phone, email, name } = values;
    const { actions, user, theme, t } = this.props;
    const {
      is_phone_verification_required,
      is_strict_phone_number_validation_enabled,
      is_strict_email_validation_enabled,
    } = theme;

    // trim phone, email before submitting
    phone = phone.trim();
    email = email.trim();
    name = name.trim();
    setValues({ phone, email, name });

    const { first: first_name, last: last_name } = getNames(name);

    const { jornaya_lead_id } = this.state;
    this.setState({ loading: true, errors: null });

    let phoneRes;
    let emailRes;
    let lookupRes;

    if (is_strict_phone_number_validation_enabled) {
      phoneRes = await verifyPhoneNumber(phone);
      lookupRes = await lookupPhoneNumber({ phone });
    }
    if (is_strict_email_validation_enabled) {
      emailRes = await verifyEmail(email);
    }
    const errors = [];

    if (!first_name) {
      errors.push(t('Please provide your full name.'));
      setFieldError('name', 'Invalid');
    }
    if (!last_name) {
      errors.push(t('Please provide your last name.'));
      setFieldError('name', 'Invalid');
    }

    if (
      is_strict_phone_number_validation_enabled &&
      !phoneRes?.success &&
      !lookupRes
    ) {
      errors.push(
        t('That number does not seem to be a valid mobile phone number.')
      );
      setFieldError('phone', 'Invalid');
    }
    if (is_strict_email_validation_enabled && !emailRes?.success) {
      errors.push(t('That email does not seem to be a valid email.'));
      setFieldError('email', 'Invalid');
    }
    if (errors.length > 0) {
      this.setState({ loading: false, loaded: true, errors });
    } else {
      actions.setUserData({
        first_name,
        last_name,
        email,
        phone: phoneRes ? phoneRes.data.national_format : phone,
        jornaya_lead_id,
        trustedform_cert_url: window.trustedForm.id
          ? `https://cert.trustedform.com/${window.trustedForm.id}`
          : '',
        step: is_phone_verification_required ? '/phoneverify' : '/result3',
      });

      actions.trackForwardProgress();
      await actions.saveSession();

      const { user: newUser } = this.props;

      if (newUser.is_disqualified) {
        actions.setUserData({
          step: '/result2',
        });
        await actions.saveSession();
        await actions.navigateTo('/disqualify');
      } else {
        const { is_phone_verified } = user;

        if (is_phone_verification_required && !is_phone_verified) {
          const { detail } = await sendVerificationCode({ phone });
          if (!detail) await actions.navigateTo('/result3');
          else await actions.navigateTo('/phoneverify');
        } else {
          await actions.navigateTo('/result3');
        }
      }
    }
  };

  getFullName = () => {
    const { user } = this.props;
    const name = [];
    if (user.first_name) {
      name.push(user.first_name);
    }
    if (user.last_name) {
      name.push(user.last_name);
    }
    return name.join(' ');
  };

  render() {
    const { user, theme, t } = this.props;
    const { loading, loaded, errors, variation, stage } = this.state;
    const errorMsg = errors ? errors.join('\n') : '';

    const {
      location: { pathname },
    } = this.props;

    return (
      <>
        <variation.header />
        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <Formik
              initialValues={{
                name: this.getFullName() || '',
                email: user.email || '',
                phone: user.phone || '',
              }}
              validationSchema={formSchema}
              onSubmit={this.handleNext}
              validateOnMount
            >
              {({ isValid, isSubmitting, submitForm }) => {
                return (
                  <>
                    <StyledResult2 className="content result-2-page">
                      <Form className="form">
                        <div className="form-content">
                          <div
                            id={generateID('div-progress-bar', pathname)}
                            className="mb-32 w100"
                          >
                            <ProgressBar value={100} step={4} />
                          </div>

                          <Chat
                            id={generateID(
                              'chat-unlock-live-pricing',
                              pathname
                            )}
                            mode={loaded || loading ? 'loading' : 'timeout'}
                            loading={loading}
                            onShow={() => this.setState({ stage: 1 })}
                          >
                            <>
                              {!loaded ? (
                                <>
                                  {t(
                                    'Your ballpark estimate is on the next page.'
                                  )}{' '}
                                  {t('Unlock your estimate below.')}
                                </>
                              ) : (
                                errorMsg
                              )}
                            </>
                          </Chat>

                          {stage > 0 && (
                            <>
                              <div
                                id={generateID('div-card', pathname)}
                                className="card"
                              >
                                <Field
                                  id={generateID('txt-name', pathname)}
                                  name="name"
                                  type="text"
                                  placeholder={t('Full Name')}
                                  theme={theme}
                                  component={TextInput}
                                  autoFocus
                                  fullWidth
                                />
                                <Field
                                  id={generateID('txt-email', pathname)}
                                  name="email"
                                  type="email"
                                  placeholder={t('Email Address')}
                                  theme={theme}
                                  component={TextInput}
                                  fullWidth
                                />
                                <Field>
                                  {({ field, form }) => (
                                    <CustomInput
                                      id={generateID('txt-phone', pathname)}
                                      mask="(999) 999-9999"
                                      name="phone"
                                      type="tel"
                                      placeholder={t('Mobile phone number')}
                                      theme={theme}
                                      value={field.value.phone}
                                      onChange={field.onChange}
                                      field={field}
                                      form={form}
                                      fullWidth
                                    />
                                  )}
                                </Field>
                              </div>

                              {!loading && (
                                <Legal
                                  id={generateID('div-legal', pathname)}
                                  className="form-legal"
                                >
                                  <label>
                                    <input
                                      type="hidden"
                                      id="leadid_tcpa_disclosure"
                                    />
                                    {t(
                                      'By clicking the “Submit” button below, you authorize NRG, and its sales and installation partners to contact you for marketing purposes at the telephone, email address or mobile number you entered using automated telephone technology including auto-dialers and text messages, even if your telephone or mobile number is currently listed on any state, federal, or corporate “Do Not Call” list, for the purposes of discussing NRG’s home energy solutions. You also authorize NRG to share your contact information and monthly electricity consumption with our sales and installation partners for the purposes of discussing NRG’s home energy solutions. You are not required to give your consent as a condition of purchase.'
                                    )}
                                  </label>
                                </Legal>
                              )}
                            </>
                          )}
                        </div>

                        {stage > 0 && (
                          <div
                            id={generateID('div-actions', pathname)}
                            className="form-actions"
                          >
                            <BackButton
                              id={generateID('btn-back', pathname)}
                              onClick={this.handleBack}
                            />
                          </div>
                        )}
                      </Form>
                    </StyledResult2>

                    {/* <variation.footer showLegal /> */}

                    <FixedBottomBar
                      id={generateID('div-fixed-bottom-bar', pathname)}
                    >
                      <Button
                        id={generateID('btn-unlock-estimate', pathname)}
                        full
                        onClick={submitForm}
                        disabled={!isValid || isSubmitting}
                      >
                        {t('Unlock Estimate')}
                      </Button>
                    </FixedBottomBar>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui: { theme } }) => ({
  user: data,
  theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      trackFacebookPixel,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Result2)
);
