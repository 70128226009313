import styled from 'styled-components';

const Question = styled.div`
  &.sunlight-question {
    font-weight: 400;
    font-size: 1.375rem;
    line-height: 1.5625rem;
  }
`;

export default Question;
