import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import Button from 'components/Button';
import ButtonSelect from 'components/ButtonSelect';
import MaterialStepper from 'components/MaterialStepper';
import { ReactComponent as BackIcon } from 'assets/images/icon-back.svg';
import { mainTimezones, mainTimezonesEsLocale } from 'common/data';
import { generateID, getTheAvailableTimeFrames, roundDate } from 'common/utils';
import { BUFFER_TIME, GAP_TIME } from 'common/constants';
import Loading from 'components/Loading';
import { VARIATION_OPTIONS } from 'pages/constants';
import { getLocalStorageItem } from 'common/localStorage';
import { withTranslation } from 'react-i18next';
import {
  setUserData,
  saveSession,
  navigateTo,
  appointmentTimes,
  availableCalendarTimes,
  trackForwardProgress,
} from '../../../store/actions';
import StyledMeeting2 from './styled';

class Meeting2NoUtilityBillVariation extends Component {
  constructor(props) {
    super(props);
    const { actions, theme } = props;

    const appLanguage = getLocalStorageItem('i18nextLng');
    const timezones =
      appLanguage === 'es'
        ? mainTimezonesEsLocale.map((t) => ({
            ...t,
            offset: moment.tz.zone(t.value).utcOffset(new Date()),
          }))
        : mainTimezones.map((t) => ({
            ...t,
            offset: moment.tz.zone(t.value).utcOffset(new Date()),
          }));

    const offset = moment.tz.zone(moment.tz.guess(true)).utcOffset(new Date());

    let timezone = timezones.filter((t) => t.offset === offset)[0];
    if (!timezone) {
      // eslint-disable-next-line prefer-destructuring
      timezone = timezones[3];
    }
    timezone = timezone.value;

    let gapTime = theme.appointment_gap_time;
    if (
      theme.appointment_gap_time === null ||
      theme.appointment_gap_time === undefined
    )
      gapTime = GAP_TIME;

    if (theme.is_duplication_meeting_prevented) {
      actions.appointmentTimes();

      if (theme.is_cronofy_connected && theme.is_calendar_active) {
        actions.availableCalendarTimes({
          timezone: props.user.meeting_timezone || timezone,
          meeting_buffer_time: gapTime,
        });
      }
    }

    const variation = getLocalStorageItem('variation');
    this.state = {
      timezone: props.user.meeting_timezone || timezone,
      timezones,
      meeting_time: props.user.meeting_time,
      variation: VARIATION_OPTIONS[variation],
    };
  }

  handleBack = () => {
    const { actions } = this.props;
    actions.navigateTo('/meeting1');
  };

  handleNext = async () => {
    const { actions } = this.props;
    const { timezone, meeting_time } = this.state;
    if (!meeting_time) {
      return;
    }
    actions.setUserData({
      meeting_time,
      meeting_timezone: timezone,
      step: '/meeting3',
    });
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo('/meeting3');
  };

  handleClick = async (value) => {
    this.setState({ meeting_time: value });

    const { actions } = this.props;
    const { timezone } = this.state;
    if (!value) {
      return;
    }
    actions.setUserData({
      meeting_time: value,
      meeting_timezone: timezone,
      step: '/meeting3',
    });
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo('/meeting3');
  };

  handleChange = (event) => {
    this.setState({ timezone: event.target.value });
  };

  getOptions = () => {
    const { theme } = this.props;

    const {
      user: { meeting_date },
      appointments,
      availableTimes,
    } = this.props;
    const { timezone } = this.state;
    const { appointment_timezone } = theme;

    let gapTime = theme.appointment_gap_time;
    if (
      theme.appointment_gap_time === null ||
      theme.appointment_gap_time === undefined
    )
      gapTime = GAP_TIME;

    const curTime = roundDate(
      moment().add(theme.appointment_time_buffer || BUFFER_TIME, 'hours')
    );
    const tz =
      !appointment_timezone ||
      appointment_timezone === 'custom_homeowner_timezone'
        ? 'UTC'
        : timezone;

    const frames = getTheAvailableTimeFrames(
      theme.appointment_schedule,
      {
        start: moment.max(moment.tz(meeting_date, tz).startOf('day'), curTime),
        end: moment.tz(meeting_date, tz).endOf('day'),
      },
      tz
    );

    const options = [];

    frames.forEach((frame) => {
      const start = moment.tz(frame.start, 'YYYY-MM-DDTHH:mm:ss', timezone);
      while (
        start.isBefore(moment.tz(frame.end, 'YYYY-MM-DDTHH:mm:ss', timezone))
      ) {
        let booked = false;
        let isAvailable =
          !theme.is_duplication_meeting_prevented ||
          theme.appointment_method !== 'CRONOFY' ||
          !theme.appointment_cronofy;
        appointments.forEach((time) => {
          if (time) {
            const appointmentStart = moment(time)
              .subtract(gapTime, 'minutes')
              .tz(timezone);
            const appointmentEnd = moment(time)
              .add(theme.appointment_duration, 'minutes')
              .add(gapTime, 'minutes')
              .tz(timezone);
            const startTime = start.clone();
            if (
              (startTime.isSameOrAfter(appointmentStart) &&
                startTime.isBefore(appointmentEnd)) ||
              (appointmentStart.isSameOrAfter(startTime) &&
                appointmentStart.isBefore(
                  startTime.add(theme.appointment_duration, 'minutes')
                ))
            ) {
              booked = true;
            }
          }
        });

        availableTimes.forEach((time) => {
          const appointmentStart = moment(time.start).tz(timezone);
          const appointmentEnd = moment(time.end).tz(timezone);
          const startTime = start.clone();
          if (
            startTime.isSameOrAfter(appointmentStart) &&
            startTime.isSameOrBefore(appointmentEnd)
          ) {
            isAvailable = true;
          }
        });
        if (!booked && isAvailable) {
          options.push({
            label: start.format('h:mm a'),
            value: start.format('HH:mm:00'),
          });
        }
        start.add('30', 'minutes');
      }
    });

    return options;
  };

  render() {
    const { timezone, timezones, meeting_time, variation } = this.state;
    const { loading, t, user } = this.props;

    const {
      location: { pathname },
    } = this.props;

    return (
      <>
        <variation.header />
        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <StyledMeeting2 className="content meeting-2-page custom-stepper">
              <div className="form" id="form-meeting2">
                <div
                  id={generateID('div-material-stepper', pathname)}
                  className="material-progress"
                >
                  <MaterialStepper activeStep={2} variation={user.variation} />
                </div>

                <div
                  id={generateID('div-timepicker-container', pathname)}
                  className="form-content"
                >
                  <div className="form-title mb-16">{t('And what time?')}</div>
                  <div className="mb-16">
                    <select
                      id={generateID('select-timezone', pathname)}
                      value={timezone}
                      onChange={this.handleChange}
                    >
                      {timezones.map((tz) => (
                        <option key={tz.value} value={tz.value}>
                          {tz.name} - (
                          {moment.tz(new Date(), tz.value).format('h:mm a')})
                        </option>
                      ))}
                    </select>
                  </div>

                  {!loading ? (
                    <div
                      id={generateID('div-timepicker', pathname)}
                      className="mb-32"
                    >
                      <ButtonSelect
                        options={this.getOptions()}
                        onChange={this.handleClick}
                        value={meeting_time}
                      />
                    </div>
                  ) : (
                    <div className="mb-32">
                      <Loading />
                    </div>
                  )}
                </div>

                <div
                  id={generateID('div-actions', pathname)}
                  className="form-actions form-footer"
                >
                  <Button
                    id={generateID('btn-back', pathname)}
                    full
                    onClick={this.handleBack}
                  >
                    <BackIcon /> {t('Back')}
                  </Button>
                </div>
              </div>
            </StyledMeeting2>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  user: { data, saving },
  ui: { theme },
  appointment: { appointments, loading, availableTimes },
}) => ({
  user: data,
  saving,
  theme,
  appointments,
  availableTimes,
  loading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      navigateTo,
      trackForwardProgress,
      appointmentTimes,
      availableCalendarTimes,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Meeting2NoUtilityBillVariation)
);
