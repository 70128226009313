import { makeStyles } from '@material-ui/core';

export const useOutlinedTextFieldStyles = makeStyles(() => ({
  outlinedTextField: {
    '& .MuiOutlinedInput-root': {
      fontFamily: 'Effra Light',
      color: (props) => props.theme.color_scheme_font,
    },

    '& .MuiInputBase-input.MuiOutlinedInput-input .MuiOutlinedInput-notchedOutline':
      {
        color: '#ccc',
      },

    '& .MuiInputBase-input.MuiOutlinedInput-input::placeholder': {
      opacity: '100%',
    },

    '& > .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #CCCCCC',
      boxShadow: '0px 0px 0px 2px #CBDAFC',
    },

    '& > .MuiInputBase-root.MuiOutlinedInput-root.Mui-error': {
      color: '#DC3545',
    },

    '& > .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #DC3545',
      boxShadow: '0px 0px 0px #CBDAFC',
    },
  },
}));
