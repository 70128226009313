/* eslint-disable no-restricted-syntax */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import Header from 'components/Header';
import MaterialStepper from 'components/MaterialStepper';
import Button from 'components/Button';
import { ReactComponent as ArrowRightIcon } from 'assets/images/icon-arrow-right.svg';
import { InlineWidget, CalendlyEventListener } from 'react-calendly';
import { mainTimezones } from 'common/data';
import { withTranslation } from 'react-i18next';
import {
  setUserData,
  saveSession,
  navigateTo,
  getEventInfo,
  trackForwardProgress,
  trackFacebookPixel,
} from 'store/actions';
import { generateID } from 'common/utils';
import StyledMeetingCalendly from './styled';
import Loading from '../../../components/Loading';

const sortEvent = (a, b) => {
  if ((a.pooling_type || '') < (b.pooling_type || '')) return -1;
  if ((a.pooling_type || '') > (b.pooling_type || '')) return 1;
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

class MeetingCalendly extends Component {
  constructor(props) {
    super(props);

    const timezones = mainTimezones.map((t) => ({
      ...t,
      offset: moment.tz.zone(t.value).utcOffset(new Date()),
    }));

    const offset = moment.tz.zone(moment.tz.guess(true)).utcOffset(new Date());

    let timezone = timezones.filter((t) => t.offset === offset)[0];
    if (!timezone) {
      // eslint-disable-next-line prefer-destructuring
      timezone = timezones[3];
    }
    timezone = timezone.value;

    const { user, theme } = props;

    const { calendly_questions } = theme;

    const customAnswers = {};
    calendly_questions.forEach((c) => {
      if (c.target_type === 'QZ') {
        const index = (user.answers || []).findIndex(
          (e) => e.question?.id === c.target_key
        );
        if (index !== -1) {
          customAnswers[`a${c.order + 1}`] = user.answers[index].choice?.option;
        }
      }
      if (c.target_type === 'FD') {
        customAnswers[`a${c.order + 1}`] = user[c.target_key];
      }
    });

    // check if only have one meeting event
    // set event_url directly
    let eventUrl = null;
    let eventLength = 0;

    // eslint-disable-next-line
    for (const token of theme.calendly_token) {
      // eslint-disable-next-line
      for (const event of token.calendly_event) {
        eventUrl = event.scheduling_url;
        eventLength++;
      }
    }

    this.state = {
      timezone: props.user.meeting_timezone || timezone,
      meeting_time: props.user.meeting_time,
      meeting_date: props.user.meeting_date,
      widgetLoading: true,
      is_scheduled_meeting: false,
      prefill_data: {
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        name: `${user.first_name} ${user.last_name}`,
        customAnswers,
      },
      eventUrl: eventLength === 1 ? eventUrl : null,
      isSingleEvent: eventLength === 1,
    };
  }

  handleNext = async () => {
    const { actions, user } = this.props;
    const { timezone, meeting_time, meeting_date } = this.state;
    if (!meeting_time || !meeting_date) {
      return;
    }

    const step = 'bills1';

    actions.trackFacebookPixel('bills1', user.variation);

    actions.setUserData({
      meeting_date,
      meeting_time,
      meeting_timezone: timezone,
      is_confirmed: true,
      step,
    });
    await actions.saveSession();
    actions.trackForwardProgress();
    await actions.navigateTo(step);
  };

  filterDate = (date) => {
    const { availableDays } = this.state;
    return availableDays[moment(date).day()];
  };

  onEventTypeViewed = () => {
    this.setState({
      widgetLoading: false,
    });
  };

  onDateAndTimeSelected = () => {
    this.setState({
      meeting_time: null,
      meeting_date: null,
    });
  };

  onEventScheduled = async (event) => {
    const { data } = event;
    const { payload } = data;
    const { actions, user } = this.props;

    this.setState({
      is_scheduled_meeting: true,
    });

    await actions.getEventInfo(user.solar_company, payload.event.uri);
    const { calendly } = this.props;

    const timezones = mainTimezones.map((t) => ({
      ...t,
      offset: moment.tz
        .zone(t.value)
        .utcOffset(new Date(calendly.meeting_time)),
    }));

    const offset = moment.tz
      .zone(moment.tz.guess(true))
      .utcOffset(new Date(calendly.meeting_time));

    let timezone = timezones.filter((t) => t.offset === offset)[0];
    if (!timezone) {
      // eslint-disable-next-line prefer-destructuring
      timezone = timezones[3];
    }
    timezone = timezone.value;
    const start_time = moment(calendly.meeting_time).tz(timezone);
    const meeting_date = start_time.format('YYYY-MM-DD');
    const meeting_time = start_time.format('HH:mm:ss');

    this.setState({
      timezone,
      meeting_time,
      meeting_date,
    });
  };

  handleEventSelect = (eventUrl) => {
    this.setState({
      eventUrl,
    });
  };

  handleEventDeselect = () => {
    this.setState({
      eventUrl: null,
    });
  };

  render() {
    const {
      meeting_time,
      meeting_date,
      widgetLoading,
      is_scheduled_meeting,
      prefill_data,
      eventUrl,
      isSingleEvent,
    } = this.state;
    const { saving, t, theme, user } = this.props;
    const { calendly_token, appointment_calendly_show_all } = theme;

    const {
      location: { pathname },
    } = this.props;

    return (
      <>
        <Header />
        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <StyledMeetingCalendly className="content meeting-1-page custom-stepper">
              <div className="form" id="form-meeting1">
                <div
                  id={generateID('div-material-stepper', pathname)}
                  className="material-progress"
                >
                  <MaterialStepper activeStep={1} variation={user.variation} />
                </div>

                <div
                  id={generateID('div-calendly-container', pathname)}
                  className="form-content text-center"
                >
                  {eventUrl ? (
                    <>
                      {widgetLoading ? (
                        <Loading />
                      ) : (
                        <>
                          {!isSingleEvent && (
                            <div
                              id={generateID(
                                'btn-back-to-event-list',
                                pathname
                              )}
                              className="back-event-list"
                              onClick={this.handleEventDeselect}
                            >
                              Back to event list
                            </div>
                          )}
                        </>
                      )}

                      <div className="form-datepicker mb-16" id="pickerWrapper">
                        <CalendlyEventListener
                          onEventScheduled={this.onEventScheduled}
                          onDateAndTimeSelected={this.onDateAndTimeSelected}
                          onEventTypeViewed={this.onEventTypeViewed}
                          onProfilePageViewed={this.onEventTypeViewed}
                        >
                          <InlineWidget
                            // url={theme.calendly_event_link}
                            url={eventUrl}
                            prefill={prefill_data}
                            styles={{
                              flex: 1,
                            }}
                          />
                        </CalendlyEventListener>
                      </div>
                    </>
                  ) : (
                    <div
                      id={generateID('div-event-list', pathname)}
                      className="event-list"
                    >
                      {calendly_token.map((token) => (
                        <div key={token.id} className="event-group">
                          {/* <div className="event-group-title">
                            Single Events
                          </div> */}
                          {token.calendly_event.sort(sortEvent).map((event) => (
                            <div key={event.id}>
                              {(appointment_calendly_show_all ||
                                event.active) && (
                                <div
                                  id={generateID(`btn-${event.name}`, pathname)}
                                  className="event-item"
                                  onClick={() =>
                                    this.handleEventSelect(event.scheduling_url)
                                  }
                                >
                                  {event.name} (
                                  {event.pooling_type ? 'shared' : 'single'})
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                {is_scheduled_meeting && (
                  <div
                    id={generateID('div-actions', pathname)}
                    className="form-actions form-footer"
                  >
                    <Button
                      id={generateID('btn-continue', pathname)}
                      onClick={this.handleNext}
                      full
                      disabled={!meeting_time || !meeting_date || saving}
                    >
                      {t('Continue')}
                      <ArrowRightIcon />
                    </Button>
                  </div>
                )}
              </div>
            </StyledMeetingCalendly>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  user: { data, saving, calendly },
  ui: { theme },
}) => ({
  user: data,
  saving,
  theme,
  calendly,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      getEventInfo,
      navigateTo,
      trackForwardProgress,
      trackFacebookPixel,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(MeetingCalendly)
);
