import styled from 'styled-components';
import { pxToRem } from 'common/utils';

export const Sungage1 = styled.div`
  &.content.sungage1-page {
    min-height: calc(100% - ${pxToRem(76)});

    @media screen and (max-width: 374px) {
      min-height: calc(100% - 61px);
    }

    @media screen and (min-width: 767px) {
      min-height: calc(100% - ${pxToRem(76)} - 3rem);
    }

    .btn {
      text-transform: none;
      font-weight: normal;
    }
  }
`;

export default Sungage1;
