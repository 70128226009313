import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { verifyZipCode } from 'common/api.mock';
import DefaultLayout from 'components/DefaultLayout';
import Button from 'components/Button';
import ProgressBar from 'components/ProgressBarNRGVariation';
import Chat from 'components/ChatNRGVariation';
import BackButton from 'components/BackButtonNRGVariation';
import * as Yup from 'yup';
import {
  setUserData,
  saveSession,
  navigateTo,
  trackForwardProgress,
} from 'store/actions';
import { withTranslation } from 'react-i18next';
import { Form, Formik, Field } from 'formik';
import TextInput from 'components/Forms/TextInputNRGVariation';
import FixedBottomBar from 'components/FixedBottomBar';
import { generateID } from 'common/utils';
import StyledValidateZipCode from './styled';

const formSchema = Yup.object().shape({
  zip_code: Yup.string().required('Required'),
});

// ValidateZipCode is not good name
// input zip code manually when there is not zipcode for the address.
class ValidateZipCode extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  handleBack = () => {
    const { actions } = this.props;
    actions.navigateTo('/start');
  };

  handleNext = async (values) => {
    const { zip_code } = values;
    const { actions } = this.props;

    let step = '/calculate12';
    if (zip_code) {
      const { status, is_coverage_area } = await verifyZipCode(zip_code);
      step = status ? '/calculate12' : '/getnotified1';
      if (status && is_coverage_area) step = '/calculate2';
    }
    actions.setUserData({
      ...values,
      step,
    });
    await actions.saveSession();
    actions.trackForwardProgress();
    actions.navigateTo(step);
  };

  render() {
    const { show } = this.state;
    const { user, theme, t } = this.props;

    const {
      location: { pathname },
    } = this.props;

    return (
      <DefaultLayout showFooter={false}>
        <Formik
          initialValues={{
            zip_code: user.zip_code || '',
          }}
          validationSchema={formSchema}
          onSubmit={this.handleNext}
          validateOnMount
        >
          {({ isValid, isSubmitting, submitForm }) => {
            return (
              <>
                <StyledValidateZipCode className="content result-2-page">
                  <Form className="form">
                    <div className="form-content">
                      <div
                        id={generateID('div-progress-bar', pathname)}
                        className="mb-32 w-100"
                      >
                        <ProgressBar value={25} step={1} />
                      </div>
                      <Chat
                        id={generateID('chat-your-zipcode', pathname)}
                        onShow={() => this.setState({ show: true })}
                      >
                        {t("What's your zipcode?")}
                      </Chat>
                      {show && (
                        <div
                          id={generateID('div-card', pathname)}
                          className="card"
                        >
                          <Field
                            id={generateID('txt-zipcode', pathname)}
                            name="zip_code"
                            type="text"
                            placeholder={t('Postal Code')}
                            theme={theme}
                            component={TextInput}
                            autoFocus
                            fullWidth
                            validateOnChange
                          />
                        </div>
                      )}
                    </div>
                    <div className="form-actions vertical">
                      <BackButton
                        id={generateID('btn-back', pathname)}
                        onClick={this.handleBack}
                      />
                    </div>
                  </Form>
                </StyledValidateZipCode>

                <FixedBottomBar
                  id={generateID('div-fixed-bottom-bar', pathname)}
                >
                  <Button
                    id={generateID('btn-continue', pathname)}
                    onClick={submitForm}
                    type="submit"
                    full
                    disabled={!isValid || isSubmitting}
                  >
                    {t('Continue')}
                  </Button>
                </FixedBottomBar>
              </>
            );
          }}
        </Formik>
      </DefaultLayout>
    );
  }
}

const mapStateToProps = ({ user: { data, saving }, ui: { theme } }) => ({
  user: data,
  saving,
  theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
      navigateTo,
      trackForwardProgress,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(ValidateZipCode)
);
