import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import StyledResult from './styled';

const Result = ({ title, content = '' }) => {
  const [t] = useTranslation('translation');

  return (
    <StyledResult className="sungage-result">
      <div className="sungage-result-title mb-32">{t(title)}</div>

      <div className="sungage-result-content">{t(content)}</div>
    </StyledResult>
  );
};

Result.defaultProps = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
};

export default Result;
