import styled from 'styled-components';
import { pxToRem } from 'common/utils';

const Thankyou = styled.div`
  .form-title {
    font-family: StellaFont, Arial, Helvetica, sans-serif;
    font-size: 1.875rem;
    color: ${(props) => props.theme.color_scheme_dark};
  }

  .form-subtext {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 1rem;
    color: #666;
  }

  .form-subtext.meeting-text {
    font-size: ${pxToRem(17)};
  }

  .form-subtext.black {
    color: #000;
  }

  .form-content {
    @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

export default Thankyou;
