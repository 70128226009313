import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import StyledProgressBar from './styled';

const ProgressBar = ({ hideLabel, value, step, total }) => {
  const [t] = useTranslation('translation');
  let labelStyle = {
    textAlign: 'left',
    paddingLeft: `${value}%`,
  };
  if (value >= 25) {
    labelStyle = {
      textAlign: 'right',
      paddingRight: `${100 - value}%`,
    };
  }

  return (
    <StyledProgressBar className="progress-bar">
      {!hideLabel && (
        <div className="progress-bar-label" style={labelStyle}>
          {t('(Step {{step}} of {{total}})', { step, total })}
        </div>
      )}
      <div className="progress-bar-bg">
        <div
          className="progress-bar-active"
          style={{ width: `${value}%` }}
        ></div>
      </div>
    </StyledProgressBar>
  );
};

ProgressBar.propTypes = {
  hideLabel: PropTypes.bool,
  value: PropTypes.number,
  step: PropTypes.number,
  total: PropTypes.number,
};

ProgressBar.defaultProps = {
  hideLabel: false,
  value: 0,
  step: 1,
  total: 4,
};

export default ProgressBar;
