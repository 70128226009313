import React from 'react';
import cls from 'classnames';
import StyledMaterialStepper from './styled.js';

const labels = ['Estimate', 'Get Qualified', 'Quote'];

const MaterialStepper = ({ value, step }) => {
  return (
    <StyledMaterialStepper className="material-stepper">
      <div className="step-label-container">
        {labels.map((label, index) => (
          <div
            key={index}
            className={cls('step-label', {
              'step-label-inactive': index >= step,
            })}
          >
            {label}
          </div>
        ))}
      </div>
      <div className="step-bar">
        <div className="step-active-bar" style={{ width: `${value}%` }} />
      </div>
    </StyledMaterialStepper>
  );
};

export default MaterialStepper;
