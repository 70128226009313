import { pxToRem } from 'common/utils';
import styled from 'styled-components';

const EnvironmentItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Roboto, Arial, Helvetica, sans-serif;

  .environment-item-icon {
    display: inline-flex;
    height: 40px;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .environment-item-text {
    margin-bottom: 2rem;
    color: #000;
    font-family: 'Effra';
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  .environment-item-subtext {
    color: #000;
    font-family: 'Effra Light';
    font-weight: 400;
    font-size: ${pxToRem(15)};
    line-height: ${pxToRem(18)};
    text-align: center;
  }
`;

export default EnvironmentItem;
