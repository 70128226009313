import styled from 'styled-components';
import { pxToRem } from 'common/utils';

export const Sunlight1 = styled.div`
  &.content.sunlight1-page {
    min-height: calc(100% - ${pxToRem(76)});

    @media screen and (max-width: 374px) {
      min-height: calc(100% - 61px);
    }

    @media screen and (min-width: 767px) {
      min-height: calc(100% - ${pxToRem(76)} - 3rem);
    }

    .btn {
      text-transform: none;
      font-weight: normal;
    }

    .sunlight-income-disclosure {
      margin-top: 2rem;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.2px;
    }
  }
`;

export default Sunlight1;
