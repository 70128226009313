import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

function usePageViews() {
  const location = useLocation();
  const google_analytics_site_tag = useSelector(
    (state) => state.ui.theme?.google_analytics_site_tag
  );

  React.useEffect(() => {
    window.gtag('event', 'stellaTracker.set', {
      page: window.location.pathname,
    });
    window.gtag('event', 'page_view');
    if (google_analytics_site_tag) {
      window.gtag('event', 'stellaTracker.set', {
        page: window.location.pathname,
        send_to: google_analytics_site_tag,
      });
      window.gtag('event', 'page_view', {
        send_to: google_analytics_site_tag,
      });
    }
  }, [location, google_analytics_site_tag]);
}

export function PageView() {
  usePageViews();
  return null;
}
