import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InputMask from 'react-input-mask';
import { withTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Button from 'components/Button';
import TextInput from 'components/Forms/TextInput2';
import Header from 'components/Sungage/Header';
import Stage from 'components/Sungage/Stage';
import Question from 'components/Sungage/Question';
import { setUserData, saveSession } from 'store/actions';
import { VARIATION_OPTIONS } from 'pages/constants';
import { getLocalStorageItem } from 'common/localStorage';
import { generateID } from 'common/utils';
import StyledSungage3 from './styled';

const CustomInput = ({ maskChar, ...props }) => (
  <InputMask maskChar={maskChar} {...props}>
    {() => <TextInput {...props} />}
  </InputMask>
);

const formSchema = Yup.object().shape({
  month: Yup.string().strict(false).trim().required('Required'),
  day: Yup.string().strict(false).trim().required('Required'),
  year: Yup.string().strict(false).trim().required('Required'),
  socialNumber: Yup.string()
    .strict(false)
    .trim()
    .matches(/\d\d\d\d/i)
    .required('Required'),
});

class Sungage3 extends Component {
  constructor(props) {
    super(props);

    const variation = getLocalStorageItem('variation');

    this.state = {
      variation: VARIATION_OPTIONS[variation],
      errors: null,
    };
  }

  handleClose = async () => {
    const { history, actions, user } = this.props;

    let step;
    if (user.sungage?.prequalification_skipped) step = '/result3';
    else step = '/qualify/end';

    actions.setUserData({ step });
    await actions.saveSession();

    history.replace(step);
  };

  handleBack = async () => {
    const { history, actions } = this.props;

    actions.setUserData({
      step: '/prequalify/sungage2',
    });
    await actions.saveSession();

    history.replace('/prequalify/sungage2');
  };

  handleNext = async (values, { setFieldError }) => {
    const { history, actions } = this.props;

    const birthday = new Date(`${values.month}-${values.day}-${values.year}`);

    // eslint-disable-next-line no-restricted-globals
    if (!(birthday instanceof Date && !isNaN(birthday))) {
      setFieldError('year', 'Invalid');
      setFieldError('month', 'Invalid');
      setFieldError('day', 'Invalid');
      const errors = [];
      errors.push('Birthday does not seem to be a valid date.');
      this.setState({ errors });
      return;
    }

    actions.setUserData({
      step: '/prequalify/sungage4',
      sungage: {
        birthday: moment(birthday).format('YYYY-MM-DD'),
        ssn_last_four: values.socialNumber,
      },
    });
    await actions.saveSession();

    history.push('/prequalify/sungage4');
  };

  handleMonthChange = (setFieldValue) => (e) => {
    this.setState({ errors: null });
    setFieldValue('month', e.target.value);
  };

  handleDayChange = (setFieldValue) => (e) => {
    this.setState({ errors: null });
    setFieldValue('day', e.target.value);
  };

  handleYearChange = (setFieldValue) => (e) => {
    this.setState({ errors: null });
    setFieldValue('year', e.target.value);
  };

  render() {
    const { theme, user, t } = this.props;
    const { variation, errors } = this.state;
    const errMsg = errors ? errors.join('\n') : '';

    let year = '';
    let month = '';
    let day = '';

    if (user.sungage?.birthday) {
      const birth = new Date(user.sungage?.birthday);
      year = birth.getFullYear().toString(10);
      month = (birth.getMonth() + 1).toString(10);
      day = birth.getDate().toString(10);
    }

    const {
      location: { pathname },
    } = this.props;

    return (
      <>
        <variation.header />

        <div className="main-container" style={{ overflow: 'auto' }}>
          <div className="main">
            <StyledSungage3 className="content sungage3-page">
              <div id={generateID('div-header', pathname)} className="mb-32">
                <Header onCloseClick={this.handleClose} />
              </div>

              <div id={generateID('div-stage', pathname)} className="mb-16">
                <Stage stage={3} onBackClick={this.handleBack} />
              </div>

              <Formik
                initialValues={{
                  month: month || '',
                  day: day || '',
                  year: year || '',
                  socialNumber: user.sungage?.ssn_last_four || '',
                }}
                validationSchema={formSchema}
                onSubmit={this.handleNext}
                validateOnMount
              >
                {({ isValid, isSubmitting, setFieldValue }) => {
                  return (
                    <Form className="form">
                      <div className="form-content">
                        <div
                          id={generateID('div-question', pathname)}
                          className="mb-16"
                        >
                          <Question>
                            {t('What is your date of birth?')}
                          </Question>
                        </div>

                        <div
                          id={generateID('div-answer', pathname)}
                          className="birth-container mb-16"
                        >
                          <Field>
                            {({ field, form }) => (
                              <CustomInput
                                id={generateID('txt-month', pathname)}
                                mask="99"
                                maskChar=""
                                name="month"
                                placeholder="10"
                                theme={theme}
                                value={field.value.month}
                                onChange={this.handleMonthChange(setFieldValue)}
                                field={field}
                                form={form}
                                autoFocus
                              />
                            )}
                          </Field>

                          <Field>
                            {({ field, form }) => (
                              <CustomInput
                                id={generateID('txt-day', pathname)}
                                mask="99"
                                maskChar=""
                                name="day"
                                placeholder="23"
                                theme={theme}
                                value={field.value.day}
                                onChange={this.handleDayChange(setFieldValue)}
                                field={field}
                                form={form}
                              />
                            )}
                          </Field>

                          <Field>
                            {({ field, form }) => (
                              <CustomInput
                                id={generateID('txt-year', pathname)}
                                mask="9999"
                                maskChar=""
                                name="year"
                                placeholder="1983"
                                theme={theme}
                                value={field.value.year}
                                onChange={this.handleYearChange(setFieldValue)}
                                field={field}
                                form={form}
                              />
                            )}
                          </Field>
                        </div>

                        <div className="error-message mb-32">{errMsg}</div>

                        <div
                          id={generateID('div-question2', pathname)}
                          className="mb-16"
                        >
                          <Question>
                            {t(
                              'Last four digits of your social security number?'
                            )}
                          </Question>
                        </div>

                        <div className="social-container mb-32">
                          <Field>
                            {({ field, form }) => (
                              <CustomInput
                                id={generateID('txt-social-number', pathname)}
                                mask="9999"
                                name="socialNumber"
                                theme={theme}
                                placeholder="1234"
                                value={field.value.socialNumber}
                                onChange={field.onChange}
                                field={field}
                                form={form}
                              />
                            )}
                          </Field>
                        </div>

                        <div
                          id={generateID('div-actions', pathname)}
                          className="form-actions"
                        >
                          <Button
                            id={generateID('btn-next', pathname)}
                            type="submit"
                            disabled={!isValid || isSubmitting}
                            full
                          >
                            {t('Next')}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </StyledSungage3>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ user: { data }, ui }) => ({
  user: data,
  theme: ui.theme,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setUserData,
      saveSession,
    },
    dispatch
  ),
});

export default withTranslation('translation')(
  connect(mapStateToProps, mapDispatchToProps)(Sungage3)
);
