import styled from 'styled-components';

const InactiveCompany = styled.div`
  &.content.inactive-company-page {
    padding-bottom: 0;

    @media screen and (min-width: 768px) {
      .form {
        max-width: initial;

        .form-content,
        .form-actions {
          max-width: 555px;
          width: 100%;
          margin: 0 auto;
        }
      }
    }
  }

  .form-legal {
    font-size: 0.5625rem;
    color: #666666;
    margin: 1.25rem 0;
    text-align: justify;

    @media screen and (min-width: 768px) {
      font-size: 0.75rem;
    }

    label {
      margin-bottom: 0;
    }
  }

  .form-title {
    font-size: 2.5rem;
    color: ${(props) => props.theme.color_scheme_dark};
  }

  .form-subtitle {
    font-size: 1.875rem;
    color: ${(props) => props.theme.color_scheme_dark};
  }

  .form-subtext {
    font-size: 1rem;
    line-height: 1.2;
    color: #666;
  }

  .icon {
    height: 4.5rem;
  }

  .text-center {
    text-align: center;
  }
`;

export default InactiveCompany;
