import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLocalStorageItem } from 'common/localStorage';
import StyledAvatar from './styled';

const Avatar = ({ size, theme }) => {
  const language = getLocalStorageItem('i18nextLng');
  const avatar = /^es\b/.test(language)
    ? theme.spanish_ai_avatar
    : theme.ai_avatar;
  return (
    <StyledAvatar
      className="avatar"
      size={size}
      style={{
        backgroundImage: `url(${avatar})`,
      }}
    />
  );
};

Avatar.propTypes = {
  size: PropTypes.number,
};

Avatar.defaultProps = {
  size: 41,
};

const mapStateToProps = ({ ui: { theme } }) => ({
  theme,
});

export default connect(mapStateToProps)(Avatar);
