import React from 'react';
import { generateTileImages } from 'common/utils';
import imgMarker from 'assets/images/marker.png';
import './style.scss';

const SunroofImage = ({ lat, lng }) => {
  if (!lat || !lng) {
    return null;
  }

  const { images, pinX, pinY } = generateTileImages(lat, lng);
  const imageTags = [];

  for (let i = -1; i <= 1; i++) {
    for (let j = -1; j <= 1; j++) {
      imageTags.push(
        <image
          key={`${i} ${j}`}
          width="256"
          height="256"
          x={j * 256 - j - 1}
          y={i * 256 - i - 1}
          href={images[(i + 1) * 3 + j + 1]}
          preserveAspectRatio="xMidYMid slice"
        />
      );
    }
  }

  return (
    <div className="sunroof-image-container">
      <div className="sunroof-image-main">
        <svg viewBox="0 0 512 200">
          <g transform={`translate(${256 - pinX} ${100 - pinY})`}>
            {imageTags}
          </g>
        </svg>
        <img src={imgMarker} alt="marker" className="sunroof-image-marker" />
      </div>
    </div>
  );
};

export default SunroofImage;
