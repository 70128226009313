import styled from 'styled-components';
import { pxToRem } from 'common/utils';

const Meeting3 = styled.div`
  #form-meeting3 {
    @media screen and (min-width: 768px) {
      max-width: none;
      align-items: center;
      .form-content {
        width: 100%;
        max-width: ${pxToRem(555)};
      }
      .form-footer {
        width: 100%;
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
        padding-left: calc(50% - ${pxToRem(555 / 2)} + ${pxToRem(20)});
        padding-right: calc(50% - ${pxToRem(555 / 2)} + ${pxToRem(20)});
      }
    }
  }

  .form-title {
    font-family: StellaFont, Arial, Helvetica, sans-serif;
    font-size: 1.875rem;
    color: ${(props) => props.theme.color_scheme_dark};
    text-align: center;
  }

  .form-subtext {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 1rem;
    color: #666;
    & > span {
      display: inline-block;
    }
  }
  .form-error {
    color: red;
    font-size: 0.875rem;
  }

  .modal-btns-group {
    display: flex;
    flex-direction: row;

    button {
      font-size: 14px;
    }
  }
`;

export default Meeting3;
