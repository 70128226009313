import React from 'react';
import StyledCheckbox from './styled';

const Checkbox = ({ id = null, onChange, ...rest }) => (
  <StyledCheckbox className="checkbox">
    <input id={id ?? null} type="checkbox" onChange={onChange} {...rest} />
    <span className="checkmark"></span>
  </StyledCheckbox>
);

export default Checkbox;
