import styled from 'styled-components';
import { pxToRem } from 'common/utils';

export const Result2 = styled.div`
  &.content.result-2-page {
    min-height: calc(100% - ${pxToRem(76)});

    @media screen and (max-width: 374px) {
      min-height: calc(100% - 61px);
    }

    @media screen and (min-width: 767px) {
      min-height: calc(100% - ${pxToRem(76)} - 3rem);
    }

    .message {
      line-height: 30px;
    }

    .card {
      margin-top: 2.25rem;
      margin-bottom: 2.25rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .form-actions {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
`;

export const Legal = styled.div`
  &.form-legal {
    margin: 0 0 1.25rem 0;
    color: #666666;
    font-family: 'Effra Light';
    font-size: 12px;
    line-height: 20px;
    text-align: justify;

    @media screen and (max-width: 767px) {
      margin: 0 0 1.25rem 0;
      font-size: 12px;
      line-height: 20px;
    }

    label {
      margin-bottom: 0;
    }
  }
`;
