import styled from 'styled-components';

const Bills1 = styled.div`
  .form-title {
    font-family: StellaFont, Arial, Helvetica, sans-serif;
    font-size: 1.6rem;
    color: ${(props) => props.theme.color_scheme_dark};
  }

  .form-subtitle {
    font-family: StellaFont, Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    color: ${(props) => props.theme.color_scheme_dark};
  }

  .form-subtext {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 1rem;
    color: #666;
  }

  .camera-btn {
    position: relative;

    svg {
      position: absolute;
      left: 32px;
      top: 12px;
    }
  }

  .icon {
    height: 4.5rem;
  }

  .note {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 0.75rem;
    color: #666;
    font-weight: 700;
  }

  .flex-wrapper {
    display: flex;
    flex-flow: column;

    .link-container {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 10px;

      a {
        color: ${(props) => props.theme.color_scheme_dark};
      }
    }
  }
`;

export default Bills1;
