import styled from 'styled-components';

const TypingIndicator = styled.div`
  background-color: ${(props) => props.theme.color_scheme_neutral};
  will-change: transform;
  width: auto;
  border-radius: 50px;
  padding: 1rem;
  display: table;
  position: relative;
  animation: 2s bulge infinite ease-out;
  flex-shrink: 0;

  &::before,
  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: -2px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.color_scheme_neutral};
  }

  &::after {
    height: 10px;
    width: 10px;
    left: -10px;
    bottom: -10px;
  }

  span {
    background-color: #666;
    height: 10px;
    width: 10px;
    float: left;
    margin: 0 0.125rem;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
  }
`;

export default TypingIndicator;
